export const PFCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    element: 'lightning',
    current_app_rate: 55.33,
    prev_app_rate: 58.48,
    current_avg_score: 32395,
    prev_avg_score: 31124,
    current_dev: 4438,
    rarity: 'Limited 5*'
  },
  {
    char: 'argenti',
    name: 'Argenti',
    element: 'physical',
    current_app_rate: 4.03,
    prev_app_rate: 17.12,
    current_avg_score: 29285,
    prev_avg_score: 39622,
    current_dev: 4540,
    rarity: 'Limited 5*'
  },
  {
    char: 'arlan',
    name: 'Arlan',
    element: 'lightning',
    current_app_rate: 0,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'asta',
    name: 'Asta',
    element: 'fire',
    current_app_rate: 0.86,
    prev_app_rate: 1.65,
    current_avg_score: 27726,
    prev_avg_score: 28163,
    current_dev: 4785,
    rarity: '4*'
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    element: 'imaginary',
    current_app_rate: 37.3,
    prev_app_rate: 37.53,
    current_avg_score: 32352,
    prev_avg_score: 33524,
    current_dev: 4630,
    rarity: 'Limited 5*'
  },
  {
    char: 'bailu',
    name: 'Bailu',
    element: 'lightning',
    current_app_rate: 1,
    prev_app_rate: 1.35,
    current_avg_score: 28531,
    prev_avg_score: 32476,
    current_dev: 4920,
    rarity: 'Standard 5*'
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    element: 'wind',
    current_app_rate: 21.39,
    prev_app_rate: 29.83,
    current_avg_score: 30785,
    prev_avg_score: 30831,
    current_dev: 3799,
    rarity: 'Limited 5*'
  },
  {
    char: 'blade',
    name: 'Blade',
    element: 'wind',
    current_app_rate: 1.05,
    prev_app_rate: 4.85,
    current_avg_score: 31113,
    prev_avg_score: 34843,
    current_dev: 4692,
    rarity: 'Limited 5*'
  },
  {
    char: 'boothill',
    name: 'Boothill',
    element: 'physical',
    current_app_rate: 0.15,
    prev_app_rate: 0.28,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'bronya',
    name: 'Bronya',
    element: 'wind',
    current_app_rate: 4.44,
    prev_app_rate: 9.6,
    current_avg_score: 27506,
    prev_avg_score: 36340,
    current_dev: 5661,
    rarity: 'Standard 5*'
  },
  {
    char: 'clara',
    name: 'Clara',
    element: 'physical',
    current_app_rate: 3.45,
    prev_app_rate: 21.46,
    current_avg_score: 28951,
    prev_avg_score: 36468,
    current_dev: 4448,
    rarity: 'Standard 5*'
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    element: 'wind',
    current_app_rate: 0,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    element: 'imaginary',
    current_app_rate: 0.24,
    prev_app_rate: 0.45,
    current_avg_score: 0,
    prev_avg_score: 24292,
    current_dev: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    element: 'wind',
    current_app_rate: 14.62,
    prev_app_rate: 14.62,
    current_avg_score: 30866,
    prev_avg_score: 30866,
    current_dev: 4998,
    rarity: 'Limited 5*'
  },
  {
    char: 'firefly',
    name: 'Firefly',
    element: 'fire',
    current_app_rate: 59.26,
    prev_app_rate: 20.8,
    current_avg_score: 32216,
    prev_avg_score: 25716,
    current_dev: 4380,
    rarity: 'Limited 5*'
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    element: 'quantum',
    current_app_rate: 40.14,
    prev_app_rate: 45.58,
    current_avg_score: 31464,
    prev_avg_score: 34491,
    current_dev: 4751,
    rarity: 'Limited 5*'
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    element: 'fire',
    current_app_rate: 59.01,
    prev_app_rate: 41.63,
    current_avg_score: 31070,
    prev_avg_score: 29354,
    current_dev: 4062,
    rarity: '4*'
  },
  {
    char: 'gepard',
    name: 'Gepard',
    element: 'ice',
    current_app_rate: 2.67,
    prev_app_rate: 3.64,
    current_avg_score: 31434,
    prev_avg_score: 30697,
    current_dev: 3219,
    rarity: 'Standard 5*'
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    element: 'fire',
    current_app_rate: 0.7,
    prev_app_rate: 3.95,
    current_avg_score: 25799,
    prev_avg_score: 26806,
    current_dev: 3333,
    rarity: '4*'
  },
  {
    char: 'hanya',
    name: 'Hanya',
    element: 'physical',
    current_app_rate: 0.11,
    prev_app_rate: 1.17,
    current_avg_score: 0,
    prev_avg_score: 39241,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'herta',
    name: 'Herta',
    element: 'ice',
    current_app_rate: 32.38,
    prev_app_rate: 56.22,
    current_avg_score: 31502,
    prev_avg_score: 36288,
    current_dev: 5037,
    rarity: '4*'
  },
  {
    char: 'himeko',
    name: 'Himeko',
    element: 'fire',
    current_app_rate: 43.28,
    prev_app_rate: 38.7,
    current_avg_score: 32809,
    prev_avg_score: 29626,
    current_dev: 4888,
    rarity: 'Standard 5*'
  },
  {
    char: 'hook',
    name: 'Hook',
    element: 'fire',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    element: 'wind',
    current_app_rate: 20.4,
    prev_app_rate: 33.55,
    current_avg_score: 30131,
    prev_avg_score: 37905,
    current_dev: 4501,
    rarity: 'Limited 5*'
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    element: 'imaginary',
    current_app_rate: 1.55,
    prev_app_rate: 1.67,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'jade',
    name: 'Jade',
    element: 'quantum',
    current_app_rate: 22.17,
    prev_app_rate: 26.68,
    current_avg_score: 33671,
    prev_avg_score: 39162,
    current_dev: 4230,
    rarity: 'Limited 5*'
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    element: 'fire',
    current_app_rate: 29.02,
    prev_app_rate: 12.95,
    current_avg_score: 34473,
    prev_avg_score: 33894,
    current_dev: 3781,
    rarity: 'Limited 5*'
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    element: 'lightning',
    current_app_rate: 6.03,
    prev_app_rate: 6.33,
    current_avg_score: 28802,
    prev_avg_score: 25462,
    current_dev: 4749,
    rarity: 'Limited 5*'
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    element: 'ice',
    current_app_rate: 0.19,
    prev_app_rate: 1.63,
    current_avg_score: 0,
    prev_avg_score: 30372,
    current_dev: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'kafka',
    name: 'Kafka',
    element: 'lightning',
    current_app_rate: 28.4,
    prev_app_rate: 35.25,
    current_avg_score: 31364,
    prev_avg_score: 30873,
    current_dev: 4283,
    rarity: 'Limited 5*'
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    element: 'fire',
    current_app_rate: 10.01,
    prev_app_rate: 10.01,
    current_avg_score: 36985,
    prev_avg_score: 36985,
    current_dev: 3787,
    rarity: 'Limited 5*'
  },
  {
    char: 'luka',
    name: 'Luka',
    element: 'physical',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'luocha',
    name: 'Luocha',
    element: 'imaginary',
    current_app_rate: 9.17,
    prev_app_rate: 16.32,
    current_avg_score: 29901,
    prev_avg_score: 34578,
    current_dev: 4329,
    rarity: 'Limited 5*'
  },
  {
    char: 'lynx',
    name: 'Lynx',
    element: 'quantum',
    current_app_rate: 0.91,
    prev_app_rate: 3.84,
    current_avg_score: 29896,
    prev_avg_score: 34434,
    current_dev: 4839,
    rarity: '4*'
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    element: 'ice',
    current_app_rate: 0.1,
    prev_app_rate: 0.41,
    current_avg_score: 0,
    prev_avg_score: 33527,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    element: 'imaginary',
    current_app_rate: 6.31,
    prev_app_rate: 3.35,
    current_avg_score: 30203,
    prev_avg_score: 32295,
    current_dev: 5172,
    rarity: '4*'
  },
  {
    char: 'misha',
    name: 'Misha',
    element: 'ice',
    current_app_rate: 0,
    prev_app_rate: 0.02,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'moze',
    name: 'Moze',
    element: 'lightning',
    current_app_rate: 0.19,
    prev_app_rate: 0.19,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'natasha',
    name: 'Natasha',
    element: 'physical',
    current_app_rate: 0.1,
    prev_app_rate: 0.22,
    current_avg_score: 0,
    prev_avg_score: 36881,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'pela',
    name: 'Pela',
    element: 'ice',
    current_app_rate: 15.87,
    prev_app_rate: 24.89,
    current_avg_score: 32378,
    prev_avg_score: 30186,
    current_dev: 4757,
    rarity: '4*'
  },
  {
    char: 'qingque',
    name: 'Qingque',
    element: 'quantum',
    current_app_rate: 0.19,
    prev_app_rate: 0.28,
    current_avg_score: 0,
    prev_avg_score: 29292,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'robin',
    name: 'Robin',
    element: 'physical',
    current_app_rate: 52.7,
    prev_app_rate: 44.32,
    current_avg_score: 31739,
    prev_avg_score: 38597,
    current_dev: 4882,
    rarity: 'Limited 5*'
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    element: 'ice',
    current_app_rate: 84.42,
    prev_app_rate: 65.07,
    current_avg_score: 32311,
    prev_avg_score: 31126,
    current_dev: 4514,
    rarity: 'Limited 5*'
  },
  {
    char: 'sampo',
    name: 'Sampo',
    element: 'wind',
    current_app_rate: 0.12,
    prev_app_rate: 0.38,
    current_avg_score: 0,
    prev_avg_score: 26839,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'seele',
    name: 'Seele',
    element: 'quantum',
    current_app_rate: 1.63,
    prev_app_rate: 3.51,
    current_avg_score: 27391,
    prev_avg_score: 34549,
    current_dev: 4347,
    rarity: 'Limited 5*'
  },
  {
    char: 'serval',
    name: 'Serval',
    element: 'lightning',
    current_app_rate: 0.96,
    prev_app_rate: 1.98,
    current_avg_score: 24992,
    prev_avg_score: 27242,
    current_dev: 4240,
    rarity: '4*'
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    element: 'quantum',
    current_app_rate: 3.16,
    prev_app_rate: 6.43,
    current_avg_score: 28541,
    prev_avg_score: 26833,
    current_dev: 4547,
    rarity: 'Limited 5*'
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    element: 'quantum',
    current_app_rate: 27.22,
    prev_app_rate: 38.12,
    current_avg_score: 29221,
    prev_avg_score: 36361,
    current_dev: 4952,
    rarity: 'Limited 5*'
  },
  {
    char: 'sushang',
    name: 'Sushang',
    element: 'physical',
    current_app_rate: 0,
    prev_app_rate: 0.03,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    element: 'lightning',
    current_app_rate: 11.1,
    prev_app_rate: 30.74,
    current_avg_score: 28864,
    prev_avg_score: 37309,
    current_dev: 4717,
    rarity: '4*'
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    element: 'fire',
    current_app_rate: 6.14,
    prev_app_rate: 5.79,
    current_avg_score: 30361,
    prev_avg_score: 29715,
    current_dev: 5037,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    element: 'fire',
    current_app_rate: 0.3,
    prev_app_rate: 0.67,
    current_avg_score: 29764,
    prev_avg_score: 30479,
    current_dev: 5936,
    rarity: '4*'
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    element: 'imaginary',
    current_app_rate: 68.96,
    prev_app_rate: 17.17,
    current_avg_score: 32188,
    prev_avg_score: 24981,
    current_dev: 4494,
    rarity: '4*'
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    element: 'physical',
    current_app_rate: 0,
    prev_app_rate: 0.02,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'welt',
    name: 'Welt',
    element: 'imaginary',
    current_app_rate: 0.37,
    prev_app_rate: 0.65,
    current_avg_score: 0,
    prev_avg_score: 26016,
    current_dev: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    element: 'quantum',
    current_app_rate: 0.75,
    prev_app_rate: 1.15,
    current_avg_score: 25679,
    prev_avg_score: 31673,
    current_dev: 3142,
    rarity: '4*'
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    element: 'ice',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'yukong',
    name: 'Yukong',
    element: 'imaginary',
    current_app_rate: 0.03,
    prev_app_rate: 0.12,
    current_avg_score: 0,
    prev_avg_score: 0,
    current_dev: 0,
    rarity: '4*'
  },
  {
    char: 'yunli',
    name: 'Yunli',
    element: 'physical',
    current_app_rate: 9.96,
    prev_app_rate: 22,
    current_avg_score: 31118,
    prev_avg_score: 38596,
    current_dev: 4527,
    rarity: 'Limited 5*'
  }
];
