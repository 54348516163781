import React from 'react';
import { Table } from 'react-bootstrap';
import { HSRCharacter } from '../common/components/hsr-character';

interface IProps {
  slug: string;
}

export const ProfileSynergies: React.FC<IProps> = ({ slug }) => {
  return (
    <>
      <Table striped bordered responsive className="synergy-list">
        <thead>
          <tr>
            <th>Character</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {slug === 'lingsha' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="firefly" enablePopover />
                </td>
                <td>
                  <p>
                    As a break-themed support Lingsha is an outstanding partner
                    for Firefly, offering her excellent assistance with
                    whittling enemies Toughness bars down quickly - especially
                    in AoE - and providing her a solid Break damage taken
                    multiplier with her Ultimate. Thanks to Firefly's Fire
                    Weakness implant, Lingsha wont have any concerns about being
                    able to damage enemy Toughness bars either. While Lingsha is
                    strong with Firefly and her teammates (Ruan Mei and Harmony
                    Trailblazer especially), at E0 Firefly is notoriously SP
                    hungry - something Lingsha can struggle to keep up with in
                    longer fights without careful planning. When playing this
                    combo pay specific attention to Skill Point economy and try
                    to get avoid wasting skill points.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="feixiao" enablePopover />
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                </td>
                <td>
                  <p>
                    Lingsha is an incredibly competent Follow-Up attacker - only
                    beaten out by the dedicated Follow-Up sustain, Aventurine.
                    Thanks to Fuyuan and her Ultimate, Lingsha has no problems
                    launching a continuous stream of attacks at enemies making
                    her a good compliment to Feixiao teams, granting them a
                    constant supply of Flying Aureus stacks. Lingsha is also a
                    strong choice when supporting Dr Ratio. thanks to her in
                    built Ultimate debuff which she can keep up consistently on
                    all but the fastest targets - if you have her Signature, her
                    value only increases further in Ratio teams moving from 1
                    debuff to 2. Lingsha also has solid synergy with other
                    characters that find their way onto Ratio and Feixiao teams,
                    making her a good choice for Follow-up Enthusiasts.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    As a Break-focused sustain who benefits from Break Effect%
                    and deals a considerable amount of Toughness damage herself
                    it's no surprise the Super Break duo of Harmony Trailblazer
                    and Ruan Mei are exceptional teammates for Lingsha. Harmony
                    Trailblazer enables Lingsha to convert her Toughness damage
                    and Break Effect% into serious damage output in both single
                    target and multi target scenarios while also granting her a
                    ton of additional Break Effect%. Ruan Mei offers Break
                    Efficiency to boost Super Break damage + Toughness damage,
                    Break Effect buffs but even more importantly an enemy delay
                    which allows Lingsha to maintain her ultimate debuff even on
                    especially quick targets - something she has trouble with
                    otherwise. On top of this Ruan Mei also offers a Speed boost
                    which can ease Lingsha's Speed requirements or help her to
                    line up even more powerful Fuyuan ultimate advancement
                    combos. With this combo Lingsha becomes a DPS in and of
                    herself as long as the enemy is weak to Fire.
                  </p>
                </td>
              </tr>
            </>
          )}

          {slug === 'moze' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="feixiao" enablePopover />
                </td>
                <td>
                  <p>
                    Feixiao is the queen of Follow-Up attacks. Every standard
                    turn Feixiao gets, she launches a minimum of 3 actions but
                    sometimes even more than that when factoring in her
                    ultimate. Moze thrives in teams with allies that can trigger
                    the charge of his Prey mark as frequently as possible. There
                    is no better teammate for this than Feixiao. Feixiao also
                    benefits from Moze as well though thanks to the 25% damage
                    taken amplification debuff he applies to the target of his
                    prey - as all the majority of Feixiao's damage is considered
                    Follow-Up damage this is a substantial boost.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                </td>
                <td>
                  <p>
                    Dr Ratio relies on enemies being debuffed at least 3 times
                    (but ideally 5) in order to unleash his maximum potential,
                    Moze relies on being paired with teammates that can attack
                    as frequently as possible. Each of these character's solve
                    each others problems perfectly. Moze provides 2 debuffs
                    baseline thanks to his Prey mark; while Ratio attacks a
                    minimum of two times per action triggering Moze's charges.
                    On top of this Moze also provides Dr Ratio with a 25% damage
                    taken amplification to all his follow-up damage as long as
                    he hits his Prey, further more at E2 Moze also provides 40%
                    Crit Dmg on top of that!
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                </td>
                <td>
                  <p>
                    Moze doesn't need much help in the way of sustain thanks to
                    the fact he spends most of his time off the field BUT...
                    Aventurine is the number one option when it comes to
                    sustains that launches as many attacks as possible making
                    him the top option for juicing up how many Follow-Up's you
                    can expect when team building for Moze. Aventurine also
                    synergizes greatly with the rest of the Follow-Up attacking
                    units Moze would prefer to run alongside making the pairing
                    of them even more effective.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'feixiao' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin is the premiere and best support for Feixiao offering
                    her an absurd combination of buffs, on hit damage and action
                    advancement that isn't currently matched by any other
                    character in the game. Robin's buffs include: a massive 50%
                    DMG%, a truck load of flat ATK, and a total of 45% CRIT DMG
                    for Follow-Up Attacks. Let's not forget about the on hit
                    Physical damage for the entire team all alongside her
                    signature full team boost. The buffs alone are spectacular
                    for Feixiao and the other Follow-Up attackers she plays
                    alongside but the full team advancement does wonders for
                    granting you more total Ultimates in a fight.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz offers Feixiao some of the most Aureus stack
                    generation possible thanks to Numby and their constant
                    advancement. Enemies afflicted with Topaz's Proof of Debt
                    also take an absurd 50% additional damage from Follow-Up
                    attacks all on top of Topaz's respectable personal damage.
                    Bringing Topaz to Feixiao's teams will grant her some of the
                    most Ultimates and Damage amplification of all non-support
                    follow-up attackers. Topaz also has the option to be played
                    incredibly skill point positive opening up more flexible
                    team building and gameplay options for Feixiao.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="moze" enablePopover />
                </td>
                <td>
                  <p>
                    Moze is able to deliver incredibly frequent Follow-Up
                    attacks which assists Feixiao greatly in fueling her
                    ultimate by generating Aureus stacks for her. Enemies Moze
                    marks as Prey are also afflicted with a debuff which causes
                    them to take 25% additional damage from Follow-Up attacks,
                    boosting much of Feixiao's damage against them. Finally Moze
                    also contributes some pretty strong damage himself and also
                    has some very appealing Eidolons with his E2 specifically
                    also significantly buffing Feixiao's damage output.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="march-7th-swordmaster"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    March 7th's Hunt path is a surprisingly potent teammate for
                    Feixiao with the two of them playing off each other
                    excellently. Setting Feixiao as the Shifu grants her a solid
                    Speed 10% buff and whopping 60% CRIT DMG after March uses an
                    Enhanced Basic ATK for 2 turns. Between March's follow-up
                    attacks, standard attacks and action advances she also
                    generates Feixiao a lot of Aureus while also benefitting
                    herself from Feixiao's own incredibly frequent attacks as
                    the Shifu. Similar to Topaz March is also incredibly skill
                    point positive opening up more flexible team building and
                    gameplay options for Feixiao.
                  </p>
                </td>
                <tr>
                  <td className="char">
                    <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                    <HSRCharacter mode="icon" slug="lingsha" enablePopover />
                  </td>
                  <td>
                    <p>
                      The go-to Follow-up sustains that help Feixiao with
                      fueling her Ultimate charges.
                    </p>
                  </td>
                </tr>
              </tr>
            </>
          )}
          {slug === 'jiaoqiu' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Acheron is Jiaoqiu's top teammate and the character he feels
                    designed to support. Acheron thrives most with characters
                    that consistently and frequently apply debuffs to enemies
                    and Jiaoqiu has no equal in that regard. His Path also
                    activates Acheron's bonus ability and his debuff of choice
                    (vulnerability) is something Acheron lacks in her own kit
                    making it very valuable.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                </td>
                <td>
                  <p>
                    Dr. Ratio relies on his team to apply 3-5 debuffs to bring
                    his kit fully online. This restrict his teammate options,
                    but Jiaoqiu having 2 semi-permanent debuffs and a 3rd from
                    his Cone can do a lot to help with this. With Jiaoqiu
                    bringing tons of debuffs, Ratio has a lot more options to
                    choose from for the Amplifier and Sustain slots.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'march-7th-swordmaster' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz is one of March 7th's best dual-damage teammates at
                    the time of release for her CRIT RATE build and is a great
                    choice of Shifu. Marking Topaz as Shifu allows March to
                    grant Topaz some great buffs but more importantly also
                    generate a lot more charge stacks for herself compared to
                    other possible Shifu targets. What makes Topaz such a good
                    Shifu target is that whenever Topaz OR Numby attacks they
                    both grant her a stack of Charge allowing March to gain
                    access to her enhanced skill much faster than with other
                    Shifu's. What makes the combo even stronger is the fact that
                    Numby will advance their own action whenever March lands her
                    incredibly frequent follow-up attack accelerating the entire
                    process even further.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="gallagher" enablePopover />
                </td>
                <td>
                  <p>
                    Gallagher is one of the most potent healing characters in
                    the game with especially powerful synergies in Break Teams.
                    Gallagher is also a character who favors Speed and even has
                    his own in built action advance in his kit. Both of these
                    facts make him an ideal team mate and Shifu for Break March.
                    By building high amounts of Speed and advancing himself
                    Gallagher grants March more Charge stacks than other
                    potential Shifu targets and with his Break Amplifiers and
                    Good sustain capabilities he buffs up March and keeps her
                    team options open.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    A mandatory teammate and synergy for any Break-focused March
                    teams. March has no way to deal strong Break or Super Break
                    damage on her own despite her incredibly high toughness
                    damage forcing her to rely on external help in order to make
                    the build work. Harmony Trailblazer's ultimate is currently
                    one of the only ways to bestow Super Break capabilities to
                    teammates making them an absolute must for any March Break
                    teams.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin is an excellent teammate specifically for CRIT March
                    thanks to her incredibly team-wide buffing capabilities
                    buffing not only March 7th but any team mate you decide to
                    bring alongside her. More specifically, Robin's Advance and
                    Follow-Up attack on hit damage benefit March a lot thanks to
                    March relying executing far more individual actions than
                    most other characters in the game and gaining benefit the
                    faster she but also her Shifu are able to attack.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'yunli' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin provides the best buffs for all Follow-up characters -
                    insanely high flat ATK boost, her Crit DMG% buffs, and DMG%
                    buffs synergize with Yunli insanely well. Robin’s Crit DMG%
                    to Follow-up attacks trace ability also applies to her
                    ultimate - as Yunli’s Ultimate is considered a Follow-up
                    attack. Robin’s team-wide advance is also particularly
                    helpful to Yunli as she does not build any Speed of her own.
                    Robin also supports dual damaging teams excellently of which
                    Yunli has multiple incredibly viable options for.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Provides additional Energy for Yunli to access more
                    Ultimates pushing her damage even higher than usual. Tingyun
                    also provides a strong DMG% buff and ATK% buff to Yunli
                    through her Ultimate and Skill respectively. Her skill's
                    Benediction also does in fact trigger on Yunli’s Counter and
                    Ultimate, leading to even more damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                </td>
                <td>
                  <p>
                    Huohuo’s Ultimate has excellent synergy with Yunli -
                    providing 48 energy on use instead of the expected 24. The
                    reason for this is Huohuo considers Yunli’s total energy not
                    how much her ultimate actually costs, meaning Huohuo grants
                    her over a third of an Ultimate each time. Huohuo also
                    provides an Attack buff which will persist in-between
                    Yunli’s counters, and a bunch of cleanses to ensure those
                    Counter’s go off.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="lynx" enablePopover />
                </td>
                <td>
                  <p>
                    Lynx is a critical Synergy for Yunli's that do not have
                    access to their signature Light Cone to increase the chance
                    of Yunli being hit. Much of Yunli's energy generation - and
                    as a result damage - is tied to her constantly being hit in
                    combat and launching counters; something that is only
                    possible with a high taunt value. Yunli's baseline kit has
                    no access to increasing the chance for her to be hit outside
                    of the guaranteed 1 action taunt on her Ultimate, leaving
                    her in dire need of assistance - assistance Lynx can provide
                    with her skill. Lynx's skill provides Yunli a buff that
                    drastically increases her chance to be hit.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'jade' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="blade" enablePopover />
                </td>
                <td>
                  <p>
                    Blade and Jade share incredible synergy. Blade benefits Jade
                    as he possesses: a Follow-Up attack capable of hitting up to
                    5 enemies which triggers often, a relatively accessible
                    Ultimate, a Basic Attack that always hits 3 targets and a
                    teammate that doubles his total actions (Bronya) resulting
                    in a good double damage partner and plenty of Charges for
                    Jade. It doesn't stop there though as Jade also benefits
                    Blade - her Debt Collector buff causes Blade to take an
                    additional instance of damage when using all of his
                    abilities, allowing him to gain 2 stacks of his Talent
                    instead of 1 when using his Enhanced Basic Attack and
                    Ultimate which feeds more into Jade. Basically, it's a
                    feeding loop where both benefit a lot.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="herta" enablePopover />
                  <HSRCharacter mode="icon" slug="himeko" enablePopover />
                </td>
                <td>
                  <p>
                    Jade is at home and completely dominates in Pure Fiction.
                    What Jade wants most is allies that hit as many enemies as
                    possible, as often as possible - and Herta and Himeko fit
                    that bill to a tee being able to hit 3-5 enemies with all of
                    their attacks while also launching a continuous barrage of
                    Follow-Up attacks at every enemy with each hit, of course,
                    granting Jade a stack of Charge. What makes the combo of
                    Herta/Himeko so strong with Jade is they both require either
                    a Break or initial source of damage to trigger their
                    Follow-Ups - something that Jade can easily help in many
                    circumstances. The result of this is an incredibly potent
                    ping pong effect between Himeko and Herta's Follow-Up's and
                    Jade's. You'll generally want to run either Himeko or Herta
                    and not both but either will be incredibly potent.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Jade is made to be used in double DPS teams and Ruan Mei and
                    Robin are Supports that work well in those kinda of setups.
                    Most of Jade's double DPS teams (with the exception of
                    Blade) will benefit greatly from having either of these
                    characters on the team.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'acheron' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="jiaoqiu" enablePopover />
                </td>
                <td>
                  <p>
                    Jiaoqiu's is Acheron's top teammate and the character he
                    feels designed to support. Acheron thrives most with
                    characters that consistently and frequently apply debuffs to
                    enemies and Jiaoqiu has no equal in that regard. His Path
                    also activates Acheron's bonus ability and his debuff of
                    choice (vulnerability) is something Acheron lacks in her own
                    kit making it very valuable.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                </td>
                <td>
                  <p>
                    Silver Wolf and Pela are both fantastic debuffers for
                    Acheron, offering quick Ultimate stacks for her, as well as
                    strong defense shred debuffs on the enemy. While Pela
                    specializes in quick Ultimate rotations and AoE defense
                    shred, Silver Wolf specializes in Single Target, offering
                    stronger debuffs compared to Pela.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka is a good option for the debuffer spot in Acheron
                    teams, especially in Pure Fiction - a lot of the smaller
                    enemies will die from the DoT, making Acheron not waste her
                    Ultimate on them.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="black-swan" enablePopover />
                </td>
                <td>
                  <p>
                    Black Swan offers Acheron a lot debuffs from her skills. She
                    also uniquely grants Acheron stacks of Slashed Dream every
                    time a new enemy spawns (not including enemies that enter at
                    the start of combat or start of wave) thanks to how she
                    applies Arcana to enemies. She also grants Acheron DEF shred
                    and some great supportive damage, often finishing off
                    enemies before Acheron needs to use another Ultimate,
                    especially in Pure Fiction. As an added bonus, Black Swan
                    also grants Acheron an extra Slashed Dream stack from her
                    Technique at the start of the fight.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
                  <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                </td>
                <td>
                  <p>
                    Fu Xuan has a strong crit rate buff when her Skill is up,
                    which helps fulfill Acheron’s crit needs, while Aventurine
                    has a small crit damage debuff from his Ultimate for more
                    Single Target focus. Both these characters can utilize the
                    Trend Light Cone for additional debuffs for Acheron's
                    Slashed Dreams.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'argenti' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Tingyun is the most straightforward and well-rounded buffer
                    for Argenti as she provides a lot Energy as well as buffing
                    both his ATK% and DMG%.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Both Sparkle and Robin help Argenti with their buffs and
                    give him more actions - who you choose for your team depends
                    on Argent's build and mode you play him in.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                </td>
                <td>
                  <p>
                    Huohuo is a Sustain with a teamwide Energy Battery Ultimate,
                    basically infinite cleanses, and is able to utilize Light
                    Cones like Quid Pro Quo for even more energy. This puts her
                    on top in regards to Sustenance, however she does have the
                    drawback of being Skill Point Neutral unless she is E1.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'arlan' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                  <HSRCharacter mode="icon" slug="gepard" enablePopover />
                </td>
                <td>
                  <p>
                    Arlan’s biggest issue is surviving, so with the biggest
                    shields in the game, Aventurine or Gepard can take care of
                    the issue for him. Further, Aventurine can not only provide
                    a CRIT Dmg debuff to the enemy, but also more frequent
                    shielding compared to Gepard, tipping things in his favour.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                </td>
                <td>
                  <p>
                    Considering Arlan doesn’t use Skill Points to use Skills,
                    this allows him to take great advantage of Bronya or Sparkle
                    who can always be played Skill Point Negative.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'asta' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka has one of the best synergies with Asta as she
                    provides a massive speed boost and a constant ATK% boost
                    which can be easily kept on max stacks in most teams. Asta
                    can also perform Basic Attacks often to take advantage of
                    Kafka’s Follow-Up Attacks.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="black-swan" enablePopover />
                </td>
                <td>
                  <p>
                    Black Swan can utilize Asta’s Speed buff very well by
                    stacking more Arcana between enemy actions, at the cost of
                    Skill Points compared to having Ruan Mei supporting her.
                    Asta also provides Burn on her Basic, which allows Black
                    Swan to apply even more Arcana stacks per attack.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'aventurine' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="feixiao" enablePopover />
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                </td>
                <td>
                  <p>
                    Being the premium Sustain for Follow-up Attackers,
                    Aventurine works very well with most characters, but has
                    insane synergy with the current Follow-up Attack team
                    members. Aventurine is able to gain Blind Bet stacks from
                    Dr.Ratio and Topaz whenever they attack and trigger a
                    Follow-Up Attack, up to 3 per turn. Aventurine also can
                    trigger Numby’s advances with his Follow-Up Attack when it
                    hits the marked target, and also trigger Dr. Ratio’s
                    Ultimate even in the same turn as his previous Follow-Up
                    Attack, leading to some incredible damage bursts.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Providing all the buffs that Aventurine can actually utilise
                    places Ruan Mei at the top of the list for Aventurine’s
                    buffers. DMG%, RES PEN%, Speed%, and even DEF shred at E1,
                    combined with DMG% buff from her Light Cone if you have it,
                    makes her the perfect all-rounded partner for Aventurine
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    With the ability to apply a debuff on his Ultimate, his
                    Follow-Up Attack if using his Light Cone, or when he gets
                    hit if using the Trend Light Cone, allows for a lot of extra
                    Slashed Dream stacks for Acheron. For those who have
                    invested in Aventurine, he also provides a debuff on every
                    Basic Attack he uses, further increasing his debuff
                    application presence.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'bailu' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="blade" enablePopover />
                </td>
                <td>
                  <p>
                    Being the only character who currently benefits from having
                    their max HP increased for damage, Bailu provides some of
                    the strongest mitigation for Blade who will consistently be
                    below half health, as well as energy if put with a Quid Pro
                    Quo Light Cone to get more Ultimates.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'black-swan' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                </td>
                <td>
                  <p>
                    The DoT queen who has the best synergies in the game with
                    Black Swan, Kafka has the ability to inflict multiple DoTs
                    to increase Black Swan’s Arcana count per application,
                    alongside triggering those DoTs to further increase the
                    Arcana stacks resulting in massive damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Providing all the buffs that Black Swan uses places Ruan Mei
                    at the top of the list for Black Swans’ buffers. DMG%, RES
                    PEN%, Speed%, and even DEF shred at E1, combined with extra
                    DMG% buff from her Light Cone if you have it, makes her the
                    perfect all-rounded partner for Black Swan.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sampo" enablePopover />
                  <HSRCharacter mode="icon" slug="guinaifen" enablePopover />
                </td>
                <td>
                  <p>
                    Sampo and Guinaifen are the cheaper F2P alternates of Kafka,
                    however they can also just be paired with Kafka and Black
                    Swan, with both of them “triggering” their respective DoTs
                    on the enemy, Sampo with his Skill at E4, and Guinaifen on
                    her Ultimate. Both of them give double the stacks when Black
                    Swan has used her Ultimate and the enemy is afflicted with
                    Epiphany.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'blade' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="jade" enablePopover />
                </td>
                <td>
                  <p>
                    Blade and Jade share incredible synergy. Blade benefits Jade
                    as he possesses: a Follow-Up attack capable of hitting up to
                    5 enemies which triggers often, a relatively accessible
                    Ultimate, a Basic Attack that always hits 3 targets and a
                    teammate that doubles his total actions (Bronya) resulting
                    in a good double damage partner and plenty of Charges for
                    Jade. It doesn't stop there though as Jade also benefits
                    Blade - her Debt Collector buff causes Blade to take an
                    additional instance of damage when using all of his
                    abilities, allowing him to gain 2 stacks of his Talent
                    instead of 1 when using his Enhanced Basic Attack and
                    Ultimate which feeds more into Jade. Basically, it's a
                    feeding loop where both benefit a lot.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                </td>
                <td>
                  <p>
                    Bronya offers everything Blade needs, and her downside of
                    being Skill Point intensive is less of a burden than with
                    other traditional DPS, as Blade barely uses any. Blade takes
                    full advantage of everything Bronya provides, be it Crit
                    damage%, DMG% or Advance.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Being a Harmony character who provides DMG% automatically
                    makes her an obvious choice for Blade. Her RES PEN from her
                    Ultimate, as well as DEF shred if E1, are the two most
                    impactful scalers for him, which make her top tier for
                    Blade.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                </td>
                <td>
                  <p>
                    Both provide DEF shred which is is one of the easiest ways
                    to scale Blade’s damage when combined with the self buffs he
                    possesses. Additionally, the RES down from Silver Wolf
                    provides one of the best amps for Blade currently.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'boothill' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                </td>
                <td>
                  <p>
                    Boothill’s biggest weakness is his ramp-up time from being
                    Single Target and needing to get his Pocket Trickshots done
                    asap to deal the most damage. Bronya alleviates the ramp-up
                    time if built with -1 Speed to Boothill, and can allow for
                    much quicker rotations, at the cost of additional Skill
                    Point consumption.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Boothill loves everything Ruan Mei provides, Weakness Break
                    efficiency, Break buff, RES PEN%, and even DEF shred at E1.
                    The DMG% buff from her Signature and her Trace don’t provide
                    a lot of value, however if built with some crit chance, can
                    boost it enough to squeeze in a little bit more damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    While Boothill isn't reliant on outside Super Break sources
                    like Firefly, the Harmony MC is still a great option to
                    consider to run beside him.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                </td>
                <td>
                  <p>
                    Silver Wolf and Pela provide a big chunk of DEF shred which
                    is one of the few ways his Break scales its damage.
                    Additionally, the RES down from Silver Wolf provides one of
                    the best amps for Boothill currently.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'bronya' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="blade" enablePopover />
                </td>
                <td>
                  <p>
                    Blade being a DPS who rarely uses any Skill Points allows
                    Bronya to always skill on him for maximizing DPS output,
                    while also utilising all of her buffs, unlike other
                    Harmonies who are always missing 1 or 2 buffs compared to
                    Bronya.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="jingliu" enablePopover />
                </td>
                <td>
                  <p>
                    Jingliu has an awkward downtime after coming out of her
                    Syzygy state, with a -1 Speed Bronya setup, you can skill
                    with Jingliu into Bronya skilling her back up to go straight
                    back into her Syzygy state and prolong her insane damage
                    output.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="boothill" enablePopover />
                </td>
                <td>
                  <p>
                    Boothill’s biggest weakness is his ramp-up time from being
                    Single Target and needing to get his Pocket Trickshots done
                    asap to deal the most damage. Bronya alleviates the ramp-up
                    time if built with -1 Speed to Boothill, and can allow for
                    much quicker rotations, at the cost of additional Skill
                    Point consumption.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'clara' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin being introduced in patch 2.2 has led to Clara going
                    nuclear with the biggest attack buff in the game. Alongside
                    a DMG% buff and a crit damage buff, with an additional crit
                    damage buff to her Follow-Up Attacks, she triumphs over
                    every other Support currently in when it comes to damage
                    increase.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle has the added benefit of leaving her CRIT DMG buff
                    on Clara until her next turn - unlike Bronya, whose buff
                    falls off immediately. This allows Sparkle’s buff to prolong
                    Clara's turn applying to all her counters she will be
                    utilising in the meantime.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Tingyun’s buffs are the most comfortable for Clara’s
                    playstyle as a Main DPS, with her Ultimate refilling Clara’s
                    energy to have near permanent uptime on her Enhanced
                    Counters. Additionally she provides a DMG% and ATK% buff
                    that have “long” durations, which are useful due to how slow
                    Clara is.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz offers a Follow-Up Attack damage taken increase with
                    her mark, allowing Clara's Counters to do a lot more damage
                    to those targets, while also allowing Topaz to focus down
                    Single Target enemies and Clara to take care of the rest of
                    the field.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'dan-heng' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                </td>
                <td>
                  <p>
                    Generalist buffer for Dan Heng and can utilise the -1 Speed
                    setup for maximum amount of turns with Bronya. He uses all
                    of the buffs from Bronya very nicely be it DMG%, Crit
                    damage%, or the Advance, while Dan Heng also has his own
                    Advance on E4 after killing an enemy with his Ultimate.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'imbibitor-lunae' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle is the best partner for Imbibitor Lunae, being able
                    to advance him while running a slow build, and giving the
                    most skill points out of any character on a consistent
                    3-turn rotation.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Providing energy allows Imbibitor Lunae’s Skill Point
                    consumption to be lowered and help out with other buffers
                    using their skills. She also provides huge ATK% and DMG%
                    buffs for his burst windows benefitting him to a great
                    extent.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="yukong" enablePopover />
                </td>
                <td>
                  <p>
                    Yukong can utilise her very short burst windows of damage
                    buffs with Imbibitor Lunae’s huge multipliers, who goes
                    quite infrequently on a slow setup. However, E2 Imbibitor
                    Lunae can utilise Yukong’s buffs on both attacks after he
                    uses his Ultimate.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="luocha" enablePopover />
                </td>
                <td>
                  <p>
                    Provides a lot of Skill Points for a Sustainer and can be
                    run on the Multiplication Light Cone to go even faster. Just
                    a general use Sustainer who has a lot of nice capabilities
                    for Imbibitor Lunae.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'dr-ratio' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    With Robin being introduced in 2.2, Ratio has received a
                    massive buff thanks to the biggest attack buff in the game,
                    alongside a DMG% buff, a crit damage buff, with an
                    additional crit damage buff to his Follow-Up Attacks. She
                    triumphs over every other Support currently in when it comes
                    to damage increase.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz provides one of the necessary debuffs Dr. Ratio needs
                    for his Skill. With either E1 or her Light Cone, or with
                    both, she can completely fill the debuff needs for him as
                    well as apply some very strong Follow-Up Attack centric
                    debuffs on the enemy. Topaz also gets benefits out of Dr.
                    Ratio for when his Follow-Up Attack activates on the enemy
                    with the Mark, Numby advances forward resulting in even more
                    actions.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="moze" enablePopover />
                </td>
                <td>
                  <p>
                    Dr Ratio relies on enemies being debuffed at least 3 times
                    (but ideally 5) in order to unleash his maximum potential,
                    Moze relies on being paired with teammates that can attack
                    as frequently as possible. Each of these character's solve
                    each others problems perfectly. Moze provides 2 debuffs
                    baseline thanks to his Prey mark; while Ratio attacks a
                    minimum of two times per action triggering Moze's charges.
                    On top of this Moze also provides Dr Ratio with a 25% damage
                    taken amplification to all his follow-up damage as long as
                    he hits his Prey, further more at E2 Moze also provides 40%
                    Crit Dmg on top of that!
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                </td>
                <td>
                  <p>
                    Aventurine can provide up to 3 debuffs depending on
                    investment levels for Dr. Ratio. He also provides the most
                    comfortable shields in the game and his own damage can
                    trigger Ratio’s Ultimate stacks very easily. With
                    Aventurine’s Light Cone, his Follow-Up Attack can apply a
                    debuff, and on E2, can apply another debuff on every Basic
                    Attack.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="lingsha" enablePopover />
                </td>
                <td>
                  <p>
                    Lingsha is an incredibly competent Follow-Up attacker - only
                    beaten out by the dedicated Follow-Up sustain, Aventurine.
                    Thanks to Fuyuan and her Ultimate, Lingsha is a strong
                    choice when supporting Dr Ratio. thanks to her in built
                    Ultimate debuff which she can keep up consistently on all
                    but the fastest targets - if you have her Signature, her
                    value only increases further in Ratio teams moving from 1
                    debuff to 2. Lingsha also has solid synergy with other
                    characters that find their way onto Ratio and Feixiao teams,
                    making her a good choice for Follow-up Enthusiasts.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="jiaoqiu" enablePopover />
                </td>
                <td>
                  <p>
                    Dr. Ratio relies on his team to apply 3-5 debuffs to bring
                    his kit fully online. This restrict his teammate options,
                    but Jiaoqiu having 2 semi-permanent debuffs and a 3rd from
                    his Cone can do a lot to help with this. With Jiaoqiu
                    bringing tons of debuffs, Ratio has a lot more options to
                    choose from for the Amplifier and Sustain slots.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Both Pela and Silver Wolf provide the perfect amount of
                    debuffs for Dr.Ratio to hit 100% chance for his Skill to
                    activate Follow-Up Attack. Furthermore, Silver Wolf applies
                    even more debuffs, enough to max out his Deduction Trace to
                    increase his damage up to 50%.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'firefly' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Not just the best support for Firefly but borderline
                    mandatory for her to perform at a high level. Harmony
                    Trailblazer grants Firefly a large amount of Break Effect
                    but more importantly, another far more powerful layer of
                    Super Break, boosting her damage potential by an insane
                    amount. For reference, Firefly's Super Break deals 50% Super
                    Break damage but Harmony Trailblazer can deal up to 160%
                    Super Break damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Ruan Mei has absurd levels of synergy with not just Firefly
                    but also Harmony Trailblazer creating a holy trinity of
                    Break decimation with all of their kits feeding into each
                    other. What makes Ruan Mei so strong with Firefly and
                    Trailblazer is her increase to Break Efficiency which
                    directly scales Super Break damage - and it makes up the
                    majority of the Firefly x Trailblazer duo's damage. Firefly
                    has some Break Efficiency of her own and Ruan's does stack
                    additively with that but even so, it's a huge damage
                    increase. On top of this, Ruan Mei offers not only Break
                    Effect and Speed to the team but also RES SHRED which of
                    course massively increases BREAK and SUPER BREAK damage as
                    well. Ruan's Speed buff specifically can make gearing up
                    Firefly for the correct Speed breakpoints much easier
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="gallagher" enablePopover />
                </td>
                <td>
                  <p>
                    Gallagher is Firefly's ideal sustain offering her utility,
                    damage, sustain, and consistency. One of Firefly's (and her
                    teams) biggest issues at E0 is not having enough Skill
                    Points. Firefly herself is an extremely fast character with
                    an action advance built into her kit, Harmony Trailblazer
                    would ideally use their skill whenever possible to maximize
                    their damage and energy regeneration, and Ruan Mei wants to
                    maintain her skill at all times. The result of this is not a
                    lot of Skill Points to go around - something Gallagher as a
                    character (with the potential to have the most positive
                    skill point rotation of any sustain) helps immensely with.
                    Another thing Firefly is constantly on the lookout for is
                    keeping as many enemies broken as frequently and for as long
                    as possible, allowing her to Super Break them every action -
                    Gallagher being a Breaker himself also helps with this
                    thanks to his incredible toughness damage. Finally,
                    Gallagher also amplifies Break Damage as well, boosting the
                    overall team's damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="lingsha" enablePopover />
                </td>
                <td>
                  <p>
                    As a break-themed support Lingsha is an outstanding partner
                    for Firefly, offering her excellent assistance with
                    whittling enemies Toughness bars down quickly - especially
                    in AoE - and providing her a solid Break damage taken
                    multiplier with her Ultimate. Thanks to Firefly's Fire
                    Weakness implant, Lingsha wont have any concerns about being
                    able to damage enemy Toughness bars either. While Lingsha is
                    strong with Firefly and her teammates (Ruan Mei and Harmony
                    Trailblazer especially), at E0 Firefly is notoriously SP
                    hungry - something Lingsha can struggle to keep up with in
                    longer fights without careful planning. When playing this
                    combo pay specific attention to Skill Point economy and try
                    to get avoid wasting skill points.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    If you don't have access to Ruan Mei.... yet, you can use
                    Pela or Silver Wolf in the meantime, leveraging their enemy
                    debuffs to amplify the team's Super and standard Break
                    damage against enemies whilst generating some skill points
                    for your core duo of Firefly and Harmony Trailblazer.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'fu-xuan' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Providing the much needed CRIT Rate that Acheron wants is
                    very useful in itself, she can also hold the Trend Light
                    Cone for additional debuffs whenever Fu Xuan gets hit,
                    giving a Slashed Dream when applied.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="seele" enablePopover />
                </td>
                <td>
                  <p>
                    Giving the much needed CRIT Rate that Seele needs is
                    extremely useful in itself. She also provides a 1 time CC
                    ignore for stuff like Imprisons and Freezes, which prevents
                    Seele’s pace slowdown, making it invaluable.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Being our first 5* Quantum Sustainer has its perk, and
                    Silver Wolf takes good advantage of it. This Allows Silver
                    Wolf to have one less Element to take into consideration for
                    her Skill’s Implant. Silver Wolf can also take advantage of
                    Fu Xuans Crit Rate% buff at E2 to be built as a Sub-DPS.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'gallagher' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Harmony Trailblazer allows Gallagher to achieve some
                    ridiculous Super Break numbers with his Besotted state from
                    his Ultimate, increasing all Break damage on enemies, while
                    also contributing as the Sustain, making him the perfect
                    Sustain for any Break-centric teams currently.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Ruan Mei gives Gallagher an insane boost in content weak
                    against Fire for Breaking, and in Super Break teams makes
                    him nearly as strong as Harmony Trailblazer itself at E6 in
                    terms of Super Break damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Providing debuffs is one of the main things Acheron looks
                    for in teammates, and Gallagher just so happens to provide 2
                    debuffs very quickly in short succession; 1 on Ultimate, and
                    1 on his Enhanced Basic, giving Acheron the much-needed
                    debuffs she wants for quicker Ultimates.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'gepard' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Gepard has some of the biggest shields in the game and can
                    reasonably get them back before they wear off with an
                    inbuilt Taunt on his Trace. This works to his favour when
                    wearing the Trend Light Cone, applying a debuff whenever an
                    enemy hits him, giving Acheron her Slashed Dream stacks.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'guinaifen' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka can detonate any Burns that are on the enemy with her
                    Skill and Ultimate, and if you’ve got E4 on Guinaifen,
                    allows her to get her Ultimate 1 turn earlier depending on
                    the rotation you’ve used. Guinaifen also acts as another DoT
                    partner for Kafka.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Guinaifen can be used in place of other Nihility characters
                    for Acheron, however she only has a small DMG% taken debuff
                    on Fire Kiss. This requires a few turns to stack up and can
                    fall off if no Burn is on the enemy, compared to her
                    counterparts such as Pela and Silver Wolf.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'hanya' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="argenti" enablePopover />
                </td>
                <td>
                  <p>
                    Argenti can abuse the Burden effect from Hanya regardless of
                    what enemy it is on, as Burden checks when the enemy is hit,
                    and Argenti, with his AoE Skill and Ultimate, can trigger it
                    easily.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="imbibitor-lunae"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Hanya can be built extremely fast and generate a ton of
                    Skill Points for Imbibitor Lunae, which he heavily requires,
                    as well as providing a nice speed and ATK% boost on her
                    Ultimate.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="qingque" enablePopover />
                </td>
                <td>
                  <p>
                    Qingque struggles from a lack of Skill Points most of the
                    time after draining them all gambling on her skill. However,
                    with Hanya, Qingque’s teammates are able to easily reach
                    maximum Skill Points by the time she gets her turn again,
                    which is quicker than normal with Hanya’s speed boost on her
                    Ultimate. A trick you can utilise with a Pre-E4 Hanya, is
                    when Qingque is using her skill, activate Hanya’s Ultimate
                    for 1 extra turn on its duration.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'herta' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Herta’s Follow-Up Attacks are very consistent with lots of
                    enemies, however not having a secondary DPS in the party can
                    make them hard to trigger. Robin alleviates this problem by
                    giving a bunch of extra damage whenever anyone attacks, even
                    your Supporters will now be able to trigger Herta’s
                    Follow-Up Attack and allow her to chain them.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle provides a very strong crit damage% buff on her
                    Skill, and can allow Herta to be built slow. Her Skill buffs
                    also persist in between the end of her current turn and
                    start of her next turn, which means it will buff any and all
                    Follow-Up Attacks Herta may perform, unlike Bronya whose
                    buffs end with her turn end.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="himeko" enablePopover />
                </td>
                <td>
                  <p>
                    Solely for Pure Fiction or AoE based content in general,
                    Himeko is able to chain her own Follow-Up Attacks into
                    triggering Herta’s Follow-Up Attacks, who may retrigger
                    Himeko’s in turn, resulting into an endless loop of
                    Follow-Up Attacks.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'himeko' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Ruan Mei provides the best all-rounded buffs for Himeko in
                    both crit teams and Super Break teams. She provides the DMG%
                    buff and Weakness Break efficiency to activate her Follow-Up
                    Attacks quicker, as well as RES PEN% on Ultimate benefitting
                    both Super Break and crits.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Providing a 100% Advance already makes Robin very strong for
                    Himeko, but the insanely high ATK% buff from her Ultimate is
                    one of the things Himeko just can’t get enough of.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="herta" enablePopover />
                </td>
                <td>
                  <p>
                    Solely for Pure Fiction or AoE based content in general,
                    Herta is able to chain her own Follow-Up Attacks into
                    triggering Himeko’s Follow-Up Attacks, who may retrigger
                    Herta’s in turn, resulting into an endless loop of Follow-Up
                    Attacks.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'hook' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Hook’s main damage comes from getting quick enough Ultimates
                    to be able to use her Enhanced Skill, with her Ultimate also
                    advancing her next turn by 20%. Hook’s skill also turns a
                    Single Target ability into a Blast Attack, hitting multiple
                    enemies while also triggering her Talent for extra damage
                    and a 5% heal with her Innocence Trace.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'huohuo' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Being the only other current Energy Battery character,
                    Huohuo is able to chain her Ultimate into Tingyun’s to
                    create an “Energy Loop” for your DPS to get a substantial
                    amount more Ultimates.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="argenti" enablePopover />
                </td>
                <td>
                  <p>
                    Having the highest Ultimate cost in the game currently,
                    Argenti loves Huohuo’s energy as it scales off maximum
                    Ultimate cost, which at maximum level regenerates up to 36
                    Energy. She tops it off with up to a 40% ATK boost for his
                    burst window Ultimate.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="jingliu" enablePopover />
                </td>
                <td>
                  <p>
                    In certain compositions, Jingliu is able to stay in her
                    Transmigration state for an additional 1-2 turns based on
                    the amount of Ultimates and extra energy you can gain by
                    using combo of Huohuo and Tingyun, thereby increasing your
                    overall damage output by a huge amount.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                  <HSRCharacter mode="icon" slug="black-swan" enablePopover />
                </td>
                <td>
                  <p>
                    Huohuo provides an extremely nice ATK% buff on her Ultimate,
                    is an Energy Battery, and with E1 also provides a speed
                    boost which further pushes her as the best Sustain for DoT
                    teams currently.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'jing-yuan' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin is the best support for Jing Yuan that provides him
                    with a lot of buffs and also gives him additional actions
                    (via her Ultimate). Her Signature also provides additional
                    energy to Robin to help her with Ultimate uptime and more
                    buffs to the team which further improve Jing Yuan's
                    performance.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle is the upgraded version of Bronya for Jing Yuan,
                    allowing her Skill’s CRIT DMG buff to carry over onto the
                    Lightning Lord, which is roughly 60% of Jing Yuan’s total
                    damage. Her Ultimate’s DMG% boost also affects both Jing
                    Yuan and Lightning Lord.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Tingyun is the current perfect synergy for Jing Yuan
                    providing a flat ATK boost, energy from her Ultimate to hit
                    10 stacks on the Lightning Lord nearly every time, and a
                    DMG% buff which lasts long enough to affect the Lightning
                    Lord after Jing Yuan’s turn.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                  <HSRCharacter mode="icon" slug="himeko" enablePopover />
                  <HSRCharacter mode="icon" slug="herta" enablePopover />
                  <HSRCharacter mode="icon" slug="jade" enablePopover />
                </td>
                <td>
                  <p>
                    Dual DPS compositions partners for Jing Yuan. Topaz not only
                    brings great buffs for JY, but also helps with her own
                    damage, while Himeko, Herta and Jade shine in AoE scenarios
                    (which makes them good partners for Pure Fiction).
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                </td>
                <td>
                  <p>
                    Aventurine provides Effect RES to help counter the pesky
                    CC-throwing monsters while also doing decentish damage on
                    its own, helping to get rid of the smaller fries, so Jing
                    Yuan doesn't waste his LL on them. Also the CRIT DMG denuff
                    on Ult helps with improving Jing Yuan's damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
                </td>
                <td>
                  <p>
                    Fu Xuan gives Jing Yuan the CC protection he needs with a 1
                    time immunity to Crowd Controls like Imprison and Freeze,
                    which are the death of Jing Yuan as it causes the Lightning
                    Lord to not attack. She also provides a sizable Crit Rate%
                    buff on her Skill to buff up his stats.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                  <HSRCharacter mode="icon" slug="gallagher" enablePopover />
                </td>
                <td>
                  <p>
                    Alternative sustains that exchange the CC safety net of Fu
                    Xuan for more energy generation (especially if you use QPQ
                    on them) for Jing Yuan/Robin.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'jingliu' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                </td>
                <td>
                  <p>
                    Jingliu has an awkward downtime after coming out of her
                    Syzygy state, with a -1 Speed Bronya setup, you can skill
                    with Jingliu into Bronya skilling her back up to go straight
                    back into her Syzygy state and prolong her insane damage
                    output.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Pela at E4 can apply an additional 12% Ice RES down on her
                    skill, which Silver Wolf also applies with her implant. Both
                    characters also apply generic RES down and DEF shred.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    In certain compositions, Jingliu is able to stay in her
                    Spectral Transmigration state for an additional 1-2 turns
                    based on the amount of Ultimates and extra energy you can
                    gain by using combo of Huohuo and Tingyun, thereby
                    increasing your overall damage output by a huge amount.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'kafka' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="black-swan" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka’s partner in crime for DoT, Black Swan synergises
                    extremely well with Kafka triggering her Follow-Up Attacks
                    every action or two, and DEF shred on enemies with more than
                    7 Arcana stacks, which also applies to Kafka’s DoT’s.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Being a Harmony Character who provides DMG% automatically
                    makes her an obvious choice for Kafka. Her RES PEN from her
                    Ultimate, DEF shred if she is E1, extra DMG% from S1 and her
                    speed buffs are the most efficient scalers for Kafka, making
                    Ruan Mei the BiS for her.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Much like Ruan Mei, Robin also provides insane buffs,
                    upwards of 1000+ ATK, DMG%. Combined with damage from Robin
                    herself and with how fast Kafka is normally built, it racks
                    up a decent amount of extra damage.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'luka' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka utilises the insane Break Bleeds that Luka can apply,
                    able to trigger them over and over resulting in the
                    strongest version of DoTs in Physical Weak content. Can also
                    be applied to all content with Silver Wolf to destroy
                    basically anything in the game.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="boothill" enablePopover />
                </td>
                <td>
                  <p>
                    Boothill and Luka may seem odd, but allowing Boothill (who
                    has a higher Break effect stat than Luka) to be the one to
                    Break allows Luka to focus on Speed and defensive stats
                    instead. He can then use his Enhanced Basic Attack to
                    trigger Boothill’s huge Bleeds.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Silver Wolf allows Luka to be played in his most optimal
                    state at all times, along with applying stupidly strong
                    Physical Breaks with her Implant. However, you will require
                    proper timing or to hold your Ultimate on Luka to not
                    accidentally Break with Silver Wolf instead of him.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'luocha' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="blade" enablePopover />
                </td>
                <td>
                  <p>
                    Blade has a constant depletion of his HP which requires lots
                    of attention; thankfully Luocha’s field applies all the
                    healing Blade could ever need and more. He also features an
                    emergency heal if Blade ever gets too low from enemy attacks
                    or puts himself into the red.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="imbibitor-lunae"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Imbibitor Lunae uses a lot of Skill Points, and Luocha,
                    being the Skill Point friendly Sustain that he is, can
                    generate a ton of them for Imbibitor Lunae and the rest of
                    the team to use.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="clara" enablePopover />
                </td>
                <td>
                  <p>
                    Clara with her constant Follow-Up Attacks enables her to
                    instantly heal back any damage she sustained while Luocha’s
                    field is up, allowing you to turn off your brain for healing
                    the whole party.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'lynx' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="blade" enablePopover />
                </td>
                <td>
                  <p>
                    Blade has a constant depletion of his HP which requires lots
                    of attention, thankfully Lynx’s HoT as well as her increased
                    Taunt on Skill allows Blade to Follow-Up Attack a lot more
                    often while also healing himself from it.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="clara" enablePopover />
                </td>
                <td>
                  <p>
                    Lynx has a Taunt value increase on her skill which is very
                    useful for characters like Clara who need to be hit to get
                    the most out of their kit. Cleanse on her Ultimate allows
                    Crowd Controls to be ignored in certain scenarios, with her
                    E2 also providing a one time debuff ignore on Skill use.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'march-7th' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Acheron is one of the few remaining synergies which exist
                    for March, with using her Skill on herself with the Trend
                    Light Cone to apply debuffs whenever an enemy attacks her.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'misha' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                </td>
                <td>
                  <p>
                    Allowing Misha’s Ultimate to be normally used off turn or
                    right before gaining buffs puts Sparkle as one of his BiS
                    buffers, with Bronya being roughly the same, but lacking the
                    skill point freedom of Sparkle.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="pela" enablePopover />
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Pela at E4 can apply an additional 12% Ice RES down on her
                    skill which, when combined with Misha’s big Ultimate window,
                    can raise his damage immensely. The same also applies to
                    Silver Wolf with her implant and generic RES down and DEF
                    shred that both characters apply.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Having one of the highest toughness reduction Ultimates in
                    the game allows Misha to Break very quickly, and even gains
                    energy off others using skills, so it's beneficial to use
                    skills on Harmony Trailblazer.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'natasha' && (
            <>
              <tr>
                <td className="char">None</td>
                <td>
                  <p>
                    Natasha works fine as a F2P unit for basically everyone,
                    however does fall behind on basically everything; heals,
                    utility, and defensiveness when compared to her counterparts
                    later on.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'pela' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Pela is one of the easiest to build debuffers for Acheron
                    who pumps out debuffs extremely quickly. With the Pearls
                    Light Cone equipped, she applies a debuff on every Basic
                    Attack, E4 adds a debuff on her Skill, and lastly, another
                    debuff on her Ultimate which can be chained back-to-back due
                    to how low cost it is.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="jingliu" enablePopover />
                </td>
                <td>
                  <p>
                    Jingliu takes advantage of E4+ Pela’s Ice RES down on top of
                    the DEF shred she applies, and with how skill point positive
                    she is, she’s a perfect match for Jingliu.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="imbibitor-lunae"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Imbibitor Lunae likes the skill point generation of Pela a
                    lot, but also enjoys the DEF shred that she applies from her
                    Ultimate. This is further enhanced by Basic Attacks or her
                    Skill adding more DEF shred with the Pearls Light Lone
                    equipped, can provide a very strong boost to overall damage.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'qingque' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Being extremely Skill Point hungry and normally built slow
                    for maximum damage allows Sparkle be Qingque’s strongest
                    supports. Sparkle is preferred over Bronya for the Skill
                    Point generation as well as the Quantum damage% Trace from
                    Sparkle.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
                </td>
                <td>
                  <p>
                    Fu Xuan lets Qingque become less reliant on RNG thanks to
                    her Crit Rate% buff from her Skill, while also providing the
                    necessary sustain she needs. Fu Xuan also fulfils the count
                    check for the Mono-Quantum version.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Silver Wolf provides some disgustingly strong DEF shred
                    numbers on top of being able to always implant Quantum in
                    the Mono-Quantum team, which pairs extremely well with the
                    Quantum set for additional 10 DEF shred for “free”.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'robin' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="feixiao" enablePopover />
                </td>
                <td>
                  <p>
                    Robin is the premiere and best support for Feixiao offering
                    her an absurd combination of buffs, on hit damage and action
                    advancement that isn't currently matched by any other
                    character in the game. Robin's buffs include: a massive 50%
                    DMG%, a truck load of flat ATK, and a total of 45% CRIT DMG
                    for Follow-Up Attacks. Let's not forget about the on hit
                    Physical damage for the entire team all alongside her
                    signature full team boost. The buffs alone are spectacular
                    for Feixiao and the other Follow-Up attackers she plays
                    alongside but the full team advancement does wonders for
                    granting you more total Ultimates in a fight.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="topaz" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz is an exceptional partner for Robin with absurd
                    two-way synergy between them. Topaz and Numby's frequent
                    Follow-up Attacks grant Robin significantly higher amounts
                    of energy while also triggering her Ultimate Concerto damage
                    much more often. The majority of Topaz's damage,
                    fortunately, is also from Follow-Up Attacks meaning she can
                    take full advantage of a full 45% crit damage boost from
                    Robin's kit!
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Tingyun can alleviate the drop in Energy Generation from non
                    Follow-Up Attack teams, and give her damage buffs to Robin
                    instead, which can amusingly turn Robin into a DPS in
                    certain comps.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                </td>
                <td>
                  <p>
                    Huohuo’s Energy Regeneration tied to her Ultimate allows
                    Robin to chain her own Ultimate leading to no downtime in
                    her offensive state.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'ruan-mei' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="firefly" enablePopover />
                </td>
                <td>
                  <p>
                    Firefly loves Ruan Mei as she provides everything she needs
                    to perform well - Break Efficiency, Speed and Break Delay.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="boothill" enablePopover />
                </td>
                <td>
                  <p>
                    Boothill being the current premium Break DPS loves
                    everything from Ruan Mei, be it RES PEN, Weakness Break
                    efficiency, speed, delay upon being Broken+delay when
                    recovering, DEF shred from E1 and even the Skill Point gain
                    from her Signature Light Cone.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Harmony Trailblazer is the second Break-Centric Support we
                    currently have, and it abuses every aspect of Ruan Mei
                    except the DMG% boosts, while also allowing Ruan Mei herself
                    to even do some damage from Harmony Trailblazers Super Break
                    mechanic.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                  <HSRCharacter mode="icon" slug="black-swan" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka and Black Swan use all of Ruan Mei’s buffs to their
                    full extent. Further investments like E1/E2 and her
                    Signature Light Cone also greatly benefit DoT teams.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'sampo' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="kafka" enablePopover />
                </td>
                <td>
                  <p>
                    Kafka is the de-facto DoT enabler and is the main synergy
                    for Sampo currently. Sampo at E6 does increase his own Wind
                    Shears damage, however Kafka can also re-trigger those
                    stronger Wind Shears with her Skill and Ultimate, with her
                    E1 and E2 increasing all members DoT on the enemy/self
                    respectively.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'seele' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle is one of the best supports for Seele currently,
                    providing very high CRIT DMG buffs from her Skill as well as
                    Action Advance and plenty of Skill Points that Seele just
                    loves to devour.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Silver Wolf applies a Quantum implant for Seele, allowing
                    her Trace to always be against negative Resistance enemies.
                    Her DEF shred also pairs very well with the Quantum 4-piece
                    set to always be against Quantum Weak enemies.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
                </td>
                <td>
                  <p>
                    Fu Xuan has a strong crit rate buff when her Skill is up
                    which helps fulfil Seele’s crit needs. Seele can also use Fu
                    Xuan for Mono-Quantum teams with little to no hindrance over
                    other Sustainers.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'serval' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="bronya" enablePopover />
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Being able to use both Bronya or Sparkle in a crit DPS setup
                    is extremely handy, as well as having a cheap Ultimate cost,
                    her damage is decent enough to clear endgame content fairly
                    well.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Owing to her low cost Ultimate, Serval is able to deal a
                    significant amount of Toughness Break very quickly by
                    chaining them. Some Light Cones can further increase the
                    amount of Ultimates she can output which makes her very
                    solid in Super Break teams.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'silver-wolf' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Providing an enormous amount of debuffs on both Skills,
                    Basic and Ultimate, combined with a relatively short
                    Ultimate rotation, allows Silver Wolf to be one of the best
                    debuffers for Acheron currently. Despite being fully Single
                    Target, Silver Wolf does have stronger debuffs than Pela.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                </td>
                <td>
                  <p>
                    She provides enough debuffs to make sure Dr. Ratio always
                    has a 100% chance to activate his Follow-Up Attacks, making
                    her the best debuffer for him. She also maxes out his
                    Deduction Trace for 50% increased damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="qingque" enablePopover />
                </td>
                <td>
                  <p>
                    Considering Qingque has a lot of innate self buffs, debuffs
                    on the enemies are normally the best way to scale Qingques
                    damage, other than Harmony buffers outside Sparkle. Her DEF
                    shred and RES PEN debuffs are also the perfect two to
                    combine for very huge damage boosts.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'sparkle' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="imbibitor-lunae"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Being the most Skill Point generous unit in the game makes
                    Sparkle the ideal candidate for Imbibitor Lunae. Her
                    Ultimate provides enough Skill Points for him to use a fully
                    Enhanced Basic Attack at all times and not worry about Skill
                    Point generation, while her Skill’s buff also lingers till
                    after his turn, which can affect his Ultimate when used
                    outside of his turn.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                  <HSRCharacter mode="icon" slug="clara" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle shines with Follow-Up Attack characters, especially
                    Dr Ratio and Clara, whose Follow-Up Attacks normally happen
                    outside of their turn. They benefit immensely from her
                    Skill’s CRIT DMG buff lingering in-between turns and
                    applying to Follow-Up Attacks, unlike Bronya’s.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
                </td>
                <td>
                  <p>
                    Fu Xuan activates Sparkle's trace Quantum bonus without
                    needing to compromise one of your damage-focused team slots.
                    Additionally, Fu Xuan plays into Sparkle's kit perfectly,
                    offering the entire team crit rate which synergises with the
                    massive amount of crit damage Sparkle grants with her Skill,
                    allowing the team to more fully take advantage of her buffs.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'sushang' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Harmony Trailblazer has allowed Sushang to be back to her
                    glory days thanks to Super Break. Being able to be built
                    full crit DPS or as a Hybrid, Harmony Trailblazer takes care
                    of the awkwardness of when enemies are Broken and still
                    provides a reason to invest in Break effect.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Ruan Mei’s delay on enemies recovering from the Broken
                    status allows Sushang to get a few more hits in while the
                    enemies are Broken, which allows Sushang’s E1 to refund her
                    Skill Points making it technically free. Not to mention the
                    Weakness Break efficiency synergy.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Allowing Sushang to always hit Physical Weak enemies and
                    always have a way to put enemies into the Broken state is
                    extremely strong for Sushang’s damage output, thanks to her
                    massive Bleeds upon Breaking enemies, and a damage boost
                    from DEF shred+RES down combo from Silver Wolf.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'tingyun' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="argenti" enablePopover />
                </td>
                <td>
                  <p>
                    Having the largest Ultimate cost in the game currently,
                    Argenti loves Tingyun’s Energy Battery and damage% buff
                    Ultimate along with attack% buff from her Skill, leading to
                    short windows of burst damage - which Argenti’s Ultimate is
                    all about.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="clara" enablePopover />
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                </td>
                <td>
                  <p>
                    Dr. Ratio and Clara receive very nice buffs from Tingyun,
                    especially at the start of the battle. With this, Ratio only
                    needs 10 energy to perform his Ultimate after Tingyun’s
                    Ultimate at E6, and having a buffer who attacks frequently
                    to trigger Ratio’s Ultimate stacks. Clara on the other hand
                    gets her Ultimate up instantly, while also being a great
                    user of these due to how slow she is.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'topaz' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin’s introduction allowed Topaz to be run as a Main DPS
                    in Fire Weak content and significantly increasing her output
                    in Dual-DPS teams by almost doubling her damage. Topaz is
                    also a very fast applicant of Robin's own damage through
                    Numby’s turns.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="feixiao" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz offers Feixiao some of the most Aureus stack
                    generation possible thanks to Numby and their constant
                    advancement. Enemies afflicted with Topaz's Proof of Debt
                    also take an absurd 50% additional damage from Follow-Up
                    attacks all on top of Topaz's respectable personal damage.
                    Bringing Topaz to Feixiao's teams will grant her some of the
                    most Ultimates and Damage amplification of all non-support
                    follow-up attackers. Topaz also has the option to be played
                    incredibly skill point positive opening up more flexible
                    team building and gameplay options for Feixiao.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
                </td>
                <td>
                  <p>
                    Topaz provides one of the necessary debuffs Dr. Ratio needs
                    for his Skill. With either E1 or her Light Cone, or with
                    both, she can completely fill the debuff needs for him as
                    well as apply some very strong Follow-Up Attack centric
                    debuffs on the enemy. Topaz also gets benefits out of Dr.
                    Ratio for when his Follow-Up Attack activates on the enemy
                    with the Mark, Numby advances forward resulting in even more
                    actions.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="clara" enablePopover />
                </td>
                <td>
                  <p>
                    Clara can benefit greatly from the debuffs that Topaz
                    provides, as well as have her help out with Single Target
                    content or in places where the enemies might attack a little
                    slow or infrequently.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                </td>
                <td>
                  <p>
                    Huohuo energy regeneration tied to her Ultimate allows Robin
                    to chain her own Ultimate leading to no downtime in her
                    'offensive' state.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'trailblazer-fire' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Probably one of the last main synergies for Fire Trailblazer
                    after Harmony Trailblazer has come out. Fire Trailblazer is
                    able to provide the best taunt in the game as well as
                    utilise the Trend Light Cone and stack an immense amount of
                    debuffs for Acheron to use her Ultimate much more
                    frequently.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'trailblazer-imaginary' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Being the sole Weakness Break efficiency buffer in the game
                    makes Ruan Mei the BiS buffer for Harmony Trailblazer - and
                    Super Break in general, due to how it scales based on the
                    amount of Toughness damage it deals.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="firefly" enablePopover />
                  <HSRCharacter mode="icon" slug="boothill" enablePopover />
                </td>
                <td>
                  <p>
                    Boothill and Firefly are our premium Break damage dealers.
                    While Firefly needs to be paired with Harmony MC, Boothill
                    doesn't but he can use Harmony Trailblazer’s Super Break on
                    elites and bosses to kill them faster.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sushang" enablePopover />
                  <HSRCharacter mode="icon" slug="xueyi" enablePopover />
                </td>
                <td>
                  <p>
                    Sushang and Xueyi have gotten a resurgence due to Harmony
                    Trailblazer being added to the game, allowing them to be run
                    as a full crit DPS or Hybrid, as both take full advantage of
                    Breaking, as well as enemies being in the Broken state.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="welt" enablePopover />
                </td>
                <td>
                  <p>
                    In Imaginary-specific content, Welt is able to take up the
                    role of the Main Breaker by delaying them into the void,
                    never to move again once Broken, allowing you to run a
                    Sustain-free team with ease. Welt’s Skill bounces across
                    multiple enemies at random, but on Single Target enemies it
                    shreds Toughness bars.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'trailblazer-physical' && (
            <>
              <tr>
                <td className="char">None</td>
                <td>
                  <p>
                    Physical Trailblazer works fine as a F2P unit for basically
                    anyone early on, however does fall behind on basically
                    everything; Break, crit, DoT, etc and is better off using
                    another Path.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'welt' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="acheron" enablePopover />
                </td>
                <td>
                  <p>
                    Being a Nihility character places Welt as one of Acheron’s
                    potential partners, however Welt can also somewhat replace
                    the Sustain in most of her teams. Going for a
                    “Pseudo-Sustainer” position allows a Harmony buffer to push
                    Acheron's damage to the moon, without having the need to
                    worry about a Sustain if you can clear quick enough.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Thanks to his Ultimate, Welt is one of the best delayers in
                    the game, and Imaginary has the biggest delay upon Breaking.
                    When played in a Break team, this pushes his Skill to
                    deplete insane amounts of Toughness, furthermore, all of
                    Ruan Mei’s buffs are beneficial to his personal damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    With the introduction of Harmony Trailblazer, Welt has
                    surprisingly made a comeback for Imaginary-weak content,
                    sending enemies to the shadow realm upon Breaking. Having a
                    bounce skill which is on the higher end of Break Toughness
                    skills, alongside an Ultimate which further pushes enemies
                    back, allows him to be run “Sustain Free” in most scenarios
                    without suffering many drawbacks.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'xueyi' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
                </td>
                <td>
                  <p>
                    Ruan Mei provides the best all-rounded buffs for Xueyi in
                    both crit teams and Super Break teams, with her Break effect
                    double dipping for Xueyi - converting Break effect into DMG
                    % and Weakness Break efficiency activating more Follow-Up
                    Attacks. RES PEN% from Ruan Mei buffs her DPS as well.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="trailblazer-imaginary"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    Harmony Trailblazer allows Xueyi to be fully built as a crit
                    DPS while still having a sizable amount of Break for her
                    Trace, which converts Break effect to DMG%. She also has the
                    largest Toughness reducing Ultimate in the game which scales
                    Harmony Trailblazer’s Super Break extremely well.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Sparkle has decent synergy with Xueyi, as her crit damage
                    buff carrying over in between turns is very helpful for
                    Xueyi’s Follow-Up Attacks to deal a large amount of damage.
                    It also allows Xueyi to be run slow with ATK% boots for more
                    damage.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
                </td>
                <td>
                  <p>
                    Forcing Xueyi to always be up against Quantum Weak content
                    is an extremely strong standalone buff, allowing Xueyi to
                    get a lot more Follow-Up Attacks, along with increasing the
                    DEF shred and RES down on the enemies to boost her damage.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'yanqing' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="aventurine" enablePopover />
                  <HSRCharacter mode="icon" slug="gepard" enablePopover />
                </td>
                <td>
                  <p>
                    Proving the necessary shields for Yanqing to function puts
                    Aventurine and Gepard on top for his synergies, however
                    Gepard may lack in some scenarios due to how long his
                    Ultimate can take to get up and may run out of turns.
                    Aventurine on the other hand provides consistent shields
                    frequently enough to not fall off and allow Yanqing to keep
                    his buff up.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="robin" enablePopover />
                </td>
                <td>
                  <p>
                    Robin is a great choice for the Amplifier role in YQ teams -
                    she provides him with a lot of buffs and also extra turn,
                    further increasing his damage output.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="tingyun" enablePopover />
                </td>
                <td>
                  <p>
                    Tingyun is the usual go-to pick for hypercarries due to the
                    energy and buffs she provides - that makes her a great
                    option for YQ.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="sparkle" enablePopover />
                </td>
                <td>
                  <p>
                    Yanqing being Skill Point hungry and normally built slow for
                    maximum damage makes Sparkle one of Yanqing’s strongest
                    supports as her skill buff lasts after his turn, which
                    allows them to affect his Follow-Up Attacks.
                  </p>
                </td>
              </tr>
            </>
          )}
          {slug === 'yukong' && (
            <>
              <tr>
                <td className="char">
                  <HSRCharacter
                    mode="icon"
                    slug="imbibitor-lunae"
                    enablePopover
                  />
                </td>
                <td>
                  <p>
                    With Imbibitor Lunae being built on the slower side, Yukong
                    can take advantage of her speed tuning to always go right
                    before his turn and provide a lot of benefits, however, the
                    Skill Point consumption can be an annoyance to get right for
                    certain teams.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="char">
                  <HSRCharacter mode="icon" slug="argenti" enablePopover />
                </td>
                <td>
                  <p>
                    Argenti has the currently the biggest Ultimate cost in the
                    game and is on the slow side to get up, however with how
                    much damage is on it, makes it extremely strong for Yukong’s
                    buffs short window to shine.
                  </p>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </>
  );
};
