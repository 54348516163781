export const ApoCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 36.95,
    prev_app_rate: 24.39,
    current_avg_round: 3343,
    prev_avg_round: 3198
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.71,
    prev_app_rate: 1.28,
    current_avg_round: 3356,
    prev_avg_round: 3254
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.88,
    prev_app_rate: 2.01,
    current_avg_round: 3288,
    prev_avg_round: 3316
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 41.26,
    prev_app_rate: 40.38,
    current_avg_round: 3469,
    prev_avg_round: 3358
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 2.15,
    prev_app_rate: 1.17,
    current_avg_round: 3336,
    prev_avg_round: 3300
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 25.7,
    prev_app_rate: 7.91,
    current_avg_round: 3370,
    prev_avg_round: 3141
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 4.43,
    prev_app_rate: 3.58,
    current_avg_round: 3286,
    prev_avg_round: 3137
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 7.54,
    prev_app_rate: 10.79,
    current_avg_round: 3456,
    prev_avg_round: 3462
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 22.22,
    prev_app_rate: 16.84,
    current_avg_round: 3411,
    prev_avg_round: 3302
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 1.3,
    prev_app_rate: 8.87,
    current_avg_round: 3279,
    prev_avg_round: 3157
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.06,
    prev_app_rate: 0.01,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 3.09,
    prev_app_rate: 31.01,
    current_avg_round: 3322,
    prev_avg_round: 3343
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 52.25,
    prev_app_rate: 0,
    current_avg_round: 3516,
    prev_avg_round: 0
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    rarity: '4*',
    current_app_rate: 0.07,
    prev_app_rate: 0.02,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 50.5,
    prev_app_rate: 77.26,
    current_avg_round: 3416,
    prev_avg_round: 3640
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 32.72,
    prev_app_rate: 31.33,
    current_avg_round: 3352,
    prev_avg_round: 3249
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 66.1,
    prev_app_rate: 81.92,
    current_avg_round: 3422,
    prev_avg_round: 3613
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 2.29,
    prev_app_rate: 1.89,
    current_avg_round: 3372,
    prev_avg_round: 3337
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 1.32,
    prev_app_rate: 1.39,
    current_avg_round: 3272,
    prev_avg_round: 3170
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.23,
    prev_app_rate: 1.07,
    current_avg_round: 3282,
    prev_avg_round: 3262
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 2.92,
    prev_app_rate: 0.13,
    current_avg_round: 3428,
    prev_avg_round: 0
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 1.58,
    prev_app_rate: 3.9,
    current_avg_round: 3330,
    prev_avg_round: 3379
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.04,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 29.03,
    prev_app_rate: 25.19,
    current_avg_round: 3367,
    prev_avg_round: 3318
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    rarity: '4*',
    current_app_rate: 55.03,
    prev_app_rate: 87.48,
    current_avg_round: 3410,
    prev_avg_round: 3616
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 4.18,
    prev_app_rate: 10.32,
    current_avg_round: 3314,
    prev_avg_round: 3164
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 1.91,
    prev_app_rate: 1.44,
    current_avg_round: 3374,
    prev_avg_round: 3184
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 20.2,
    prev_app_rate: 0,
    current_avg_round: 3352,
    prev_avg_round: 0
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 3.24,
    prev_app_rate: 0.07,
    current_avg_round: 3311,
    prev_avg_round: 0
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 15.19,
    prev_app_rate: 7.56,
    current_avg_round: 3346,
    prev_avg_round: 3261
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 24.04,
    prev_app_rate: 4.4,
    current_avg_round: 3380,
    prev_avg_round: 3147
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.26,
    prev_app_rate: 0.29,
    current_avg_round: 3336,
    prev_avg_round: 3285
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 16.03,
    prev_app_rate: 13.18,
    current_avg_round: 3374,
    prev_avg_round: 3268
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 1.16,
    prev_app_rate: 2.2,
    current_avg_round: 3323,
    prev_avg_round: 3249
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.17,
    prev_app_rate: 0.04,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - Hunt',
    rarity: '4*',
    current_app_rate: 25.59,
    prev_app_rate: 14.1,
    current_avg_round: 3367,
    prev_avg_round: 3308
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.21,
    prev_app_rate: 0.04,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 5.29,
    prev_app_rate: 0,
    current_avg_round: 3452,
    prev_avg_round: 0
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.29,
    prev_app_rate: 0.21,
    current_avg_round: 3381,
    prev_avg_round: 3271
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 16.78,
    prev_app_rate: 20.67,
    current_avg_round: 3312,
    prev_avg_round: 3252
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.19,
    prev_app_rate: 0.56,
    current_avg_round: 0,
    prev_avg_round: 3134
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 62.45,
    prev_app_rate: 44.58,
    current_avg_round: 3476,
    prev_avg_round: 3385
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 81.52,
    prev_app_rate: 94.21,
    current_avg_round: 3410,
    prev_avg_round: 3608
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.79,
    prev_app_rate: 0.15,
    current_avg_round: 3295,
    prev_avg_round: 0
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.91,
    prev_app_rate: 1.4,
    current_avg_round: 3342,
    prev_avg_round: 3055
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.11,
    prev_app_rate: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 7.87,
    prev_app_rate: 21.21,
    current_avg_round: 3294,
    prev_avg_round: 3224
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 26.18,
    prev_app_rate: 33.48,
    current_avg_round: 3321,
    prev_avg_round: 3235
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.23,
    prev_app_rate: 0.62,
    current_avg_round: 0,
    prev_avg_round: 3337
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 10.03,
    prev_app_rate: 18.43,
    current_avg_round: 3320,
    prev_avg_round: 3299
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 25.07,
    prev_app_rate: 30.4,
    current_avg_round: 3506,
    prev_avg_round: 3377
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.28,
    prev_app_rate: 0.47,
    current_avg_round: 0,
    prev_avg_round: 3102
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.11,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.72,
    prev_app_rate: 0.07,
    current_avg_round: 3280,
    prev_avg_round: 0
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.13,
    prev_app_rate: 0.39,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 8.55,
    prev_app_rate: 19.51,
    current_avg_round: 3364,
    prev_avg_round: 3369
  }
];
