export const MoCDuos = [
  {
    char: 'Moze',
    round: 5.48,
    char_1: 'Feixiao',
    app_rate_1: 77.97,
    avg_round_1: 5.77,
    app_flat_1: 147,
    char_2: 'Robin',
    app_rate_2: 74.78,
    avg_round_2: 5.44,
    app_flat_2: 138,
    char_3: 'Aventurine',
    app_rate_3: 60.0,
    avg_round_3: 5.32,
    app_flat_3: 114,
    char_4: 'Gallagher',
    app_rate_4: 16.52,
    avg_round_4: 5.77,
    app_flat_4: 26,
    char_5: 'dr-ratio',
    app_rate_5: 14.49,
    avg_round_5: 5.52,
    app_flat_5: 27,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 11.59,
    avg_round_6: 7.65,
    app_flat_6: 23,
    char_7: 'fu-xuan',
    app_rate_7: 6.96,
    avg_round_7: 6.54,
    app_flat_7: 13,
    char_8: 'Luocha',
    app_rate_8: 6.09,
    avg_round_8: 6.71,
    app_flat_8: 14,
    char_9: 'topaz',
    app_rate_9: 5.8,
    avg_round_9: 4.75,
    app_flat_9: 8,
    char_10: 'Huohuo',
    app_rate_10: 5.8,
    avg_round_10: 7.5,
    app_flat_10: 14,
    char_11: 'Yunli',
    app_rate_11: 3.19,
    avg_round_11: 6.5,
    app_flat_11: 8,
    char_12: 'ruan-mei',
    app_rate_12: 3.19,
    avg_round_12: 4,
    app_flat_12: 4,
    char_13: 'Jiaoqiu',
    app_rate_13: 2.9,
    avg_round_13: 6,
    app_flat_13: 2,
    char_14: 'Sparkle',
    app_rate_14: 2.32,
    avg_round_14: 8,
    app_flat_14: 5,
    char_15: 'Bronya',
    app_rate_15: 2.03,
    avg_round_15: 7.5,
    app_flat_15: 2,
    char_16: 'Tingyun',
    app_rate_16: 1.45,
    avg_round_16: 6.2,
    app_flat_16: 5,
    char_17: 'Lynx',
    app_rate_17: 1.16,
    avg_round_17: 6.5,
    app_flat_17: 4,
    char_18: 'Clara',
    app_rate_18: 0.87,
    avg_round_18: 5.5,
    app_flat_18: 2,
    char_19: 'Hanya',
    app_rate_19: 0.58,
    avg_round_19: 7,
    app_flat_19: 1,
    char_20: 'silver-wolf',
    app_rate_20: 0.29,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'march-7th',
    app_rate_21: 0.29,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Blade',
    app_rate_22: 0.29,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Jingliu',
    app_rate_23: 0.29,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Jade',
    app_rate_24: 0.29,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Firefly',
    app_rate_25: 0.29,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.29,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Gepard',
    app_rate_27: 0.29,
    avg_round_27: 6,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Feixiao',
    round: 6.07,
    char_1: 'Robin',
    app_rate_1: 83.8,
    avg_round_1: 5.79,
    app_flat_1: 1468,
    char_2: 'Aventurine',
    app_rate_2: 49.24,
    avg_round_2: 5.52,
    app_flat_2: 865,
    char_3: 'topaz',
    app_rate_3: 45.1,
    avg_round_3: 5.82,
    app_flat_3: 729,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 41.05,
    avg_round_4: 6.33,
    app_flat_4: 841,
    char_5: 'fu-xuan',
    app_rate_5: 13.47,
    avg_round_5: 6.2,
    app_flat_5: 249,
    char_6: 'Gallagher',
    app_rate_6: 11.51,
    avg_round_6: 6.51,
    app_flat_6: 232,
    char_7: 'Huohuo',
    app_rate_7: 10.61,
    avg_round_7: 6.97,
    app_flat_7: 232,
    char_8: 'Moze',
    app_rate_8: 8.65,
    avg_round_8: 5.77,
    app_flat_8: 147,
    char_9: 'Bronya',
    app_rate_9: 8.49,
    avg_round_9: 7.05,
    app_flat_9: 128,
    char_10: 'Luocha',
    app_rate_10: 7.68,
    avg_round_10: 6.88,
    app_flat_10: 158,
    char_11: 'Sparkle',
    app_rate_11: 5.82,
    avg_round_11: 7.53,
    app_flat_11: 88,
    char_12: 'ruan-mei',
    app_rate_12: 4.21,
    avg_round_12: 8.04,
    app_flat_12: 51,
    char_13: 'Jade',
    app_rate_13: 1.35,
    avg_round_13: 9.67,
    app_flat_13: 3,
    char_14: 'Yunli',
    app_rate_14: 1.22,
    avg_round_14: 6.86,
    app_flat_14: 21,
    char_15: 'Clara',
    app_rate_15: 1.09,
    avg_round_15: 8.08,
    app_flat_15: 26,
    char_16: 'dr-ratio',
    app_rate_16: 1.06,
    avg_round_16: 7.74,
    app_flat_16: 19,
    char_17: 'Bailu',
    app_rate_17: 0.68,
    avg_round_17: 7.36,
    app_flat_17: 14,
    char_18: 'Lynx',
    app_rate_18: 0.61,
    avg_round_18: 7.65,
    app_flat_18: 17,
    char_19: 'Gepard',
    app_rate_19: 0.45,
    avg_round_19: 7.82,
    app_flat_19: 11,
    char_20: 'Blade',
    app_rate_20: 0.45,
    avg_round_20: 7.43,
    app_flat_20: 7,
    char_21: 'Jiaoqiu',
    app_rate_21: 0.42,
    avg_round_21: 6,
    app_flat_21: 4,
    char_22: 'Pela',
    app_rate_22: 0.39,
    avg_round_22: 8.4,
    app_flat_22: 7,
    char_23: 'Tingyun',
    app_rate_23: 0.32,
    avg_round_23: 8.33,
    app_flat_23: 3,
    char_24: 'march-7th',
    app_rate_24: 0.29,
    avg_round_24: 4.33,
    app_flat_24: 3,
    char_25: 'silver-wolf',
    app_rate_25: 0.29,
    avg_round_25: 8.4,
    app_flat_25: 5,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.26,
    avg_round_26: 9.5,
    app_flat_26: 4,
    char_27: 'Kafka',
    app_rate_27: 0.19,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'black-swan',
    app_rate_28: 0.16,
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'Natasha',
    app_rate_29: 0.16,
    avg_round_29: 9.0,
    app_flat_29: 4,
    char_30: 'jing-yuan',
    app_rate_30: 0.13,
    avg_round_30: 9,
    app_flat_30: 2
  },
  {
    char: 'Robin',
    round: 6.69,
    char_1: 'Aventurine',
    app_rate_1: 43.07,
    avg_round_1: 6.5,
    app_flat_1: 1515,
    char_2: 'Feixiao',
    app_rate_2: 41.28,
    avg_round_2: 5.79,
    app_flat_2: 1468,
    char_3: 'topaz',
    app_rate_3: 35.98,
    avg_round_3: 6.5,
    app_flat_3: 1179,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 23.8,
    avg_round_4: 6.72,
    app_flat_4: 1019,
    char_5: 'Huohuo',
    app_rate_5: 21.12,
    avg_round_5: 7.27,
    app_flat_5: 793,
    char_6: 'Yunli',
    app_rate_6: 19.59,
    avg_round_6: 7.31,
    app_flat_6: 781,
    char_7: 'dr-ratio',
    app_rate_7: 18.48,
    avg_round_7: 8.18,
    app_flat_7: 684,
    char_8: 'fu-xuan',
    app_rate_8: 13.19,
    avg_round_8: 7.07,
    app_flat_8: 438,
    char_9: 'Tingyun',
    app_rate_9: 10.8,
    avg_round_9: 7.27,
    app_flat_9: 411,
    char_10: 'Sparkle',
    app_rate_10: 7.98,
    avg_round_10: 7.55,
    app_flat_10: 152,
    char_11: 'Luocha',
    app_rate_11: 7.89,
    avg_round_11: 7.44,
    app_flat_11: 293,
    char_12: 'Clara',
    app_rate_12: 7.54,
    avg_round_12: 8.72,
    app_flat_12: 320,
    char_13: 'Gallagher',
    app_rate_13: 7.13,
    avg_round_13: 6.55,
    app_flat_13: 261,
    char_14: 'Moze',
    app_rate_14: 4.09,
    avg_round_14: 5.44,
    app_flat_14: 138,
    char_15: 'black-swan',
    app_rate_15: 3.91,
    avg_round_15: 8.73,
    app_flat_15: 97,
    char_16: 'Bronya',
    app_rate_16: 3.59,
    avg_round_16: 6.85,
    app_flat_16: 52,
    char_17: 'Kafka',
    app_rate_17: 3.53,
    avg_round_17: 8.6,
    app_flat_17: 82,
    char_18: 'Jiaoqiu',
    app_rate_18: 3.52,
    avg_round_18: 7.09,
    app_flat_18: 63,
    char_19: 'Acheron',
    app_rate_19: 3.06,
    avg_round_19: 6.61,
    app_flat_19: 36,
    char_20: 'imbibitor-lunae',
    app_rate_20: 2.68,
    avg_round_20: 8,
    app_flat_20: 38,
    char_21: 'Jade',
    app_rate_21: 2.3,
    avg_round_21: 9.09,
    app_flat_21: 43,
    char_22: 'Jingliu',
    app_rate_22: 2.03,
    avg_round_22: 9.44,
    app_flat_22: 30,
    char_23: 'Pela',
    app_rate_23: 1.98,
    avg_round_23: 7.92,
    app_flat_23: 62,
    char_24: 'ruan-mei',
    app_rate_24: 1.96,
    avg_round_24: 8.56,
    app_flat_24: 34,
    char_25: 'Lynx',
    app_rate_25: 1.6,
    avg_round_25: 8.46,
    app_flat_25: 75,
    char_26: 'silver-wolf',
    app_rate_26: 1.49,
    avg_round_26: 8.64,
    app_flat_26: 44,
    char_27: 'Blade',
    app_rate_27: 1.36,
    avg_round_27: 8.56,
    app_flat_27: 34,
    char_28: 'jing-yuan',
    app_rate_28: 0.79,
    avg_round_28: 8.92,
    app_flat_28: 13,
    char_29: 'Bailu',
    app_rate_29: 0.67,
    avg_round_29: 8.57,
    app_flat_29: 26,
    char_30: 'Yanqing',
    app_rate_30: 0.51,
    avg_round_30: 8.11,
    app_flat_30: 19
  },
  {
    char: 'topaz',
    round: 6.73,
    char_1: 'Robin',
    app_rate_1: 82.62,
    avg_round_1: 6.5,
    app_flat_1: 1179,
    char_2: 'Aventurine',
    app_rate_2: 66.33,
    avg_round_2: 6.6,
    app_flat_2: 940,
    char_3: 'Feixiao',
    app_rate_3: 51.02,
    avg_round_3: 5.82,
    app_flat_3: 729,
    char_4: 'dr-ratio',
    app_rate_4: 32.07,
    avg_round_4: 8.28,
    app_flat_4: 469,
    char_5: 'Huohuo',
    app_rate_5: 10.51,
    avg_round_5: 7.12,
    app_flat_5: 170,
    char_6: 'fu-xuan',
    app_rate_6: 9.93,
    avg_round_6: 7.18,
    app_flat_6: 142,
    char_7: 'Yunli',
    app_rate_7: 8.55,
    avg_round_7: 7.08,
    app_flat_7: 131,
    char_8: 'Clara',
    app_rate_8: 5.27,
    avg_round_8: 8.1,
    app_flat_8: 84,
    char_9: 'Gallagher',
    app_rate_9: 5.13,
    avg_round_9: 6.42,
    app_flat_9: 81,
    char_10: 'Luocha',
    app_rate_10: 5.13,
    avg_round_10: 7.95,
    app_flat_10: 86,
    char_11: 'ruan-mei',
    app_rate_11: 4.98,
    avg_round_11: 8.66,
    app_flat_11: 62,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 4.22,
    avg_round_12: 8.22,
    app_flat_12: 71,
    char_13: 'Sparkle',
    app_rate_13: 4.04,
    avg_round_13: 7.91,
    app_flat_13: 55,
    char_14: 'Bronya',
    app_rate_14: 2.25,
    avg_round_14: 8.12,
    app_flat_14: 32,
    char_15: 'Tingyun',
    app_rate_15: 1.75,
    avg_round_15: 7.75,
    app_flat_15: 24,
    char_16: 'Jade',
    app_rate_16: 1.27,
    avg_round_16: 9.2,
    app_flat_16: 5,
    char_17: 'Moze',
    app_rate_17: 0.73,
    avg_round_17: 4.75,
    app_flat_17: 8,
    char_18: 'silver-wolf',
    app_rate_18: 0.69,
    avg_round_18: 8.89,
    app_flat_18: 9,
    char_19: 'Pela',
    app_rate_19: 0.44,
    avg_round_19: 8.75,
    app_flat_19: 8,
    char_20: 'Lynx',
    app_rate_20: 0.44,
    avg_round_20: 8.55,
    app_flat_20: 11,
    char_21: 'Jiaoqiu',
    app_rate_21: 0.36,
    avg_round_21: 9.33,
    app_flat_21: 3,
    char_22: 'march-7th',
    app_rate_22: 0.36,
    avg_round_22: 6.2,
    app_flat_22: 5,
    char_23: 'Gepard',
    app_rate_23: 0.29,
    avg_round_23: 9.0,
    app_flat_23: 5,
    char_24: 'jing-yuan',
    app_rate_24: 0.25,
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'Bailu',
    app_rate_25: 0.18,
    avg_round_25: 6,
    app_flat_25: 1,
    char_26: 'Acheron',
    app_rate_26: 0.15,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'trailblazer-imaginary',
    app_rate_27: 0.11,
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'Yanqing',
    app_rate_28: 0.11,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'Herta',
    app_rate_29: 0.11,
    avg_round_29: 4,
    app_flat_29: 1,
    char_30: 'Blade',
    app_rate_30: 0.11,
    avg_round_30: 8.33,
    app_flat_30: 3
  },
  {
    char: 'march-7th-swordmaster',
    round: 6.84,
    char_1: 'Robin',
    app_rate_1: 64.18,
    avg_round_1: 6.72,
    app_flat_1: 1019,
    char_2: 'Feixiao',
    app_rate_2: 54.53,
    avg_round_2: 6.33,
    app_flat_2: 841,
    char_3: 'Aventurine',
    app_rate_3: 29.5,
    avg_round_3: 6.72,
    app_flat_3: 503,
    char_4: 'Huohuo',
    app_rate_4: 19.09,
    avg_round_4: 7.82,
    app_flat_4: 327,
    char_5: 'fu-xuan',
    app_rate_5: 16.7,
    avg_round_5: 6.99,
    app_flat_5: 249,
    char_6: 'Gallagher',
    app_rate_6: 13.11,
    avg_round_6: 8.17,
    app_flat_6: 226,
    char_7: 'dr-ratio',
    app_rate_7: 11.53,
    avg_round_7: 8.84,
    app_flat_7: 206,
    char_8: 'Luocha',
    app_rate_8: 10.55,
    avg_round_8: 8.14,
    app_flat_8: 171,
    char_9: 'Yunli',
    app_rate_9: 10.46,
    avg_round_9: 8.31,
    app_flat_9: 183,
    char_10: 'ruan-mei',
    app_rate_10: 9.65,
    avg_round_10: 8.94,
    app_flat_10: 108,
    char_11: 'Bronya',
    app_rate_11: 8.5,
    avg_round_11: 8.19,
    app_flat_11: 102,
    char_12: 'Sparkle',
    app_rate_12: 7.9,
    avg_round_12: 8.5,
    app_flat_12: 96,
    char_13: 'trailblazer-imaginary',
    app_rate_13: 6.75,
    avg_round_13: 9.18,
    app_flat_13: 95,
    char_14: 'Clara',
    app_rate_14: 6.02,
    avg_round_14: 8.85,
    app_flat_14: 106,
    char_15: 'topaz',
    app_rate_15: 4.95,
    avg_round_15: 8.22,
    app_flat_15: 71,
    char_16: 'Tingyun',
    app_rate_16: 3.42,
    avg_round_16: 8.07,
    app_flat_16: 56,
    char_17: 'Blade',
    app_rate_17: 3.03,
    avg_round_17: 9.09,
    app_flat_17: 42,
    char_18: 'Firefly',
    app_rate_18: 2.6,
    avg_round_18: 9,
    app_flat_18: 23,
    char_19: 'Lynx',
    app_rate_19: 2.56,
    avg_round_19: 9.04,
    app_flat_19: 48,
    char_20: 'imbibitor-lunae',
    app_rate_20: 2.18,
    avg_round_20: 9,
    app_flat_20: 23,
    char_21: 'Moze',
    app_rate_21: 1.71,
    avg_round_21: 7.65,
    app_flat_21: 23,
    char_22: 'silver-wolf',
    app_rate_22: 1.49,
    avg_round_22: 9.04,
    app_flat_22: 24,
    char_23: 'Pela',
    app_rate_23: 1.28,
    avg_round_23: 8.25,
    app_flat_23: 20,
    char_24: 'Bailu',
    app_rate_24: 1.11,
    avg_round_24: 8.27,
    app_flat_24: 21,
    char_25: 'Jingliu',
    app_rate_25: 0.9,
    avg_round_25: 9.15,
    app_flat_25: 13,
    char_26: 'Boothill',
    app_rate_26: 0.81,
    avg_round_26: 7.6,
    app_flat_26: 10,
    char_27: 'Jiaoqiu',
    app_rate_27: 0.73,
    avg_round_27: 8.8,
    app_flat_27: 10,
    char_28: 'Yanqing',
    app_rate_28: 0.6,
    avg_round_28: 7.89,
    app_flat_28: 9,
    char_29: 'Sushang',
    app_rate_29: 0.56,
    avg_round_29: 8.71,
    app_flat_29: 7,
    char_30: 'black-swan',
    app_rate_30: 0.51,
    avg_round_30: 9,
    app_flat_30: 6
  },
  {
    char: 'Aventurine',
    round: 7.16,
    char_1: 'Robin',
    app_rate_1: 47.64,
    avg_round_1: 6.5,
    app_flat_1: 1515,
    char_2: 'topaz',
    app_rate_2: 31.94,
    avg_round_2: 6.6,
    app_flat_2: 940,
    char_3: 'Acheron',
    app_rate_3: 29.49,
    avg_round_3: 7.79,
    app_flat_3: 818,
    char_4: 'Feixiao',
    app_rate_4: 26.83,
    avg_round_4: 5.52,
    app_flat_4: 865,
    char_5: 'dr-ratio',
    app_rate_5: 20.56,
    avg_round_5: 8.28,
    app_flat_5: 676,
    char_6: 'Jiaoqiu',
    app_rate_6: 19.33,
    avg_round_6: 7.37,
    app_flat_6: 526,
    char_7: 'Pela',
    app_rate_7: 17.06,
    avg_round_7: 7.77,
    app_flat_7: 637,
    char_8: 'Sparkle',
    app_rate_8: 16.95,
    avg_round_8: 8.33,
    app_flat_8: 333,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 12.1,
    avg_round_9: 6.72,
    app_flat_9: 503,
    char_10: 'ruan-mei',
    app_rate_10: 9.88,
    avg_round_10: 8.74,
    app_flat_10: 273,
    char_11: 'silver-wolf',
    app_rate_11: 8.63,
    avg_round_11: 8.46,
    app_flat_11: 297,
    char_12: 'black-swan',
    app_rate_12: 8.16,
    avg_round_12: 8.38,
    app_flat_12: 247,
    char_13: 'Tingyun',
    app_rate_13: 7.09,
    avg_round_13: 8.28,
    app_flat_13: 253,
    char_14: 'Kafka',
    app_rate_14: 5.46,
    avg_round_14: 8.58,
    app_flat_14: 153,
    char_15: 'Yunli',
    app_rate_15: 5.24,
    avg_round_15: 7.96,
    app_flat_15: 196,
    char_16: 'imbibitor-lunae',
    app_rate_16: 4.9,
    avg_round_16: 8.53,
    app_flat_16: 130,
    char_17: 'Bronya',
    app_rate_17: 4.48,
    avg_round_17: 8.34,
    app_flat_17: 116,
    char_18: 'Clara',
    app_rate_18: 3.98,
    avg_round_18: 8.3,
    app_flat_18: 154,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 3.84,
    avg_round_19: 9.05,
    app_flat_19: 112,
    char_20: 'Moze',
    app_rate_20: 3.63,
    avg_round_20: 5.32,
    app_flat_20: 114,
    char_21: 'Firefly',
    app_rate_21: 2.29,
    avg_round_21: 9.11,
    app_flat_21: 51,
    char_22: 'Boothill',
    app_rate_22: 2.24,
    avg_round_22: 8.24,
    app_flat_22: 83,
    char_23: 'Jade',
    app_rate_23: 1.52,
    avg_round_23: 9.64,
    app_flat_23: 19,
    char_24: 'Yanqing',
    app_rate_24: 1.09,
    avg_round_24: 8.3,
    app_flat_24: 37,
    char_25: 'jing-yuan',
    app_rate_25: 0.75,
    avg_round_25: 9.0,
    app_flat_25: 14,
    char_26: 'Argenti',
    app_rate_26: 0.7,
    avg_round_26: 9.3,
    app_flat_26: 18,
    char_27: 'Jingliu',
    app_rate_27: 0.63,
    avg_round_27: 9.38,
    app_flat_27: 14,
    char_28: 'Blade',
    app_rate_28: 0.51,
    avg_round_28: 9.14,
    app_flat_28: 11,
    char_29: 'Guinaifen',
    app_rate_29: 0.47,
    avg_round_29: 8.31,
    app_flat_29: 13,
    char_30: 'Welt',
    app_rate_30: 0.37,
    avg_round_30: 9.17,
    app_flat_30: 12
  },
  {
    char: 'Jiaoqiu',
    round: 7.18,
    char_1: 'Acheron',
    app_rate_1: 88.97,
    avg_round_1: 7.15,
    app_flat_1: 1441,
    char_2: 'Pela',
    app_rate_2: 38.4,
    avg_round_2: 7.12,
    app_flat_2: 966,
    char_3: 'fu-xuan',
    app_rate_3: 37.7,
    avg_round_3: 6.82,
    app_flat_3: 617,
    char_4: 'Aventurine',
    app_rate_4: 31.22,
    avg_round_4: 7.37,
    app_flat_4: 526,
    char_5: 'Sparkle',
    app_rate_5: 27.55,
    avg_round_5: 6.97,
    app_flat_5: 103,
    char_6: 'silver-wolf',
    app_rate_6: 10.21,
    avg_round_6: 7.06,
    app_flat_6: 209,
    char_7: 'black-swan',
    app_rate_7: 8.94,
    avg_round_7: 7.79,
    app_flat_7: 146,
    char_8: 'Gallagher',
    app_rate_8: 8.34,
    avg_round_8: 7.19,
    app_flat_8: 148,
    char_9: 'Huohuo',
    app_rate_9: 7.38,
    avg_round_9: 7.9,
    app_flat_9: 124,
    char_10: 'Luocha',
    app_rate_10: 6.33,
    avg_round_10: 7.98,
    app_flat_10: 103,
    char_11: 'Robin',
    app_rate_11: 6.28,
    avg_round_11: 7.09,
    app_flat_11: 63,
    char_12: 'Bronya',
    app_rate_12: 4.81,
    avg_round_12: 8.5,
    app_flat_12: 6,
    char_13: 'Kafka',
    app_rate_13: 4.16,
    avg_round_13: 8.22,
    app_flat_13: 49,
    char_14: 'dr-ratio',
    app_rate_14: 3.9,
    avg_round_14: 7.72,
    app_flat_14: 65,
    char_15: 'ruan-mei',
    app_rate_15: 3.11,
    avg_round_15: 7.6,
    app_flat_15: 10,
    char_16: 'Gepard',
    app_rate_16: 2.97,
    avg_round_16: 7.9,
    app_flat_16: 41,
    char_17: 'Tingyun',
    app_rate_17: 1.27,
    avg_round_17: 8.0,
    app_flat_17: 17,
    char_18: 'imbibitor-lunae',
    app_rate_18: 1.02,
    avg_round_18: 9.2,
    app_flat_18: 5,
    char_19: 'Yunli',
    app_rate_19: 0.96,
    avg_round_19: 6.35,
    app_flat_19: 17,
    char_20: 'Bailu',
    app_rate_20: 0.65,
    avg_round_20: 8.86,
    app_flat_20: 11,
    char_21: 'Blade',
    app_rate_21: 0.57,
    avg_round_21: 8.8,
    app_flat_21: 7,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 0.48,
    avg_round_22: 8.8,
    app_flat_22: 10,
    char_23: 'Lynx',
    app_rate_23: 0.48,
    avg_round_23: 8.25,
    app_flat_23: 8,
    char_24: 'Welt',
    app_rate_24: 0.42,
    avg_round_24: 8.4,
    app_flat_24: 9,
    char_25: 'Feixiao',
    app_rate_25: 0.37,
    avg_round_25: 6,
    app_flat_25: 4,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.37,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Sampo',
    app_rate_27: 0.34,
    avg_round_27: 8.25,
    app_flat_27: 4,
    char_28: 'Argenti',
    app_rate_28: 0.34,
    avg_round_28: 7.75,
    app_flat_28: 4,
    char_29: 'Firefly',
    app_rate_29: 0.31,
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'topaz',
    app_rate_30: 0.28,
    avg_round_30: 9.33,
    app_flat_30: 3
  },
  {
    char: 'Yunli',
    round: 7.28,
    char_1: 'Robin',
    app_rate_1: 54.95,
    avg_round_1: 7.31,
    app_flat_1: 781,
    char_2: 'Tingyun',
    app_rate_2: 53.44,
    avg_round_2: 7.14,
    app_flat_2: 790,
    char_3: 'Huohuo',
    app_rate_3: 47.53,
    avg_round_3: 7.12,
    app_flat_3: 677,
    char_4: 'Sparkle',
    app_rate_4: 34.38,
    avg_round_4: 7.09,
    app_flat_4: 435,
    char_5: 'fu-xuan',
    app_rate_5: 19.19,
    avg_round_5: 7.55,
    app_flat_5: 229,
    char_6: 'Aventurine',
    app_rate_6: 13.28,
    avg_round_6: 7.96,
    app_flat_6: 196,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 10.88,
    avg_round_7: 8.31,
    app_flat_7: 183,
    char_8: 'ruan-mei',
    app_rate_8: 10.8,
    avg_round_8: 8.74,
    app_flat_8: 105,
    char_9: 'topaz',
    app_rate_9: 10.44,
    avg_round_9: 7.08,
    app_flat_9: 131,
    char_10: 'Luocha',
    app_rate_10: 8.0,
    avg_round_10: 7.57,
    app_flat_10: 122,
    char_11: 'Clara',
    app_rate_11: 5.51,
    avg_round_11: 8.71,
    app_flat_11: 81,
    char_12: 'Lynx',
    app_rate_12: 4.8,
    avg_round_12: 8.39,
    app_flat_12: 88,
    char_13: 'Pela',
    app_rate_13: 4.04,
    avg_round_13: 8.79,
    app_flat_13: 63,
    char_14: 'Jade',
    app_rate_14: 2.8,
    avg_round_14: 8.67,
    app_flat_14: 36,
    char_15: 'Jingliu',
    app_rate_15: 2.71,
    avg_round_15: 9.35,
    app_flat_15: 20,
    char_16: 'Gallagher',
    app_rate_16: 2.58,
    avg_round_16: 8.0,
    app_flat_16: 41,
    char_17: 'Bronya',
    app_rate_17: 1.78,
    avg_round_17: 9.1,
    app_flat_17: 20,
    char_18: 'Feixiao',
    app_rate_18: 1.69,
    avg_round_18: 6.86,
    app_flat_18: 21,
    char_19: 'Jiaoqiu',
    app_rate_19: 1.51,
    avg_round_19: 6.35,
    app_flat_19: 17,
    char_20: 'Bailu',
    app_rate_20: 1.16,
    avg_round_20: 8.83,
    app_flat_20: 18,
    char_21: 'Acheron',
    app_rate_21: 0.76,
    avg_round_21: 9.25,
    app_flat_21: 4,
    char_22: 'Blade',
    app_rate_22: 0.67,
    avg_round_22: 9.2,
    app_flat_22: 7,
    char_23: 'Boothill',
    app_rate_23: 0.58,
    avg_round_23: 9,
    app_flat_23: 4,
    char_24: 'dr-ratio',
    app_rate_24: 0.58,
    avg_round_24: 8.33,
    app_flat_24: 12,
    char_25: 'trailblazer-imaginary',
    app_rate_25: 0.58,
    avg_round_25: 10.0,
    app_flat_25: 6,
    char_26: 'black-swan',
    app_rate_26: 0.53,
    avg_round_26: 8.88,
    app_flat_26: 8,
    char_27: 'Moze',
    app_rate_27: 0.49,
    avg_round_27: 6.5,
    app_flat_27: 8,
    char_28: 'Herta',
    app_rate_28: 0.44,
    avg_round_28: 9.0,
    app_flat_28: 4,
    char_29: 'Gepard',
    app_rate_29: 0.44,
    avg_round_29: 10.0,
    app_flat_29: 5,
    char_30: 'Kafka',
    app_rate_30: 0.44,
    avg_round_30: 8,
    app_flat_30: 4
  },
  {
    char: 'Tingyun',
    round: 7.53,
    char_1: 'Sparkle',
    app_rate_1: 51.18,
    avg_round_1: 7.5,
    app_flat_1: 630,
    char_2: 'Yunli',
    app_rate_2: 47.32,
    avg_round_2: 7.14,
    app_flat_2: 790,
    char_3: 'Huohuo',
    app_rate_3: 42.01,
    avg_round_3: 7.29,
    app_flat_3: 620,
    char_4: 'imbibitor-lunae',
    app_rate_4: 30.29,
    avg_round_4: 8.09,
    app_flat_4: 283,
    char_5: 'Robin',
    app_rate_5: 26.83,
    avg_round_5: 7.27,
    app_flat_5: 411,
    char_6: 'fu-xuan',
    app_rate_6: 17.86,
    avg_round_6: 7.54,
    app_flat_6: 226,
    char_7: 'Aventurine',
    app_rate_7: 15.93,
    avg_round_7: 8.28,
    app_flat_7: 253,
    char_8: 'Luocha',
    app_rate_8: 11.8,
    avg_round_8: 7.84,
    app_flat_8: 131,
    char_9: 'ruan-mei',
    app_rate_9: 8.65,
    avg_round_9: 8.37,
    app_flat_9: 69,
    char_10: 'Clara',
    app_rate_10: 7.67,
    avg_round_10: 8.71,
    app_flat_10: 124,
    char_11: 'dr-ratio',
    app_rate_11: 4.37,
    avg_round_11: 8.15,
    app_flat_11: 78,
    char_12: 'Pela',
    app_rate_12: 3.74,
    avg_round_12: 8.55,
    app_flat_12: 55,
    char_13: 'Lynx',
    app_rate_13: 3.3,
    avg_round_13: 8.43,
    app_flat_13: 69,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 3.15,
    avg_round_14: 8.07,
    app_flat_14: 56,
    char_15: 'Gallagher',
    app_rate_15: 2.91,
    avg_round_15: 7.83,
    app_flat_15: 46,
    char_16: 'Jingliu',
    app_rate_16: 2.4,
    avg_round_16: 9.33,
    app_flat_16: 15,
    char_17: 'Bronya',
    app_rate_17: 2.24,
    avg_round_17: 9.29,
    app_flat_17: 26,
    char_18: 'jing-yuan',
    app_rate_18: 2.24,
    avg_round_18: 8.26,
    app_flat_18: 19,
    char_19: 'Argenti',
    app_rate_19: 1.93,
    avg_round_19: 8.83,
    app_flat_19: 24,
    char_20: 'topaz',
    app_rate_20: 1.89,
    avg_round_20: 7.75,
    app_flat_20: 24,
    char_21: 'Jiaoqiu',
    app_rate_21: 1.77,
    avg_round_21: 8.0,
    app_flat_21: 17,
    char_22: 'silver-wolf',
    app_rate_22: 1.49,
    avg_round_22: 7.56,
    app_flat_22: 25,
    char_23: 'black-swan',
    app_rate_23: 1.42,
    avg_round_23: 8.73,
    app_flat_23: 15,
    char_24: 'Kafka',
    app_rate_24: 1.14,
    avg_round_24: 8.75,
    app_flat_24: 8,
    char_25: 'Yanqing',
    app_rate_25: 1.02,
    avg_round_25: 8.89,
    app_flat_25: 15,
    char_26: 'Bailu',
    app_rate_26: 0.94,
    avg_round_26: 8.85,
    app_flat_26: 13,
    char_27: 'Hanya',
    app_rate_27: 0.94,
    avg_round_27: 9.38,
    app_flat_27: 14,
    char_28: 'Seele',
    app_rate_28: 0.51,
    avg_round_28: 7.67,
    app_flat_28: 3,
    char_29: 'Feixiao',
    app_rate_29: 0.39,
    avg_round_29: 8.33,
    app_flat_29: 3,
    char_30: 'Blade',
    app_rate_30: 0.35,
    avg_round_30: 9,
    app_flat_30: 5
  },
  {
    char: 'fu-xuan',
    round: 7.55,
    char_1: 'Acheron',
    app_rate_1: 58.26,
    avg_round_1: 7.55,
    app_flat_1: 1326,
    char_2: 'Pela',
    app_rate_2: 34.08,
    avg_round_2: 7.61,
    app_flat_2: 989,
    char_3: 'Sparkle',
    app_rate_3: 31.72,
    avg_round_3: 7.89,
    app_flat_3: 370,
    char_4: 'silver-wolf',
    app_rate_4: 25.69,
    avg_round_4: 7.86,
    app_flat_4: 625,
    char_5: 'Jiaoqiu',
    app_rate_5: 23.7,
    avg_round_5: 6.82,
    app_flat_5: 617,
    char_6: 'Robin',
    app_rate_6: 14.81,
    avg_round_6: 7.07,
    app_flat_6: 438,
    char_7: 'black-swan',
    app_rate_7: 12.96,
    avg_round_7: 8.36,
    app_flat_7: 301,
    char_8: 'ruan-mei',
    app_rate_8: 12.02,
    avg_round_8: 8.59,
    app_flat_8: 232,
    char_9: 'Kafka',
    app_rate_9: 9.9,
    avg_round_9: 8.59,
    app_flat_9: 217,
    char_10: 'Tingyun',
    app_rate_10: 8.07,
    avg_round_10: 7.54,
    app_flat_10: 226,
    char_11: 'Yunli',
    app_rate_11: 7.68,
    avg_round_11: 7.55,
    app_flat_11: 229,
    char_12: 'Feixiao',
    app_rate_12: 7.45,
    avg_round_12: 6.2,
    app_flat_12: 249,
    char_13: 'Bronya',
    app_rate_13: 6.97,
    avg_round_13: 8.23,
    app_flat_13: 85,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 6.95,
    avg_round_14: 6.99,
    app_flat_14: 249,
    char_15: 'topaz',
    app_rate_15: 4.85,
    avg_round_15: 7.18,
    app_flat_15: 142,
    char_16: 'Jingliu',
    app_rate_16: 4.48,
    avg_round_16: 8.84,
    app_flat_16: 49,
    char_17: 'Clara',
    app_rate_17: 4.0,
    avg_round_17: 8.73,
    app_flat_17: 109,
    char_18: 'imbibitor-lunae',
    app_rate_18: 3.96,
    avg_round_18: 8.26,
    app_flat_18: 82,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 3.88,
    avg_round_19: 8.08,
    app_flat_19: 91,
    char_20: 'dr-ratio',
    app_rate_20: 2.76,
    avg_round_20: 9.11,
    app_flat_20: 88,
    char_21: 'Firefly',
    app_rate_21: 2.58,
    avg_round_21: 8.75,
    app_flat_21: 40,
    char_22: 'Boothill',
    app_rate_22: 2.52,
    avg_round_22: 8.11,
    app_flat_22: 88,
    char_23: 'Seele',
    app_rate_23: 2.4,
    avg_round_23: 8.73,
    app_flat_23: 21,
    char_24: 'Blade',
    app_rate_24: 1.97,
    avg_round_24: 8.57,
    app_flat_24: 35,
    char_25: 'Qingque',
    app_rate_25: 1.19,
    avg_round_25: 8.6,
    app_flat_25: 20,
    char_26: 'Jade',
    app_rate_26: 1.17,
    avg_round_26: 8.44,
    app_flat_26: 18,
    char_27: 'Guinaifen',
    app_rate_27: 0.6,
    avg_round_27: 9.09,
    app_flat_27: 21,
    char_28: 'jing-yuan',
    app_rate_28: 0.57,
    avg_round_28: 9.0,
    app_flat_28: 8,
    char_29: 'Moze',
    app_rate_29: 0.43,
    avg_round_29: 6.54,
    app_flat_29: 13,
    char_30: 'Argenti',
    app_rate_30: 0.32,
    avg_round_30: 9.33,
    app_flat_30: 3
  },
  {
    char: 'Boothill',
    round: 7.66,
    char_1: 'ruan-mei',
    app_rate_1: 92.12,
    avg_round_1: 7.62,
    app_flat_1: 663,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 56.06,
    avg_round_2: 8.01,
    app_flat_2: 457,
    char_3: 'Gallagher',
    app_rate_3: 43.57,
    avg_round_3: 7.47,
    app_flat_3: 403,
    char_4: 'Bronya',
    app_rate_4: 42.38,
    avg_round_4: 7.17,
    app_flat_4: 262,
    char_5: 'Pela',
    app_rate_5: 15.84,
    avg_round_5: 7,
    app_flat_5: 29,
    char_6: 'Luocha',
    app_rate_6: 10.86,
    avg_round_6: 8.17,
    app_flat_6: 104,
    char_7: 'fu-xuan',
    app_rate_7: 10.56,
    avg_round_7: 8.11,
    app_flat_7: 88,
    char_8: 'Aventurine',
    app_rate_8: 9.52,
    avg_round_8: 8.24,
    app_flat_8: 83,
    char_9: 'Huohuo',
    app_rate_9: 4.83,
    avg_round_9: 7.79,
    app_flat_9: 42,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 1.41,
    avg_round_10: 7.6,
    app_flat_10: 10,
    char_11: 'Firefly',
    app_rate_11: 1.34,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Gepard',
    app_rate_12: 1.26,
    avg_round_12: 6.3,
    app_flat_12: 10,
    char_13: 'Robin',
    app_rate_13: 1.19,
    avg_round_13: 8.33,
    app_flat_13: 6,
    char_14: 'Yunli',
    app_rate_14: 0.97,
    avg_round_14: 9,
    app_flat_14: 4,
    char_15: 'Sparkle',
    app_rate_15: 0.74,
    avg_round_15: 9.67,
    app_flat_15: 5,
    char_16: 'Jiaoqiu',
    app_rate_16: 0.67,
    avg_round_16: 6.25,
    app_flat_16: 4,
    char_17: 'Clara',
    app_rate_17: 0.67,
    avg_round_17: 9.17,
    app_flat_17: 6,
    char_18: 'silver-wolf',
    app_rate_18: 0.67,
    avg_round_18: 8.67,
    app_flat_18: 5,
    char_19: 'Argenti',
    app_rate_19: 0.67,
    avg_round_19: 9.2,
    app_flat_19: 5,
    char_20: 'Bailu',
    app_rate_20: 0.52,
    avg_round_20: 8,
    app_flat_20: 3,
    char_21: 'Acheron',
    app_rate_21: 0.52,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Kafka',
    app_rate_22: 0.45,
    avg_round_22: 8.67,
    app_flat_22: 3,
    char_23: 'Lynx',
    app_rate_23: 0.37,
    avg_round_23: 9.25,
    app_flat_23: 4,
    char_24: 'Luka',
    app_rate_24: 0.37,
    avg_round_24: 9.0,
    app_flat_24: 4,
    char_25: 'Hanya',
    app_rate_25: 0.37,
    avg_round_25: 7.67,
    app_flat_25: 3,
    char_26: 'Misha',
    app_rate_26: 0.37,
    avg_round_26: 8.5,
    app_flat_26: 4,
    char_27: 'Herta',
    app_rate_27: 0.22,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Jingliu',
    app_rate_28: 0.22,
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'Xueyi',
    app_rate_29: 0.22,
    avg_round_29: 9,
    app_flat_29: 1,
    char_30: 'black-swan',
    app_rate_30: 0.15,
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'Acheron',
    round: 7.73,
    char_1: 'Pela',
    app_rate_1: 50.79,
    avg_round_1: 7.68,
    app_flat_1: 1938,
    char_2: 'fu-xuan',
    app_rate_2: 47.65,
    avg_round_2: 7.55,
    app_flat_2: 1326,
    char_3: 'Jiaoqiu',
    app_rate_3: 45.75,
    avg_round_3: 7.15,
    app_flat_3: 1441,
    char_4: 'silver-wolf',
    app_rate_4: 28.02,
    avg_round_4: 7.88,
    app_flat_4: 948,
    char_5: 'Sparkle',
    app_rate_5: 26.93,
    avg_round_5: 7.67,
    app_flat_5: 147,
    char_6: 'Aventurine',
    app_rate_6: 24.49,
    avg_round_6: 7.79,
    app_flat_6: 818,
    char_7: 'black-swan',
    app_rate_7: 21.51,
    avg_round_7: 8.5,
    app_flat_7: 654,
    char_8: 'Kafka',
    app_rate_8: 15.25,
    avg_round_8: 8.5,
    app_flat_8: 436,
    char_9: 'Huohuo',
    app_rate_9: 7.56,
    avg_round_9: 8.42,
    app_flat_9: 220,
    char_10: 'Gallagher',
    app_rate_10: 7.02,
    avg_round_10: 8.14,
    app_flat_10: 220,
    char_11: 'Bronya',
    app_rate_11: 5.29,
    avg_round_11: 8.5,
    app_flat_11: 2,
    char_12: 'Luocha',
    app_rate_12: 5.22,
    avg_round_12: 8.24,
    app_flat_12: 148,
    char_13: 'Gepard',
    app_rate_13: 3.87,
    avg_round_13: 8.0,
    app_flat_13: 91,
    char_14: 'ruan-mei',
    app_rate_14: 2.86,
    avg_round_14: 9.0,
    app_flat_14: 19,
    char_15: 'Robin',
    app_rate_15: 2.81,
    avg_round_15: 6.61,
    app_flat_15: 36,
    char_16: 'Guinaifen',
    app_rate_16: 1.02,
    avg_round_16: 9.09,
    app_flat_16: 42,
    char_17: 'Bailu',
    app_rate_17: 1.0,
    avg_round_17: 9.0,
    app_flat_17: 27,
    char_18: 'Welt',
    app_rate_18: 0.7,
    avg_round_18: 9.0,
    app_flat_18: 22,
    char_19: 'Lynx',
    app_rate_19: 0.41,
    avg_round_19: 8,
    app_flat_19: 6,
    char_20: 'Sampo',
    app_rate_20: 0.29,
    avg_round_20: 9.6,
    app_flat_20: 9,
    char_21: 'Yunli',
    app_rate_21: 0.25,
    avg_round_21: 9.25,
    app_flat_21: 4,
    char_22: 'Luka',
    app_rate_22: 0.19,
    avg_round_22: 9.0,
    app_flat_22: 13,
    char_23: 'Jingliu',
    app_rate_23: 0.12,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Boothill',
    app_rate_24: 0.1,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'march-7th',
    app_rate_25: 0.1,
    avg_round_25: 8,
    app_flat_25: 3,
    char_26: 'Clara',
    app_rate_26: 0.09,
    avg_round_26: 9,
    app_flat_26: 2,
    char_27: 'Tingyun',
    app_rate_27: 0.06,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: 0.06,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'dr-ratio',
    app_rate_29: 0.06,
    avg_round_29: 7.5,
    app_flat_29: 2,
    char_30: 'Blade',
    app_rate_30: 0.06,
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'Pela',
    round: 7.76,
    char_1: 'Acheron',
    app_rate_1: 82.3,
    avg_round_1: 7.68,
    app_flat_1: 1938,
    char_2: 'fu-xuan',
    app_rate_2: 45.17,
    avg_round_2: 7.61,
    app_flat_2: 989,
    char_3: 'Jiaoqiu',
    app_rate_3: 32.0,
    avg_round_3: 7.12,
    app_flat_3: 966,
    char_4: 'silver-wolf',
    app_rate_4: 25.09,
    avg_round_4: 8.07,
    app_flat_4: 683,
    char_5: 'Aventurine',
    app_rate_5: 22.95,
    avg_round_5: 7.77,
    app_flat_5: 637,
    char_6: 'Sparkle',
    app_rate_6: 13.45,
    avg_round_6: 9.02,
    app_flat_6: 79,
    char_7: 'Bronya',
    app_rate_7: 8.98,
    avg_round_7: 9.03,
    app_flat_7: 58,
    char_8: 'ruan-mei',
    app_rate_8: 8.36,
    avg_round_8: 9.21,
    app_flat_8: 46,
    char_9: 'Gallagher',
    app_rate_9: 7.42,
    avg_round_9: 8.05,
    app_flat_9: 190,
    char_10: 'Huohuo',
    app_rate_10: 7.16,
    avg_round_10: 8.54,
    app_flat_10: 177,
    char_11: 'Luocha',
    app_rate_11: 5.56,
    avg_round_11: 8.31,
    app_flat_11: 126,
    char_12: 'black-swan',
    app_rate_12: 5.37,
    avg_round_12: 8.42,
    app_flat_12: 145,
    char_13: 'Boothill',
    app_rate_13: 5.02,
    avg_round_13: 7,
    app_flat_13: 29,
    char_14: 'Jingliu',
    app_rate_14: 4.9,
    avg_round_14: 9.24,
    app_flat_14: 71,
    char_15: 'Gepard',
    app_rate_15: 4.03,
    avg_round_15: 7.91,
    app_flat_15: 74,
    char_16: 'Robin',
    app_rate_16: 2.95,
    avg_round_16: 7.92,
    app_flat_16: 62,
    char_17: 'dr-ratio',
    app_rate_17: 2.4,
    avg_round_17: 8.29,
    app_flat_17: 74,
    char_18: 'Kafka',
    app_rate_18: 2.33,
    avg_round_18: 9.32,
    app_flat_18: 44,
    char_19: 'Tingyun',
    app_rate_19: 2.24,
    avg_round_19: 8.55,
    app_flat_19: 55,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 2.19,
    avg_round_20: 9.5,
    app_flat_20: 8,
    char_21: 'Yunli',
    app_rate_21: 2.14,
    avg_round_21: 8.79,
    app_flat_21: 63,
    char_22: 'Guinaifen',
    app_rate_22: 1.06,
    avg_round_22: 8.62,
    app_flat_22: 32,
    char_23: 'Bailu',
    app_rate_23: 0.85,
    avg_round_23: 9.22,
    app_flat_23: 17,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.8,
    avg_round_24: 9.25,
    app_flat_24: 14,
    char_25: 'Clara',
    app_rate_25: 0.73,
    avg_round_25: 9.0,
    app_flat_25: 19,
    char_26: 'Firefly',
    app_rate_26: 0.73,
    avg_round_26: 10,
    app_flat_26: 4,
    char_27: 'march-7th-swordmaster',
    app_rate_27: 0.71,
    avg_round_27: 8.25,
    app_flat_27: 20,
    char_28: 'Lynx',
    app_rate_28: 0.66,
    avg_round_28: 8.88,
    app_flat_28: 16,
    char_29: 'Welt',
    app_rate_29: 0.35,
    avg_round_29: 10.0,
    app_flat_29: 8,
    char_30: 'Blade',
    app_rate_30: 0.31,
    avg_round_30: 9.67,
    app_flat_30: 5
  },
  {
    char: 'Gallagher',
    round: 7.87,
    char_1: 'ruan-mei',
    app_rate_1: 85.99,
    avg_round_1: 7.89,
    app_flat_1: 3114,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 83.39,
    avg_round_2: 7.98,
    app_flat_2: 3034,
    char_3: 'Firefly',
    app_rate_3: 78.02,
    avg_round_3: 7.92,
    app_flat_3: 2691,
    char_4: 'Boothill',
    app_rate_4: 7.16,
    avg_round_4: 7.47,
    app_flat_4: 403,
    char_5: 'Acheron',
    app_rate_5: 5.9,
    avg_round_5: 8.14,
    app_flat_5: 220,
    char_6: 'Robin',
    app_rate_6: 5.5,
    avg_round_6: 6.55,
    app_flat_6: 261,
    char_7: 'Feixiao',
    app_rate_7: 4.37,
    avg_round_7: 6.51,
    app_flat_7: 232,
    char_8: 'Bronya',
    app_rate_8: 4.02,
    avg_round_8: 6.76,
    app_flat_8: 178,
    char_9: 'Pela',
    app_rate_9: 3.85,
    avg_round_9: 8.05,
    app_flat_9: 190,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 3.75,
    avg_round_10: 8.17,
    app_flat_10: 226,
    char_11: 'Jiaoqiu',
    app_rate_11: 3.6,
    avg_round_11: 7.19,
    app_flat_11: 148,
    char_12: 'black-swan',
    app_rate_12: 1.87,
    avg_round_12: 8.65,
    app_flat_12: 50,
    char_13: 'Sparkle',
    app_rate_13: 1.81,
    avg_round_13: 8.16,
    app_flat_13: 37,
    char_14: 'topaz',
    app_rate_14: 1.72,
    avg_round_14: 6.42,
    app_flat_14: 81,
    char_15: 'Kafka',
    app_rate_15: 1.45,
    avg_round_15: 8.63,
    app_flat_15: 37,
    char_16: 'silver-wolf',
    app_rate_16: 1.29,
    avg_round_16: 8.21,
    app_flat_16: 57,
    char_17: 'dr-ratio',
    app_rate_17: 1.01,
    avg_round_17: 7.79,
    app_flat_17: 55,
    char_18: 'Tingyun',
    app_rate_18: 0.9,
    avg_round_18: 7.83,
    app_flat_18: 46,
    char_19: 'Yunli',
    app_rate_19: 0.71,
    avg_round_19: 8.0,
    app_flat_19: 41,
    char_20: 'Moze',
    app_rate_20: 0.7,
    avg_round_20: 5.77,
    app_flat_20: 26,
    char_21: 'imbibitor-lunae',
    app_rate_21: 0.5,
    avg_round_21: 8.83,
    app_flat_21: 10,
    char_22: 'Clara',
    app_rate_22: 0.4,
    avg_round_22: 9.5,
    app_flat_22: 22,
    char_23: 'Asta',
    app_rate_23: 0.39,
    avg_round_23: 9.56,
    app_flat_23: 15,
    char_24: 'Jingliu',
    app_rate_24: 0.28,
    avg_round_24: 10.0,
    app_flat_24: 5,
    char_25: 'Blade',
    app_rate_25: 0.26,
    avg_round_25: 9.25,
    app_flat_25: 8,
    char_26: 'Guinaifen',
    app_rate_26: 0.16,
    avg_round_26: 9.33,
    app_flat_26: 10,
    char_27: 'Sampo',
    app_rate_27: 0.15,
    avg_round_27: 7.83,
    app_flat_27: 6,
    char_28: 'Welt',
    app_rate_28: 0.13,
    avg_round_28: 8.12,
    app_flat_28: 8,
    char_29: 'Jade',
    app_rate_29: 0.11,
    avg_round_29: 9,
    app_flat_29: 2,
    char_30: 'Himeko',
    app_rate_30: 0.09,
    avg_round_30: 8.67,
    app_flat_30: 3
  },
  {
    char: 'Huohuo',
    round: 7.99,
    char_1: 'Robin',
    app_rate_1: 32.48,
    avg_round_1: 7.27,
    app_flat_1: 793,
    char_2: 'Sparkle',
    app_rate_2: 27.39,
    avg_round_2: 7.46,
    app_flat_2: 487,
    char_3: 'ruan-mei',
    app_rate_3: 26.98,
    avg_round_3: 8.64,
    app_flat_3: 390,
    char_4: 'Yunli',
    app_rate_4: 26.05,
    avg_round_4: 7.12,
    app_flat_4: 677,
    char_5: 'Tingyun',
    app_rate_5: 26.0,
    avg_round_5: 7.29,
    app_flat_5: 620,
    char_6: 'black-swan',
    app_rate_6: 21.65,
    avg_round_6: 8.66,
    app_flat_6: 307,
    char_7: 'Kafka',
    app_rate_7: 19.82,
    avg_round_7: 8.56,
    app_flat_7: 266,
    char_8: 'Acheron',
    app_rate_8: 12.66,
    avg_round_8: 8.42,
    app_flat_8: 220,
    char_9: 'imbibitor-lunae',
    app_rate_9: 11.4,
    avg_round_9: 8.13,
    app_flat_9: 159,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 10.88,
    avg_round_10: 7.82,
    app_flat_10: 327,
    char_11: 'Feixiao',
    app_rate_11: 8.04,
    avg_round_11: 6.97,
    app_flat_11: 232,
    char_12: 'trailblazer-imaginary',
    app_rate_12: 7.94,
    avg_round_12: 8.54,
    app_flat_12: 115,
    char_13: 'Bronya',
    app_rate_13: 7.77,
    avg_round_13: 8.49,
    app_flat_13: 143,
    char_14: 'Pela',
    app_rate_14: 7.4,
    avg_round_14: 8.54,
    app_flat_14: 177,
    char_15: 'topaz',
    app_rate_15: 7.04,
    avg_round_15: 7.12,
    app_flat_15: 170,
    char_16: 'Firefly',
    app_rate_16: 6.62,
    avg_round_16: 8.61,
    app_flat_16: 76,
    char_17: 'Jiaoqiu',
    app_rate_17: 6.36,
    avg_round_17: 7.9,
    app_flat_17: 124,
    char_18: 'dr-ratio',
    app_rate_18: 5.84,
    avg_round_18: 8.5,
    app_flat_18: 146,
    char_19: 'Jingliu',
    app_rate_19: 5.72,
    avg_round_19: 9.07,
    app_flat_19: 80,
    char_20: 'Clara',
    app_rate_20: 4.82,
    avg_round_20: 8.56,
    app_flat_20: 125,
    char_21: 'silver-wolf',
    app_rate_21: 3.68,
    avg_round_21: 8.28,
    app_flat_21: 71,
    char_22: 'Blade',
    app_rate_22: 3.65,
    avg_round_22: 8.21,
    app_flat_22: 73,
    char_23: 'Boothill',
    app_rate_23: 1.58,
    avg_round_23: 7.79,
    app_flat_23: 42,
    char_24: 'Jade',
    app_rate_24: 1.44,
    avg_round_24: 8.23,
    app_flat_24: 31,
    char_25: 'Sampo',
    app_rate_25: 1.36,
    avg_round_25: 9.0,
    app_flat_25: 32,
    char_26: 'jing-yuan',
    app_rate_26: 0.63,
    avg_round_26: 7.0,
    app_flat_26: 5,
    char_27: 'Hanya',
    app_rate_27: 0.61,
    avg_round_27: 8.89,
    app_flat_27: 15,
    char_28: 'Guinaifen',
    app_rate_28: 0.56,
    avg_round_28: 8.86,
    app_flat_28: 7,
    char_29: 'Argenti',
    app_rate_29: 0.56,
    avg_round_29: 9.12,
    app_flat_29: 14,
    char_30: 'Moze',
    app_rate_30: 0.49,
    avg_round_30: 7.5,
    app_flat_30: 14
  },
  {
    char: 'Firefly',
    round: 8.0,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 99.8,
    avg_round_1: 8.01,
    app_flat_1: 2960,
    char_2: 'ruan-mei',
    app_rate_2: 98.02,
    avg_round_2: 7.99,
    app_flat_2: 2905,
    char_3: 'Gallagher',
    app_rate_3: 86.05,
    avg_round_3: 7.92,
    app_flat_3: 2691,
    char_4: 'Huohuo',
    app_rate_4: 3.66,
    avg_round_4: 8.61,
    app_flat_4: 76,
    char_5: 'Luocha',
    app_rate_5: 2.55,
    avg_round_5: 8.8,
    app_flat_5: 81,
    char_6: 'fu-xuan',
    app_rate_6: 1.95,
    avg_round_6: 8.75,
    app_flat_6: 40,
    char_7: 'Aventurine',
    app_rate_7: 1.76,
    avg_round_7: 9.11,
    app_flat_7: 51,
    char_8: 'Bronya',
    app_rate_8: 1.55,
    avg_round_8: 9.33,
    app_flat_8: 3,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 0.82,
    avg_round_9: 9,
    app_flat_9: 23,
    char_10: 'Asta',
    app_rate_10: 0.57,
    avg_round_10: 9.64,
    app_flat_10: 11,
    char_11: 'Bailu',
    app_rate_11: 0.42,
    avg_round_11: 9.4,
    app_flat_11: 5,
    char_12: 'Pela',
    app_rate_12: 0.42,
    avg_round_12: 10,
    app_flat_12: 4,
    char_13: 'Lynx',
    app_rate_13: 0.32,
    avg_round_13: 8.25,
    app_flat_13: 8,
    char_14: 'Himeko',
    app_rate_14: 0.24,
    avg_round_14: 10,
    app_flat_14: 2,
    char_15: 'Boothill',
    app_rate_15: 0.24,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Gepard',
    app_rate_16: 0.23,
    avg_round_16: 8.25,
    app_flat_16: 6,
    char_17: 'Sparkle',
    app_rate_17: 0.19,
    avg_round_17: 8.5,
    app_flat_17: 2,
    char_18: 'Robin',
    app_rate_18: 0.18,
    avg_round_18: 9.67,
    app_flat_18: 3,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.15,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'black-swan',
    app_rate_20: 0.09,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: 0.09,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Yunli',
    app_rate_22: 0.08,
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'Welt',
    app_rate_23: 0.07,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Clara',
    app_rate_24: 0.05,
    avg_round_24: 9.33,
    app_flat_24: 3,
    char_25: 'Natasha',
    app_rate_25: 0.04,
    avg_round_25: 8,
    app_flat_25: 1,
    char_26: 'Jingliu',
    app_rate_26: 0.04,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'march-7th',
    app_rate_27: 0.04,
    avg_round_27: 10,
    app_flat_27: 2,
    char_28: 'Guinaifen',
    app_rate_28: 0.04,
    avg_round_28: 8.5,
    app_flat_28: 2,
    char_29: 'Acheron',
    app_rate_29: 0.04,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Hanya',
    app_rate_30: 0.03,
    avg_round_30: 6,
    app_flat_30: 1
  },
  {
    char: 'Gepard',
    round: 8.03,
    char_1: 'Acheron',
    app_rate_1: 75.78,
    avg_round_1: 8.0,
    app_flat_1: 91,
    char_2: 'Pela',
    app_rate_2: 48.72,
    avg_round_2: 7.91,
    app_flat_2: 74,
    char_3: 'Jiaoqiu',
    app_rate_3: 29.91,
    avg_round_3: 7.9,
    app_flat_3: 41,
    char_4: 'silver-wolf',
    app_rate_4: 22.22,
    avg_round_4: 7.84,
    app_flat_4: 37,
    char_5: 'Sparkle',
    app_rate_5: 18.8,
    avg_round_5: 8.5,
    app_flat_5: 2,
    char_6: 'ruan-mei',
    app_rate_6: 18.23,
    avg_round_6: 7.74,
    app_flat_6: 23,
    char_7: 'black-swan',
    app_rate_7: 15.1,
    avg_round_7: 8.4,
    app_flat_7: 20,
    char_8: 'Kafka',
    app_rate_8: 13.96,
    avg_round_8: 8.39,
    app_flat_8: 18,
    char_9: 'Bronya',
    app_rate_9: 9.97,
    avg_round_9: 8,
    app_flat_9: 4,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 7.69,
    avg_round_10: 7.08,
    app_flat_10: 12,
    char_11: 'Robin',
    app_rate_11: 5.13,
    avg_round_11: 8.5,
    app_flat_11: 12,
    char_12: 'Firefly',
    app_rate_12: 4.84,
    avg_round_12: 8.25,
    app_flat_12: 6,
    char_13: 'Boothill',
    app_rate_13: 4.84,
    avg_round_13: 6.3,
    app_flat_13: 10,
    char_14: 'Feixiao',
    app_rate_14: 3.99,
    avg_round_14: 7.82,
    app_flat_14: 11,
    char_15: 'Yunli',
    app_rate_15: 2.85,
    avg_round_15: 10.0,
    app_flat_15: 5,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 2.85,
    avg_round_16: 8.6,
    app_flat_16: 7,
    char_17: 'topaz',
    app_rate_17: 2.28,
    avg_round_17: 9.0,
    app_flat_17: 5,
    char_18: 'Tingyun',
    app_rate_18: 2.28,
    avg_round_18: 10.0,
    app_flat_18: 4,
    char_19: 'Luka',
    app_rate_19: 1.71,
    avg_round_19: 8.67,
    app_flat_19: 5,
    char_20: 'dr-ratio',
    app_rate_20: 0.85,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Jingliu',
    app_rate_21: 0.85,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Asta',
    app_rate_22: 0.85,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Gallagher',
    app_rate_23: 0.57,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Sampo',
    app_rate_24: 0.57,
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'imbibitor-lunae',
    app_rate_25: 0.57,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 0.57,
    avg_round_26: 9.5,
    app_flat_26: 2,
    char_27: 'Guinaifen',
    app_rate_27: 0.57,
    avg_round_27: 8,
    app_flat_27: 2,
    char_28: 'Huohuo',
    app_rate_28: 0.57,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'Qingque',
    app_rate_29: 0.28,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Blade',
    app_rate_30: 0.28,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-imaginary',
    round: 8.07,
    char_1: 'ruan-mei',
    app_rate_1: 97.25,
    avg_round_1: 8.04,
    app_flat_1: 3412,
    char_2: 'Firefly',
    app_rate_2: 89.12,
    avg_round_2: 8.01,
    app_flat_2: 2960,
    char_3: 'Gallagher',
    app_rate_3: 82.13,
    avg_round_3: 7.98,
    app_flat_3: 3034,
    char_4: 'Boothill',
    app_rate_4: 9.07,
    avg_round_4: 8.01,
    app_flat_4: 457,
    char_5: 'Huohuo',
    app_rate_5: 3.92,
    avg_round_5: 8.54,
    app_flat_5: 115,
    char_6: 'Luocha',
    app_rate_6: 3.39,
    avg_round_6: 8.94,
    app_flat_6: 142,
    char_7: 'Aventurine',
    app_rate_7: 2.63,
    avg_round_7: 9.05,
    app_flat_7: 112,
    char_8: 'fu-xuan',
    app_rate_8: 2.62,
    avg_round_8: 8.08,
    app_flat_8: 91,
    char_9: 'Bronya',
    app_rate_9: 2.39,
    avg_round_9: 9.31,
    app_flat_9: 30,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 1.9,
    avg_round_10: 9.18,
    app_flat_10: 95,
    char_11: 'Pela',
    app_rate_11: 1.12,
    avg_round_11: 9.5,
    app_flat_11: 8,
    char_12: 'Asta',
    app_rate_12: 0.51,
    avg_round_12: 9.64,
    app_flat_12: 11,
    char_13: 'Bailu',
    app_rate_13: 0.42,
    avg_round_13: 8.88,
    app_flat_13: 8,
    char_14: 'Lynx',
    app_rate_14: 0.36,
    avg_round_14: 8.29,
    app_flat_14: 11,
    char_15: 'Gepard',
    app_rate_15: 0.32,
    avg_round_15: 7.08,
    app_flat_15: 12,
    char_16: 'Blade',
    app_rate_16: 0.23,
    avg_round_16: 9.62,
    app_flat_16: 14,
    char_17: 'Himeko',
    app_rate_17: 0.22,
    avg_round_17: 10,
    app_flat_17: 2,
    char_18: 'dr-ratio',
    app_rate_18: 0.22,
    avg_round_18: 9.38,
    app_flat_18: 13,
    char_19: 'black-swan',
    app_rate_19: 0.17,
    avg_round_19: 10,
    app_flat_19: 2,
    char_20: 'Sparkle',
    app_rate_20: 0.16,
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'silver-wolf',
    app_rate_21: 0.16,
    avg_round_21: 8,
    app_flat_21: 2,
    char_22: 'Jiaoqiu',
    app_rate_22: 0.16,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Yunli',
    app_rate_23: 0.16,
    avg_round_23: 10.0,
    app_flat_23: 6,
    char_24: 'Clara',
    app_rate_24: 0.13,
    avg_round_24: 9.67,
    app_flat_24: 9,
    char_25: 'Robin',
    app_rate_25: 0.13,
    avg_round_25: 10.0,
    app_flat_25: 4,
    char_26: 'Sushang',
    app_rate_26: 0.11,
    avg_round_26: 9.33,
    app_flat_26: 5,
    char_27: 'Welt',
    app_rate_27: 0.11,
    avg_round_27: 10,
    app_flat_27: 3,
    char_28: 'Misha',
    app_rate_28: 0.11,
    avg_round_28: 9,
    app_flat_28: 3,
    char_29: 'imbibitor-lunae',
    app_rate_29: 0.1,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Feixiao',
    app_rate_30: 0.1,
    avg_round_30: 9.5,
    app_flat_30: 4
  },
  {
    char: 'ruan-mei',
    round: 8.11,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 73.15,
    avg_round_1: 8.04,
    app_flat_1: 3412,
    char_2: 'Firefly',
    app_rate_2: 65.84,
    avg_round_2: 7.99,
    app_flat_2: 2905,
    char_3: 'Gallagher',
    app_rate_3: 63.71,
    avg_round_3: 7.89,
    app_flat_3: 3114,
    char_4: 'Boothill',
    app_rate_4: 11.21,
    avg_round_4: 7.62,
    app_flat_4: 663,
    char_5: 'Huohuo',
    app_rate_5: 10.02,
    avg_round_5: 8.64,
    app_flat_5: 390,
    char_6: 'Bronya',
    app_rate_6: 8.72,
    avg_round_6: 7.44,
    app_flat_6: 322,
    char_7: 'Luocha',
    app_rate_7: 7.07,
    avg_round_7: 8.6,
    app_flat_7: 337,
    char_8: 'black-swan',
    app_rate_8: 6.55,
    avg_round_8: 8.5,
    app_flat_8: 216,
    char_9: 'Kafka',
    app_rate_9: 6.36,
    avg_round_9: 8.54,
    app_flat_9: 218,
    char_10: 'fu-xuan',
    app_rate_10: 6.12,
    avg_round_10: 8.59,
    app_flat_10: 232,
    char_11: 'Aventurine',
    app_rate_11: 5.1,
    avg_round_11: 8.74,
    app_flat_11: 273,
    char_12: 'Jingliu',
    app_rate_12: 4.05,
    avg_round_12: 9.17,
    app_flat_12: 129,
    char_13: 'Sparkle',
    app_rate_13: 3.69,
    avg_round_13: 8.56,
    app_flat_13: 106,
    char_14: 'Pela',
    app_rate_14: 3.21,
    avg_round_14: 9.21,
    app_flat_14: 46,
    char_15: 'imbibitor-lunae',
    app_rate_15: 3.2,
    avg_round_15: 8.17,
    app_flat_15: 92,
    char_16: 'Yunli',
    app_rate_16: 2.2,
    avg_round_16: 8.74,
    app_flat_16: 105,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 2.04,
    avg_round_17: 8.94,
    app_flat_17: 108,
    char_18: 'Tingyun',
    app_rate_18: 1.99,
    avg_round_18: 8.37,
    app_flat_18: 69,
    char_19: 'Acheron',
    app_rate_19: 1.78,
    avg_round_19: 9.0,
    app_flat_19: 19,
    char_20: 'dr-ratio',
    app_rate_20: 1.47,
    avg_round_20: 9.2,
    app_flat_20: 88,
    char_21: 'Blade',
    app_rate_21: 1.39,
    avg_round_21: 8.33,
    app_flat_21: 64,
    char_22: 'topaz',
    app_rate_22: 1.24,
    avg_round_22: 8.66,
    app_flat_22: 62,
    char_23: 'Feixiao',
    app_rate_23: 1.19,
    avg_round_23: 8.04,
    app_flat_23: 51,
    char_24: 'Robin',
    app_rate_24: 1.12,
    avg_round_24: 8.56,
    app_flat_24: 34,
    char_25: 'Jiaoqiu',
    app_rate_25: 1.0,
    avg_round_25: 7.6,
    app_flat_25: 10,
    char_26: 'Clara',
    app_rate_26: 0.9,
    avg_round_26: 9.32,
    app_flat_26: 47,
    char_27: 'Bailu',
    app_rate_27: 0.87,
    avg_round_27: 9.42,
    app_flat_27: 22,
    char_28: 'Lynx',
    app_rate_28: 0.7,
    avg_round_28: 8.44,
    app_flat_28: 32,
    char_29: 'Gepard',
    app_rate_29: 0.58,
    avg_round_29: 7.74,
    app_flat_29: 23,
    char_30: 'silver-wolf',
    app_rate_30: 0.53,
    avg_round_30: 9.67,
    app_flat_30: 12
  },
  {
    char: 'Sparkle',
    round: 8.11,
    char_1: 'Acheron',
    app_rate_1: 36.77,
    avg_round_1: 7.67,
    app_flat_1: 147,
    char_2: 'fu-xuan',
    app_rate_2: 35.42,
    avg_round_2: 7.89,
    app_flat_2: 370,
    char_3: 'Tingyun',
    app_rate_3: 25.83,
    avg_round_3: 7.5,
    app_flat_3: 630,
    char_4: 'imbibitor-lunae',
    app_rate_4: 24.56,
    avg_round_4: 8.18,
    app_flat_4: 432,
    char_5: 'Huohuo',
    app_rate_5: 22.33,
    avg_round_5: 7.46,
    app_flat_5: 487,
    char_6: 'Jiaoqiu',
    app_rate_6: 19.34,
    avg_round_6: 6.97,
    app_flat_6: 103,
    char_7: 'Aventurine',
    app_rate_7: 19.22,
    avg_round_7: 8.33,
    app_flat_7: 333,
    char_8: 'Yunli',
    app_rate_8: 15.37,
    avg_round_8: 7.09,
    app_flat_8: 435,
    char_9: 'silver-wolf',
    app_rate_9: 13.2,
    avg_round_9: 8.89,
    app_flat_9: 88,
    char_10: 'Pela',
    app_rate_10: 11.34,
    avg_round_10: 9.02,
    app_flat_10: 79,
    char_11: 'Luocha',
    app_rate_11: 10.34,
    avg_round_11: 8.1,
    app_flat_11: 195,
    char_12: 'Robin',
    app_rate_12: 10.01,
    avg_round_12: 7.55,
    app_flat_12: 152,
    char_13: 'ruan-mei',
    app_rate_13: 8.1,
    avg_round_13: 8.56,
    app_flat_13: 106,
    char_14: 'Bronya',
    app_rate_14: 4.73,
    avg_round_14: 8.33,
    app_flat_14: 84,
    char_15: 'Blade',
    app_rate_15: 4.17,
    avg_round_15: 8.2,
    app_flat_15: 101,
    char_16: 'Clara',
    app_rate_16: 4.09,
    avg_round_16: 8.47,
    app_flat_16: 113,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 3.67,
    avg_round_17: 8.5,
    app_flat_17: 96,
    char_18: 'dr-ratio',
    app_rate_18: 3.63,
    avg_round_18: 8.82,
    app_flat_18: 97,
    char_19: 'Feixiao',
    app_rate_19: 3.59,
    avg_round_19: 7.53,
    app_flat_19: 88,
    char_20: 'Jingliu',
    app_rate_20: 2.98,
    avg_round_20: 8.95,
    app_flat_20: 37,
    char_21: 'Gallagher',
    app_rate_21: 2.94,
    avg_round_21: 8.16,
    app_flat_21: 37,
    char_22: 'Seele',
    app_rate_22: 2.72,
    avg_round_22: 8.6,
    app_flat_22: 18,
    char_23: 'topaz',
    app_rate_23: 2.2,
    avg_round_23: 7.91,
    app_flat_23: 55,
    char_24: 'black-swan',
    app_rate_24: 2.16,
    avg_round_24: 8.67,
    app_flat_24: 15,
    char_25: 'Lynx',
    app_rate_25: 2.04,
    avg_round_25: 7.91,
    app_flat_25: 56,
    char_26: 'Qingque',
    app_rate_26: 1.45,
    avg_round_26: 8.73,
    app_flat_26: 21,
    char_27: 'Gepard',
    app_rate_27: 1.31,
    avg_round_27: 8.5,
    app_flat_27: 2,
    char_28: 'jing-yuan',
    app_rate_28: 1.05,
    avg_round_28: 8.5,
    app_flat_28: 18,
    char_29: 'Jade',
    app_rate_29: 0.64,
    avg_round_29: 8.5,
    app_flat_29: 10,
    char_30: 'Bailu',
    app_rate_30: 0.64,
    avg_round_30: 9.43,
    app_flat_30: 11
  },
  {
    char: 'Bronya',
    round: 8.11,
    char_1: 'ruan-mei',
    app_rate_1: 41.88,
    avg_round_1: 7.44,
    app_flat_1: 322,
    char_2: 'Boothill',
    app_rate_2: 24.76,
    avg_round_2: 7.17,
    app_flat_2: 262,
    char_3: 'Jingliu',
    app_rate_3: 20.46,
    avg_round_3: 8.86,
    app_flat_3: 132,
    char_4: 'Luocha',
    app_rate_4: 19.68,
    avg_round_4: 8.52,
    app_flat_4: 212,
    char_5: 'fu-xuan',
    app_rate_5: 17.03,
    avg_round_5: 8.23,
    app_flat_5: 85,
    char_6: 'Pela',
    app_rate_6: 16.55,
    avg_round_6: 9.03,
    app_flat_6: 58,
    char_7: 'Acheron',
    app_rate_7: 15.81,
    avg_round_7: 8.5,
    app_flat_7: 2,
    char_8: 'Blade',
    app_rate_8: 14.64,
    avg_round_8: 8.28,
    app_flat_8: 157,
    char_9: 'Gallagher',
    app_rate_9: 14.29,
    avg_round_9: 6.76,
    app_flat_9: 178,
    char_10: 'Huohuo',
    app_rate_10: 13.86,
    avg_round_10: 8.49,
    app_flat_10: 143,
    char_11: 'Feixiao',
    app_rate_11: 11.47,
    avg_round_11: 7.05,
    app_flat_11: 128,
    char_12: 'Aventurine',
    app_rate_12: 11.12,
    avg_round_12: 8.34,
    app_flat_12: 116,
    char_13: 'Sparkle',
    app_rate_13: 10.34,
    avg_round_13: 8.33,
    app_flat_13: 84,
    char_14: 'Robin',
    app_rate_14: 9.86,
    avg_round_14: 6.85,
    app_flat_14: 52,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 8.64,
    avg_round_15: 9.31,
    app_flat_15: 30,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 8.64,
    avg_round_16: 8.19,
    app_flat_16: 102,
    char_17: 'Jiaoqiu',
    app_rate_17: 7.38,
    avg_round_17: 8.5,
    app_flat_17: 6,
    char_18: 'Firefly',
    app_rate_18: 5.0,
    avg_round_18: 9.33,
    app_flat_18: 3,
    char_19: 'imbibitor-lunae',
    app_rate_19: 3.91,
    avg_round_19: 8.58,
    app_flat_19: 35,
    char_20: 'Jade',
    app_rate_20: 3.13,
    avg_round_20: 8.11,
    app_flat_20: 28,
    char_21: 'silver-wolf',
    app_rate_21: 2.87,
    avg_round_21: 9.0,
    app_flat_21: 6,
    char_22: 'topaz',
    app_rate_22: 2.69,
    avg_round_22: 8.12,
    app_flat_22: 32,
    char_23: 'Tingyun',
    app_rate_23: 2.48,
    avg_round_23: 9.29,
    app_flat_23: 26,
    char_24: 'Lynx',
    app_rate_24: 2.17,
    avg_round_24: 8.39,
    app_flat_24: 23,
    char_25: 'dr-ratio',
    app_rate_25: 2.0,
    avg_round_25: 9.07,
    app_flat_25: 28,
    char_26: 'Yunli',
    app_rate_26: 1.74,
    avg_round_26: 9.1,
    app_flat_26: 20,
    char_27: 'Gepard',
    app_rate_27: 1.52,
    avg_round_27: 8,
    app_flat_27: 4,
    char_28: 'Bailu',
    app_rate_28: 1.43,
    avg_round_28: 8.36,
    app_flat_28: 11,
    char_29: 'Clara',
    app_rate_29: 1.04,
    avg_round_29: 8.25,
    app_flat_29: 12,
    char_30: 'black-swan',
    app_rate_30: 0.91,
    avg_round_30: 8.5,
    app_flat_30: 2
  },
  {
    char: 'imbibitor-lunae',
    round: 8.15,
    char_1: 'Sparkle',
    app_rate_1: 85.96,
    avg_round_1: 8.18,
    app_flat_1: 432,
    char_2: 'Tingyun',
    app_rate_2: 53.51,
    avg_round_2: 8.09,
    app_flat_2: 283,
    char_3: 'Huohuo',
    app_rate_3: 32.52,
    avg_round_3: 8.13,
    app_flat_3: 159,
    char_4: 'Luocha',
    app_rate_4: 25.02,
    avg_round_4: 8.27,
    app_flat_4: 101,
    char_5: 'ruan-mei',
    app_rate_5: 24.6,
    avg_round_5: 8.17,
    app_flat_5: 92,
    char_6: 'Aventurine',
    app_rate_6: 19.46,
    avg_round_6: 8.53,
    app_flat_6: 130,
    char_7: 'fu-xuan',
    app_rate_7: 15.5,
    avg_round_7: 8.26,
    app_flat_7: 82,
    char_8: 'Robin',
    app_rate_8: 11.74,
    avg_round_8: 8,
    app_flat_8: 38,
    char_9: 'Bronya',
    app_rate_9: 6.25,
    avg_round_9: 8.58,
    app_flat_9: 35,
    char_10: 'Hanya',
    app_rate_10: 3.68,
    avg_round_10: 9.0,
    app_flat_10: 23,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 3.54,
    avg_round_11: 9,
    app_flat_11: 23,
    char_12: 'Gallagher',
    app_rate_12: 2.85,
    avg_round_12: 8.83,
    app_flat_12: 10,
    char_13: 'Jiaoqiu',
    app_rate_13: 2.5,
    avg_round_13: 9.2,
    app_flat_13: 5,
    char_14: 'Pela',
    app_rate_14: 2.36,
    avg_round_14: 9.25,
    app_flat_14: 14,
    char_15: 'Blade',
    app_rate_15: 2.15,
    avg_round_15: 9,
    app_flat_15: 10,
    char_16: 'Yukong',
    app_rate_16: 2.15,
    avg_round_16: 8.17,
    app_flat_16: 6,
    char_17: 'silver-wolf',
    app_rate_17: 1.25,
    avg_round_17: 9,
    app_flat_17: 4,
    char_18: 'Bailu',
    app_rate_18: 0.9,
    avg_round_18: 9.33,
    app_flat_18: 3,
    char_19: 'Lynx',
    app_rate_19: 0.63,
    avg_round_19: 9.0,
    app_flat_19: 4,
    char_20: 'Clara',
    app_rate_20: 0.56,
    avg_round_20: 8.33,
    app_flat_20: 6,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 0.56,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Welt',
    app_rate_22: 0.49,
    avg_round_22: 8.5,
    app_flat_22: 2,
    char_23: 'Yunli',
    app_rate_23: 0.42,
    avg_round_23: 9,
    app_flat_23: 2,
    char_24: 'dr-ratio',
    app_rate_24: 0.42,
    avg_round_24: 7,
    app_flat_24: 1,
    char_25: 'black-swan',
    app_rate_25: 0.35,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Asta',
    app_rate_26: 0.14,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Gepard',
    app_rate_27: 0.14,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Argenti',
    app_rate_28: 0.07,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Kafka',
    app_rate_29: 0.07,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Feixiao',
    app_rate_30: 0.07,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'silver-wolf',
    round: 8.22,
    char_1: 'Acheron',
    app_rate_1: 78.05,
    avg_round_1: 7.88,
    app_flat_1: 948,
    char_2: 'fu-xuan',
    app_rate_2: 58.53,
    avg_round_2: 7.86,
    app_flat_2: 625,
    char_3: 'Pela',
    app_rate_3: 43.13,
    avg_round_3: 8.07,
    app_flat_3: 683,
    char_4: 'Sparkle',
    app_rate_4: 26.93,
    avg_round_4: 8.89,
    app_flat_4: 88,
    char_5: 'Aventurine',
    app_rate_5: 19.97,
    avg_round_5: 8.46,
    app_flat_5: 297,
    char_6: 'Jiaoqiu',
    app_rate_6: 14.62,
    avg_round_6: 7.06,
    app_flat_6: 209,
    char_7: 'dr-ratio',
    app_rate_7: 9.88,
    avg_round_7: 8.9,
    app_flat_7: 140,
    char_8: 'Huohuo',
    app_rate_8: 6.12,
    avg_round_8: 8.28,
    app_flat_8: 71,
    char_9: 'Seele',
    app_rate_9: 4.9,
    avg_round_9: 8.73,
    app_flat_9: 19,
    char_10: 'Gallagher',
    app_rate_10: 4.29,
    avg_round_10: 8.21,
    app_flat_10: 57,
    char_11: 'Luocha',
    app_rate_11: 3.89,
    avg_round_11: 8.42,
    app_flat_11: 46,
    char_12: 'Robin',
    app_rate_12: 3.81,
    avg_round_12: 8.64,
    app_flat_12: 44,
    char_13: 'Gepard',
    app_rate_13: 3.16,
    avg_round_13: 7.84,
    app_flat_13: 37,
    char_14: 'Bronya',
    app_rate_14: 2.67,
    avg_round_14: 9.0,
    app_flat_14: 6,
    char_15: 'Qingque',
    app_rate_15: 2.59,
    avg_round_15: 8.47,
    app_flat_15: 17,
    char_16: 'black-swan',
    app_rate_16: 2.55,
    avg_round_16: 8.86,
    app_flat_16: 28,
    char_17: 'ruan-mei',
    app_rate_17: 2.39,
    avg_round_17: 9.67,
    app_flat_17: 12,
    char_18: 'Tingyun',
    app_rate_18: 1.54,
    avg_round_18: 7.56,
    app_flat_18: 25,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 1.42,
    avg_round_19: 9.04,
    app_flat_19: 24,
    char_20: 'Kafka',
    app_rate_20: 1.22,
    avg_round_20: 9.09,
    app_flat_20: 11,
    char_21: 'Bailu',
    app_rate_21: 0.93,
    avg_round_21: 8.33,
    app_flat_21: 9,
    char_22: 'topaz',
    app_rate_22: 0.77,
    avg_round_22: 8.89,
    app_flat_22: 9,
    char_23: 'Lynx',
    app_rate_23: 0.77,
    avg_round_23: 8.5,
    app_flat_23: 6,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.73,
    avg_round_24: 9,
    app_flat_24: 4,
    char_25: 'Jingliu',
    app_rate_25: 0.65,
    avg_round_25: 9,
    app_flat_25: 2,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.53,
    avg_round_26: 8,
    app_flat_26: 2,
    char_27: 'Blade',
    app_rate_27: 0.41,
    avg_round_27: 9.5,
    app_flat_27: 4,
    char_28: 'Boothill',
    app_rate_28: 0.36,
    avg_round_28: 8.67,
    app_flat_28: 5,
    char_29: 'Clara',
    app_rate_29: 0.36,
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Xueyi',
    app_rate_30: 0.36,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Jade',
    round: 8.25,
    char_1: 'Robin',
    app_rate_1: 46.33,
    avg_round_1: 9.09,
    app_flat_1: 43,
    char_2: 'Blade',
    app_rate_2: 37.38,
    avg_round_2: 8.14,
    app_flat_2: 44,
    char_3: 'Aventurine',
    app_rate_3: 27.8,
    avg_round_3: 9.64,
    app_flat_3: 19,
    char_4: 'Bronya',
    app_rate_4: 23.0,
    avg_round_4: 8.11,
    app_flat_4: 28,
    char_5: 'Luocha',
    app_rate_5: 21.41,
    avg_round_5: 7.96,
    app_flat_5: 23,
    char_6: 'fu-xuan',
    app_rate_6: 21.09,
    avg_round_6: 8.44,
    app_flat_6: 18,
    char_7: 'Yunli',
    app_rate_7: 20.13,
    avg_round_7: 8.67,
    app_flat_7: 36,
    char_8: 'Huohuo',
    app_rate_8: 18.85,
    avg_round_8: 8.23,
    app_flat_8: 31,
    char_9: 'Feixiao',
    app_rate_9: 13.42,
    avg_round_9: 9.67,
    app_flat_9: 3,
    char_10: 'ruan-mei',
    app_rate_10: 12.46,
    avg_round_10: 7.3,
    app_flat_10: 10,
    char_11: 'topaz',
    app_rate_11: 11.18,
    avg_round_11: 9.2,
    app_flat_11: 5,
    char_12: 'Clara',
    app_rate_12: 10.54,
    avg_round_12: 9.8,
    app_flat_12: 9,
    char_13: 'Sparkle',
    app_rate_13: 10.22,
    avg_round_13: 8.5,
    app_flat_13: 10,
    char_14: 'Gallagher',
    app_rate_14: 2.88,
    avg_round_14: 9,
    app_flat_14: 2,
    char_15: 'Lynx',
    app_rate_15: 2.56,
    avg_round_15: 9,
    app_flat_15: 3,
    char_16: 'dr-ratio',
    app_rate_16: 2.24,
    avg_round_16: 9.33,
    app_flat_16: 3,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 2.24,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: 1.92,
    avg_round_18: 8,
    app_flat_18: 3,
    char_19: 'jing-yuan',
    app_rate_19: 1.92,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Jingliu',
    app_rate_20: 1.92,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: 1.6,
    avg_round_21: 9.5,
    app_flat_21: 2,
    char_22: 'Herta',
    app_rate_22: 1.6,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Tingyun',
    app_rate_23: 1.6,
    avg_round_23: 9.5,
    app_flat_23: 2,
    char_24: 'Seele',
    app_rate_24: 0.96,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Argenti',
    app_rate_25: 0.96,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Jiaoqiu',
    app_rate_26: 0.64,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Pela',
    app_rate_27: 0.64,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'Boothill',
    app_rate_28: 0.32,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Natasha',
    app_rate_29: 0.32,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Gepard',
    app_rate_30: 0.32,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    round: 8.27,
    char_1: 'Aventurine',
    app_rate_1: 65.66,
    avg_round_1: 8.28,
    app_flat_1: 676,
    char_2: 'Robin',
    app_rate_2: 65.27,
    avg_round_2: 8.18,
    app_flat_2: 684,
    char_3: 'topaz',
    app_rate_3: 49.33,
    avg_round_3: 8.28,
    app_flat_3: 469,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 15.1,
    avg_round_4: 8.84,
    app_flat_4: 206,
    char_5: 'silver-wolf',
    app_rate_5: 13.65,
    avg_round_5: 8.9,
    app_flat_5: 140,
    char_6: 'Huohuo',
    app_rate_6: 13.42,
    avg_round_6: 8.5,
    app_flat_6: 146,
    char_7: 'Sparkle',
    app_rate_7: 10.23,
    avg_round_7: 8.82,
    app_flat_7: 97,
    char_8: 'ruan-mei',
    app_rate_8: 9.06,
    avg_round_8: 9.2,
    app_flat_8: 88,
    char_9: 'fu-xuan',
    app_rate_9: 8.67,
    avg_round_9: 9.11,
    app_flat_9: 88,
    char_10: 'Jiaoqiu',
    app_rate_10: 7.72,
    avg_round_10: 7.72,
    app_flat_10: 65,
    char_11: 'Luocha',
    app_rate_11: 6.26,
    avg_round_11: 9.03,
    app_flat_11: 66,
    char_12: 'Tingyun',
    app_rate_12: 6.21,
    avg_round_12: 8.15,
    app_flat_12: 78,
    char_13: 'Pela',
    app_rate_13: 5.7,
    avg_round_13: 8.29,
    app_flat_13: 74,
    char_14: 'Gallagher',
    app_rate_14: 4.64,
    avg_round_14: 7.79,
    app_flat_14: 55,
    char_15: 'Moze',
    app_rate_15: 2.8,
    avg_round_15: 5.52,
    app_flat_15: 27,
    char_16: 'black-swan',
    app_rate_16: 2.68,
    avg_round_16: 9.07,
    app_flat_16: 29,
    char_17: 'Bronya',
    app_rate_17: 2.57,
    avg_round_17: 9.07,
    app_flat_17: 28,
    char_18: 'Clara',
    app_rate_18: 2.35,
    avg_round_18: 8.93,
    app_flat_18: 29,
    char_19: 'Feixiao',
    app_rate_19: 1.85,
    avg_round_19: 7.74,
    app_flat_19: 19,
    char_20: 'Blade',
    app_rate_20: 1.23,
    avg_round_20: 10.0,
    app_flat_20: 9,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 1.01,
    avg_round_21: 9.38,
    app_flat_21: 13,
    char_22: 'Yunli',
    app_rate_22: 0.73,
    avg_round_22: 8.33,
    app_flat_22: 12,
    char_23: 'Lynx',
    app_rate_23: 0.56,
    avg_round_23: 8.88,
    app_flat_23: 8,
    char_24: 'Jade',
    app_rate_24: 0.39,
    avg_round_24: 9.33,
    app_flat_24: 3,
    char_25: 'Welt',
    app_rate_25: 0.39,
    avg_round_25: 8.4,
    app_flat_25: 5,
    char_26: 'imbibitor-lunae',
    app_rate_26: 0.34,
    avg_round_26: 7,
    app_flat_26: 1,
    char_27: 'Guinaifen',
    app_rate_27: 0.34,
    avg_round_27: 8.2,
    app_flat_27: 5,
    char_28: 'Hanya',
    app_rate_28: 0.28,
    avg_round_28: 10,
    app_flat_28: 2,
    char_29: 'Bailu',
    app_rate_29: 0.28,
    avg_round_29: 9.5,
    app_flat_29: 2,
    char_30: 'Acheron',
    app_rate_30: 0.22,
    avg_round_30: 7.5,
    app_flat_30: 2
  },
  {
    char: 'Luocha',
    round: 8.27,
    char_1: 'ruan-mei',
    app_rate_1: 34.42,
    avg_round_1: 8.6,
    app_flat_1: 337,
    char_2: 'Sparkle',
    app_rate_2: 22.93,
    avg_round_2: 8.1,
    app_flat_2: 195,
    char_3: 'Robin',
    app_rate_3: 21.92,
    avg_round_3: 7.44,
    app_flat_3: 293,
    char_4: 'Bronya',
    app_rate_4: 19.94,
    avg_round_4: 8.52,
    app_flat_4: 212,
    char_5: 'imbibitor-lunae',
    app_rate_5: 15.85,
    avg_round_5: 8.27,
    app_flat_5: 101,
    char_6: 'Acheron',
    app_rate_6: 15.8,
    avg_round_6: 8.24,
    app_flat_6: 148,
    char_7: 'black-swan',
    app_rate_7: 14.92,
    avg_round_7: 8.43,
    app_flat_7: 126,
    char_8: 'Tingyun',
    app_rate_8: 13.2,
    avg_round_8: 7.84,
    app_flat_8: 131,
    char_9: 'Kafka',
    app_rate_9: 12.81,
    avg_round_9: 8.85,
    app_flat_9: 102,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 12.41,
    avg_round_10: 8.94,
    app_flat_10: 142,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 10.87,
    avg_round_11: 8.14,
    app_flat_11: 171,
    char_12: 'Feixiao',
    app_rate_12: 10.52,
    avg_round_12: 6.88,
    app_flat_12: 158,
    char_13: 'Blade',
    app_rate_13: 10.43,
    avg_round_13: 8.9,
    app_flat_13: 114,
    char_14: 'Pela',
    app_rate_14: 10.39,
    avg_round_14: 8.31,
    app_flat_14: 126,
    char_15: 'Jingliu',
    app_rate_15: 10.26,
    avg_round_15: 9.2,
    app_flat_15: 81,
    char_16: 'Jiaoqiu',
    app_rate_16: 9.86,
    avg_round_16: 7.98,
    app_flat_16: 103,
    char_17: 'Firefly',
    app_rate_17: 8.32,
    avg_round_17: 8.8,
    app_flat_17: 81,
    char_18: 'Yunli',
    app_rate_18: 7.92,
    avg_round_18: 7.57,
    app_flat_18: 122,
    char_19: 'Boothill',
    app_rate_19: 6.43,
    avg_round_19: 8.17,
    app_flat_19: 104,
    char_20: 'topaz',
    app_rate_20: 6.21,
    avg_round_20: 7.95,
    app_flat_20: 86,
    char_21: 'Clara',
    app_rate_21: 5.37,
    avg_round_21: 9.07,
    app_flat_21: 85,
    char_22: 'dr-ratio',
    app_rate_22: 4.93,
    avg_round_22: 9.03,
    app_flat_22: 66,
    char_23: 'silver-wolf',
    app_rate_23: 4.23,
    avg_round_23: 8.42,
    app_flat_23: 46,
    char_24: 'Jade',
    app_rate_24: 2.95,
    avg_round_24: 7.96,
    app_flat_24: 23,
    char_25: 'Hanya',
    app_rate_25: 0.97,
    avg_round_25: 8.62,
    app_flat_25: 8,
    char_26: 'Moze',
    app_rate_26: 0.92,
    avg_round_26: 6.71,
    app_flat_26: 14,
    char_27: 'Argenti',
    app_rate_27: 0.7,
    avg_round_27: 9.2,
    app_flat_27: 5,
    char_28: 'Sampo',
    app_rate_28: 0.7,
    avg_round_28: 8.5,
    app_flat_28: 4,
    char_29: 'fu-xuan',
    app_rate_29: 0.48,
    avg_round_29: 9,
    app_flat_29: 2,
    char_30: 'Yukong',
    app_rate_30: 0.48,
    avg_round_30: 6.5,
    app_flat_30: 2
  },
  {
    char: 'Blade',
    round: 8.32,
    char_1: 'Bronya',
    app_rate_1: 54.35,
    avg_round_1: 8.28,
    app_flat_1: 157,
    char_2: 'Luocha',
    app_rate_2: 38.23,
    avg_round_2: 8.9,
    app_flat_2: 114,
    char_3: 'Sparkle',
    app_rate_3: 33.87,
    avg_round_3: 8.2,
    app_flat_3: 101,
    char_4: 'ruan-mei',
    app_rate_4: 24.84,
    avg_round_4: 8.33,
    app_flat_4: 64,
    char_5: 'Huohuo',
    app_rate_5: 24.19,
    avg_round_5: 8.21,
    app_flat_5: 73,
    char_6: 'Jade',
    app_rate_6: 18.87,
    avg_round_6: 8.14,
    app_flat_6: 44,
    char_7: 'fu-xuan',
    app_rate_7: 17.9,
    avg_round_7: 8.57,
    app_flat_7: 35,
    char_8: 'Robin',
    app_rate_8: 13.87,
    avg_round_8: 8.56,
    app_flat_8: 34,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 11.45,
    avg_round_9: 9.09,
    app_flat_9: 42,
    char_10: 'Lynx',
    app_rate_10: 8.87,
    avg_round_10: 8.35,
    app_flat_10: 26,
    char_11: 'Jingliu',
    app_rate_11: 8.71,
    avg_round_11: 8.5,
    app_flat_11: 12,
    char_12: 'imbibitor-lunae',
    app_rate_12: 5.0,
    avg_round_12: 9,
    app_flat_12: 10,
    char_13: 'Aventurine',
    app_rate_13: 4.68,
    avg_round_13: 9.14,
    app_flat_13: 11,
    char_14: 'Clara',
    app_rate_14: 4.19,
    avg_round_14: 9.33,
    app_flat_14: 12,
    char_15: 'dr-ratio',
    app_rate_15: 3.55,
    avg_round_15: 10.0,
    app_flat_15: 9,
    char_16: 'Gallagher',
    app_rate_16: 3.39,
    avg_round_16: 9.25,
    app_flat_16: 8,
    char_17: 'Jiaoqiu',
    app_rate_17: 3.23,
    avg_round_17: 8.8,
    app_flat_17: 7,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 3.06,
    avg_round_18: 9.62,
    app_flat_18: 14,
    char_19: 'black-swan',
    app_rate_19: 3.06,
    avg_round_19: 9.25,
    app_flat_19: 4,
    char_20: 'Yunli',
    app_rate_20: 2.42,
    avg_round_20: 9.2,
    app_flat_20: 7,
    char_21: 'Feixiao',
    app_rate_21: 2.26,
    avg_round_21: 7.43,
    app_flat_21: 7,
    char_22: 'Pela',
    app_rate_22: 2.1,
    avg_round_22: 9.67,
    app_flat_22: 5,
    char_23: 'silver-wolf',
    app_rate_23: 1.61,
    avg_round_23: 9.5,
    app_flat_23: 4,
    char_24: 'Tingyun',
    app_rate_24: 1.45,
    avg_round_24: 9,
    app_flat_24: 5,
    char_25: 'Bailu',
    app_rate_25: 1.29,
    avg_round_25: 7,
    app_flat_25: 5,
    char_26: 'Acheron',
    app_rate_26: 0.65,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Welt',
    app_rate_27: 0.48,
    avg_round_27: 7,
    app_flat_27: 1,
    char_28: 'topaz',
    app_rate_28: 0.48,
    avg_round_28: 8.33,
    app_flat_28: 3,
    char_29: 'Kafka',
    app_rate_29: 0.48,
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Boothill',
    app_rate_30: 0.32,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    round: 8.32,
    char_1: 'imbibitor-lunae',
    app_rate_1: 56.38,
    avg_round_1: 9.0,
    app_flat_1: 23,
    char_2: 'Huohuo',
    app_rate_2: 26.6,
    avg_round_2: 8.89,
    app_flat_2: 15,
    char_3: 'Tingyun',
    app_rate_3: 25.53,
    avg_round_3: 9.38,
    app_flat_3: 14,
    char_4: 'Luocha',
    app_rate_4: 23.4,
    avg_round_4: 8.62,
    app_flat_4: 8,
    char_5: 'Sparkle',
    app_rate_5: 21.28,
    avg_round_5: 8.8,
    app_flat_5: 9,
    char_6: 'Aventurine',
    app_rate_6: 21.28,
    avg_round_6: 8.6,
    app_flat_6: 10,
    char_7: 'fu-xuan',
    app_rate_7: 13.83,
    avg_round_7: 8.0,
    app_flat_7: 5,
    char_8: 'ruan-mei',
    app_rate_8: 12.77,
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Yunli',
    app_rate_9: 8.51,
    avg_round_9: 8,
    app_flat_9: 5,
    char_10: 'Clara',
    app_rate_10: 7.45,
    avg_round_10: 7.6,
    app_flat_10: 5,
    char_11: 'Robin',
    app_rate_11: 6.38,
    avg_round_11: 7.25,
    app_flat_11: 6,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 5.32,
    avg_round_12: 9.5,
    app_flat_12: 2,
    char_13: 'dr-ratio',
    app_rate_13: 5.32,
    avg_round_13: 10,
    app_flat_13: 2,
    char_14: 'Boothill',
    app_rate_14: 5.32,
    avg_round_14: 7.67,
    app_flat_14: 3,
    char_15: 'Bronya',
    app_rate_15: 5.32,
    avg_round_15: 7.5,
    app_flat_15: 2,
    char_16: 'Lynx',
    app_rate_16: 4.26,
    avg_round_16: 8.33,
    app_flat_16: 3,
    char_17: 'Argenti',
    app_rate_17: 4.26,
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'Yukong',
    app_rate_18: 4.26,
    avg_round_18: 8,
    app_flat_18: 1,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 4.26,
    avg_round_19: 6,
    app_flat_19: 1,
    char_20: 'Feixiao',
    app_rate_20: 4.26,
    avg_round_20: 8,
    app_flat_20: 3,
    char_21: 'black-swan',
    app_rate_21: 3.19,
    avg_round_21: 9.33,
    app_flat_21: 3,
    char_22: 'Gallagher',
    app_rate_22: 3.19,
    avg_round_22: 7.67,
    app_flat_22: 3,
    char_23: 'topaz',
    app_rate_23: 3.19,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: 2.13,
    avg_round_24: 6,
    app_flat_24: 1,
    char_25: 'Asta',
    app_rate_25: 2.13,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Acheron',
    app_rate_26: 2.13,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Pela',
    app_rate_27: 2.13,
    avg_round_27: 6,
    app_flat_27: 1,
    char_28: 'Moze',
    app_rate_28: 2.13,
    avg_round_28: 7,
    app_flat_28: 1,
    char_29: 'Misha',
    app_rate_29: 2.13,
    avg_round_29: 8,
    app_flat_29: 2,
    char_30: 'Bailu',
    app_rate_30: 1.06,
    avg_round_30: 7,
    app_flat_30: 1
  },
  {
    char: 'jing-yuan',
    round: 8.36,
    char_1: 'Tingyun',
    app_rate_1: 46.34,
    avg_round_1: 8.26,
    app_flat_1: 19,
    char_2: 'Sparkle',
    app_rate_2: 43.09,
    avg_round_2: 8.5,
    app_flat_2: 18,
    char_3: 'Robin',
    app_rate_3: 40.65,
    avg_round_3: 8.92,
    app_flat_3: 13,
    char_4: 'Aventurine',
    app_rate_4: 34.96,
    avg_round_4: 9.0,
    app_flat_4: 14,
    char_5: 'fu-xuan',
    app_rate_5: 26.02,
    avg_round_5: 9.0,
    app_flat_5: 8,
    char_6: 'ruan-mei',
    app_rate_6: 23.58,
    avg_round_6: 9,
    app_flat_6: 3,
    char_7: 'Huohuo',
    app_rate_7: 21.14,
    avg_round_7: 7.0,
    app_flat_7: 5,
    char_8: 'Clara',
    app_rate_8: 10.57,
    avg_round_8: 9.5,
    app_flat_8: 8,
    char_9: 'Luocha',
    app_rate_9: 8.94,
    avg_round_9: 9,
    app_flat_9: 7,
    char_10: 'topaz',
    app_rate_10: 5.69,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'Jade',
    app_rate_11: 4.88,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Jiaoqiu',
    app_rate_12: 3.25,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Yunli',
    app_rate_13: 3.25,
    avg_round_13: 7,
    app_flat_13: 1,
    char_14: 'Jingliu',
    app_rate_14: 3.25,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Feixiao',
    app_rate_15: 3.25,
    avg_round_15: 9,
    app_flat_15: 2,
    char_16: 'Pela',
    app_rate_16: 2.44,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Gallagher',
    app_rate_17: 2.44,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Bronya',
    app_rate_18: 2.44,
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 2.44,
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'Acheron',
    app_rate_20: 1.63,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Lynx',
    app_rate_21: 1.63,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 1.63,
    avg_round_22: 7,
    app_flat_22: 1,
    char_23: 'Argenti',
    app_rate_23: 1.63,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: 1.63,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Herta',
    app_rate_25: 0.81,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Bailu',
    app_rate_26: 0.81,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'silver-wolf',
    app_rate_27: 0.81,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'black-swan',
    app_rate_28: 0.81,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    round: 8.36,
    char_1: 'march-7th-swordmaster',
    app_rate_1: 54.17,
    avg_round_1: 8.71,
    app_flat_1: 7,
    char_2: 'ruan-mei',
    app_rate_2: 45.83,
    avg_round_2: 7.6,
    app_flat_2: 5,
    char_3: 'trailblazer-imaginary',
    app_rate_3: 37.5,
    avg_round_3: 9.33,
    app_flat_3: 5,
    char_4: 'Robin',
    app_rate_4: 37.5,
    avg_round_4: 8.75,
    app_flat_4: 4,
    char_5: 'Gallagher',
    app_rate_5: 20.83,
    avg_round_5: 8.2,
    app_flat_5: 5,
    char_6: 'Aventurine',
    app_rate_6: 16.67,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Luocha',
    app_rate_7: 12.5,
    avg_round_7: 7.5,
    app_flat_7: 2,
    char_8: 'Tingyun',
    app_rate_8: 8.33,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'march-7th',
    app_rate_9: 8.33,
    avg_round_9: 8.5,
    app_flat_9: 2,
    char_10: 'Bronya',
    app_rate_10: 8.33,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: 8.33,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Huohuo',
    app_rate_12: 8.33,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'Firefly',
    app_rate_13: 8.33,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Pela',
    app_rate_14: 4.17,
    avg_round_14: 7,
    app_flat_14: 1,
    char_15: 'Boothill',
    app_rate_15: 4.17,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Kafka',
    app_rate_16: 4.17,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Misha',
    app_rate_17: 4.17,
    avg_round_17: 7,
    app_flat_17: 1,
    char_18: 'Hanya',
    app_rate_18: 4.17,
    avg_round_18: 7,
    app_flat_18: 1,
    char_19: 'Bailu',
    app_rate_19: 4.17,
    avg_round_19: 7,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    round: 8.38,
    char_1: 'Yunli',
    app_rate_1: 33.23,
    avg_round_1: 8.39,
    app_flat_1: 88,
    char_2: 'Sparkle',
    app_rate_2: 31.69,
    avg_round_2: 7.91,
    app_flat_2: 56,
    char_3: 'Robin',
    app_rate_3: 31.08,
    avg_round_3: 8.46,
    app_flat_3: 75,
    char_4: 'Tingyun',
    app_rate_4: 25.85,
    avg_round_4: 8.43,
    app_flat_4: 69,
    char_5: 'ruan-mei',
    app_rate_5: 23.69,
    avg_round_5: 8.44,
    app_flat_5: 32,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 18.46,
    avg_round_6: 9.04,
    app_flat_6: 48,
    char_7: 'Blade',
    app_rate_7: 16.92,
    avg_round_7: 8.35,
    app_flat_7: 26,
    char_8: 'Bronya',
    app_rate_8: 15.38,
    avg_round_8: 8.39,
    app_flat_8: 23,
    char_9: 'Clara',
    app_rate_9: 10.46,
    avg_round_9: 9.0,
    app_flat_9: 21,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 9.23,
    avg_round_10: 8.29,
    app_flat_10: 11,
    char_11: 'Pela',
    app_rate_11: 8.62,
    avg_round_11: 8.88,
    app_flat_11: 16,
    char_12: 'Acheron',
    app_rate_12: 8.62,
    avg_round_12: 8,
    app_flat_12: 6,
    char_13: 'Firefly',
    app_rate_13: 7.38,
    avg_round_13: 8.25,
    app_flat_13: 8,
    char_14: 'black-swan',
    app_rate_14: 7.38,
    avg_round_14: 8.9,
    app_flat_14: 10,
    char_15: 'Kafka',
    app_rate_15: 6.77,
    avg_round_15: 8.88,
    app_flat_15: 8,
    char_16: 'Feixiao',
    app_rate_16: 5.85,
    avg_round_16: 7.65,
    app_flat_16: 17,
    char_17: 'Jingliu',
    app_rate_17: 5.85,
    avg_round_17: 8.6,
    app_flat_17: 5,
    char_18: 'silver-wolf',
    app_rate_18: 5.85,
    avg_round_18: 8.5,
    app_flat_18: 6,
    char_19: 'Jiaoqiu',
    app_rate_19: 5.23,
    avg_round_19: 8.25,
    app_flat_19: 8,
    char_20: 'topaz',
    app_rate_20: 3.69,
    avg_round_20: 8.55,
    app_flat_20: 11,
    char_21: 'dr-ratio',
    app_rate_21: 3.08,
    avg_round_21: 8.88,
    app_flat_21: 8,
    char_22: 'imbibitor-lunae',
    app_rate_22: 2.77,
    avg_round_22: 9.0,
    app_flat_22: 4,
    char_23: 'Jade',
    app_rate_23: 2.46,
    avg_round_23: 9,
    app_flat_23: 3,
    char_24: 'Boothill',
    app_rate_24: 1.54,
    avg_round_24: 9.25,
    app_flat_24: 4,
    char_25: 'Hanya',
    app_rate_25: 1.23,
    avg_round_25: 8.33,
    app_flat_25: 3,
    char_26: 'Moze',
    app_rate_26: 1.23,
    avg_round_26: 6.5,
    app_flat_26: 4,
    char_27: 'Sampo',
    app_rate_27: 0.62,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Asta',
    app_rate_28: 0.62,
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'jing-yuan',
    app_rate_29: 0.62,
    avg_round_29: 9,
    app_flat_29: 1,
    char_30: 'Misha',
    app_rate_30: 0.62,
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    round: 8.5,
    char_1: 'Kafka',
    app_rate_1: 71.92,
    avg_round_1: 8.63,
    app_flat_1: 710,
    char_2: 'Acheron',
    app_rate_2: 54.08,
    avg_round_2: 8.5,
    app_flat_2: 654,
    char_3: 'Huohuo',
    app_rate_3: 32.5,
    avg_round_3: 8.66,
    app_flat_3: 307,
    char_4: 'fu-xuan',
    app_rate_4: 26.65,
    avg_round_4: 8.36,
    app_flat_4: 301,
    char_5: 'ruan-mei',
    app_rate_5: 26.47,
    avg_round_5: 8.5,
    app_flat_5: 216,
    char_6: 'Aventurine',
    app_rate_6: 17.04,
    avg_round_6: 8.38,
    app_flat_6: 247,
    char_7: 'Luocha',
    app_rate_7: 12.39,
    avg_round_7: 8.43,
    app_flat_7: 126,
    char_8: 'Jiaoqiu',
    app_rate_8: 11.55,
    avg_round_8: 7.79,
    app_flat_8: 146,
    char_9: 'Robin',
    app_rate_9: 9.03,
    avg_round_9: 8.73,
    app_flat_9: 97,
    char_10: 'Pela',
    app_rate_10: 8.34,
    avg_round_10: 8.42,
    app_flat_10: 145,
    char_11: 'Gallagher',
    app_rate_11: 5.59,
    avg_round_11: 8.65,
    app_flat_11: 50,
    char_12: 'Sparkle',
    app_rate_12: 3.99,
    avg_round_12: 8.67,
    app_flat_12: 15,
    char_13: 'Sampo',
    app_rate_13: 3.4,
    avg_round_13: 8.82,
    app_flat_13: 42,
    char_14: 'silver-wolf',
    app_rate_14: 2.3,
    avg_round_14: 8.86,
    app_flat_14: 28,
    char_15: 'Bailu',
    app_rate_15: 1.94,
    avg_round_15: 8.69,
    app_flat_15: 16,
    char_16: 'Gepard',
    app_rate_16: 1.94,
    avg_round_16: 8.4,
    app_flat_16: 20,
    char_17: 'dr-ratio',
    app_rate_17: 1.76,
    avg_round_17: 9.07,
    app_flat_17: 29,
    char_18: 'Tingyun',
    app_rate_18: 1.32,
    avg_round_18: 8.73,
    app_flat_18: 15,
    char_19: 'Asta',
    app_rate_19: 1.17,
    avg_round_19: 9.29,
    app_flat_19: 11,
    char_20: 'Guinaifen',
    app_rate_20: 1.13,
    avg_round_20: 9.0,
    app_flat_20: 7,
    char_21: 'Lynx',
    app_rate_21: 0.88,
    avg_round_21: 8.9,
    app_flat_21: 10,
    char_22: 'Bronya',
    app_rate_22: 0.77,
    avg_round_22: 8.5,
    app_flat_22: 2,
    char_23: 'Blade',
    app_rate_23: 0.69,
    avg_round_23: 9.25,
    app_flat_23: 4,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.51,
    avg_round_24: 10,
    app_flat_24: 2,
    char_25: 'march-7th-swordmaster',
    app_rate_25: 0.44,
    avg_round_25: 9,
    app_flat_25: 6,
    char_26: 'Yunli',
    app_rate_26: 0.44,
    avg_round_26: 8.88,
    app_flat_26: 8,
    char_27: 'Welt',
    app_rate_27: 0.29,
    avg_round_27: 8.6,
    app_flat_27: 5,
    char_28: 'Luka',
    app_rate_28: 0.26,
    avg_round_28: 7,
    app_flat_28: 2,
    char_29: 'Firefly',
    app_rate_29: 0.26,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Feixiao',
    app_rate_30: 0.18,
    avg_round_30: 9.5,
    app_flat_30: 2
  },
  {
    char: 'Seele',
    round: 8.6,
    char_1: 'Sparkle',
    app_rate_1: 92.57,
    avg_round_1: 8.6,
    app_flat_1: 18,
    char_2: 'fu-xuan',
    app_rate_2: 91.22,
    avg_round_2: 8.73,
    app_flat_2: 21,
    char_3: 'silver-wolf',
    app_rate_3: 81.76,
    avg_round_3: 8.73,
    app_flat_3: 19,
    char_4: 'Robin',
    app_rate_4: 11.49,
    avg_round_4: 9.5,
    app_flat_4: 2,
    char_5: 'Tingyun',
    app_rate_5: 8.78,
    avg_round_5: 7.67,
    app_flat_5: 3,
    char_6: 'ruan-mei',
    app_rate_6: 3.38,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Jade',
    app_rate_7: 2.03,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Huohuo',
    app_rate_8: 2.03,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 1.35,
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'Clara',
    app_rate_10: 0.68,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Firefly',
    app_rate_11: 0.68,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Aventurine',
    app_rate_12: 0.68,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Lynx',
    app_rate_13: 0.68,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Yukong',
    app_rate_14: 0.68,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Yunli',
    app_rate_15: 0.68,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Jiaoqiu',
    app_rate_16: 0.68,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Bailu',
    app_rate_17: 0.68,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    round: 8.61,
    char_1: 'black-swan',
    app_rate_1: 75.0,
    avg_round_1: 8.82,
    app_flat_1: 42,
    char_2: 'Kafka',
    app_rate_2: 47.58,
    avg_round_2: 8.8,
    app_flat_2: 20,
    char_3: 'Huohuo',
    app_rate_3: 45.16,
    avg_round_3: 9.0,
    app_flat_3: 32,
    char_4: 'ruan-mei',
    app_rate_4: 35.48,
    avg_round_4: 8.3,
    app_flat_4: 20,
    char_5: 'Acheron',
    app_rate_5: 16.13,
    avg_round_5: 9.6,
    app_flat_5: 9,
    char_6: 'Aventurine',
    app_rate_6: 15.32,
    avg_round_6: 8.78,
    app_flat_6: 9,
    char_7: 'Luocha',
    app_rate_7: 12.9,
    avg_round_7: 8.5,
    app_flat_7: 4,
    char_8: 'Gallagher',
    app_rate_8: 9.68,
    avg_round_8: 7.83,
    app_flat_8: 6,
    char_9: 'Jiaoqiu',
    app_rate_9: 9.68,
    avg_round_9: 8.25,
    app_flat_9: 4,
    char_10: 'fu-xuan',
    app_rate_10: 9.68,
    avg_round_10: 8,
    app_flat_10: 3,
    char_11: 'Robin',
    app_rate_11: 4.84,
    avg_round_11: 9.0,
    app_flat_11: 4,
    char_12: 'trailblazer-imaginary',
    app_rate_12: 3.23,
    avg_round_12: 7.67,
    app_flat_12: 3,
    char_13: 'Bailu',
    app_rate_13: 3.23,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Lynx',
    app_rate_14: 1.61,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'dr-ratio',
    app_rate_15: 1.61,
    avg_round_15: 9,
    app_flat_15: 2,
    char_16: 'Gepard',
    app_rate_16: 1.61,
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'Sparkle',
    app_rate_17: 0.81,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'Clara',
    app_rate_18: 0.81,
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 0.81,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Blade',
    app_rate_20: 0.81,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Guinaifen',
    app_rate_21: 0.81,
    avg_round_21: 8,
    app_flat_21: 1,
    char_22: 'Asta',
    app_rate_22: 0.81,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'Tingyun',
    app_rate_23: 0.81,
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'Pela',
    app_rate_24: 0.81,
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'Xueyi',
    app_rate_25: 0.81,
    avg_round_25: 6,
    app_flat_25: 1,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    round: 8.64,
    char_1: 'Robin',
    app_rate_1: 54.9,
    avg_round_1: 8.72,
    app_flat_1: 320,
    char_2: 'Aventurine',
    app_rate_2: 26.18,
    avg_round_2: 8.3,
    app_flat_2: 154,
    char_3: 'fu-xuan',
    app_rate_3: 25.95,
    avg_round_3: 8.73,
    app_flat_3: 109,
    char_4: 'Sparkle',
    app_rate_4: 23.76,
    avg_round_4: 8.47,
    app_flat_4: 113,
    char_5: 'Huohuo',
    app_rate_5: 22.84,
    avg_round_5: 8.56,
    app_flat_5: 125,
    char_6: 'Tingyun',
    app_rate_6: 22.49,
    avg_round_6: 8.71,
    app_flat_6: 124,
    char_7: 'topaz',
    app_rate_7: 16.72,
    avg_round_7: 8.1,
    app_flat_7: 84,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 16.26,
    avg_round_8: 8.85,
    app_flat_8: 106,
    char_9: 'Yunli',
    app_rate_9: 14.3,
    avg_round_9: 8.71,
    app_flat_9: 81,
    char_10: 'Luocha',
    app_rate_10: 14.07,
    avg_round_10: 9.07,
    app_flat_10: 85,
    char_11: 'ruan-mei',
    app_rate_11: 11.53,
    avg_round_11: 9.32,
    app_flat_11: 47,
    char_12: 'Jingliu',
    app_rate_12: 5.42,
    avg_round_12: 9.45,
    app_flat_12: 21,
    char_13: 'dr-ratio',
    app_rate_13: 4.84,
    avg_round_13: 8.93,
    app_flat_13: 29,
    char_14: 'Lynx',
    app_rate_14: 3.92,
    avg_round_14: 9.0,
    app_flat_14: 21,
    char_15: 'Feixiao',
    app_rate_15: 3.92,
    avg_round_15: 8.08,
    app_flat_15: 26,
    char_16: 'Gallagher',
    app_rate_16: 3.81,
    avg_round_16: 9.5,
    app_flat_16: 22,
    char_17: 'Jade',
    app_rate_17: 3.81,
    avg_round_17: 9.8,
    app_flat_17: 9,
    char_18: 'Pela',
    app_rate_18: 3.58,
    avg_round_18: 9.0,
    app_flat_18: 19,
    char_19: 'Blade',
    app_rate_19: 3.0,
    avg_round_19: 9.33,
    app_flat_19: 12,
    char_20: 'Bronya',
    app_rate_20: 2.77,
    avg_round_20: 8.25,
    app_flat_20: 12,
    char_21: 'Bailu',
    app_rate_21: 2.08,
    avg_round_21: 8.83,
    app_flat_21: 12,
    char_22: 'jing-yuan',
    app_rate_22: 1.5,
    avg_round_22: 9.5,
    app_flat_22: 8,
    char_23: 'Argenti',
    app_rate_23: 1.38,
    avg_round_23: 9.6,
    app_flat_23: 5,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 1.27,
    avg_round_24: 9.67,
    app_flat_24: 9,
    char_25: 'Boothill',
    app_rate_25: 1.04,
    avg_round_25: 9.17,
    app_flat_25: 6,
    char_26: 'silver-wolf',
    app_rate_26: 1.04,
    avg_round_26: 9.33,
    app_flat_26: 3,
    char_27: 'imbibitor-lunae',
    app_rate_27: 0.92,
    avg_round_27: 8.33,
    app_flat_27: 6,
    char_28: 'Jiaoqiu',
    app_rate_28: 0.81,
    avg_round_28: 8.75,
    app_flat_28: 4,
    char_29: 'Hanya',
    app_rate_29: 0.81,
    avg_round_29: 7.6,
    app_flat_29: 5,
    char_30: 'Acheron',
    app_rate_30: 0.69,
    avg_round_30: 9,
    app_flat_30: 2
  },
  {
    char: 'Kafka',
    round: 8.66,
    char_1: 'black-swan',
    app_rate_1: 88.01,
    avg_round_1: 8.63,
    app_flat_1: 710,
    char_2: 'Acheron',
    app_rate_2: 46.94,
    avg_round_2: 8.5,
    app_flat_2: 436,
    char_3: 'Huohuo',
    app_rate_3: 36.42,
    avg_round_3: 8.56,
    app_flat_3: 266,
    char_4: 'ruan-mei',
    app_rate_4: 31.45,
    avg_round_4: 8.54,
    app_flat_4: 218,
    char_5: 'fu-xuan',
    app_rate_5: 24.92,
    avg_round_5: 8.59,
    app_flat_5: 217,
    char_6: 'Aventurine',
    app_rate_6: 13.96,
    avg_round_6: 8.58,
    app_flat_6: 153,
    char_7: 'Luocha',
    app_rate_7: 13.02,
    avg_round_7: 8.85,
    app_flat_7: 102,
    char_8: 'Robin',
    app_rate_8: 9.98,
    avg_round_8: 8.6,
    app_flat_8: 82,
    char_9: 'Jiaoqiu',
    app_rate_9: 6.58,
    avg_round_9: 8.22,
    app_flat_9: 49,
    char_10: 'Gallagher',
    app_rate_10: 5.32,
    avg_round_10: 8.63,
    app_flat_10: 37,
    char_11: 'Pela',
    app_rate_11: 4.43,
    avg_round_11: 9.32,
    app_flat_11: 44,
    char_12: 'Sampo',
    app_rate_12: 2.64,
    avg_round_12: 8.8,
    app_flat_12: 20,
    char_13: 'Bailu',
    app_rate_13: 2.51,
    avg_round_13: 8.81,
    app_flat_13: 16,
    char_14: 'Gepard',
    app_rate_14: 2.19,
    avg_round_14: 8.39,
    app_flat_14: 18,
    char_15: 'Asta',
    app_rate_15: 1.48,
    avg_round_15: 9.17,
    app_flat_15: 12,
    char_16: 'silver-wolf',
    app_rate_16: 1.34,
    avg_round_16: 9.09,
    app_flat_16: 11,
    char_17: 'Guinaifen',
    app_rate_17: 1.34,
    avg_round_17: 9.0,
    app_flat_17: 8,
    char_18: 'Sparkle',
    app_rate_18: 1.3,
    avg_round_18: 9,
    app_flat_18: 5,
    char_19: 'Tingyun',
    app_rate_19: 1.3,
    avg_round_19: 8.75,
    app_flat_19: 8,
    char_20: 'Luka',
    app_rate_20: 1.07,
    avg_round_20: 8.45,
    app_flat_20: 11,
    char_21: 'Lynx',
    app_rate_21: 0.98,
    avg_round_21: 8.88,
    app_flat_21: 8,
    char_22: 'Yunli',
    app_rate_22: 0.45,
    avg_round_22: 8,
    app_flat_22: 4,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.36,
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: 'Feixiao',
    app_rate_24: 0.27,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Boothill',
    app_rate_25: 0.27,
    avg_round_25: 8.67,
    app_flat_25: 3,
    char_26: 'Bronya',
    app_rate_26: 0.27,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'dr-ratio',
    app_rate_27: 0.13,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Jingliu',
    app_rate_28: 0.13,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Blade',
    app_rate_29: 0.13,
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Clara',
    app_rate_30: 0.13,
    avg_round_30: 9,
    app_flat_30: 2
  },
  {
    char: 'Yanqing',
    round: 8.71,
    char_1: 'Aventurine',
    app_rate_1: 93.94,
    avg_round_1: 8.3,
    app_flat_1: 37,
    char_2: 'Robin',
    app_rate_2: 48.48,
    avg_round_2: 8.11,
    app_flat_2: 19,
    char_3: 'Tingyun',
    app_rate_3: 39.39,
    avg_round_3: 8.89,
    app_flat_3: 15,
    char_4: 'ruan-mei',
    app_rate_4: 33.33,
    avg_round_4: 8.46,
    app_flat_4: 13,
    char_5: 'Sparkle',
    app_rate_5: 22.73,
    avg_round_5: 8.57,
    app_flat_5: 7,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 21.21,
    avg_round_6: 7.89,
    app_flat_6: 9,
    char_7: 'Bronya',
    app_rate_7: 9.09,
    avg_round_7: 8.2,
    app_flat_7: 5,
    char_8: 'Pela',
    app_rate_8: 9.09,
    avg_round_8: 8,
    app_flat_8: 3,
    char_9: 'Yunli',
    app_rate_9: 7.58,
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'topaz',
    app_rate_10: 4.55,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'Jiaoqiu',
    app_rate_11: 3.03,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Hanya',
    app_rate_12: 1.52,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'march-7th',
    app_rate_13: 1.52,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'silver-wolf',
    app_rate_14: 1.52,
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'Huohuo',
    app_rate_15: 1.52,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'fu-xuan',
    app_rate_16: 1.52,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    round: 8.71,
    char_1: 'Yunli',
    app_rate_1: 30.43,
    avg_round_1: 9,
    app_flat_1: 4,
    char_2: 'ruan-mei',
    app_rate_2: 30.43,
    avg_round_2: 8.67,
    app_flat_2: 3,
    char_3: 'Robin',
    app_rate_3: 26.09,
    avg_round_3: 8.33,
    app_flat_3: 5,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 21.74,
    avg_round_4: 9,
    app_flat_4: 2,
    char_5: 'Feixiao',
    app_rate_5: 21.74,
    avg_round_5: 9.0,
    app_flat_5: 4,
    char_6: 'Bronya',
    app_rate_6: 21.74,
    avg_round_6: 8,
    app_flat_6: 1,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 21.74,
    avg_round_7: 9.0,
    app_flat_7: 4,
    char_8: 'Sparkle',
    app_rate_8: 17.39,
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Tingyun',
    app_rate_9: 17.39,
    avg_round_9: 8,
    app_flat_9: 2,
    char_10: 'Firefly',
    app_rate_10: 13.04,
    avg_round_10: 8,
    app_flat_10: 1,
    char_11: 'Clara',
    app_rate_11: 13.04,
    avg_round_11: 8.33,
    app_flat_11: 3,
    char_12: 'Pela',
    app_rate_12: 8.7,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Blade',
    app_rate_13: 8.7,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'topaz',
    app_rate_14: 8.7,
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'Jingliu',
    app_rate_15: 4.35,
    avg_round_15: 8,
    app_flat_15: 1,
    char_16: 'Yukong',
    app_rate_16: 4.35,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Jade',
    app_rate_17: 4.35,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Hanya',
    app_rate_18: 4.35,
    avg_round_18: 6,
    app_flat_18: 1,
    char_19: 'Acheron',
    app_rate_19: 4.35,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Jiaoqiu',
    app_rate_20: 4.35,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'dr-ratio',
    app_rate_21: 4.35,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Boothill',
    app_rate_22: 4.35,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'black-swan',
    app_rate_23: 4.35,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    round: 8.73,
    char_1: 'Sparkle',
    app_rate_1: 100.0,
    avg_round_1: 8.73,
    app_flat_1: 21,
    char_2: 'fu-xuan',
    app_rate_2: 91.78,
    avg_round_2: 8.6,
    app_flat_2: 20,
    char_3: 'silver-wolf',
    app_rate_3: 87.67,
    avg_round_3: 8.47,
    app_flat_3: 17,
    char_4: 'Pela',
    app_rate_4: 4.11,
    avg_round_4: 10,
    app_flat_4: 2,
    char_5: 'Huohuo',
    app_rate_5: 2.74,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Tingyun',
    app_rate_6: 2.74,
    avg_round_6: 4.5,
    app_flat_6: 2,
    char_7: 'Robin',
    app_rate_7: 2.74,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Aventurine',
    app_rate_8: 1.37,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Gepard',
    app_rate_9: 1.37,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Bailu',
    app_rate_10: 1.37,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Lynx',
    app_rate_11: 1.37,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 1.37,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: 1.37,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    round: 8.88,
    char_1: 'ruan-mei',
    app_rate_1: 38.71,
    avg_round_1: 9.42,
    app_flat_1: 22,
    char_2: 'Acheron',
    app_rate_2: 27.82,
    avg_round_2: 9.0,
    app_flat_2: 27,
    char_3: 'Kafka',
    app_rate_3: 22.58,
    avg_round_3: 8.81,
    app_flat_3: 16,
    char_4: 'black-swan',
    app_rate_4: 21.37,
    avg_round_4: 8.69,
    app_flat_4: 16,
    char_5: 'Robin',
    app_rate_5: 16.94,
    avg_round_5: 8.57,
    app_flat_5: 26,
    char_6: 'Pela',
    app_rate_6: 14.52,
    avg_round_6: 9.22,
    app_flat_6: 17,
    char_7: 'trailblazer-imaginary',
    app_rate_7: 14.11,
    avg_round_7: 8.88,
    app_flat_7: 8,
    char_8: 'Bronya',
    app_rate_8: 13.31,
    avg_round_8: 8.36,
    app_flat_8: 11,
    char_9: 'Sparkle',
    app_rate_9: 12.9,
    avg_round_9: 9.43,
    app_flat_9: 11,
    char_10: 'Jingliu',
    app_rate_10: 12.9,
    avg_round_10: 10.0,
    app_flat_10: 8,
    char_11: 'Firefly',
    app_rate_11: 12.5,
    avg_round_11: 9.4,
    app_flat_11: 5,
    char_12: 'Yunli',
    app_rate_12: 10.48,
    avg_round_12: 8.83,
    app_flat_12: 18,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 10.48,
    avg_round_13: 8.27,
    app_flat_13: 21,
    char_14: 'Tingyun',
    app_rate_14: 9.68,
    avg_round_14: 8.85,
    app_flat_14: 13,
    char_15: 'Jiaoqiu',
    app_rate_15: 9.27,
    avg_round_15: 8.86,
    app_flat_15: 11,
    char_16: 'silver-wolf',
    app_rate_16: 9.27,
    avg_round_16: 8.33,
    app_flat_16: 9,
    char_17: 'Feixiao',
    app_rate_17: 8.47,
    avg_round_17: 7.36,
    app_flat_17: 14,
    char_18: 'Clara',
    app_rate_18: 7.26,
    avg_round_18: 8.83,
    app_flat_18: 12,
    char_19: 'imbibitor-lunae',
    app_rate_19: 5.24,
    avg_round_19: 9.33,
    app_flat_19: 3,
    char_20: 'Blade',
    app_rate_20: 3.23,
    avg_round_20: 7,
    app_flat_20: 5,
    char_21: 'Boothill',
    app_rate_21: 2.82,
    avg_round_21: 8,
    app_flat_21: 3,
    char_22: 'Jade',
    app_rate_22: 2.42,
    avg_round_22: 8,
    app_flat_22: 3,
    char_23: 'topaz',
    app_rate_23: 2.02,
    avg_round_23: 6,
    app_flat_23: 1,
    char_24: 'dr-ratio',
    app_rate_24: 2.02,
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'Sampo',
    app_rate_25: 1.61,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'Luka',
    app_rate_26: 1.21,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Gallagher',
    app_rate_27: 0.81,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Yukong',
    app_rate_28: 0.81,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Hanya',
    app_rate_29: 0.4,
    avg_round_29: 7,
    app_flat_29: 1,
    char_30: 'Hook',
    app_rate_30: 0.4,
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'Guinaifen',
    round: 9.04,
    char_1: 'Acheron',
    app_rate_1: 65.42,
    avg_round_1: 9.09,
    app_flat_1: 42,
    char_2: 'Pela',
    app_rate_2: 42.06,
    avg_round_2: 8.62,
    app_flat_2: 32,
    char_3: 'fu-xuan',
    app_rate_3: 31.78,
    avg_round_3: 9.09,
    app_flat_3: 21,
    char_4: 'black-swan',
    app_rate_4: 28.97,
    avg_round_4: 9.0,
    app_flat_4: 7,
    char_5: 'Kafka',
    app_rate_5: 28.04,
    avg_round_5: 9.0,
    app_flat_5: 8,
    char_6: 'Aventurine',
    app_rate_6: 25.23,
    avg_round_6: 8.31,
    app_flat_6: 13,
    char_7: 'Huohuo',
    app_rate_7: 21.5,
    avg_round_7: 8.86,
    app_flat_7: 7,
    char_8: 'Gallagher',
    app_rate_8: 12.15,
    avg_round_8: 9.33,
    app_flat_8: 10,
    char_9: 'silver-wolf',
    app_rate_9: 5.61,
    avg_round_9: 9.6,
    app_flat_9: 5,
    char_10: 'dr-ratio',
    app_rate_10: 5.61,
    avg_round_10: 8.2,
    app_flat_10: 5,
    char_11: 'Luocha',
    app_rate_11: 4.67,
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'Jiaoqiu',
    app_rate_12: 4.67,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'ruan-mei',
    app_rate_13: 3.74,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Sparkle',
    app_rate_14: 3.74,
    avg_round_14: 9,
    app_flat_14: 3,
    char_15: 'Firefly',
    app_rate_15: 2.8,
    avg_round_15: 8.5,
    app_flat_15: 2,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 2.8,
    avg_round_16: 8.5,
    app_flat_16: 2,
    char_17: 'Bronya',
    app_rate_17: 1.87,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Lynx',
    app_rate_18: 1.87,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Gepard',
    app_rate_19: 1.87,
    avg_round_19: 8,
    app_flat_19: 2,
    char_20: 'Robin',
    app_rate_20: 1.87,
    avg_round_20: 8,
    app_flat_20: 1,
    char_21: 'Tingyun',
    app_rate_21: 0.93,
    avg_round_21: 6,
    app_flat_21: 1,
    char_22: 'Sampo',
    app_rate_22: 0.93,
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'Jingliu',
    app_rate_23: 0.93,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 0.93,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    round: 9.06,
    char_1: 'Tingyun',
    app_rate_1: 49.0,
    avg_round_1: 8.83,
    app_flat_1: 24,
    char_2: 'Aventurine',
    app_rate_2: 40.0,
    avg_round_2: 9.3,
    app_flat_2: 18,
    char_3: 'ruan-mei',
    app_rate_3: 31.0,
    avg_round_3: 9.3,
    app_flat_3: 10,
    char_4: 'Sparkle',
    app_rate_4: 26.0,
    avg_round_4: 8.71,
    app_flat_4: 13,
    char_5: 'Huohuo',
    app_rate_5: 23.0,
    avg_round_5: 9.12,
    app_flat_5: 14,
    char_6: 'Robin',
    app_rate_6: 23.0,
    avg_round_6: 9.4,
    app_flat_6: 7,
    char_7: 'fu-xuan',
    app_rate_7: 18.0,
    avg_round_7: 9.33,
    app_flat_7: 3,
    char_8: 'Luocha',
    app_rate_8: 16.0,
    avg_round_8: 9.2,
    app_flat_8: 5,
    char_9: 'Clara',
    app_rate_9: 12.0,
    avg_round_9: 9.6,
    app_flat_9: 5,
    char_10: 'Jiaoqiu',
    app_rate_10: 12.0,
    avg_round_10: 7.75,
    app_flat_10: 4,
    char_11: 'Bronya',
    app_rate_11: 10.0,
    avg_round_11: 9.67,
    app_flat_11: 3,
    char_12: 'Boothill',
    app_rate_12: 9.0,
    avg_round_12: 9.2,
    app_flat_12: 5,
    char_13: 'Yunli',
    app_rate_13: 5.0,
    avg_round_13: 9.33,
    app_flat_13: 3,
    char_14: 'Jingliu',
    app_rate_14: 5.0,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Hanya',
    app_rate_15: 4.0,
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'Jade',
    app_rate_16: 3.0,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Gallagher',
    app_rate_17: 3.0,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Acheron',
    app_rate_18: 2.0,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'jing-yuan',
    app_rate_19: 2.0,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Herta',
    app_rate_20: 1.0,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'imbibitor-lunae',
    app_rate_21: 1.0,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Blade',
    app_rate_22: 1.0,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Welt',
    app_rate_23: 1.0,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Feixiao',
    app_rate_24: 1.0,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'silver-wolf',
    app_rate_25: 1.0,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Pela',
    app_rate_26: 1.0,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    round: 9.07,
    char_1: 'ruan-mei',
    app_rate_1: 50.91,
    avg_round_1: 8.92,
    app_flat_1: 13,
    char_2: 'Kafka',
    app_rate_2: 43.64,
    avg_round_2: 8.45,
    app_flat_2: 11,
    char_3: 'Acheron',
    app_rate_3: 23.64,
    avg_round_3: 9.0,
    app_flat_3: 13,
    char_4: 'Pela',
    app_rate_4: 21.82,
    avg_round_4: 9.3,
    app_flat_4: 10,
    char_5: 'fu-xuan',
    app_rate_5: 21.82,
    avg_round_5: 9.5,
    app_flat_5: 10,
    char_6: 'Huohuo',
    app_rate_6: 18.18,
    avg_round_6: 7.67,
    app_flat_6: 6,
    char_7: 'Luocha',
    app_rate_7: 16.36,
    avg_round_7: 9.0,
    app_flat_7: 4,
    char_8: 'Aventurine',
    app_rate_8: 14.55,
    avg_round_8: 9,
    app_flat_8: 4,
    char_9: 'black-swan',
    app_rate_9: 12.73,
    avg_round_9: 7,
    app_flat_9: 2,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 12.73,
    avg_round_10: 7.67,
    app_flat_10: 3,
    char_11: 'Gepard',
    app_rate_11: 10.91,
    avg_round_11: 8.67,
    app_flat_11: 5,
    char_12: 'Boothill',
    app_rate_12: 9.09,
    avg_round_12: 9.0,
    app_flat_12: 4,
    char_13: 'Jiaoqiu',
    app_rate_13: 5.45,
    avg_round_13: 7.67,
    app_flat_13: 3,
    char_14: 'Gallagher',
    app_rate_14: 5.45,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Misha',
    app_rate_15: 5.45,
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Bailu',
    app_rate_16: 5.45,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Clara',
    app_rate_17: 5.45,
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'silver-wolf',
    app_rate_18: 3.64,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'march-7th',
    app_rate_19: 3.64,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Lynx',
    app_rate_20: 1.82,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Firefly',
    app_rate_21: 1.82,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Tingyun',
    app_rate_22: 1.82,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Sparkle',
    app_rate_23: 1.82,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Bronya',
    app_rate_24: 1.82,
    avg_round_24: 8,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    round: 9.11,
    char_1: 'Bronya',
    app_rate_1: 55.28,
    avg_round_1: 8.86,
    app_flat_1: 132,
    char_2: 'ruan-mei',
    app_rate_2: 52.58,
    avg_round_2: 9.17,
    app_flat_2: 129,
    char_3: 'fu-xuan',
    app_rate_3: 29.58,
    avg_round_3: 8.84,
    app_flat_3: 49,
    char_4: 'Huohuo',
    app_rate_4: 27.58,
    avg_round_4: 9.07,
    app_flat_4: 80,
    char_5: 'Luocha',
    app_rate_5: 27.35,
    avg_round_5: 9.2,
    app_flat_5: 81,
    char_6: 'Pela',
    app_rate_6: 24.41,
    avg_round_6: 9.24,
    app_flat_6: 71,
    char_7: 'Sparkle',
    app_rate_7: 17.61,
    avg_round_7: 8.95,
    app_flat_7: 37,
    char_8: 'Robin',
    app_rate_8: 15.02,
    avg_round_8: 9.44,
    app_flat_8: 30,
    char_9: 'Tingyun',
    app_rate_9: 7.16,
    avg_round_9: 9.33,
    app_flat_9: 15,
    char_10: 'Yunli',
    app_rate_10: 7.16,
    avg_round_10: 9.35,
    app_flat_10: 20,
    char_11: 'Blade',
    app_rate_11: 6.34,
    avg_round_11: 8.5,
    app_flat_11: 12,
    char_12: 'Clara',
    app_rate_12: 5.52,
    avg_round_12: 9.45,
    app_flat_12: 21,
    char_13: 'Aventurine',
    app_rate_13: 4.23,
    avg_round_13: 9.38,
    app_flat_13: 14,
    char_14: 'Bailu',
    app_rate_14: 3.76,
    avg_round_14: 10.0,
    app_flat_14: 8,
    char_15: 'Gallagher',
    app_rate_15: 2.7,
    avg_round_15: 10.0,
    app_flat_15: 5,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 2.46,
    avg_round_16: 9.15,
    app_flat_16: 13,
    char_17: 'Lynx',
    app_rate_17: 2.23,
    avg_round_17: 8.6,
    app_flat_17: 5,
    char_18: 'silver-wolf',
    app_rate_18: 1.88,
    avg_round_18: 9,
    app_flat_18: 2,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.94,
    avg_round_19: 10,
    app_flat_19: 2,
    char_20: 'Acheron',
    app_rate_20: 0.94,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Jade',
    app_rate_21: 0.7,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Argenti',
    app_rate_22: 0.59,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'jing-yuan',
    app_rate_23: 0.47,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'march-7th',
    app_rate_24: 0.47,
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'Firefly',
    app_rate_25: 0.35,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Kafka',
    app_rate_26: 0.35,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Boothill',
    app_rate_27: 0.35,
    avg_round_27: 8,
    app_flat_27: 1,
    char_28: 'Gepard',
    app_rate_28: 0.35,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Feixiao',
    app_rate_29: 0.35,
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'trailblazer-imaginary',
    app_rate_30: 0.23,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    round: 9.12,
    char_1: 'Acheron',
    app_rate_1: 55.17,
    avg_round_1: 9.0,
    app_flat_1: 22,
    char_2: 'Aventurine',
    app_rate_2: 24.14,
    avg_round_2: 9.17,
    app_flat_2: 12,
    char_3: 'Sparkle',
    app_rate_3: 21.84,
    avg_round_3: 7.67,
    app_flat_3: 3,
    char_4: 'fu-xuan',
    app_rate_4: 19.54,
    avg_round_4: 8.33,
    app_flat_4: 6,
    char_5: 'Jiaoqiu',
    app_rate_5: 17.24,
    avg_round_5: 8.4,
    app_flat_5: 9,
    char_6: 'Pela',
    app_rate_6: 17.24,
    avg_round_6: 10.0,
    app_flat_6: 8,
    char_7: 'ruan-mei',
    app_rate_7: 14.94,
    avg_round_7: 10.0,
    app_flat_7: 4,
    char_8: 'Huohuo',
    app_rate_8: 14.94,
    avg_round_8: 9.5,
    app_flat_8: 8,
    char_9: 'Gallagher',
    app_rate_9: 12.64,
    avg_round_9: 8.12,
    app_flat_9: 8,
    char_10: 'Luocha',
    app_rate_10: 12.64,
    avg_round_10: 8.5,
    app_flat_10: 6,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 10.34,
    avg_round_11: 10,
    app_flat_11: 3,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 10.34,
    avg_round_12: 9.4,
    app_flat_12: 5,
    char_13: 'black-swan',
    app_rate_13: 9.2,
    avg_round_13: 8.6,
    app_flat_13: 5,
    char_14: 'silver-wolf',
    app_rate_14: 9.2,
    avg_round_14: 9.33,
    app_flat_14: 3,
    char_15: 'imbibitor-lunae',
    app_rate_15: 8.05,
    avg_round_15: 8.5,
    app_flat_15: 2,
    char_16: 'dr-ratio',
    app_rate_16: 8.05,
    avg_round_16: 8.4,
    app_flat_16: 5,
    char_17: 'Bronya',
    app_rate_17: 6.9,
    avg_round_17: 6.5,
    app_flat_17: 2,
    char_18: 'Firefly',
    app_rate_18: 5.75,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Blade',
    app_rate_19: 3.45,
    avg_round_19: 7,
    app_flat_19: 1,
    char_20: 'Robin',
    app_rate_20: 3.45,
    avg_round_20: 4.5,
    app_flat_20: 2,
    char_21: 'Tingyun',
    app_rate_21: 3.45,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Feixiao',
    app_rate_22: 2.3,
    avg_round_22: 9,
    app_flat_22: 2,
    char_23: 'Kafka',
    app_rate_23: 2.3,
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'Bailu',
    app_rate_24: 1.15,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Lynx',
    app_rate_25: 1.15,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Gepard',
    app_rate_26: 1.15,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Argenti',
    app_rate_27: 1.15,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Yunli',
    app_rate_28: 1.15,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Clara',
    app_rate_29: 1.15,
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    round: 9.25,
    char_1: 'Robin',
    app_rate_1: 40.0,
    avg_round_1: 8.4,
    app_flat_1: 7,
    char_2: 'ruan-mei',
    app_rate_2: 35.0,
    avg_round_2: 9.8,
    app_flat_2: 7,
    char_3: 'topaz',
    app_rate_3: 25.0,
    avg_round_3: 6.2,
    app_flat_3: 5,
    char_4: 'Feixiao',
    app_rate_4: 22.5,
    avg_round_4: 4.33,
    app_flat_4: 3,
    char_5: 'Pela',
    app_rate_5: 22.5,
    avg_round_5: 8.2,
    app_flat_5: 5,
    char_6: 'Acheron',
    app_rate_6: 17.5,
    avg_round_6: 8,
    app_flat_6: 3,
    char_7: 'silver-wolf',
    app_rate_7: 15.0,
    avg_round_7: 8,
    app_flat_7: 3,
    char_8: 'Clara',
    app_rate_8: 12.5,
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Yunli',
    app_rate_9: 10.0,
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 10.0,
    avg_round_10: 10,
    app_flat_10: 3,
    char_11: 'Jingliu',
    app_rate_11: 10.0,
    avg_round_11: 9.5,
    app_flat_11: 2,
    char_12: 'Jiaoqiu',
    app_rate_12: 7.5,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'dr-ratio',
    app_rate_13: 7.5,
    avg_round_13: 10,
    app_flat_13: 2,
    char_14: 'Firefly',
    app_rate_14: 7.5,
    avg_round_14: 10,
    app_flat_14: 2,
    char_15: 'Sparkle',
    app_rate_15: 7.5,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Tingyun',
    app_rate_16: 7.5,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Boothill',
    app_rate_17: 5.0,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Sushang',
    app_rate_18: 5.0,
    avg_round_18: 8.5,
    app_flat_18: 2,
    char_19: 'Kafka',
    app_rate_19: 5.0,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Misha',
    app_rate_20: 5.0,
    avg_round_20: 9.5,
    app_flat_20: 2,
    char_21: 'Luka',
    app_rate_21: 5.0,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'fu-xuan',
    app_rate_22: 2.5,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Aventurine',
    app_rate_23: 2.5,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Moze',
    app_rate_24: 2.5,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Yanqing',
    app_rate_25: 2.5,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Bronya',
    app_rate_26: 2.5,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Huohuo',
    app_rate_27: 2.5,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'Hanya',
    app_rate_28: 2.5,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    round: 9.5,
    char_1: 'Firefly',
    app_rate_1: 47.73,
    avg_round_1: 9.64,
    app_flat_1: 11,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 47.73,
    avg_round_2: 9.64,
    app_flat_2: 11,
    char_3: 'Kafka',
    app_rate_3: 37.5,
    avg_round_3: 9.17,
    app_flat_3: 12,
    char_4: 'black-swan',
    app_rate_4: 36.36,
    avg_round_4: 9.29,
    app_flat_4: 11,
    char_5: 'Gallagher',
    app_rate_5: 36.36,
    avg_round_5: 9.56,
    app_flat_5: 15,
    char_6: 'Huohuo',
    app_rate_6: 19.32,
    avg_round_6: 9.0,
    app_flat_6: 4,
    char_7: 'ruan-mei',
    app_rate_7: 13.64,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Luocha',
    app_rate_8: 11.36,
    avg_round_8: 9.67,
    app_flat_8: 5,
    char_9: 'Aventurine',
    app_rate_9: 6.82,
    avg_round_9: 9,
    app_flat_9: 2,
    char_10: 'fu-xuan',
    app_rate_10: 5.68,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 3.41,
    avg_round_11: 8,
    app_flat_11: 2,
    char_12: 'Feixiao',
    app_rate_12: 3.41,
    avg_round_12: 8,
    app_flat_12: 3,
    char_13: 'Gepard',
    app_rate_13: 3.41,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'Hook',
    app_rate_14: 2.27,
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: 'imbibitor-lunae',
    app_rate_15: 2.27,
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Hanya',
    app_rate_16: 2.27,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Lynx',
    app_rate_17: 2.27,
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'topaz',
    app_rate_18: 2.27,
    avg_round_18: 9,
    app_flat_18: 2,
    char_19: 'Clara',
    app_rate_19: 2.27,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Robin',
    app_rate_20: 2.27,
    avg_round_20: 6,
    app_flat_20: 1,
    char_21: 'Pela',
    app_rate_21: 2.27,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Bronya',
    app_rate_22: 1.14,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'Bailu',
    app_rate_23: 1.14,
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'Sparkle',
    app_rate_24: 1.14,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Himeko',
    app_rate_25: 1.14,
    avg_round_25: 6,
    app_flat_25: 1,
    char_26: 'Sampo',
    app_rate_26: 1.14,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Boothill',
    app_rate_27: 1.14,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Jingliu',
    app_rate_28: 1.14,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Acheron',
    app_rate_29: 1.14,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    round: 99.99,
    char_1: 'imbibitor-lunae',
    app_rate_1: 79.49,
    avg_round_1: 8.17,
    app_flat_1: 6,
    char_2: 'Sparkle',
    app_rate_2: 35.9,
    avg_round_2: 6.67,
    app_flat_2: 3,
    char_3: 'Luocha',
    app_rate_3: 28.21,
    avg_round_3: 6.5,
    app_flat_3: 2,
    char_4: 'Huohuo',
    app_rate_4: 23.08,
    avg_round_4: 5,
    app_flat_4: 2,
    char_5: 'Aventurine',
    app_rate_5: 23.08,
    avg_round_5: 8.67,
    app_flat_5: 3,
    char_6: 'Tingyun',
    app_rate_6: 20.51,
    avg_round_6: 7.75,
    app_flat_6: 4,
    char_7: 'Robin',
    app_rate_7: 12.82,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 10.26,
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Hanya',
    app_rate_9: 10.26,
    avg_round_9: 8,
    app_flat_9: 1,
    char_10: 'ruan-mei',
    app_rate_10: 10.26,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Bailu',
    app_rate_11: 5.13,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Feixiao',
    app_rate_12: 5.13,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Yunli',
    app_rate_13: 5.13,
    avg_round_13: 5,
    app_flat_13: 2,
    char_14: 'Firefly',
    app_rate_14: 2.56,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 2.56,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'fu-xuan',
    app_rate_16: 2.56,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Natasha',
    app_rate_17: 2.56,
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'Lynx',
    app_rate_18: 2.56,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Clara',
    app_rate_19: 2.56,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Gepard',
    app_rate_20: 2.56,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Seele',
    app_rate_21: 2.56,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Gallagher',
    app_rate_22: 2.56,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'dr-ratio',
    app_rate_23: 2.56,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Pela',
    app_rate_24: 2.56,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Bronya',
    app_rate_25: 2.56,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    round: 99.99,
    char_1: 'Firefly',
    app_rate_1: 64.29,
    avg_round_1: 10,
    app_flat_1: 2,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 64.29,
    avg_round_2: 10,
    app_flat_2: 2,
    char_3: 'ruan-mei',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Gallagher',
    app_rate_4: 25.0,
    avg_round_4: 8.67,
    app_flat_4: 3,
    char_5: 'Aventurine',
    app_rate_5: 17.86,
    avg_round_5: 7.5,
    app_flat_5: 2,
    char_6: 'Robin',
    app_rate_6: 14.29,
    avg_round_6: 7,
    app_flat_6: 3,
    char_7: 'Yunli',
    app_rate_7: 10.71,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'dr-ratio',
    app_rate_8: 7.14,
    avg_round_8: 8,
    app_flat_8: 1,
    char_9: 'Sparkle',
    app_rate_9: 7.14,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'topaz',
    app_rate_10: 7.14,
    avg_round_10: 7,
    app_flat_10: 1,
    char_11: 'Acheron',
    app_rate_11: 3.57,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Jiaoqiu',
    app_rate_12: 3.57,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'silver-wolf',
    app_rate_13: 3.57,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Gepard',
    app_rate_14: 3.57,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Blade',
    app_rate_15: 3.57,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 3.57,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Luocha',
    app_rate_17: 3.57,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Huohuo',
    app_rate_18: 3.57,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Asta',
    app_rate_19: 3.57,
    avg_round_19: 6,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 56.67,
    avg_round_1: 8.25,
    app_flat_1: 6,
    char_2: 'Aventurine',
    app_rate_2: 40.0,
    avg_round_2: 9.0,
    app_flat_2: 5,
    char_3: 'Yunli',
    app_rate_3: 33.33,
    avg_round_3: 9.0,
    app_flat_3: 4,
    char_4: 'fu-xuan',
    app_rate_4: 23.33,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Huohuo',
    app_rate_5: 16.67,
    avg_round_5: 7.5,
    app_flat_5: 2,
    char_6: 'Jade',
    app_rate_6: 16.67,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Sparkle',
    app_rate_7: 16.67,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'ruan-mei',
    app_rate_8: 13.33,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: 13.33,
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'Boothill',
    app_rate_10: 10.0,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'topaz',
    app_rate_11: 10.0,
    avg_round_11: 4,
    app_flat_11: 1,
    char_12: 'Acheron',
    app_rate_12: 10.0,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'Clara',
    app_rate_13: 6.67,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Jiaoqiu',
    app_rate_14: 6.67,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Argenti',
    app_rate_15: 3.33,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'jing-yuan',
    app_rate_16: 3.33,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 3.33,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Luocha',
    app_rate_18: 3.33,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'dr-ratio',
    app_rate_19: 3.33,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Bailu',
    app_rate_20: 3.33,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Pela',
    app_rate_21: 3.33,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'Tingyun',
    app_rate_22: 3.33,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    round: 99.99,
    char_1: 'ruan-mei',
    app_rate_1: 60.71,
    avg_round_1: 9.5,
    app_flat_1: 2,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 32.14,
    avg_round_2: 9,
    app_flat_2: 3,
    char_3: 'Aventurine',
    app_rate_3: 17.86,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Boothill',
    app_rate_4: 17.86,
    avg_round_4: 8.5,
    app_flat_4: 4,
    char_5: 'Robin',
    app_rate_5: 14.29,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Jiaoqiu',
    app_rate_6: 14.29,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Pela',
    app_rate_7: 14.29,
    avg_round_7: 7.5,
    app_flat_7: 2,
    char_8: 'Luocha',
    app_rate_8: 14.29,
    avg_round_8: 7.5,
    app_flat_8: 2,
    char_9: 'fu-xuan',
    app_rate_9: 10.71,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Luka',
    app_rate_10: 10.71,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Gallagher',
    app_rate_11: 10.71,
    avg_round_11: 7.5,
    app_flat_11: 2,
    char_12: 'Huohuo',
    app_rate_12: 10.71,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'Tingyun',
    app_rate_13: 7.14,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 7.14,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Firefly',
    app_rate_15: 7.14,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Hanya',
    app_rate_16: 7.14,
    avg_round_16: 8,
    app_flat_16: 2,
    char_17: 'Lynx',
    app_rate_17: 7.14,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'march-7th',
    app_rate_18: 7.14,
    avg_round_18: 9.5,
    app_flat_18: 2,
    char_19: 'Yunli',
    app_rate_19: 3.57,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: 3.57,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: 3.57,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Sparkle',
    app_rate_22: 3.57,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Sushang',
    app_rate_23: 3.57,
    avg_round_23: 7,
    app_flat_23: 1,
    char_24: 'Gepard',
    app_rate_24: 3.57,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Jingliu',
    app_rate_25: 3.57,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'Acheron',
    app_rate_26: 3.57,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    round: 99.99,
    char_1: 'fu-xuan',
    app_rate_1: 61.11,
    avg_round_1: 8.5,
    app_flat_1: 2,
    char_2: 'silver-wolf',
    app_rate_2: 50.0,
    avg_round_2: 10,
    app_flat_2: 1,
    char_3: 'Sparkle',
    app_rate_3: 44.44,
    avg_round_3: 8,
    app_flat_3: 2,
    char_4: 'ruan-mei',
    app_rate_4: 22.22,
    avg_round_4: 9.5,
    app_flat_4: 2,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 22.22,
    avg_round_5: 9.0,
    app_flat_5: 4,
    char_6: 'Boothill',
    app_rate_6: 16.67,
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'Gallagher',
    app_rate_7: 16.67,
    avg_round_7: 8.33,
    app_flat_7: 3,
    char_8: 'Yunli',
    app_rate_8: 11.11,
    avg_round_8: 9,
    app_flat_8: 1,
    char_9: 'Robin',
    app_rate_9: 11.11,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Lynx',
    app_rate_10: 5.56,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Bailu',
    app_rate_11: 5.56,
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'Feixiao',
    app_rate_12: 5.56,
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: 'Huohuo',
    app_rate_13: 5.56,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 5.56,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Sampo',
    app_rate_15: 5.56,
    avg_round_15: 6,
    app_flat_15: 1,
    char_16: 'topaz',
    app_rate_16: 5.56,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Aventurine',
    app_rate_17: 5.56,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    round: 99.99,
    char_1: 'march-7th-swordmaster',
    app_rate_1: 66.67,
    avg_round_1: 7.67,
    app_flat_1: 3,
    char_2: 'Huohuo',
    app_rate_2: 55.56,
    avg_round_2: 7.67,
    app_flat_2: 3,
    char_3: 'Tingyun',
    app_rate_3: 33.33,
    avg_round_3: 8.33,
    app_flat_3: 3,
    char_4: 'Robin',
    app_rate_4: 33.33,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: 'ruan-mei',
    app_rate_5: 22.22,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Gallagher',
    app_rate_6: 22.22,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Sparkle',
    app_rate_7: 22.22,
    avg_round_7: 10,
    app_flat_7: 2,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 11.11,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Aventurine',
    app_rate_9: 11.11,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'fu-xuan',
    app_rate_10: 11.11,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Hanya',
    app_rate_11: 11.11,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Arlan',
    round: 99.99,
    char_1: 'Bronya',
    app_rate_1: 100.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Aventurine',
    app_rate_2: 75.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Robin',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: 25.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'silver-wolf',
    app_rate_5: 25.0,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Tingyun',
    app_rate_6: 25.0,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 50.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Asta',
    app_rate_2: 50.0,
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'fu-xuan',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: 25.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Aventurine',
    app_rate_5: 25.0,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Bronya',
    app_rate_6: 25.0,
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'Bailu',
    app_rate_7: 25.0,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'dr-ratio',
    app_rate_8: 25.0,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Hanya',
    app_rate_9: 25.0,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 99.99,
    char_1: 'Acheron',
    app_rate_1: 100.0,
    avg_round_1: 8,
    app_flat_1: 1,
    char_2: 'silver-wolf',
    app_rate_2: 100.0,
    avg_round_2: 8,
    app_flat_2: 1,
    char_3: 'Lynx',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: 50.0,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    round: 99.99,
    char_1: 'Clara',
    app_rate_1: 66.67,
    avg_round_1: 10,
    app_flat_1: 1,
    char_2: 'Aventurine',
    app_rate_2: 66.67,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Pela',
    app_rate_3: 33.33,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Kafka',
    app_rate_4: 33.33,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'black-swan',
    app_rate_5: 33.33,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Jingliu',
    app_rate_6: 33.33,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Bailu',
    app_rate_7: 33.33,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
