export const HSRDPSRankingArrayNew = [
  {
    id: 0,
    name: 'Seele',
    slug: 'seele',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (20.4%), CRIT DMG (81.6%), Speed (6.9)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'Seeles reset mechanic is not included in her calculations. Seele`s E6 is being counted with 5 total strikes per Ultimate applying more strikes will raise her E6 damage substantially.',
    single_dmg: {
      damage: 347727,
      damage_e1: 376296,
      damage_e2: 448108,
      damage_e3: 488828,
      damage_e4: 488828,
      damage_e5: 502260,
      damage_e6: 638254,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    },
    aoe_dmg: {
      damage: 347727,
      damage_e1: 376296,
      damage_e2: 448108,
      damage_e3: 488828,
      damage_e4: 488828,
      damage_e5: 502260,
      damage_e6: 638254,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    }
  },
  {
    id: 2,
    name: 'Yanqing',
    slug: 'yanqing',
    element: 'ice',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK%',
    substats: 'CRIT DMG (81.6%), ATK (38.9%)',
    relic_set_1: 'Hunter of Glacial Forest',
    relic_set_2: 'Pioneer Diver of Dead Waters',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'Yanqing will deal more damage post E1 when placed in teams that can consistently Freeze. These calculations assume Yanqing is never hit in battle.',
    single_dmg: {
      damage: 372685,
      damage_e1: 390936,
      damage_e2: 390936,
      damage_e3: 407912,
      damage_e4: 456862,
      damage_e5: 482807,
      damage_e6: 482806.5,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 316307,
      damage_e1: 330621,
      damage_e2: 330621,
      damage_e3: 346061,
      damage_e4: 387588,
      damage_e5: 405992,
      damage_e6: 405992,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 30,
    name: 'Kafka',
    slug: 'kafka',
    element: 'lightning',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'ATK (50.5%), Effect Hit Rate (11.66%), Speed (18.4)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'fermata',
    cone_s: '5',
    disclaimer:
      'The enemies Speed in these calculations is at 100. As a baseline faster or slower enemies will increase or decrease Kafka`s damage potential impacting your in game results.',
    single_dmg: {
      damage: 350301,
      damage_e1: 417144,
      damage_e2: 456757,
      damage_e3: 467897,
      damage_e4: 467897,
      damage_e5: 511132,
      damage_e6: 692057,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 697933,
      damage_e1: 806773,
      damage_e2: 890420,
      damage_e3: 906244,
      damage_e4: 906244,
      damage_e5: 990601,
      damage_e6: 1370487,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 1,
    name: 'Dan Heng',
    slug: 'dan-heng',
    element: 'wind',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Wind DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (43.7%), CRIT DMG (52.5%)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'The simulation assumes a perfect rotation where you time his Talent on each of his Ultimates.',
    single_dmg: {
      damage: 283382,
      damage_e1: 296213,
      damage_e2: 315821,
      damage_e3: 334122,
      damage_e4: 334122,
      damage_e5: 351467,
      damage_e6: 351467,
      skillPoints: 14,
      skillPoints_e1: 14,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    },
    aoe_dmg: {
      damage: 283382,
      damage_e1: 296213,
      damage_e2: 315821,
      damage_e3: 334122,
      damage_e4: 334122,
      damage_e5: 351467,
      damage_e6: 351467,
      skillPoints: 14,
      skillPoints_e1: 14,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    }
  },
  {
    id: 3,
    name: 'Jing Yuan',
    slug: 'jing-yuan',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (20.4%), CRIT DMG (70%), Speed (11.5)',
    relic_set_1: 'The Ashblazing Grand Duke',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'the-day-the-cosmos-fell',
    cone_s: '5',
    disclaimer:
      'Jing Yuan requires 5 energy to complete his rotation in these calculations before he acquires E4, without it his damage will decrease. Getting hit, a kill or leveraging your team and Cones are recommended.',
    single_dmg: {
      damage: 282616,
      damage_e1: 282616,
      damage_e2: 293535,
      damage_e3: 296065,
      damage_e4: 296065,
      damage_e5: 319726,
      damage_e6: 381741,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 506985,
      damage_e1: 574342,
      damage_e2: 607098,
      damage_e3: 614689,
      damage_e4: 614689,
      damage_e5: 658320,
      damage_e6: 761266,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 20,
    name: 'Blade',
    slug: 'blade',
    element: 'wind',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Wind DMG',
    rope: 'HP%',
    substats: 'Crit Rate (35%), CRIT DMG (35%), SPD (13.8)',
    relic_set_1: 'Longevous Disciple',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'flames-afar',
    cone_s: '5',
    disclaimer:
      'Blade can gain and lose SUBSTANTIAL amounts of damage based on how many times he is hit. These calculations assume he is hit ONCE per every full action he takes for the purpose of his talent charges.',
    single_dmg: {
      damage: 266380,
      damage_e1: 300066,
      damage_e2: 331597,
      damage_e3: 344910,
      damage_e4: 404730,
      damage_e5: 433514,
      damage_e6: 523586,
      skillPoints: 3,
      skillPoints_e1: 3,
      skillPoints_e2: 3,
      skillPoints_e3: 3,
      skillPoints_e4: 3,
      skillPoints_e5: 3,
      skillPoints_e6: 3
    },
    aoe_dmg: {
      damage: 583626,
      damage_e1: 617312,
      damage_e2: 682177,
      damage_e3: 717326,
      damage_e4: 840928,
      damage_e5: 895866,
      damage_e6: 1166083,
      skillPoints: 3,
      skillPoints_e1: 3,
      skillPoints_e2: 3,
      skillPoints_e3: 3,
      skillPoints_e4: 3,
      skillPoints_e5: 3,
      skillPoints_e6: 3
    }
  },
  {
    id: 4,
    name: 'Hook',
    slug: 'hook',
    element: 'fire',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Fire DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (29.2%), CRIT DMG (70%), Speed (4.6)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 271787,
      damage_e1: 277450,
      damage_e2: 277450,
      damage_e3: 293794,
      damage_e4: 293794,
      damage_e5: 304001,
      damage_e6: 338505.5,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 301363,
      damage_e1: 310261,
      damage_e2: 310261,
      damage_e3: 329887,
      damage_e4: 365475,
      damage_e5: 375682,
      damage_e6: 418117,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 5,
    name: 'Sushang',
    slug: 'sushang',
    element: 'phys',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (43.7%), CRIT DMG (46.7%), SPD (2.3)',
    relic_set_1: 'Champion of Streetwise Boxing',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 309418,
      damage_e1: 309418,
      damage_e2: 309418,
      damage_e3: 316096,
      damage_e4: 316096,
      damage_e5: 341212,
      damage_e6: 341212,
      skillPoints: 16,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 245778,
      damage_e1: 245778,
      damage_e2: 245778,
      damage_e3: 250360,
      damage_e4: 250360,
      damage_e5: 270259,
      damage_e6: 270259,
      skillPoints: 16,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 9,
    name: 'Arlan',
    slug: 'arlan',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (20.4%), CRIT DMG (75.8%), Speed (9.2)',
    relic_set_1: 'Band of Sizzling Thunder',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer:
      'The simulation assumes Arlan is at low HP, so you need to pair him with a Shielder to achieve the damage output.',
    single_dmg: {
      damage: 194129,
      damage_e1: 201003,
      damage_e2: 201003,
      damage_e3: 216594,
      damage_e4: 216594,
      damage_e5: 223772,
      damage_e6: 228727.1,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 239224,
      damage_e1: 246099,
      damage_e2: 246099,
      damage_e3: 261689,
      damage_e4: 261689,
      damage_e5: 273398,
      damage_e6: 337890,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    }
  },
  {
    id: 8,
    name: 'Himeko',
    slug: 'himeko',
    element: 'fire',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Fire DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (17.5%), CRIT DMG (70%), SPD (13.8)',
    relic_set_1: 'Firesmith of Lava-Forging',
    relic_set_2: 'Musketeer of Wild Wheat',
    relic_set_3: 'Inert Salsotto',
    cone: 'the-day-the-cosmos-fell',
    cone_s: '5',
    disclaimer:
      "Himeko's damage output changes based on how much Breaking she is doing - if you Break them often, the numbers you see below will increase.",
    single_dmg: {
      damage: 181281,
      damage_e1: 192860,
      damage_e2: 200188,
      damage_e3: 214176,
      damage_e4: 214176,
      damage_e5: 218749,
      damage_e6: 247095,
      skillPoints: 10,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 391839,
      damage_e1: 416469,
      damage_e2: 432696,
      damage_e3: 457876,
      damage_e4: 457876,
      damage_e5: 471592,
      damage_e6: 499939,
      skillPoints: 10,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 22,
    name: 'Luka',
    slug: 'luka',
    element: 'phys',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'ATK (62.2%), Effect Hit Rate (11.6%), Speed (11.5)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'eyes-of-the-prey',
    cone_s: '5',
    disclaimer:
      'Luka`s damage is reliant on the enemies actions - faster enemies will lead to him dealing substantially more damage while slower ones will lead to less.',
    single_dmg: {
      damage: 282005,
      damage_e1: 300991,
      damage_e2: 332232,
      damage_e3: 359734,
      damage_e4: 383497,
      damage_e5: 395584,
      damage_e6: 427584,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: -2,
      skillPoints_e3: -2,
      skillPoints_e4: -2,
      skillPoints_e5: -2,
      skillPoints_e6: -2
    },
    aoe_dmg: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    }
  },
  {
    id: 13,
    name: 'Silver Wolf',
    slug: 'silver-wolf',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (11.7%), CRIT DMG (46.7%), EHR (31.1%), Speed (9.2)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Pan-Cosmic Commercial Enterprise',
    cone: 'before-the-tutorial-mission-starts',
    cone_s: '5',
    disclaimer:
      'The rules of this calculation (enemies vulnerable to attackers element) disable a portion of Silver Wolfs skill. Against neutral enemies she will have a competitive advantage over other characters not shown here.',
    single_dmg: {
      damage: 174820,
      damage_e1: 209797,
      damage_e2: 245163,
      damage_e3: 259960,
      damage_e4: 292592,
      damage_e5: 308072,
      damage_e6: 499824,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 174820,
      damage_e1: 209797,
      damage_e2: 245163,
      damage_e3: 259960,
      damage_e4: 292592,
      damage_e5: 308072,
      damage_e6: 499824,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 7,
    name: 'Sampo',
    slug: 'sampo',
    element: 'wind',
    body: 'Effect Hit Rate',
    feet: 'ATK%',
    sphere: 'Wind DMG',
    rope: 'ATK%',
    substats: 'ATK% (46.7%), Effect Hit Rate (15.55%), Speed (18.4)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'its-showtime',
    cone_s: '5',
    disclaimer:
      'Sampos damage is reliant on the enemies actions, faster enemies will lead to him dealing substantially more damage while slower ones will lead to less.',
    single_dmg: {
      damage: 209053,
      damage_e1: 237818,
      damage_e2: 237818,
      damage_e3: 250599,
      damage_e4: 302471,
      damage_e5: 320123,
      damage_e6: 364223,
      skillPoints: 10,
      skillPoints_e1: 10,
      skillPoints_e2: 10,
      skillPoints_e3: 10,
      skillPoints_e4: 10,
      skillPoints_e5: 10,
      skillPoints_e6: 10
    },
    aoe_dmg: {
      damage: 428344,
      damage_e1: 474876,
      damage_e2: 474876,
      damage_e3: 487657,
      damage_e4: 539529,
      damage_e5: 581449,
      damage_e6: 683543,
      skillPoints: 10,
      skillPoints_e1: 10,
      skillPoints_e2: 10,
      skillPoints_e3: 10,
      skillPoints_e4: 10,
      skillPoints_e5: 10,
      skillPoints_e6: 10
    }
  },
  {
    id: 10,
    name: 'Serval',
    slug: 'serval',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (23.3%), CRIT DMG (81.6%), Speed (4.6)',
    relic_set_1: 'Band of Sizzling Thunder',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'the-seriousness-of-breakfast',
    cone_s: '5',
    disclaimer:
      'Serval requires 5 energy to complete her rotation in these calculations before she acquires E2, without it her damage will decrease. Getting hit, a kill or leveraging your team and Cones are recommended.',
    single_dmg: {
      damage: 177428,
      damage_e1: 177428,
      damage_e2: 177428,
      damage_e3: 187142,
      damage_e4: 187142,
      damage_e5: 194253,
      damage_e6: 225788,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 451005,
      damage_e1: 451005,
      damage_e2: 515011,
      damage_e3: 536025,
      damage_e4: 536025,
      damage_e5: 562695,
      damage_e6: 654041,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 11,
    name: 'Trailblazer (Physical)',
    slug: 'trailblazer-physical',
    element: 'phys',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (20.4%), CRIT DMG (75.8%), Speed (9.2)',
    relic_set_1: 'Champion of Streetwise Boxing',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 145497,
      damage_e1: 145497,
      damage_e2: 145497,
      damage_e3: 156248,
      damage_e4: 160354,
      damage_e5: 164407,
      damage_e6: 164407,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 335571,
      damage_e1: 335571,
      damage_e2: 335571,
      damage_e3: 366603,
      damage_e4: 378921,
      damage_e5: 384325,
      damage_e6: 384325,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 12,
    name: 'Herta',
    slug: 'herta',
    element: 'ice',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (29.2%), CRIT DMG (70%), Speed (4.6)',
    relic_set_1: 'Hunter of Glacial Forest',
    relic_set_2: 'Musketeer of Wild Wheat',
    relic_set_3: 'Inert Salsotto',
    cone: 'the-day-the-cosmos-fell',
    cone_s: '5',
    single_dmg: {
      damage: 91063,
      damage_e1: 91063,
      damage_e2: 100565,
      damage_e3: 106693,
      damage_e4: 107076,
      damage_e5: 109803,
      damage_e6: 116673,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 308757,
      damage_e1: 308757,
      damage_e2: 340972,
      damage_e3: 359358,
      damage_e4: 362799,
      damage_e5: 371632,
      damage_e6: 392245,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 25,
    name: 'Imbibitor Lunae',
    slug: 'imbibitor-lunae',
    element: 'imaginary',
    body: 'CRIT Rate',
    feet: 'SPD',
    sphere: 'Imaginary DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (10%), CRIT DMG (75.8%), SPD (2.3)',
    relic_set_1: 'Musketeer of Wild Wheat',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    single_dmg: {
      damage: 490518,
      damage_e1: 552913,
      damage_e2: 737218,
      damage_e3: 806501,
      damage_e4: 882193,
      damage_e5: 912383,
      damage_e6: 1070153,
      skillPoints: 28.8,
      skillPoints_e1: 28.8,
      skillPoints_e2: 34.5,
      skillPoints_e3: 34.5,
      skillPoints_e4: 34.5,
      skillPoints_e5: 34.5,
      skillPoints_e6: 34.5
    },
    aoe_dmg: {
      damage: 891155,
      damage_e1: 1018955,
      damage_e2: 1358606,
      damage_e3: 1487375,
      damage_e4: 1599768,
      damage_e5: 1660242,
      damage_e6: 1944556,
      skillPoints: 28.8,
      skillPoints_e1: 28.8,
      skillPoints_e2: 34.5,
      skillPoints_e3: 34.5,
      skillPoints_e4: 34.5,
      skillPoints_e5: 34.5,
      skillPoints_e6: 34.5
    }
  },
  {
    id: 25,
    name: 'Clara',
    slug: 'clara',
    element: 'physical',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (20.4%), CRIT DMG (78.8%), Speed (9.2)',
    relic_set_1: 'Champion of Streetwise Boxing',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer:
      'Clara damage output heavily depends on getting hit. The calculations assume she gets hit twice per turn and if the number increases, so will her damage output.',
    single_dmg: {
      damage: 279509,
      damage_e1: 279509,
      damage_e2: 299641,
      damage_e3: 311704,
      damage_e4: 311704,
      damage_e5: 328306,
      damage_e6: 392436.0,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 567794,
      damage_e1: 795315,
      damage_e2: 846041,
      damage_e3: 882230,
      damage_e4: 882230,
      damage_e5: 928488,
      damage_e6: 1237401,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 26,
    name: 'Jingliu',
    slug: 'jingliu',
    element: 'ice',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (35%), CRIT DMG (58.3%), Speed (4.6)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: 'Hunter of Glacial Forest',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer:
      'Fall of Aeons conditional Break bonus is not included in the calculations.',
    single_dmg: {
      damage: 437177,
      damage_e1: 623625,
      damage_e2: 680643,
      damage_e3: 726981,
      damage_e4: 773441,
      damage_e5: 816789,
      damage_e6: 892547,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    },
    aoe_dmg: {
      damage: 820012,
      damage_e1: 878821,
      damage_e2: 960275,
      damage_e3: 1032821,
      damage_e4: 1100405,
      damage_e5: 1183185,
      damage_e6: 1299279,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    }
  },
  {
    id: 27,
    name: 'Topaz & Numby',
    slug: 'topaz',
    element: 'fire',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'ATK%',
    rope: 'ATK%',
    substats: 'CRIT Rate (20.4%), CRIT DMG (99.1%)',
    relic_set_1: 'The Ashblazing Grand Duke',
    relic_set_2: '',
    relic_set_3: 'Duran, Dynasty of Running Wolves',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'These calcs assume you always get the full 50% action advance from hitting Proof of Debt, in combat this will not always be the case which will lower Topaz`s damage and at E2 can impact her Energy Recharge.',
    single_dmg: {
      damage: 504496,
      damage_e1: 591934,
      damage_e2: 686591,
      damage_e3: 726648,
      damage_e4: 1094667,
      damage_e5: 1176645,
      damage_e6: 1473793,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 724871,
      damage_e1: 779280,
      damage_e2: 862924,
      damage_e3: 927688,
      damage_e4: 988228,
      damage_e5: 1061518,
      damage_e6: 1290386,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    }
  },
  {
    id: 28,
    name: 'Guinaifen',
    slug: 'guinaifen',
    element: 'fire',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'Fire DMG',
    rope: 'ATK%',
    substats: 'ATK% (54%), Speed (4.6), EHR (15.5%)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'eyes-of-the-prey',
    cone_s: '5',
    disclaimer:
      'Enemies Speed is set to 140 - against faster enemies, Guinaifen damage will increase.',
    single_dmg: {
      damage: 187536,
      damage_e1: 187536,
      damage_e2: 211964,
      damage_e3: 229850,
      damage_e4: 229850,
      damage_e5: 235214,
      damage_e6: 249771,
      skillPoints: 2,
      skillPoints_e1: 2,
      skillPoints_e2: 2,
      skillPoints_e3: 2,
      skillPoints_e4: 2,
      skillPoints_e5: 2,
      skillPoints_e6: 2
    },
    aoe_dmg: {
      damage: 486769,
      damage_e1: 486769,
      damage_e2: 560051,
      damage_e3: 606127,
      damage_e4: 637478,
      damage_e5: 649973,
      damage_e6: 690199,
      skillPoints: 2,
      skillPoints_e1: 2,
      skillPoints_e2: 2,
      skillPoints_e3: 2,
      skillPoints_e4: 2,
      skillPoints_e5: 2,
      skillPoints_e6: 2
    }
  },
  {
    id: 29,
    name: 'Argenti',
    slug: 'argenti',
    element: 'physical',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (11.7%), CRIT DMG (99.1%), Speed (6.9)',
    relic_set_1: 'Champion of Streetwise Boxing',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'the-seriousness-of-breakfast',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 190558,
      damage_e1: 220757,
      damage_e2: 220757,
      damage_e3: 234781,
      damage_e4: 250850,
      damage_e5: 262478,
      damage_e6: 291690,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 482635,
      damage_e1: 550573,
      damage_e2: 602933,
      damage_e3: 646748,
      damage_e4: 692026,
      damage_e5: 719841,
      damage_e6: 789168,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 30,
    name: 'Xueyi',
    slug: 'xueyi',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'ATK',
    rope: 'Break Effect',
    substats: 'Crit Rate (20.4%), CRIT DMG (81.6%), Speed (6.9)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 257491,
      damage_e1: 276103,
      damage_e2: 276103,
      damage_e3: 285062,
      damage_e4: 312080,
      damage_e5: 332280,
      damage_e6: 385526,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 418381,
      damage_e1: 445678,
      damage_e2: 445678,
      damage_e3: 463597,
      damage_e4: 512495,
      damage_e5: 541365,
      damage_e6: 619460,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 31,
    name: 'Dr. Ratio',
    slug: 'dr-ratio',
    element: 'imaginary',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Imaginary DMG',
    rope: 'ATK',
    substats: 'Crit Rate (32.1%), CRIT DMG (75.8%)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: 'The Ashblazing Grand Duke',
    relic_set_3: 'Inert Salsotto',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'The calculations assume you are hitting a debuffed target (no value on the debuff we just calculated that it is there) to activate the Crit Rate/Crit DMG trace, so you need to pair Dr. Ratio with someone who can provide the debuffs as he can not do it by himself.',
    single_dmg: {
      damage: 556528,
      damage_e1: 630285,
      damage_e2: 759314,
      damage_e3: 763965,
      damage_e4: 1015532,
      damage_e5: 1087201,
      damage_e6: 1516295,
      skillPoints: 11,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 556528,
      damage_e1: 630285,
      damage_e2: 759314,
      damage_e3: 763965,
      damage_e4: 1015532,
      damage_e5: 1087201,
      damage_e6: 1516295,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 32,
    name: 'Misha',
    slug: 'misha',
    element: 'ice',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK',
    substats: 'Crit Rate (20.4%), CRIT DMG (75.8%), SPD (9.2)',
    relic_set_1: 'Hunter of Glacial Forest',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: 'Assumes allies consume 6 Skill Points per rotation.',
    single_dmg: {
      damage: 221806,
      damage_e1: 232594,
      damage_e2: 254363,
      damage_e3: 265362,
      damage_e4: 278339,
      damage_e5: 290521,
      damage_e6: 315397,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 310920,
      damage_e1: 343284,
      damage_e2: 375412,
      damage_e3: 388378,
      damage_e4: 403715,
      damage_e5: 425642,
      damage_e6: 455040,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 33,
    name: 'Black Swan',
    slug: 'black-swan',
    element: 'wind',
    body: 'Effect Hit Rate',
    feet: 'Speed',
    sphere: 'Wind DMG',
    rope: 'ATK',
    substats: 'ATK (23.3%), EHR (54.4%), SPD (9.2)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Pan-Cosmic Commercial Enterprise',
    cone: 'its-showtime',
    cone_s: '5',
    disclaimer:
      'Assumes allies detonate Arcana 3 times per rotation (the detonations are not included in the damage as this is only used to inflict Arcana stacks on enemies).',
    single_dmg: {
      damage: 339431,
      damage_e1: 424289,
      damage_e2: 424289,
      damage_e3: 469588,
      damage_e4: 469588,
      damage_e5: 475037,
      damage_e6: 633490,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 1328183,
      damage_e1: 1660229,
      damage_e2: 1660229,
      damage_e3: 1836344,
      damage_e4: 1947486,
      damage_e5: 1973970,
      damage_e6: 2391046,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 34,
    name: 'Qingque',
    slug: 'qingque',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'SPD',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (23.3%), CRIT DMG (88.5%), Speed (2.3)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'the-day-the-cosmos-fell',
    cone_s: '5',
    disclaimer:
      'Assumes 2 Skill Points used to achieve Full Hand. If you manage to use more, the damage will increase.',
    single_dmg: {
      damage: 277909,
      damage_e1: 279059,
      damage_e2: 288913,
      damage_e3: 298844,
      damage_e4: 398174,
      damage_e5: 444068,
      damage_e6: 444068,
      skillPoints: 23,
      skillPoints_e1: 23,
      skillPoints_e2: 23,
      skillPoints_e3: 23,
      skillPoints_e4: 23,
      skillPoints_e5: 23,
      skillPoints_e6: 7
    },
    aoe_dmg: {
      damage: 541594,
      damage_e1: 545044,
      damage_e2: 574605,
      damage_e3: 597545,
      damage_e4: 779650,
      damage_e5: 865035,
      damage_e6: 865035,
      skillPoints: 23,
      skillPoints_e1: 23,
      skillPoints_e2: 23,
      skillPoints_e3: 23,
      skillPoints_e4: 23,
      skillPoints_e5: 23,
      skillPoints_e6: 7
    }
  },
  {
    id: 35,
    name: 'Welt',
    slug: 'welt',
    element: 'imaginary',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Imaginary DMG',
    rope: 'ATK',
    substats: 'CRIT Rate (11.7%), CRIT DMG (35%), EHR (38.88%), SPD (9.2)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'its-showtime',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 275290,
      damage_e1: 294250,
      damage_e2: 318321,
      damage_e3: 334111,
      damage_e4: 334111,
      damage_e5: 349110,
      damage_e6: 492773,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 355147,
      damage_e1: 374108,
      damage_e2: 424798,
      damage_e3: 440588,
      damage_e4: 440588,
      damage_e5: 465052,
      damage_e6: 666686,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 36,
    name: 'March 7th - The Hunt',
    slug: 'march-7th-swordmaster',
    element: 'imaginary',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Imaginary DMG',
    rope: 'ATK',
    substats: 'CRIT Rate (29.2%), CRIT DMG (81.6%)',
    relic_set_1: 'Musketeer of Wild Wheat',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 474612,
      damage_e1: 474612,
      damage_e2: 474612,
      damage_e3: 474612,
      damage_e4: 486149,
      damage_e5: 499957,
      damage_e6: 546789,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 355147,
      damage_e1: 374108,
      damage_e2: 424798,
      damage_e3: 440588,
      damage_e4: 440588,
      damage_e5: 465052,
      damage_e6: 666686,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 37,
    name: 'Moze',
    slug: 'moze',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'CRIT Rate (29.2%), CRIT DMG (81.6%)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Duran, Dynasty of Running Wolves',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 399132,
      damage_e1: 427603,
      damage_e2: 482752,
      damage_e3: 524789,
      damage_e4: 618689,
      damage_e5: 624196,
      damage_e6: 672774,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 724871,
      damage_e1: 779280,
      damage_e2: 862924,
      damage_e3: 927688,
      damage_e4: 988228,
      damage_e5: 1061518,
      damage_e6: 1290386,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    }
  },
  {
    id: 38,
    name: 'Gallagher',
    slug: 'gallagher',
    element: 'fire',
    body: 'Outgoing Healing',
    feet: 'Speed',
    sphere: 'HP%',
    rope: 'Energy Regen Rate',
    substats: 'Speed (32), Break Effect (58%)',
    relic_set_1: 'Iron Cavalry Against the Scourge',
    relic_set_2: '',
    relic_set_3: 'Forge of the Kalpagni Lantern',
    cone: 'quid-pro-quo',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 353010,
      damage_e1: 353010,
      damage_e2: 353010,
      damage_e3: 354540,
      damage_e4: 367150,
      damage_e5: 371275,
      damage_e6: 466419,
      skillPoints: -18,
      skillPoints_e1: -18,
      skillPoints_e2: -18,
      skillPoints_e3: -18,
      skillPoints_e4: -18,
      skillPoints_e5: -18,
      skillPoints_e6: -18
    },
    aoe_dmg: {
      damage: 465585,
      damage_e1: 465585,
      damage_e2: 465585,
      damage_e3: 467114,
      damage_e4: 483665,
      damage_e5: 489784,
      damage_e6: 614660,
      skillPoints: -18,
      skillPoints_e1: -18,
      skillPoints_e2: -18,
      skillPoints_e3: -18,
      skillPoints_e4: -18,
      skillPoints_e5: -18,
      skillPoints_e6: -18
    }
  },
  {
    id: 39,
    name: 'Lingsha',
    slug: 'lingsha',
    element: 'fire',
    body: 'Outgoing Healing',
    feet: 'Speed',
    sphere: 'HP%',
    rope: 'Energy Regen Rate',
    substats: 'Speed (32), Break Effect (58%)',
    relic_set_1: 'Iron Cavalry Against the Scourge',
    relic_set_2: '',
    relic_set_3: 'Forge of the Kalpagni Lantern',
    cone: 'quid-pro-quo',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 597483,
      damage_e1: 961159,
      damage_e2: 1061533,
      damage_e3: 1075321,
      damage_e4: 1075321,
      damage_e5: 1076936,
      damage_e6: 2050642,
      skillPoints: -5,
      skillPoints_e1: -5,
      skillPoints_e2: -5,
      skillPoints_e3: -5,
      skillPoints_e4: -5,
      skillPoints_e5: -5,
      skillPoints_e6: -5
    },
    aoe_dmg: {
      damage: 1088175,
      damage_e1: 1744434,
      damage_e2: 1925255,
      damage_e3: 1951554,
      damage_e4: 1951554,
      damage_e5: 1954017,
      damage_e6: 3014306,
      skillPoints: -5,
      skillPoints_e1: -5,
      skillPoints_e2: -5,
      skillPoints_e3: -5,
      skillPoints_e4: -5,
      skillPoints_e5: -5,
      skillPoints_e6: -5
    }
  }
];
