export const PFDuos = [
  {
    char: 'Lingsha',
    round: 36985,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 85.85,
    avg_round_1: 36943,
    app_flat_1: 289,
    char_2: 'ruan-mei',
    app_rate_2: 85.53,
    avg_round_2: 37110,
    app_flat_2: 283,
    char_3: 'Firefly',
    app_rate_3: 72.33,
    avg_round_3: 36446,
    app_flat_3: 202,
    char_4: 'Himeko',
    app_rate_4: 23.23,
    avg_round_4: 35611,
    app_flat_4: 118,
    char_5: 'Robin',
    app_rate_5: 8.76,
    avg_round_5: 37662,
    app_flat_5: 36,
    char_6: 'Herta',
    app_rate_6: 6.76,
    avg_round_6: 34770,
    app_flat_6: 31,
    char_7: 'Jade',
    app_rate_7: 2.85,
    avg_round_7: 38202,
    app_flat_7: 10,
    char_8: 'Feixiao',
    app_rate_8: 2.11,
    avg_round_8: 35918,
    app_flat_8: 10,
    char_9: 'Sparkle',
    app_rate_9: 1.58,
    avg_round_9: 25262,
    app_flat_9: 1,
    char_10: 'Jiaoqiu',
    app_rate_10: 1.37,
    avg_round_10: 40000,
    app_flat_10: 1,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 1.37,
    avg_round_11: 33938,
    app_flat_11: 7,
    char_12: 'Acheron',
    app_rate_12: 1.27,
    avg_round_12: 34328,
    app_flat_12: 4,
    char_13: 'topaz',
    app_rate_13: 0.95,
    avg_round_13: 40000,
    app_flat_13: 1,
    char_14: 'Kafka',
    app_rate_14: 0.84,
    avg_round_14: 28655,
    app_flat_14: 2,
    char_15: 'Yunli',
    app_rate_15: 0.84,
    avg_round_15: 36260,
    app_flat_15: 4,
    char_16: 'Tingyun',
    app_rate_16: 0.74,
    avg_round_16: 37490,
    app_flat_16: 3,
    char_17: 'Asta',
    app_rate_17: 0.63,
    avg_round_17: 24773,
    app_flat_17: 1,
    char_18: 'Bronya',
    app_rate_18: 0.63,
    avg_round_18: 40000,
    app_flat_18: 1,
    char_19: 'black-swan',
    app_rate_19: 0.42,
    avg_round_19: 28395,
    app_flat_19: 1,
    char_20: 'Pela',
    app_rate_20: 0.32,
    avg_round_20: 33975,
    app_flat_20: 3,
    char_21: 'Gallagher',
    app_rate_21: 0.32,
    avg_round_21: 29003,
    app_flat_21: 3,
    char_22: 'Argenti',
    app_rate_22: 0.21,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jing-yuan',
    app_rate_23: 0.21,
    avg_round_23: 30971,
    app_flat_23: 2,
    char_24: 'Xueyi',
    app_rate_24: 0.21,
    avg_round_24: 26155,
    app_flat_24: 1,
    char_25: 'Clara',
    app_rate_25: 0.11,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Jingliu',
    app_rate_26: 0.11,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jiaoqiu',
    round: 34473,
    char_1: 'Acheron',
    app_rate_1: 91.15,
    avg_round_1: 34554,
    app_flat_1: 1075,
    char_2: 'fu-xuan',
    app_rate_2: 39.23,
    avg_round_2: 34572,
    app_flat_2: 462,
    char_3: 'Sparkle',
    app_rate_3: 28.38,
    avg_round_3: 33621,
    app_flat_3: 104,
    char_4: 'Aventurine',
    app_rate_4: 27.54,
    avg_round_4: 34872,
    app_flat_4: 309,
    char_5: 'Pela',
    app_rate_5: 26.89,
    avg_round_5: 34390,
    app_flat_5: 531,
    char_6: 'Kafka',
    app_rate_6: 15.3,
    avg_round_6: 35699,
    app_flat_6: 191,
    char_7: 'Gallagher',
    app_rate_7: 12.71,
    avg_round_7: 33999,
    app_flat_7: 191,
    char_8: 'Robin',
    app_rate_8: 8.42,
    avg_round_8: 35771,
    app_flat_8: 84,
    char_9: 'black-swan',
    app_rate_9: 7.58,
    avg_round_9: 33669,
    app_flat_9: 82,
    char_10: 'Huohuo',
    app_rate_10: 6.63,
    avg_round_10: 32896,
    app_flat_10: 60,
    char_11: 'ruan-mei',
    app_rate_11: 6.59,
    avg_round_11: 36984,
    app_flat_11: 67,
    char_12: 'Luocha',
    app_rate_12: 5.17,
    avg_round_12: 33492,
    app_flat_12: 51,
    char_13: 'Himeko',
    app_rate_13: 4.74,
    avg_round_13: 34542,
    app_flat_13: 65,
    char_14: 'Bronya',
    app_rate_14: 2.77,
    avg_round_14: 38445,
    app_flat_14: 2,
    char_15: 'silver-wolf',
    app_rate_15: 2.59,
    avg_round_15: 32236,
    app_flat_15: 35,
    char_16: 'Gepard',
    app_rate_16: 2.11,
    avg_round_16: 32627,
    app_flat_16: 25,
    char_17: 'Jade',
    app_rate_17: 2.04,
    avg_round_17: 34230,
    app_flat_17: 26,
    char_18: 'Herta',
    app_rate_18: 1.82,
    avg_round_18: 32111,
    app_flat_18: 23,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 1.46,
    avg_round_19: 34221,
    app_flat_19: 21,
    char_20: 'Argenti',
    app_rate_20: 1.17,
    avg_round_20: 31998,
    app_flat_20: 11,
    char_21: 'Tingyun',
    app_rate_21: 0.66,
    avg_round_21: 33659,
    app_flat_21: 9,
    char_22: 'Yunli',
    app_rate_22: 0.51,
    avg_round_22: 32431,
    app_flat_22: 5,
    char_23: 'Lingsha',
    app_rate_23: 0.47,
    avg_round_23: 40000,
    app_flat_23: 1,
    char_24: 'Feixiao',
    app_rate_24: 0.47,
    avg_round_24: 34159,
    app_flat_24: 4,
    char_25: 'Bailu',
    app_rate_25: 0.47,
    avg_round_25: 33819,
    app_flat_25: 5,
    char_26: 'Guinaifen',
    app_rate_26: 0.47,
    avg_round_26: 29213,
    app_flat_26: 7,
    char_27: 'Firefly',
    app_rate_27: 0.44,
    avg_round_27: 28690,
    app_flat_27: 3,
    char_28: 'jing-yuan',
    app_rate_28: 0.29,
    avg_round_28: 37170,
    app_flat_28: 2,
    char_29: 'Lynx',
    app_rate_29: 0.29,
    avg_round_29: 37422,
    app_flat_29: 2,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.26,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    round: 33671,
    char_1: 'Robin',
    app_rate_1: 65.19,
    avg_round_1: 36766,
    app_flat_1: 745,
    char_2: 'Herta',
    app_rate_2: 54.22,
    avg_round_2: 37005,
    app_flat_2: 709,
    char_3: 'fu-xuan',
    app_rate_3: 34.43,
    avg_round_3: 35452,
    app_flat_3: 355,
    char_4: 'Aventurine',
    app_rate_4: 31.14,
    avg_round_4: 37298,
    app_flat_4: 335,
    char_5: 'Huohuo',
    app_rate_5: 15.93,
    avg_round_5: 36022,
    app_flat_5: 165,
    char_6: 'ruan-mei',
    app_rate_6: 13.45,
    avg_round_6: 35604,
    app_flat_6: 102,
    char_7: 'Sparkle',
    app_rate_7: 10.21,
    avg_round_7: 33667,
    app_flat_7: 85,
    char_8: 'Feixiao',
    app_rate_8: 8.39,
    avg_round_8: 36807,
    app_flat_8: 44,
    char_9: 'jing-yuan',
    app_rate_9: 8.25,
    avg_round_9: 32893,
    app_flat_9: 76,
    char_10: 'Himeko',
    app_rate_10: 7.92,
    avg_round_10: 32862,
    app_flat_10: 80,
    char_11: 'Luocha',
    app_rate_11: 6.53,
    avg_round_11: 34148,
    app_flat_11: 68,
    char_12: 'Acheron',
    app_rate_12: 6.49,
    avg_round_12: 33114,
    app_flat_12: 44,
    char_13: 'Kafka',
    app_rate_13: 5.29,
    avg_round_13: 32007,
    app_flat_13: 30,
    char_14: 'Yunli',
    app_rate_14: 4.05,
    avg_round_14: 35051,
    app_flat_14: 42,
    char_15: 'Gallagher',
    app_rate_15: 3.48,
    avg_round_15: 34210,
    app_flat_15: 35,
    char_16: 'Blade',
    app_rate_16: 3.24,
    avg_round_16: 31463,
    app_flat_16: 28,
    char_17: 'Jiaoqiu',
    app_rate_17: 2.67,
    avg_round_17: 34230,
    app_flat_17: 26,
    char_18: 'Argenti',
    app_rate_18: 2.38,
    avg_round_18: 29762,
    app_flat_18: 22,
    char_19: 'Bronya',
    app_rate_19: 2.19,
    avg_round_19: 33166,
    app_flat_19: 21,
    char_20: 'Tingyun',
    app_rate_20: 2.0,
    avg_round_20: 32668,
    app_flat_20: 20,
    char_21: 'topaz',
    app_rate_21: 1.96,
    avg_round_21: 28228,
    app_flat_21: 3,
    char_22: 'Lingsha',
    app_rate_22: 1.29,
    avg_round_22: 38202,
    app_flat_22: 10,
    char_23: 'Lynx',
    app_rate_23: 1.24,
    avg_round_23: 32489,
    app_flat_23: 16,
    char_24: 'Clara',
    app_rate_24: 1.24,
    avg_round_24: 28959,
    app_flat_24: 12,
    char_25: 'Bailu',
    app_rate_25: 0.95,
    avg_round_25: 35141,
    app_flat_25: 13,
    char_26: 'Xueyi',
    app_rate_26: 0.86,
    avg_round_26: 30336,
    app_flat_26: 5,
    char_27: 'Seele',
    app_rate_27: 0.62,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Gepard',
    app_rate_28: 0.62,
    avg_round_28: 28348,
    app_flat_28: 4,
    char_29: 'Serval',
    app_rate_29: 0.48,
    avg_round_29: 26612,
    app_flat_29: 3,
    char_30: 'march-7th-swordmaster',
    app_rate_30: 0.48,
    avg_round_30: 29100,
    app_flat_30: 4
  },
  {
    char: 'Himeko',
    round: 32809,
    char_1: 'ruan-mei',
    app_rate_1: 62.58,
    avg_round_1: 33226,
    app_flat_1: 1384,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 52.44,
    avg_round_2: 33074,
    app_flat_2: 1245,
    char_3: 'Gallagher',
    app_rate_3: 35.25,
    avg_round_3: 31207,
    app_flat_3: 972,
    char_4: 'Firefly',
    app_rate_4: 31.22,
    avg_round_4: 34011,
    app_flat_4: 533,
    char_5: 'Robin',
    app_rate_5: 27.53,
    avg_round_5: 33062,
    app_flat_5: 624,
    char_6: 'Herta',
    app_rate_6: 23.91,
    avg_round_6: 31807,
    app_flat_6: 536,
    char_7: 'Aventurine',
    app_rate_7: 19.3,
    avg_round_7: 33236,
    app_flat_7: 433,
    char_8: 'Lingsha',
    app_rate_8: 5.37,
    avg_round_8: 35611,
    app_flat_8: 118,
    char_9: 'topaz',
    app_rate_9: 4.69,
    avg_round_9: 31683,
    app_flat_9: 71,
    char_10: 'fu-xuan',
    app_rate_10: 4.13,
    avg_round_10: 29626,
    app_flat_10: 57,
    char_11: 'Jade',
    app_rate_11: 4.05,
    avg_round_11: 32862,
    app_flat_11: 80,
    char_12: 'Luocha',
    app_rate_12: 3.83,
    avg_round_12: 29990,
    app_flat_12: 73,
    char_13: 'Huohuo',
    app_rate_13: 3.79,
    avg_round_13: 30840,
    app_flat_13: 74,
    char_14: 'Jiaoqiu',
    app_rate_14: 3.18,
    avg_round_14: 34542,
    app_flat_14: 65,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 3.0,
    avg_round_15: 31443,
    app_flat_15: 76,
    char_16: 'Sparkle',
    app_rate_16: 2.86,
    avg_round_16: 30278,
    app_flat_16: 38,
    char_17: 'Feixiao',
    app_rate_17: 2.76,
    avg_round_17: 32709,
    app_flat_17: 62,
    char_18: 'Yunli',
    app_rate_18: 2.27,
    avg_round_18: 33243,
    app_flat_18: 56,
    char_19: 'Clara',
    app_rate_19: 1.42,
    avg_round_19: 29024,
    app_flat_19: 35,
    char_20: 'Acheron',
    app_rate_20: 1.37,
    avg_round_20: 33989,
    app_flat_20: 31,
    char_21: 'Asta',
    app_rate_21: 0.95,
    avg_round_21: 29175,
    app_flat_21: 20,
    char_22: 'Tingyun',
    app_rate_22: 0.9,
    avg_round_22: 29147,
    app_flat_22: 20,
    char_23: 'Argenti',
    app_rate_23: 0.68,
    avg_round_23: 28743,
    app_flat_23: 11,
    char_24: 'Bronya',
    app_rate_24: 0.54,
    avg_round_24: 28856,
    app_flat_24: 5,
    char_25: 'imbibitor-lunae',
    app_rate_25: 0.29,
    avg_round_25: 20544,
    app_flat_25: 1,
    char_26: 'Pela',
    app_rate_26: 0.29,
    avg_round_26: 34379,
    app_flat_26: 6,
    char_27: 'Kafka',
    app_rate_27: 0.22,
    avg_round_27: 29723,
    app_flat_27: 6,
    char_28: 'Welt',
    app_rate_28: 0.15,
    avg_round_28: 26074,
    app_flat_28: 3,
    char_29: 'Bailu',
    app_rate_29: 0.15,
    avg_round_29: 29875,
    app_flat_29: 6,
    char_30: 'black-swan',
    app_rate_30: 0.12,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Acheron',
    round: 32395,
    char_1: 'Jiaoqiu',
    app_rate_1: 47.8,
    avg_round_1: 34554,
    app_flat_1: 1075,
    char_2: 'fu-xuan',
    app_rate_2: 44.13,
    avg_round_2: 32263,
    app_flat_2: 900,
    char_3: 'Kafka',
    app_rate_3: 40.37,
    avg_round_3: 31670,
    app_flat_3: 922,
    char_4: 'black-swan',
    app_rate_4: 30.47,
    avg_round_4: 31032,
    app_flat_4: 677,
    char_5: 'Pela',
    app_rate_5: 28.28,
    avg_round_5: 32368,
    app_flat_5: 797,
    char_6: 'Sparkle',
    app_rate_6: 24.93,
    avg_round_6: 31123,
    app_flat_6: 188,
    char_7: 'Aventurine',
    app_rate_7: 21.28,
    avg_round_7: 33182,
    app_flat_7: 458,
    char_8: 'Gallagher',
    app_rate_8: 9.84,
    avg_round_8: 32515,
    app_flat_8: 272,
    char_9: 'Huohuo',
    app_rate_9: 8.62,
    avg_round_9: 30467,
    app_flat_9: 139,
    char_10: 'Robin',
    app_rate_10: 8.14,
    avg_round_10: 32877,
    app_flat_10: 157,
    char_11: 'ruan-mei',
    app_rate_11: 6.9,
    avg_round_11: 34842,
    app_flat_11: 129,
    char_12: 'silver-wolf',
    app_rate_12: 5.46,
    avg_round_12: 28481,
    app_flat_12: 107,
    char_13: 'Gepard',
    app_rate_13: 4.66,
    avg_round_13: 31422,
    app_flat_13: 99,
    char_14: 'Luocha',
    app_rate_14: 4.41,
    avg_round_14: 31725,
    app_flat_14: 82,
    char_15: 'Bronya',
    app_rate_15: 3.1,
    avg_round_15: 24384,
    app_flat_15: 12,
    char_16: 'Jade',
    app_rate_16: 2.6,
    avg_round_16: 33114,
    app_flat_16: 44,
    char_17: 'Herta',
    app_rate_17: 1.55,
    avg_round_17: 31799,
    app_flat_17: 41,
    char_18: 'Himeko',
    app_rate_18: 1.07,
    avg_round_18: 33989,
    app_flat_18: 31,
    char_19: 'Guinaifen',
    app_rate_19: 0.94,
    avg_round_19: 25937,
    app_flat_19: 28,
    char_20: 'Yunli',
    app_rate_20: 0.73,
    avg_round_20: 32587,
    app_flat_20: 18,
    char_21: 'trailblazer-fire',
    app_rate_21: 0.48,
    avg_round_21: 29764,
    app_flat_21: 11,
    char_22: 'Welt',
    app_rate_22: 0.44,
    avg_round_22: 28874,
    app_flat_22: 9,
    char_23: 'Bailu',
    app_rate_23: 0.44,
    avg_round_23: 30811,
    app_flat_23: 9,
    char_24: 'Feixiao',
    app_rate_24: 0.4,
    avg_round_24: 30262,
    app_flat_24: 9,
    char_25: 'trailblazer-imaginary',
    app_rate_25: 0.4,
    avg_round_25: 34321,
    app_flat_25: 6,
    char_26: 'Serval',
    app_rate_26: 0.32,
    avg_round_26: 26877,
    app_flat_26: 11,
    char_27: 'Lynx',
    app_rate_27: 0.29,
    avg_round_27: 31827,
    app_flat_27: 5,
    char_28: 'Firefly',
    app_rate_28: 0.29,
    avg_round_28: 35460,
    app_flat_28: 1,
    char_29: 'Lingsha',
    app_rate_29: 0.23,
    avg_round_29: 34328,
    app_flat_29: 4,
    char_30: 'jing-yuan',
    app_rate_30: 0.21,
    avg_round_30: 31137,
    app_flat_30: 6
  },
  {
    char: 'Pela',
    round: 32378,
    char_1: 'Acheron',
    app_rate_1: 98.6,
    avg_round_1: 32368,
    app_flat_1: 797,
    char_2: 'Jiaoqiu',
    app_rate_2: 49.17,
    avg_round_2: 34390,
    app_flat_2: 531,
    char_3: 'fu-xuan',
    app_rate_3: 47.57,
    avg_round_3: 32451,
    app_flat_3: 363,
    char_4: 'Aventurine',
    app_rate_4: 20.79,
    avg_round_4: 33021,
    app_flat_4: 192,
    char_5: 'Sparkle',
    app_rate_5: 18.45,
    avg_round_5: 28499,
    app_flat_5: 49,
    char_6: 'Gallagher',
    app_rate_6: 11.19,
    avg_round_6: 32148,
    app_flat_6: 105,
    char_7: 'Kafka',
    app_rate_7: 9.06,
    avg_round_7: 30019,
    app_flat_7: 78,
    char_8: 'silver-wolf',
    app_rate_8: 6.53,
    avg_round_8: 26052,
    app_flat_8: 50,
    char_9: 'Gepard',
    app_rate_9: 6.2,
    avg_round_9: 30372,
    app_flat_9: 38,
    char_10: 'Robin',
    app_rate_10: 4.4,
    avg_round_10: 29474,
    app_flat_10: 22,
    char_11: 'Bronya',
    app_rate_11: 4.2,
    avg_round_11: 23177,
    app_flat_11: 9,
    char_12: 'black-swan',
    app_rate_12: 4.0,
    avg_round_12: 28058,
    app_flat_12: 29,
    char_13: 'ruan-mei',
    app_rate_13: 3.73,
    avg_round_13: 34522,
    app_flat_13: 26,
    char_14: 'Luocha',
    app_rate_14: 3.33,
    avg_round_14: 31295,
    app_flat_14: 24,
    char_15: 'Huohuo',
    app_rate_15: 3.33,
    avg_round_15: 31360,
    app_flat_15: 24,
    char_16: 'trailblazer-fire',
    app_rate_16: 1.33,
    avg_round_16: 29764,
    app_flat_16: 11,
    char_17: 'Guinaifen',
    app_rate_17: 1.2,
    avg_round_17: 24417,
    app_flat_17: 12,
    char_18: 'Herta',
    app_rate_18: 0.87,
    avg_round_18: 29574,
    app_flat_18: 6,
    char_19: 'Himeko',
    app_rate_19: 0.8,
    avg_round_19: 34379,
    app_flat_19: 6,
    char_20: 'Welt',
    app_rate_20: 0.67,
    avg_round_20: 28829,
    app_flat_20: 6,
    char_21: 'Jade',
    app_rate_21: 0.6,
    avg_round_21: 32488,
    app_flat_21: 4,
    char_22: 'Serval',
    app_rate_22: 0.4,
    avg_round_22: 25434,
    app_flat_22: 5,
    char_23: 'Bailu',
    app_rate_23: 0.33,
    avg_round_23: 33778,
    app_flat_23: 3,
    char_24: 'Lynx',
    app_rate_24: 0.33,
    avg_round_24: 28504,
    app_flat_24: 2,
    char_25: 'trailblazer-imaginary',
    app_rate_25: 0.27,
    avg_round_25: 23044,
    app_flat_25: 4,
    char_26: 'Tingyun',
    app_rate_26: 0.27,
    avg_round_26: 24675,
    app_flat_26: 3,
    char_27: 'Yunli',
    app_rate_27: 0.27,
    avg_round_27: 29862,
    app_flat_27: 3,
    char_28: 'jing-yuan',
    app_rate_28: 0.27,
    avg_round_28: 24276,
    app_flat_28: 2,
    char_29: 'Sampo',
    app_rate_29: 0.27,
    avg_round_29: 25707,
    app_flat_29: 2,
    char_30: 'Lingsha',
    app_rate_30: 0.2,
    avg_round_30: 33975,
    app_flat_30: 3
  },
  {
    char: 'Aventurine',
    round: 32352,
    char_1: 'Robin',
    app_rate_1: 54.31,
    avg_round_1: 34230,
    app_flat_1: 950,
    char_2: 'Acheron',
    app_rate_2: 31.58,
    avg_round_2: 33182,
    app_flat_2: 458,
    char_3: 'Herta',
    app_rate_3: 29.45,
    avg_round_3: 35818,
    app_flat_3: 625,
    char_4: 'Himeko',
    app_rate_4: 22.39,
    avg_round_4: 33236,
    app_flat_4: 433,
    char_5: 'Feixiao',
    app_rate_5: 21.46,
    avg_round_5: 33286,
    app_flat_5: 344,
    char_6: 'Jiaoqiu',
    app_rate_6: 21.43,
    avg_round_6: 34872,
    app_flat_6: 309,
    char_7: 'Jade',
    app_rate_7: 18.51,
    avg_round_7: 37298,
    app_flat_7: 335,
    char_8: 'Sparkle',
    app_rate_8: 14.74,
    avg_round_8: 30182,
    app_flat_8: 119,
    char_9: 'topaz',
    app_rate_9: 12.41,
    avg_round_9: 30686,
    app_flat_9: 129,
    char_10: 'ruan-mei',
    app_rate_10: 11.37,
    avg_round_10: 32225,
    app_flat_10: 182,
    char_11: 'Kafka',
    app_rate_11: 10.46,
    avg_round_11: 32221,
    app_flat_11: 181,
    char_12: 'Pela',
    app_rate_12: 8.84,
    avg_round_12: 33021,
    app_flat_12: 192,
    char_13: 'black-swan',
    app_rate_13: 7.99,
    avg_round_13: 31415,
    app_flat_13: 132,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 5.53,
    avg_round_14: 30969,
    app_flat_14: 110,
    char_15: 'jing-yuan',
    app_rate_15: 4.65,
    avg_round_15: 30378,
    app_flat_15: 80,
    char_16: 'Yunli',
    app_rate_16: 4.45,
    avg_round_16: 32370,
    app_flat_16: 72,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 3.83,
    avg_round_17: 30834,
    app_flat_17: 72,
    char_18: 'Tingyun',
    app_rate_18: 3.32,
    avg_round_18: 28072,
    app_flat_18: 62,
    char_19: 'Clara',
    app_rate_19: 3.26,
    avg_round_19: 29886,
    app_flat_19: 61,
    char_20: 'Firefly',
    app_rate_20: 2.01,
    avg_round_20: 28014,
    app_flat_20: 28,
    char_21: 'silver-wolf',
    app_rate_21: 1.67,
    avg_round_21: 27432,
    app_flat_21: 26,
    char_22: 'Argenti',
    app_rate_22: 1.59,
    avg_round_22: 28786,
    app_flat_22: 26,
    char_23: 'Bronya',
    app_rate_23: 1.3,
    avg_round_23: 27938,
    app_flat_23: 13,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.68,
    avg_round_24: 20860,
    app_flat_24: 3,
    char_25: 'dr-ratio',
    app_rate_25: 0.54,
    avg_round_25: 29263,
    app_flat_25: 1,
    char_26: 'Xueyi',
    app_rate_26: 0.48,
    avg_round_26: 29700,
    app_flat_26: 8,
    char_27: 'Moze',
    app_rate_27: 0.4,
    avg_round_27: 25857,
    app_flat_27: 4,
    char_28: 'Asta',
    app_rate_28: 0.34,
    avg_round_28: 26748,
    app_flat_28: 6,
    char_29: 'Serval',
    app_rate_29: 0.26,
    avg_round_29: 28008,
    app_flat_29: 6,
    char_30: 'Seele',
    app_rate_30: 0.26,
    avg_round_30: 30158,
    app_flat_30: 3
  },
  {
    char: 'ruan-mei',
    round: 32311,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 76.97,
    avg_round_1: 32382,
    app_flat_1: 2637,
    char_2: 'Firefly',
    app_rate_2: 66.19,
    avg_round_2: 32307,
    app_flat_2: 1938,
    char_3: 'Gallagher',
    app_rate_3: 53.86,
    avg_round_3: 31045,
    app_flat_3: 1962,
    char_4: 'Himeko',
    app_rate_4: 32.09,
    avg_round_4: 33226,
    app_flat_4: 1384,
    char_5: 'Lingsha',
    app_rate_5: 10.14,
    avg_round_5: 37110,
    app_flat_5: 283,
    char_6: 'Herta',
    app_rate_6: 7.03,
    avg_round_6: 32453,
    app_flat_6: 244,
    char_7: 'Kafka',
    app_rate_7: 6.02,
    avg_round_7: 31827,
    app_flat_7: 143,
    char_8: 'Aventurine',
    app_rate_8: 5.02,
    avg_round_8: 32225,
    app_flat_8: 182,
    char_9: 'Huohuo',
    app_rate_9: 4.82,
    avg_round_9: 29763,
    app_flat_9: 91,
    char_10: 'Acheron',
    app_rate_10: 4.52,
    avg_round_10: 34842,
    app_flat_10: 129,
    char_11: 'black-swan',
    app_rate_11: 4.28,
    avg_round_11: 31418,
    app_flat_11: 84,
    char_12: 'fu-xuan',
    app_rate_12: 4.21,
    avg_round_12: 34347,
    app_flat_12: 92,
    char_13: 'Jade',
    app_rate_13: 3.53,
    avg_round_13: 35604,
    app_flat_13: 102,
    char_14: 'Luocha',
    app_rate_14: 2.87,
    avg_round_14: 29566,
    app_flat_14: 80,
    char_15: 'Jiaoqiu',
    app_rate_15: 2.27,
    avg_round_15: 36984,
    app_flat_15: 67,
    char_16: 'Sparkle',
    app_rate_16: 1.87,
    avg_round_16: 30684,
    app_flat_16: 35,
    char_17: 'Robin',
    app_rate_17: 1.83,
    avg_round_17: 33986,
    app_flat_17: 52,
    char_18: 'Tingyun',
    app_rate_18: 1.58,
    avg_round_18: 30885,
    app_flat_18: 53,
    char_19: 'Argenti',
    app_rate_19: 1.54,
    avg_round_19: 31000,
    app_flat_19: 56,
    char_20: 'Bronya',
    app_rate_20: 1.39,
    avg_round_20: 28062,
    app_flat_20: 14,
    char_21: 'march-7th-swordmaster',
    app_rate_21: 0.96,
    avg_round_21: 31454,
    app_flat_21: 41,
    char_22: 'Yunli',
    app_rate_22: 0.95,
    avg_round_22: 31772,
    app_flat_22: 33,
    char_23: 'jing-yuan',
    app_rate_23: 0.91,
    avg_round_23: 30997,
    app_flat_23: 21,
    char_24: 'Pela',
    app_rate_24: 0.7,
    avg_round_24: 34522,
    app_flat_24: 26,
    char_25: 'topaz',
    app_rate_25: 0.66,
    avg_round_25: 28178,
    app_flat_25: 18,
    char_26: 'Feixiao',
    app_rate_26: 0.64,
    avg_round_26: 33413,
    app_flat_26: 22,
    char_27: 'imbibitor-lunae',
    app_rate_27: 0.5,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Serval',
    app_rate_28: 0.3,
    avg_round_28: 25304,
    app_flat_28: 14,
    char_29: 'Bailu',
    app_rate_29: 0.28,
    avg_round_29: 29184,
    app_flat_29: 8,
    char_30: 'Clara',
    app_rate_30: 0.24,
    avg_round_30: 27144,
    app_flat_30: 7
  },
  {
    char: 'Firefly',
    round: 32216,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 96.98,
    avg_round_1: 32145,
    app_flat_1: 2032,
    char_2: 'ruan-mei',
    app_rate_2: 94.29,
    avg_round_2: 32307,
    app_flat_2: 1938,
    char_3: 'Gallagher',
    app_rate_3: 63.46,
    avg_round_3: 30653,
    app_flat_3: 1402,
    char_4: 'Himeko',
    app_rate_4: 22.8,
    avg_round_4: 34011,
    app_flat_4: 533,
    char_5: 'Lingsha',
    app_rate_5: 12.22,
    avg_round_5: 36446,
    app_flat_5: 202,
    char_6: 'Huohuo',
    app_rate_6: 1.64,
    avg_round_6: 26549,
    app_flat_6: 14,
    char_7: 'Bronya',
    app_rate_7: 1.62,
    avg_round_7: 23395,
    app_flat_7: 9,
    char_8: 'Luocha',
    app_rate_8: 1.46,
    avg_round_8: 27912,
    app_flat_8: 24,
    char_9: 'Aventurine',
    app_rate_9: 1.27,
    avg_round_9: 28014,
    app_flat_9: 28,
    char_10: 'fu-xuan',
    app_rate_10: 0.73,
    avg_round_10: 24180,
    app_flat_10: 3,
    char_11: 'Herta',
    app_rate_11: 0.7,
    avg_round_11: 29332,
    app_flat_11: 13,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 0.46,
    avg_round_12: 24148,
    app_flat_12: 10,
    char_13: 'Asta',
    app_rate_13: 0.36,
    avg_round_13: 25110,
    app_flat_13: 7,
    char_14: 'Robin',
    app_rate_14: 0.32,
    avg_round_14: 23485,
    app_flat_14: 1,
    char_15: 'Sparkle',
    app_rate_15: 0.29,
    avg_round_15: 27922,
    app_flat_15: 2,
    char_16: 'Acheron',
    app_rate_16: 0.27,
    avg_round_16: 35460,
    app_flat_16: 1,
    char_17: 'Jiaoqiu',
    app_rate_17: 0.21,
    avg_round_17: 28690,
    app_flat_17: 3,
    char_18: 'Jade',
    app_rate_18: 0.14,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Bailu',
    app_rate_19: 0.12,
    avg_round_19: 26745,
    app_flat_19: 1,
    char_20: 'Kafka',
    app_rate_20: 0.11,
    avg_round_20: 27100,
    app_flat_20: 2,
    char_21: 'Yunli',
    app_rate_21: 0.09,
    avg_round_21: 31500,
    app_flat_21: 1,
    char_22: 'Pela',
    app_rate_22: 0.05,
    avg_round_22: 22410,
    app_flat_22: 3,
    char_23: 'topaz',
    app_rate_23: 0.05,
    avg_round_23: 22168,
    app_flat_23: 1,
    char_24: 'Lynx',
    app_rate_24: 0.05,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Xueyi',
    app_rate_25: 0.04,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'imbibitor-lunae',
    app_rate_26: 0.02,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Guinaifen',
    app_rate_27: 0.02,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Tingyun',
    app_rate_28: 0.02,
    avg_round_28: 21141,
    app_flat_28: 1,
    char_29: 'black-swan',
    app_rate_29: 0.02,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Boothill',
    app_rate_30: 0.02,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-imaginary',
    round: 32188,
    char_1: 'ruan-mei',
    app_rate_1: 94.22,
    avg_round_1: 32382,
    app_flat_1: 2637,
    char_2: 'Firefly',
    app_rate_2: 83.34,
    avg_round_2: 32145,
    app_flat_2: 2032,
    char_3: 'Gallagher',
    app_rate_3: 65.02,
    avg_round_3: 30848,
    app_flat_3: 1969,
    char_4: 'Himeko',
    app_rate_4: 32.91,
    avg_round_4: 33074,
    app_flat_4: 1245,
    char_5: 'Lingsha',
    app_rate_5: 12.46,
    avg_round_5: 36943,
    app_flat_5: 289,
    char_6: 'Aventurine',
    app_rate_6: 2.07,
    avg_round_6: 30834,
    app_flat_6: 72,
    char_7: 'Luocha',
    app_rate_7: 1.55,
    avg_round_7: 28569,
    app_flat_7: 39,
    char_8: 'Bronya',
    app_rate_8: 1.33,
    avg_round_8: 23185,
    app_flat_8: 11,
    char_9: 'Huohuo',
    app_rate_9: 1.24,
    avg_round_9: 26897,
    app_flat_9: 12,
    char_10: 'Herta',
    app_rate_10: 0.98,
    avg_round_10: 28864,
    app_flat_10: 39,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 0.98,
    avg_round_11: 28468,
    app_flat_11: 41,
    char_12: 'Jiaoqiu',
    app_rate_12: 0.61,
    avg_round_12: 34221,
    app_flat_12: 21,
    char_13: 'fu-xuan',
    app_rate_13: 0.57,
    avg_round_13: 23164,
    app_flat_13: 3,
    char_14: 'Asta',
    app_rate_14: 0.38,
    avg_round_14: 26714,
    app_flat_14: 14,
    char_15: 'Acheron',
    app_rate_15: 0.32,
    avg_round_15: 34321,
    app_flat_15: 6,
    char_16: 'Robin',
    app_rate_16: 0.25,
    avg_round_16: 28795,
    app_flat_16: 5,
    char_17: 'Kafka',
    app_rate_17: 0.25,
    avg_round_17: 25851,
    app_flat_17: 9,
    char_18: 'Guinaifen',
    app_rate_18: 0.17,
    avg_round_18: 24395,
    app_flat_18: 6,
    char_19: 'Sparkle',
    app_rate_19: 0.17,
    avg_round_19: 29893,
    app_flat_19: 3,
    char_20: 'Yunli',
    app_rate_20: 0.14,
    avg_round_20: 28290,
    app_flat_20: 5,
    char_21: 'Bailu',
    app_rate_21: 0.12,
    avg_round_21: 26378,
    app_flat_21: 2,
    char_22: 'Jade',
    app_rate_22: 0.12,
    avg_round_22: 33709,
    app_flat_22: 4,
    char_23: 'Xueyi',
    app_rate_23: 0.09,
    avg_round_23: 24004,
    app_flat_23: 3,
    char_24: 'Welt',
    app_rate_24: 0.09,
    avg_round_24: 23189,
    app_flat_24: 4,
    char_25: 'topaz',
    app_rate_25: 0.08,
    avg_round_25: 25958,
    app_flat_25: 2,
    char_26: 'Argenti',
    app_rate_26: 0.08,
    avg_round_26: 26878,
    app_flat_26: 2,
    char_27: 'Serval',
    app_rate_27: 0.08,
    avg_round_27: 20468,
    app_flat_27: 4,
    char_28: 'Pela',
    app_rate_28: 0.06,
    avg_round_28: 23044,
    app_flat_28: 4,
    char_29: 'Lynx',
    app_rate_29: 0.06,
    avg_round_29: 34610,
    app_flat_29: 1,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.05,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Robin',
    round: 31739,
    char_1: 'Herta',
    app_rate_1: 44.29,
    avg_round_1: 35437,
    app_flat_1: 1297,
    char_2: 'Aventurine',
    app_rate_2: 38.44,
    avg_round_2: 34230,
    app_flat_2: 950,
    char_3: 'Jade',
    app_rate_3: 27.42,
    avg_round_3: 36766,
    app_flat_3: 745,
    char_4: 'Feixiao',
    app_rate_4: 24.31,
    avg_round_4: 33229,
    app_flat_4: 533,
    char_5: 'Himeko',
    app_rate_5: 22.61,
    avg_round_5: 33062,
    app_flat_5: 624,
    char_6: 'fu-xuan',
    app_rate_6: 17.99,
    avg_round_6: 32778,
    app_flat_6: 419,
    char_7: 'Huohuo',
    app_rate_7: 16.47,
    avg_round_7: 32367,
    app_flat_7: 388,
    char_8: 'Yunli',
    app_rate_8: 13.3,
    avg_round_8: 32157,
    app_flat_8: 348,
    char_9: 'Gallagher',
    app_rate_9: 11.03,
    avg_round_9: 32578,
    app_flat_9: 306,
    char_10: 'topaz',
    app_rate_10: 9.33,
    avg_round_10: 30789,
    app_flat_10: 139,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 8.73,
    avg_round_11: 30790,
    app_flat_11: 238,
    char_12: 'Acheron',
    app_rate_12: 8.55,
    avg_round_12: 32877,
    app_flat_12: 157,
    char_13: 'Luocha',
    app_rate_13: 6.6,
    avg_round_13: 31651,
    app_flat_13: 155,
    char_14: 'Tingyun',
    app_rate_14: 6.44,
    avg_round_14: 30299,
    app_flat_14: 155,
    char_15: 'Sparkle',
    app_rate_15: 5.58,
    avg_round_15: 28296,
    app_flat_15: 75,
    char_16: 'Kafka',
    app_rate_16: 5.38,
    avg_round_16: 27938,
    app_flat_16: 76,
    char_17: 'Clara',
    app_rate_17: 5.02,
    avg_round_17: 29611,
    app_flat_17: 128,
    char_18: 'jing-yuan',
    app_rate_18: 4.77,
    avg_round_18: 29951,
    app_flat_18: 105,
    char_19: 'Jiaoqiu',
    app_rate_19: 4.63,
    avg_round_19: 35771,
    app_flat_19: 84,
    char_20: 'ruan-mei',
    app_rate_20: 2.93,
    avg_round_20: 33986,
    app_flat_20: 52,
    char_21: 'black-swan',
    app_rate_21: 2.83,
    avg_round_21: 25255,
    app_flat_21: 36,
    char_22: 'Argenti',
    app_rate_22: 2.33,
    avg_round_22: 30784,
    app_flat_22: 51,
    char_23: 'Lingsha',
    app_rate_23: 1.66,
    avg_round_23: 37662,
    app_flat_23: 36,
    char_24: 'Pela',
    app_rate_24: 1.32,
    avg_round_24: 29474,
    app_flat_24: 22,
    char_25: 'Seele',
    app_rate_25: 1.08,
    avg_round_25: 27050,
    app_flat_25: 8,
    char_26: 'Bronya',
    app_rate_26: 0.9,
    avg_round_26: 33135,
    app_flat_26: 9,
    char_27: 'Blade',
    app_rate_27: 0.78,
    avg_round_27: 29145,
    app_flat_27: 12,
    char_28: 'Lynx',
    app_rate_28: 0.72,
    avg_round_28: 30892,
    app_flat_28: 20,
    char_29: 'Bailu',
    app_rate_29: 0.62,
    avg_round_29: 30461,
    app_flat_29: 19,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.58,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    round: 31502,
    char_1: 'Robin',
    app_rate_1: 72.09,
    avg_round_1: 35437,
    app_flat_1: 1297,
    char_2: 'Jade',
    app_rate_2: 37.12,
    avg_round_2: 37005,
    app_flat_2: 709,
    char_3: 'Aventurine',
    app_rate_3: 33.92,
    avg_round_3: 35818,
    app_flat_3: 625,
    char_4: 'Himeko',
    app_rate_4: 31.96,
    avg_round_4: 31807,
    app_flat_4: 536,
    char_5: 'fu-xuan',
    app_rate_5: 22.69,
    avg_round_5: 34986,
    app_flat_5: 365,
    char_6: 'ruan-mei',
    app_rate_6: 18.32,
    avg_round_6: 32453,
    app_flat_6: 244,
    char_7: 'Feixiao',
    app_rate_7: 15.77,
    avg_round_7: 35178,
    app_flat_7: 269,
    char_8: 'Gallagher',
    app_rate_8: 13.61,
    avg_round_8: 31549,
    app_flat_8: 258,
    char_9: 'Huohuo',
    app_rate_9: 10.94,
    avg_round_9: 34977,
    app_flat_9: 180,
    char_10: 'Sparkle',
    app_rate_10: 7.8,
    avg_round_10: 30853,
    app_flat_10: 88,
    char_11: 'Luocha',
    app_rate_11: 6.56,
    avg_round_11: 32418,
    app_flat_11: 112,
    char_12: 'Acheron',
    app_rate_12: 2.64,
    avg_round_12: 31799,
    app_flat_12: 41,
    char_13: 'Clara',
    app_rate_13: 2.45,
    avg_round_13: 29634,
    app_flat_13: 43,
    char_14: 'jing-yuan',
    app_rate_14: 2.32,
    avg_round_14: 27773,
    app_flat_14: 37,
    char_15: 'Yunli',
    app_rate_15: 2.32,
    avg_round_15: 30682,
    app_flat_15: 38,
    char_16: 'Lingsha',
    app_rate_16: 2.09,
    avg_round_16: 34770,
    app_flat_16: 31,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 2.09,
    avg_round_17: 28864,
    app_flat_17: 39,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 1.89,
    avg_round_18: 31308,
    app_flat_18: 37,
    char_19: 'Tingyun',
    app_rate_19: 1.83,
    avg_round_19: 29054,
    app_flat_19: 28,
    char_20: 'Jiaoqiu',
    app_rate_20: 1.63,
    avg_round_20: 32111,
    app_flat_20: 23,
    char_21: 'Firefly',
    app_rate_21: 1.27,
    avg_round_21: 29332,
    app_flat_21: 13,
    char_22: 'Kafka',
    app_rate_22: 1.01,
    avg_round_22: 25801,
    app_flat_22: 11,
    char_23: 'topaz',
    app_rate_23: 0.91,
    avg_round_23: 27957,
    app_flat_23: 11,
    char_24: 'Bronya',
    app_rate_24: 0.91,
    avg_round_24: 32099,
    app_flat_24: 14,
    char_25: 'Argenti',
    app_rate_25: 0.85,
    avg_round_25: 32801,
    app_flat_25: 9,
    char_26: 'Bailu',
    app_rate_26: 0.62,
    avg_round_26: 32093,
    app_flat_26: 13,
    char_27: 'Seele',
    app_rate_27: 0.62,
    avg_round_27: 26930,
    app_flat_27: 2,
    char_28: 'Xueyi',
    app_rate_28: 0.56,
    avg_round_28: 26474,
    app_flat_28: 10,
    char_29: 'Lynx',
    app_rate_29: 0.49,
    avg_round_29: 31604,
    app_flat_29: 9,
    char_30: 'Serval',
    app_rate_30: 0.46,
    avg_round_30: 23009,
    app_flat_30: 8
  },
  {
    char: 'fu-xuan',
    round: 31464,
    char_1: 'Acheron',
    app_rate_1: 60.84,
    avg_round_1: 32263,
    app_flat_1: 900,
    char_2: 'Sparkle',
    app_rate_2: 30.08,
    avg_round_2: 29576,
    app_flat_2: 258,
    char_3: 'Jiaoqiu',
    app_rate_3: 28.36,
    avg_round_3: 34572,
    app_flat_3: 462,
    char_4: 'Kafka',
    app_rate_4: 26.07,
    avg_round_4: 31206,
    app_flat_4: 421,
    char_5: 'Robin',
    app_rate_5: 23.62,
    avg_round_5: 32778,
    app_flat_5: 419,
    char_6: 'Jade',
    app_rate_6: 19.02,
    avg_round_6: 35452,
    app_flat_6: 355,
    char_7: 'Pela',
    app_rate_7: 18.8,
    avg_round_7: 32451,
    app_flat_7: 363,
    char_8: 'Herta',
    app_rate_8: 18.3,
    avg_round_8: 34986,
    app_flat_8: 365,
    char_9: 'black-swan',
    app_rate_9: 17.62,
    avg_round_9: 30496,
    app_flat_9: 273,
    char_10: 'ruan-mei',
    app_rate_10: 8.85,
    avg_round_10: 34347,
    app_flat_10: 92,
    char_11: 'Tingyun',
    app_rate_11: 7.08,
    avg_round_11: 27171,
    app_flat_11: 106,
    char_12: 'jing-yuan',
    app_rate_12: 6.03,
    avg_round_12: 28636,
    app_flat_12: 92,
    char_13: 'Feixiao',
    app_rate_13: 4.79,
    avg_round_13: 31633,
    app_flat_13: 68,
    char_14: 'Yunli',
    app_rate_14: 4.58,
    avg_round_14: 30907,
    app_flat_14: 75,
    char_15: 'Himeko',
    app_rate_15: 4.45,
    avg_round_15: 29626,
    app_flat_15: 57,
    char_16: 'silver-wolf',
    app_rate_16: 4.35,
    avg_round_16: 29331,
    app_flat_16: 50,
    char_17: 'Seele',
    app_rate_17: 2.79,
    avg_round_17: 26699,
    app_flat_17: 24,
    char_18: 'Bronya',
    app_rate_18: 2.74,
    avg_round_18: 26320,
    app_flat_18: 12,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 1.74,
    avg_round_19: 28836,
    app_flat_19: 26,
    char_20: 'Argenti',
    app_rate_20: 1.63,
    avg_round_20: 28140,
    app_flat_20: 32,
    char_21: 'Clara',
    app_rate_21: 1.55,
    avg_round_21: 27344,
    app_flat_21: 28,
    char_22: 'Firefly',
    app_rate_22: 1.08,
    avg_round_22: 24180,
    app_flat_22: 3,
    char_23: 'Serval',
    app_rate_23: 1.0,
    avg_round_23: 23325,
    app_flat_23: 12,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.97,
    avg_round_24: 23164,
    app_flat_24: 3,
    char_25: 'Blade',
    app_rate_25: 0.74,
    avg_round_25: 29772,
    app_flat_25: 12,
    char_26: 'Xueyi',
    app_rate_26: 0.61,
    avg_round_26: 26020,
    app_flat_26: 5,
    char_27: 'topaz',
    app_rate_27: 0.53,
    avg_round_27: 29790,
    app_flat_27: 1,
    char_28: 'Guinaifen',
    app_rate_28: 0.5,
    avg_round_28: 24980,
    app_flat_28: 6,
    char_29: 'Qingque',
    app_rate_29: 0.32,
    avg_round_29: 28836,
    app_flat_29: 2,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.21,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    round: 31434,
    char_1: 'Acheron',
    app_rate_1: 96.44,
    avg_round_1: 31422,
    app_flat_1: 99,
    char_2: 'Kafka',
    app_rate_2: 41.11,
    avg_round_2: 32059,
    app_flat_2: 54,
    char_3: 'black-swan',
    app_rate_3: 39.53,
    avg_round_3: 32180,
    app_flat_3: 48,
    char_4: 'Pela',
    app_rate_4: 36.76,
    avg_round_4: 30372,
    app_flat_4: 38,
    char_5: 'Jiaoqiu',
    app_rate_5: 22.92,
    avg_round_5: 32627,
    app_flat_5: 25,
    char_6: 'Sparkle',
    app_rate_6: 20.55,
    avg_round_6: 30280,
    app_flat_6: 6,
    char_7: 'silver-wolf',
    app_rate_7: 9.88,
    avg_round_7: 29312,
    app_flat_7: 9,
    char_8: 'Robin',
    app_rate_8: 7.11,
    avg_round_8: 29153,
    app_flat_8: 9,
    char_9: 'Jade',
    app_rate_9: 5.14,
    avg_round_9: 28348,
    app_flat_9: 4,
    char_10: 'Bronya',
    app_rate_10: 4.74,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Herta',
    app_rate_11: 4.35,
    avg_round_11: 28919,
    app_flat_11: 4,
    char_12: 'ruan-mei',
    app_rate_12: 4.35,
    avg_round_12: 32732,
    app_flat_12: 2,
    char_13: 'Guinaifen',
    app_rate_13: 2.77,
    avg_round_13: 26662,
    app_flat_13: 4,
    char_14: 'Serval',
    app_rate_14: 1.19,
    avg_round_14: 29695,
    app_flat_14: 1,
    char_15: 'topaz',
    app_rate_15: 0.79,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Welt',
    app_rate_16: 0.79,
    avg_round_16: 30517,
    app_flat_16: 1,
    char_17: 'Boothill',
    app_rate_17: 0.4,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Himeko',
    app_rate_18: 0.4,
    avg_round_18: 27985,
    app_flat_18: 1,
    char_19: 'Yunli',
    app_rate_19: 0.4,
    avg_round_19: 27760,
    app_flat_19: 1,
    char_20: 'Feixiao',
    app_rate_20: 0.4,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    round: 31364,
    char_1: 'Acheron',
    app_rate_1: 78.67,
    avg_round_1: 31670,
    app_flat_1: 922,
    char_2: 'black-swan',
    app_rate_2: 63.85,
    avg_round_2: 30129,
    app_flat_2: 648,
    char_3: 'fu-xuan',
    app_rate_3: 36.86,
    avg_round_3: 31206,
    app_flat_3: 421,
    char_4: 'Huohuo',
    app_rate_4: 20.44,
    avg_round_4: 28632,
    app_flat_4: 135,
    char_5: 'ruan-mei',
    app_rate_5: 17.91,
    avg_round_5: 31827,
    app_flat_5: 143,
    char_6: 'Jiaoqiu',
    app_rate_6: 15.64,
    avg_round_6: 35699,
    app_flat_6: 191,
    char_7: 'Aventurine',
    app_rate_7: 13.74,
    avg_round_7: 32221,
    app_flat_7: 181,
    char_8: 'Robin',
    app_rate_8: 9.98,
    avg_round_8: 27938,
    app_flat_8: 76,
    char_9: 'Gallagher',
    app_rate_9: 7.71,
    avg_round_9: 30818,
    app_flat_9: 99,
    char_10: 'Luocha',
    app_rate_10: 6.81,
    avg_round_10: 29653,
    app_flat_10: 58,
    char_11: 'Pela',
    app_rate_11: 5.06,
    avg_round_11: 30019,
    app_flat_11: 78,
    char_12: 'Jade',
    app_rate_12: 4.13,
    avg_round_12: 32007,
    app_flat_12: 30,
    char_13: 'Sparkle',
    app_rate_13: 4.02,
    avg_round_13: 27333,
    app_flat_13: 19,
    char_14: 'Gepard',
    app_rate_14: 3.87,
    avg_round_14: 32059,
    app_flat_14: 54,
    char_15: 'Tingyun',
    app_rate_15: 1.45,
    avg_round_15: 26167,
    app_flat_15: 14,
    char_16: 'Bailu',
    app_rate_16: 1.41,
    avg_round_16: 26968,
    app_flat_16: 15,
    char_17: 'Herta',
    app_rate_17: 1.15,
    avg_round_17: 25801,
    app_flat_17: 11,
    char_18: 'silver-wolf',
    app_rate_18: 1.04,
    avg_round_18: 29135,
    app_flat_18: 13,
    char_19: 'Serval',
    app_rate_19: 0.89,
    avg_round_19: 25783,
    app_flat_19: 10,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 0.6,
    avg_round_20: 25851,
    app_flat_20: 9,
    char_21: 'Guinaifen',
    app_rate_21: 0.56,
    avg_round_21: 25597,
    app_flat_21: 8,
    char_22: 'Yunli',
    app_rate_22: 0.52,
    avg_round_22: 27630,
    app_flat_22: 9,
    char_23: 'Asta',
    app_rate_23: 0.41,
    avg_round_23: 22062,
    app_flat_23: 2,
    char_24: 'Himeko',
    app_rate_24: 0.34,
    avg_round_24: 29723,
    app_flat_24: 6,
    char_25: 'Lingsha',
    app_rate_25: 0.3,
    avg_round_25: 28655,
    app_flat_25: 2,
    char_26: 'Bronya',
    app_rate_26: 0.3,
    avg_round_26: 23264,
    app_flat_26: 2,
    char_27: 'Sampo',
    app_rate_27: 0.26,
    avg_round_27: 25314,
    app_flat_27: 2,
    char_28: 'Xueyi',
    app_rate_28: 0.26,
    avg_round_28: 23985,
    app_flat_28: 6,
    char_29: 'Firefly',
    app_rate_29: 0.22,
    avg_round_29: 27100,
    app_flat_29: 2,
    char_30: 'Feixiao',
    app_rate_30: 0.22,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Yunli',
    round: 31118,
    char_1: 'Robin',
    app_rate_1: 70.38,
    avg_round_1: 32157,
    app_flat_1: 348,
    char_2: 'Huohuo',
    app_rate_2: 43.52,
    avg_round_2: 31713,
    app_flat_2: 213,
    char_3: 'Tingyun',
    app_rate_3: 32.91,
    avg_round_3: 30512,
    app_flat_3: 157,
    char_4: 'Sparkle',
    app_rate_4: 23.78,
    avg_round_4: 29963,
    app_flat_4: 88,
    char_5: 'fu-xuan',
    app_rate_5: 18.47,
    avg_round_5: 30907,
    app_flat_5: 75,
    char_6: 'Aventurine',
    app_rate_6: 16.67,
    avg_round_6: 32370,
    app_flat_6: 72,
    char_7: 'Feixiao',
    app_rate_7: 11.46,
    avg_round_7: 33915,
    app_flat_7: 52,
    char_8: 'Himeko',
    app_rate_8: 9.87,
    avg_round_8: 33243,
    app_flat_8: 56,
    char_9: 'Jade',
    app_rate_9: 9.02,
    avg_round_9: 35051,
    app_flat_9: 42,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 8.17,
    avg_round_10: 30168,
    app_flat_10: 51,
    char_11: 'ruan-mei',
    app_rate_11: 8.07,
    avg_round_11: 31772,
    app_flat_11: 33,
    char_12: 'Herta',
    app_rate_12: 7.54,
    avg_round_12: 30682,
    app_flat_12: 38,
    char_13: 'topaz',
    app_rate_13: 6.79,
    avg_round_13: 30116,
    app_flat_13: 23,
    char_14: 'Luocha',
    app_rate_14: 6.37,
    avg_round_14: 30880,
    app_flat_14: 31,
    char_15: 'Gallagher',
    app_rate_15: 4.46,
    avg_round_15: 32664,
    app_flat_15: 33,
    char_16: 'Acheron',
    app_rate_16: 4.03,
    avg_round_16: 32587,
    app_flat_16: 18,
    char_17: 'Lynx',
    app_rate_17: 3.08,
    avg_round_17: 29618,
    app_flat_17: 19,
    char_18: 'Clara',
    app_rate_18: 3.08,
    avg_round_18: 27534,
    app_flat_18: 18,
    char_19: 'Kafka',
    app_rate_19: 1.49,
    avg_round_19: 27630,
    app_flat_19: 9,
    char_20: 'Jiaoqiu',
    app_rate_20: 1.49,
    avg_round_20: 32431,
    app_flat_20: 5,
    char_21: 'jing-yuan',
    app_rate_21: 1.17,
    avg_round_21: 29421,
    app_flat_21: 7,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.96,
    avg_round_22: 28290,
    app_flat_22: 5,
    char_23: 'Bailu',
    app_rate_23: 0.85,
    avg_round_23: 26397,
    app_flat_23: 6,
    char_24: 'Lingsha',
    app_rate_24: 0.85,
    avg_round_24: 36260,
    app_flat_24: 4,
    char_25: 'silver-wolf',
    app_rate_25: 0.74,
    avg_round_25: 30908,
    app_flat_25: 5,
    char_26: 'Bronya',
    app_rate_26: 0.53,
    avg_round_26: 30765,
    app_flat_26: 3,
    char_27: 'Firefly',
    app_rate_27: 0.53,
    avg_round_27: 31500,
    app_flat_27: 1,
    char_28: 'Pela',
    app_rate_28: 0.42,
    avg_round_28: 29862,
    app_flat_28: 3,
    char_29: 'Xueyi',
    app_rate_29: 0.42,
    avg_round_29: 32665,
    app_flat_29: 1,
    char_30: 'Argenti',
    app_rate_30: 0.32,
    avg_round_30: 26065,
    app_flat_30: 1
  },
  {
    char: 'Blade',
    round: 31113,
    char_1: 'Jade',
    app_rate_1: 68.69,
    avg_round_1: 31463,
    app_flat_1: 28,
    char_2: 'Robin',
    app_rate_2: 39.39,
    avg_round_2: 29145,
    app_flat_2: 12,
    char_3: 'fu-xuan',
    app_rate_3: 28.28,
    avg_round_3: 29772,
    app_flat_3: 12,
    char_4: 'Bronya',
    app_rate_4: 28.28,
    avg_round_4: 30069,
    app_flat_4: 9,
    char_5: 'Luocha',
    app_rate_5: 28.28,
    avg_round_5: 27583,
    app_flat_5: 8,
    char_6: 'Huohuo',
    app_rate_6: 21.21,
    avg_round_6: 33625,
    app_flat_6: 5,
    char_7: 'Sparkle',
    app_rate_7: 19.19,
    avg_round_7: 28684,
    app_flat_7: 6,
    char_8: 'ruan-mei',
    app_rate_8: 17.17,
    avg_round_8: 35541,
    app_flat_8: 4,
    char_9: 'Lynx',
    app_rate_9: 11.11,
    avg_round_9: 33910,
    app_flat_9: 5,
    char_10: 'Jingliu',
    app_rate_10: 7.07,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Gallagher',
    app_rate_11: 7.07,
    avg_round_11: 25560,
    app_flat_11: 2,
    char_12: 'Herta',
    app_rate_12: 6.06,
    avg_round_12: 22777,
    app_flat_12: 5,
    char_13: 'Jiaoqiu',
    app_rate_13: 3.03,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Yunli',
    app_rate_14: 3.03,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Himeko',
    app_rate_15: 3.03,
    avg_round_15: 21312,
    app_flat_15: 1,
    char_16: 'Feixiao',
    app_rate_16: 2.02,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'topaz',
    app_rate_17: 2.02,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Acheron',
    app_rate_18: 1.01,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Aventurine',
    app_rate_19: 1.01,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Clara',
    app_rate_20: 1.01,
    avg_round_20: 21554,
    app_flat_20: 1,
    char_21: 'Bailu',
    app_rate_21: 1.01,
    avg_round_21: 32175,
    app_flat_21: 1,
    char_22: 'Xueyi',
    app_rate_22: 1.01,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gallagher',
    round: 31070,
    char_1: 'ruan-mei',
    app_rate_1: 77.05,
    avg_round_1: 31045,
    app_flat_1: 1962,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 75.98,
    avg_round_2: 30848,
    app_flat_2: 1969,
    char_3: 'Firefly',
    app_rate_3: 63.72,
    avg_round_3: 30653,
    app_flat_3: 1402,
    char_4: 'Himeko',
    app_rate_4: 25.85,
    avg_round_4: 31207,
    app_flat_4: 972,
    char_5: 'Robin',
    app_rate_5: 9.85,
    avg_round_5: 32578,
    app_flat_5: 306,
    char_6: 'Acheron',
    app_rate_6: 9.23,
    avg_round_6: 32515,
    app_flat_6: 272,
    char_7: 'Herta',
    app_rate_7: 7.47,
    avg_round_7: 31549,
    app_flat_7: 258,
    char_8: 'Jiaoqiu',
    app_rate_8: 6.25,
    avg_round_8: 33999,
    app_flat_8: 191,
    char_9: 'Kafka',
    app_rate_9: 3.71,
    avg_round_9: 30818,
    app_flat_9: 99,
    char_10: 'Pela',
    app_rate_10: 3.01,
    avg_round_10: 32148,
    app_flat_10: 105,
    char_11: 'Sparkle',
    app_rate_11: 2.97,
    avg_round_11: 28959,
    app_flat_11: 49,
    char_12: 'black-swan',
    app_rate_12: 2.83,
    avg_round_12: 29556,
    app_flat_12: 77,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 2.06,
    avg_round_13: 27983,
    app_flat_13: 62,
    char_14: 'Feixiao',
    app_rate_14: 2.06,
    avg_round_14: 31801,
    app_flat_14: 51,
    char_15: 'Jade',
    app_rate_15: 1.31,
    avg_round_15: 34210,
    app_flat_15: 35,
    char_16: 'Tingyun',
    app_rate_16: 1.2,
    avg_round_16: 28257,
    app_flat_16: 36,
    char_17: 'Bronya',
    app_rate_17: 0.93,
    avg_round_17: 26546,
    app_flat_17: 16,
    char_18: 'Yunli',
    app_rate_18: 0.75,
    avg_round_18: 32664,
    app_flat_18: 33,
    char_19: 'Argenti',
    app_rate_19: 0.59,
    avg_round_19: 26871,
    app_flat_19: 16,
    char_20: 'topaz',
    app_rate_20: 0.47,
    avg_round_20: 29216,
    app_flat_20: 15,
    char_21: 'Asta',
    app_rate_21: 0.41,
    avg_round_21: 26385,
    app_flat_21: 13,
    char_22: 'Clara',
    app_rate_22: 0.41,
    avg_round_22: 25260,
    app_flat_22: 12,
    char_23: 'imbibitor-lunae',
    app_rate_23: 0.36,
    avg_round_23: 20035,
    app_flat_23: 1,
    char_24: 'Guinaifen',
    app_rate_24: 0.32,
    avg_round_24: 24531,
    app_flat_24: 14,
    char_25: 'jing-yuan',
    app_rate_25: 0.3,
    avg_round_25: 27258,
    app_flat_25: 7,
    char_26: 'Seele',
    app_rate_26: 0.13,
    avg_round_26: 29340,
    app_flat_26: 1,
    char_27: 'Welt',
    app_rate_27: 0.13,
    avg_round_27: 23046,
    app_flat_27: 4,
    char_28: 'Blade',
    app_rate_28: 0.13,
    avg_round_28: 25560,
    app_flat_28: 2,
    char_29: 'silver-wolf',
    app_rate_29: 0.11,
    avg_round_29: 30070,
    app_flat_29: 4,
    char_30: 'Boothill',
    app_rate_30: 0.09,
    avg_round_30: 26208,
    app_flat_30: 1
  },
  {
    char: 'Feixiao',
    round: 30866,
    char_1: 'Robin',
    app_rate_1: 87.64,
    avg_round_1: 33229,
    app_flat_1: 533,
    char_2: 'Aventurine',
    app_rate_2: 54.74,
    avg_round_2: 33286,
    app_flat_2: 344,
    char_3: 'Herta',
    app_rate_3: 34.92,
    avg_round_3: 35178,
    app_flat_3: 269,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 20.39,
    avg_round_4: 30414,
    app_flat_4: 140,
    char_5: 'topaz',
    app_rate_5: 18.37,
    avg_round_5: 29101,
    app_flat_5: 75,
    char_6: 'fu-xuan',
    app_rate_6: 13.16,
    avg_round_6: 31633,
    app_flat_6: 68,
    char_7: 'Jade',
    app_rate_7: 12.73,
    avg_round_7: 36807,
    app_flat_7: 44,
    char_8: 'Gallagher',
    app_rate_8: 8.32,
    avg_round_8: 31801,
    app_flat_8: 51,
    char_9: 'Himeko',
    app_rate_9: 8.17,
    avg_round_9: 32709,
    app_flat_9: 62,
    char_10: 'Yunli',
    app_rate_10: 7.81,
    avg_round_10: 33915,
    app_flat_10: 52,
    char_11: 'Huohuo',
    app_rate_11: 7.59,
    avg_round_11: 31400,
    app_flat_11: 41,
    char_12: 'Luocha',
    app_rate_12: 5.5,
    avg_round_12: 31691,
    app_flat_12: 35,
    char_13: 'Clara',
    app_rate_13: 3.76,
    avg_round_13: 31260,
    app_flat_13: 24,
    char_14: 'ruan-mei',
    app_rate_14: 3.69,
    avg_round_14: 33413,
    app_flat_14: 22,
    char_15: 'Sparkle',
    app_rate_15: 2.6,
    avg_round_15: 31200,
    app_flat_15: 10,
    char_16: 'Acheron',
    app_rate_16: 1.52,
    avg_round_16: 30262,
    app_flat_16: 9,
    char_17: 'Lingsha',
    app_rate_17: 1.45,
    avg_round_17: 35918,
    app_flat_17: 10,
    char_18: 'Bronya',
    app_rate_18: 1.16,
    avg_round_18: 31098,
    app_flat_18: 6,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.94,
    avg_round_19: 34159,
    app_flat_19: 4,
    char_20: 'Moze',
    app_rate_20: 0.8,
    avg_round_20: 25017,
    app_flat_20: 3,
    char_21: 'jing-yuan',
    app_rate_21: 0.8,
    avg_round_21: 32155,
    app_flat_21: 3,
    char_22: 'Bailu',
    app_rate_22: 0.65,
    avg_round_22: 27658,
    app_flat_22: 6,
    char_23: 'Kafka',
    app_rate_23: 0.43,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Seele',
    app_rate_24: 0.43,
    avg_round_24: 29148,
    app_flat_24: 2,
    char_25: 'Tingyun',
    app_rate_25: 0.43,
    avg_round_25: 32203,
    app_flat_25: 1,
    char_26: 'Argenti',
    app_rate_26: 0.36,
    avg_round_26: 32858,
    app_flat_26: 2,
    char_27: 'march-7th',
    app_rate_27: 0.29,
    avg_round_27: 34988,
    app_flat_27: 3,
    char_28: 'Lynx',
    app_rate_28: 0.29,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Pela',
    app_rate_29: 0.22,
    avg_round_29: 28689,
    app_flat_29: 3,
    char_30: 'Blade',
    app_rate_30: 0.14,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    round: 30785,
    char_1: 'Kafka',
    app_rate_1: 84.78,
    avg_round_1: 30129,
    app_flat_1: 648,
    char_2: 'Acheron',
    app_rate_2: 78.84,
    avg_round_2: 31032,
    app_flat_2: 677,
    char_3: 'fu-xuan',
    app_rate_3: 33.07,
    avg_round_3: 30496,
    app_flat_3: 273,
    char_4: 'Huohuo',
    app_rate_4: 23.28,
    avg_round_4: 28334,
    app_flat_4: 116,
    char_5: 'ruan-mei',
    app_rate_5: 16.91,
    avg_round_5: 31418,
    app_flat_5: 84,
    char_6: 'Aventurine',
    app_rate_6: 13.94,
    avg_round_6: 31415,
    app_flat_6: 132,
    char_7: 'Jiaoqiu',
    app_rate_7: 10.28,
    avg_round_7: 33669,
    app_flat_7: 82,
    char_8: 'Gallagher',
    app_rate_8: 7.81,
    avg_round_8: 29556,
    app_flat_8: 77,
    char_9: 'Robin',
    app_rate_9: 6.97,
    avg_round_9: 25255,
    app_flat_9: 36,
    char_10: 'Luocha',
    app_rate_10: 6.87,
    avg_round_10: 28764,
    app_flat_10: 43,
    char_11: 'Gepard',
    app_rate_11: 4.94,
    avg_round_11: 32180,
    app_flat_11: 48,
    char_12: 'Pela',
    app_rate_12: 2.97,
    avg_round_12: 28058,
    app_flat_12: 29,
    char_13: 'Sparkle',
    app_rate_13: 2.52,
    avg_round_13: 22587,
    app_flat_13: 3,
    char_14: 'Bailu',
    app_rate_14: 1.24,
    avg_round_14: 27739,
    app_flat_14: 9,
    char_15: 'Tingyun',
    app_rate_15: 1.09,
    avg_round_15: 24222,
    app_flat_15: 6,
    char_16: 'Jade',
    app_rate_16: 0.44,
    avg_round_16: 23339,
    app_flat_16: 1,
    char_17: 'Asta',
    app_rate_17: 0.4,
    avg_round_17: 22062,
    app_flat_17: 2,
    char_18: 'silver-wolf',
    app_rate_18: 0.4,
    avg_round_18: 28476,
    app_flat_18: 2,
    char_19: 'Bronya',
    app_rate_19: 0.4,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Lynx',
    app_rate_20: 0.35,
    avg_round_20: 25676,
    app_flat_20: 2,
    char_21: 'Guinaifen',
    app_rate_21: 0.3,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: 0.25,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Serval',
    app_rate_23: 0.25,
    avg_round_23: 22232,
    app_flat_23: 2,
    char_24: 'Herta',
    app_rate_24: 0.25,
    avg_round_24: 27334,
    app_flat_24: 2,
    char_25: 'Lingsha',
    app_rate_25: 0.2,
    avg_round_25: 28395,
    app_flat_25: 1,
    char_26: 'Natasha',
    app_rate_26: 0.15,
    avg_round_26: 27525,
    app_flat_26: 1,
    char_27: 'march-7th-swordmaster',
    app_rate_27: 0.15,
    avg_round_27: 21939,
    app_flat_27: 1,
    char_28: 'Yunli',
    app_rate_28: 0.1,
    avg_round_28: 25350,
    app_flat_28: 2,
    char_29: 'jing-yuan',
    app_rate_29: 0.1,
    avg_round_29: 30665,
    app_flat_29: 1,
    char_30: 'Xueyi',
    app_rate_30: 0.1,
    avg_round_30: 22591,
    app_flat_30: 1
  },
  {
    char: 'topaz',
    round: 30361,
    char_1: 'Robin',
    app_rate_1: 80.03,
    avg_round_1: 30789,
    app_flat_1: 139,
    char_2: 'Aventurine',
    app_rate_2: 75.39,
    avg_round_2: 30686,
    app_flat_2: 129,
    char_3: 'Feixiao',
    app_rate_3: 43.72,
    avg_round_3: 29101,
    app_flat_3: 75,
    char_4: 'Himeko',
    app_rate_4: 33.05,
    avg_round_4: 31683,
    app_flat_4: 71,
    char_5: 'Yunli',
    app_rate_5: 11.02,
    avg_round_5: 30116,
    app_flat_5: 23,
    char_6: 'ruan-mei',
    app_rate_6: 9.12,
    avg_round_6: 28178,
    app_flat_6: 18,
    char_7: 'Jade',
    app_rate_7: 7.06,
    avg_round_7: 28228,
    app_flat_7: 3,
    char_8: 'Herta',
    app_rate_8: 4.82,
    avg_round_8: 27957,
    app_flat_8: 11,
    char_9: 'Huohuo',
    app_rate_9: 4.82,
    avg_round_9: 29356,
    app_flat_9: 10,
    char_10: 'Clara',
    app_rate_10: 4.65,
    avg_round_10: 26849,
    app_flat_10: 9,
    char_11: 'Gallagher',
    app_rate_11: 4.48,
    avg_round_11: 29216,
    app_flat_11: 15,
    char_12: 'Luocha',
    app_rate_12: 3.61,
    avg_round_12: 26967,
    app_flat_12: 10,
    char_13: 'fu-xuan',
    app_rate_13: 3.44,
    avg_round_13: 29790,
    app_flat_13: 1,
    char_14: 'dr-ratio',
    app_rate_14: 3.1,
    avg_round_14: 24433,
    app_flat_14: 1,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 1.89,
    avg_round_15: 26494,
    app_flat_15: 3,
    char_16: 'Lingsha',
    app_rate_16: 1.55,
    avg_round_16: 40000,
    app_flat_16: 1,
    char_17: 'Asta',
    app_rate_17: 1.2,
    avg_round_17: 22117,
    app_flat_17: 1,
    char_18: 'Sparkle',
    app_rate_18: 1.03,
    avg_round_18: 23724,
    app_flat_18: 1,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 0.86,
    avg_round_19: 25958,
    app_flat_19: 2,
    char_20: 'Jiaoqiu',
    app_rate_20: 0.86,
    avg_round_20: 26200,
    app_flat_20: 1,
    char_21: 'Acheron',
    app_rate_21: 0.69,
    avg_round_21: 24433,
    app_flat_21: 1,
    char_22: 'Firefly',
    app_rate_22: 0.52,
    avg_round_22: 22168,
    app_flat_22: 1,
    char_23: 'Gepard',
    app_rate_23: 0.34,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Blade',
    app_rate_24: 0.34,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: 0.34,
    avg_round_25: 24435,
    app_flat_25: 1,
    char_26: 'Tingyun',
    app_rate_26: 0.34,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Kafka',
    app_rate_27: 0.34,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'black-swan',
    app_rate_28: 0.34,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Bronya',
    app_rate_29: 0.17,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Xueyi',
    app_rate_30: 0.17,
    avg_round_30: 23406,
    app_flat_30: 1
  },
  {
    char: 'march-7th-swordmaster',
    round: 30203,
    char_1: 'Robin',
    app_rate_1: 72.86,
    avg_round_1: 30790,
    app_flat_1: 238,
    char_2: 'Feixiao',
    app_rate_2: 47.24,
    avg_round_2: 30414,
    app_flat_2: 140,
    char_3: 'Aventurine',
    app_rate_3: 32.66,
    avg_round_3: 30969,
    app_flat_3: 110,
    char_4: 'Himeko',
    app_rate_4: 20.6,
    avg_round_4: 31443,
    app_flat_4: 76,
    char_5: 'Gallagher',
    app_rate_5: 19.26,
    avg_round_5: 27983,
    app_flat_5: 62,
    char_6: 'Huohuo',
    app_rate_6: 13.07,
    avg_round_6: 30154,
    app_flat_6: 45,
    char_7: 'Yunli',
    app_rate_7: 12.9,
    avg_round_7: 30168,
    app_flat_7: 51,
    char_8: 'ruan-mei',
    app_rate_8: 12.9,
    avg_round_8: 31454,
    app_flat_8: 41,
    char_9: 'fu-xuan',
    app_rate_9: 11.06,
    avg_round_9: 28836,
    app_flat_9: 26,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 10.72,
    avg_round_10: 28468,
    app_flat_10: 41,
    char_11: 'Herta',
    app_rate_11: 9.72,
    avg_round_11: 31308,
    app_flat_11: 37,
    char_12: 'Luocha',
    app_rate_12: 5.7,
    avg_round_12: 28103,
    app_flat_12: 16,
    char_13: 'Firefly',
    app_rate_13: 4.36,
    avg_round_13: 24148,
    app_flat_13: 10,
    char_14: 'Sparkle',
    app_rate_14: 4.19,
    avg_round_14: 26731,
    app_flat_14: 8,
    char_15: 'Clara',
    app_rate_15: 3.69,
    avg_round_15: 28089,
    app_flat_15: 14,
    char_16: 'Tingyun',
    app_rate_16: 2.18,
    avg_round_16: 25210,
    app_flat_16: 4,
    char_17: 'Lingsha',
    app_rate_17: 2.18,
    avg_round_17: 33938,
    app_flat_17: 7,
    char_18: 'topaz',
    app_rate_18: 1.84,
    avg_round_18: 26494,
    app_flat_18: 3,
    char_19: 'Seele',
    app_rate_19: 1.84,
    avg_round_19: 20000,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: 1.68,
    avg_round_20: 29100,
    app_flat_20: 4,
    char_21: 'Bronya',
    app_rate_21: 1.68,
    avg_round_21: 28791,
    app_flat_21: 4,
    char_22: 'Lynx',
    app_rate_22: 1.34,
    avg_round_22: 26395,
    app_flat_22: 5,
    char_23: 'jing-yuan',
    app_rate_23: 1.01,
    avg_round_23: 24115,
    app_flat_23: 1,
    char_24: 'Kafka',
    app_rate_24: 1.01,
    avg_round_24: 23940,
    app_flat_24: 2,
    char_25: 'Bailu',
    app_rate_25: 0.84,
    avg_round_25: 21610,
    app_flat_25: 4,
    char_26: 'imbibitor-lunae',
    app_rate_26: 0.67,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Acheron',
    app_rate_27: 0.5,
    avg_round_27: 27228,
    app_flat_27: 3,
    char_28: 'black-swan',
    app_rate_28: 0.5,
    avg_round_28: 21939,
    app_flat_28: 1,
    char_29: 'Pela',
    app_rate_29: 0.34,
    avg_round_29: 26335,
    app_flat_29: 2,
    char_30: 'Welt',
    app_rate_30: 0.34,
    avg_round_30: 20051,
    app_flat_30: 1
  },
  {
    char: 'Huohuo',
    round: 30131,
    char_1: 'Robin',
    app_rate_1: 42.54,
    avg_round_1: 32367,
    app_flat_1: 388,
    char_2: 'Kafka',
    app_rate_2: 28.45,
    avg_round_2: 28632,
    app_flat_2: 135,
    char_3: 'black-swan',
    app_rate_3: 24.4,
    avg_round_3: 28334,
    app_flat_3: 116,
    char_4: 'Acheron',
    app_rate_4: 23.37,
    avg_round_4: 30467,
    app_flat_4: 139,
    char_5: 'Yunli',
    app_rate_5: 21.24,
    avg_round_5: 31713,
    app_flat_5: 213,
    char_6: 'Tingyun',
    app_rate_6: 21.04,
    avg_round_6: 29832,
    app_flat_6: 193,
    char_7: 'ruan-mei',
    app_rate_7: 19.95,
    avg_round_7: 29763,
    app_flat_7: 91,
    char_8: 'Sparkle',
    app_rate_8: 19.48,
    avg_round_8: 29343,
    app_flat_8: 132,
    char_9: 'Herta',
    app_rate_9: 17.36,
    avg_round_9: 34977,
    app_flat_9: 180,
    char_10: 'Jade',
    app_rate_10: 17.31,
    avg_round_10: 36022,
    app_flat_10: 165,
    char_11: 'Jiaoqiu',
    app_rate_11: 9.43,
    avg_round_11: 32896,
    app_flat_11: 60,
    char_12: 'Himeko',
    app_rate_12: 8.03,
    avg_round_12: 30840,
    app_flat_12: 74,
    char_13: 'Argenti',
    app_rate_13: 6.79,
    avg_round_13: 29510,
    app_flat_13: 71,
    char_14: 'jing-yuan',
    app_rate_14: 5.65,
    avg_round_14: 26823,
    app_flat_14: 40,
    char_15: 'Feixiao',
    app_rate_15: 5.44,
    avg_round_15: 31400,
    app_flat_15: 41,
    char_16: 'Firefly',
    app_rate_16: 4.77,
    avg_round_16: 26549,
    app_flat_16: 14,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 4.2,
    avg_round_17: 26897,
    app_flat_17: 12,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 4.04,
    avg_round_18: 30154,
    app_flat_18: 45,
    char_19: 'Clara',
    app_rate_19: 3.58,
    avg_round_19: 29321,
    app_flat_19: 39,
    char_20: 'imbibitor-lunae',
    app_rate_20: 2.75,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Pela',
    app_rate_21: 2.59,
    avg_round_21: 31360,
    app_flat_21: 24,
    char_22: 'topaz',
    app_rate_22: 1.45,
    avg_round_22: 29356,
    app_flat_22: 10,
    char_23: 'Bronya',
    app_rate_23: 1.09,
    avg_round_23: 28140,
    app_flat_23: 2,
    char_24: 'Blade',
    app_rate_24: 1.09,
    avg_round_24: 33625,
    app_flat_24: 5,
    char_25: 'Serval',
    app_rate_25: 0.73,
    avg_round_25: 27688,
    app_flat_25: 5,
    char_26: 'Seele',
    app_rate_26: 0.67,
    avg_round_26: 27830,
    app_flat_26: 2,
    char_27: 'silver-wolf',
    app_rate_27: 0.57,
    avg_round_27: 29981,
    app_flat_27: 5,
    char_28: 'Asta',
    app_rate_28: 0.47,
    avg_round_28: 25012,
    app_flat_28: 2,
    char_29: 'Xueyi',
    app_rate_29: 0.41,
    avg_round_29: 27657,
    app_flat_29: 3,
    char_30: 'Guinaifen',
    app_rate_30: 0.36,
    avg_round_30: 23731,
    app_flat_30: 3
  },
  {
    char: 'Luocha',
    round: 29901,
    char_1: 'Robin',
    app_rate_1: 37.95,
    avg_round_1: 31651,
    app_flat_1: 155,
    char_2: 'Acheron',
    app_rate_2: 26.64,
    avg_round_2: 31725,
    app_flat_2: 82,
    char_3: 'ruan-mei',
    app_rate_3: 26.41,
    avg_round_3: 29566,
    app_flat_3: 80,
    char_4: 'Herta',
    app_rate_4: 23.18,
    avg_round_4: 32418,
    app_flat_4: 112,
    char_5: 'Kafka',
    app_rate_5: 21.11,
    avg_round_5: 29653,
    app_flat_5: 58,
    char_6: 'Himeko',
    app_rate_6: 18.11,
    avg_round_6: 29990,
    app_flat_6: 73,
    char_7: 'Jiaoqiu',
    app_rate_7: 16.38,
    avg_round_7: 33492,
    app_flat_7: 51,
    char_8: 'black-swan',
    app_rate_8: 16.03,
    avg_round_8: 28764,
    app_flat_8: 43,
    char_9: 'Jade',
    app_rate_9: 15.8,
    avg_round_9: 34148,
    app_flat_9: 68,
    char_10: 'Sparkle',
    app_rate_10: 12.23,
    avg_round_10: 27574,
    app_flat_10: 27,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 11.65,
    avg_round_11: 28569,
    app_flat_11: 39,
    char_12: 'Firefly',
    app_rate_12: 9.46,
    avg_round_12: 27912,
    app_flat_12: 24,
    char_13: 'Tingyun',
    app_rate_13: 8.88,
    avg_round_13: 28348,
    app_flat_13: 39,
    char_14: 'Feixiao',
    app_rate_14: 8.77,
    avg_round_14: 31691,
    app_flat_14: 35,
    char_15: 'Yunli',
    app_rate_15: 6.92,
    avg_round_15: 30880,
    app_flat_15: 31,
    char_16: 'Pela',
    app_rate_16: 5.77,
    avg_round_16: 31295,
    app_flat_16: 24,
    char_17: 'Bronya',
    app_rate_17: 5.54,
    avg_round_17: 28308,
    app_flat_17: 11,
    char_18: 'Argenti',
    app_rate_18: 4.15,
    avg_round_18: 28392,
    app_flat_18: 16,
    char_19: 'jing-yuan',
    app_rate_19: 3.92,
    avg_round_19: 27905,
    app_flat_19: 14,
    char_20: 'march-7th-swordmaster',
    app_rate_20: 3.92,
    avg_round_20: 28103,
    app_flat_20: 16,
    char_21: 'Clara',
    app_rate_21: 3.81,
    avg_round_21: 28319,
    app_flat_21: 16,
    char_22: 'imbibitor-lunae',
    app_rate_22: 3.58,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Blade',
    app_rate_23: 3.23,
    avg_round_23: 27583,
    app_flat_23: 8,
    char_24: 'topaz',
    app_rate_24: 2.42,
    avg_round_24: 26967,
    app_flat_24: 10,
    char_25: 'silver-wolf',
    app_rate_25: 0.81,
    avg_round_25: 27672,
    app_flat_25: 4,
    char_26: 'Jingliu',
    app_rate_26: 0.58,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Xueyi',
    app_rate_27: 0.46,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Welt',
    app_rate_28: 0.46,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Asta',
    app_rate_29: 0.35,
    avg_round_29: 27695,
    app_flat_29: 1,
    char_30: 'Serval',
    app_rate_30: 0.35,
    avg_round_30: 20163,
    app_flat_30: 1
  },
  {
    char: 'Lynx',
    round: 29896,
    char_1: 'Robin',
    app_rate_1: 41.86,
    avg_round_1: 30892,
    app_flat_1: 20,
    char_2: 'Yunli',
    app_rate_2: 33.72,
    avg_round_2: 29618,
    app_flat_2: 19,
    char_3: 'Jade',
    app_rate_3: 30.23,
    avg_round_3: 32489,
    app_flat_3: 16,
    char_4: 'Sparkle',
    app_rate_4: 30.23,
    avg_round_4: 26938,
    app_flat_4: 11,
    char_5: 'Tingyun',
    app_rate_5: 19.77,
    avg_round_5: 29141,
    app_flat_5: 9,
    char_6: 'Acheron',
    app_rate_6: 17.44,
    avg_round_6: 31827,
    app_flat_6: 5,
    char_7: 'Herta',
    app_rate_7: 17.44,
    avg_round_7: 31604,
    app_flat_7: 9,
    char_8: 'ruan-mei',
    app_rate_8: 15.12,
    avg_round_8: 33395,
    app_flat_8: 5,
    char_9: 'Blade',
    app_rate_9: 12.79,
    avg_round_9: 33910,
    app_flat_9: 5,
    char_10: 'Jiaoqiu',
    app_rate_10: 9.3,
    avg_round_10: 37422,
    app_flat_10: 2,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 9.3,
    avg_round_11: 26395,
    app_flat_11: 5,
    char_12: 'black-swan',
    app_rate_12: 8.14,
    avg_round_12: 25676,
    app_flat_12: 2,
    char_13: 'Clara',
    app_rate_13: 8.14,
    avg_round_13: 26392,
    app_flat_13: 3,
    char_14: 'Kafka',
    app_rate_14: 6.98,
    avg_round_14: 29224,
    app_flat_14: 3,
    char_15: 'Pela',
    app_rate_15: 5.81,
    avg_round_15: 28504,
    app_flat_15: 2,
    char_16: 'Bronya',
    app_rate_16: 4.65,
    avg_round_16: 37203,
    app_flat_16: 3,
    char_17: 'Feixiao',
    app_rate_17: 4.65,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 4.65,
    avg_round_18: 34610,
    app_flat_18: 1,
    char_19: 'Argenti',
    app_rate_19: 3.49,
    avg_round_19: 27626,
    app_flat_19: 1,
    char_20: 'jing-yuan',
    app_rate_20: 3.49,
    avg_round_20: 27852,
    app_flat_20: 2,
    char_21: 'Seele',
    app_rate_21: 3.49,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Firefly',
    app_rate_22: 3.49,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Himeko',
    app_rate_23: 2.33,
    avg_round_23: 33225,
    app_flat_23: 2,
    char_24: 'Jingliu',
    app_rate_24: 1.16,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'topaz',
    app_rate_25: 1.16,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Serval',
    app_rate_26: 1.16,
    avg_round_26: 25542,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 29764,
    char_1: 'Acheron',
    app_rate_1: 89.29,
    avg_round_1: 29764,
    app_flat_1: 11,
    char_2: 'Pela',
    app_rate_2: 71.43,
    avg_round_2: 29764,
    app_flat_2: 11,
    char_3: 'Sparkle',
    app_rate_3: 35.71,
    avg_round_3: 29258,
    app_flat_3: 2,
    char_4: 'silver-wolf',
    app_rate_4: 21.43,
    avg_round_4: 25236,
    app_flat_4: 4,
    char_5: 'Jiaoqiu',
    app_rate_5: 14.29,
    avg_round_5: 38725,
    app_flat_5: 2,
    char_6: 'Kafka',
    app_rate_6: 14.29,
    avg_round_6: 31446,
    app_flat_6: 1,
    char_7: 'Himeko',
    app_rate_7: 10.71,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Herta',
    app_rate_8: 7.14,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'black-swan',
    app_rate_9: 7.14,
    avg_round_9: 27130,
    app_flat_9: 1,
    char_10: 'Bronya',
    app_rate_10: 7.14,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Serval',
    app_rate_11: 3.57,
    avg_round_11: 31912,
    app_flat_11: 1,
    char_12: 'ruan-mei',
    app_rate_12: 3.57,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Welt',
    app_rate_13: 3.57,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'topaz',
    app_rate_14: 3.57,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Asta',
    app_rate_15: 3.57,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Robin',
    app_rate_16: 3.57,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    round: 29285,
    char_1: 'Tingyun',
    app_rate_1: 60.37,
    avg_round_1: 29148,
    app_flat_1: 121,
    char_2: 'Sparkle',
    app_rate_2: 39.63,
    avg_round_2: 28846,
    app_flat_2: 85,
    char_3: 'Huohuo',
    app_rate_3: 34.38,
    avg_round_3: 29510,
    app_flat_3: 71,
    char_4: 'ruan-mei',
    app_rate_4: 32.28,
    avg_round_4: 31000,
    app_flat_4: 56,
    char_5: 'Robin',
    app_rate_5: 30.45,
    avg_round_5: 30784,
    app_flat_5: 51,
    char_6: 'fu-xuan',
    app_rate_6: 16.27,
    avg_round_6: 28140,
    app_flat_6: 32,
    char_7: 'Aventurine',
    app_rate_7: 14.7,
    avg_round_7: 28786,
    app_flat_7: 26,
    char_8: 'Jade',
    app_rate_8: 13.12,
    avg_round_8: 29762,
    app_flat_8: 22,
    char_9: 'Luocha',
    app_rate_9: 9.45,
    avg_round_9: 28392,
    app_flat_9: 16,
    char_10: 'Gallagher',
    app_rate_10: 8.66,
    avg_round_10: 26871,
    app_flat_10: 16,
    char_11: 'Jiaoqiu',
    app_rate_11: 8.4,
    avg_round_11: 31998,
    app_flat_11: 11,
    char_12: 'Himeko',
    app_rate_12: 7.35,
    avg_round_12: 28743,
    app_flat_12: 11,
    char_13: 'Herta',
    app_rate_13: 6.82,
    avg_round_13: 32801,
    app_flat_13: 9,
    char_14: 'Bronya',
    app_rate_14: 5.77,
    avg_round_14: 28892,
    app_flat_14: 8,
    char_15: 'Acheron',
    app_rate_15: 2.1,
    avg_round_15: 29766,
    app_flat_15: 4,
    char_16: 'Feixiao',
    app_rate_16: 1.31,
    avg_round_16: 32858,
    app_flat_16: 2,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 1.31,
    avg_round_17: 26878,
    app_flat_17: 2,
    char_18: 'Hanya',
    app_rate_18: 1.31,
    avg_round_18: 25715,
    app_flat_18: 3,
    char_19: 'Yunli',
    app_rate_19: 0.79,
    avg_round_19: 26065,
    app_flat_19: 1,
    char_20: 'Lynx',
    app_rate_20: 0.79,
    avg_round_20: 27626,
    app_flat_20: 1,
    char_21: 'jing-yuan',
    app_rate_21: 0.79,
    avg_round_21: 28170,
    app_flat_21: 1,
    char_22: 'Boothill',
    app_rate_22: 0.52,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: 0.52,
    avg_round_23: 31966,
    app_flat_23: 1,
    char_24: 'Lingsha',
    app_rate_24: 0.52,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: 0.52,
    avg_round_25: 32970,
    app_flat_25: 2,
    char_26: 'black-swan',
    app_rate_26: 0.26,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Kafka',
    app_rate_27: 0.26,
    avg_round_27: 30426,
    app_flat_27: 1,
    char_28: 'Seele',
    app_rate_28: 0.26,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Welt',
    app_rate_29: 0.26,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Qingque',
    app_rate_30: 0.26,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Sparkle',
    round: 29221,
    char_1: 'Acheron',
    app_rate_1: 50.68,
    avg_round_1: 31123,
    app_flat_1: 188,
    char_2: 'fu-xuan',
    app_rate_2: 44.35,
    avg_round_2: 29576,
    app_flat_2: 258,
    char_3: 'Jiaoqiu',
    app_rate_3: 30.25,
    avg_round_3: 33621,
    app_flat_3: 104,
    char_4: 'Aventurine',
    app_rate_4: 20.19,
    avg_round_4: 30182,
    app_flat_4: 119,
    char_5: 'Tingyun',
    app_rate_5: 19.88,
    avg_round_5: 28004,
    app_flat_5: 233,
    char_6: 'Huohuo',
    app_rate_6: 14.6,
    avg_round_6: 29343,
    app_flat_6: 132,
    char_7: 'jing-yuan',
    app_rate_7: 11.38,
    avg_round_7: 27102,
    app_flat_7: 120,
    char_8: 'Robin',
    app_rate_8: 10.8,
    avg_round_8: 28296,
    app_flat_8: 75,
    char_9: 'Pela',
    app_rate_9: 10.76,
    avg_round_9: 28499,
    app_flat_9: 49,
    char_10: 'Herta',
    app_rate_10: 9.28,
    avg_round_10: 30853,
    app_flat_10: 88,
    char_11: 'Yunli',
    app_rate_11: 8.7,
    avg_round_11: 29963,
    app_flat_11: 88,
    char_12: 'Jade',
    app_rate_12: 8.31,
    avg_round_12: 33667,
    app_flat_12: 85,
    char_13: 'Gallagher',
    app_rate_13: 6.45,
    avg_round_13: 28959,
    app_flat_13: 49,
    char_14: 'Argenti',
    app_rate_14: 5.86,
    avg_round_14: 28846,
    app_flat_14: 85,
    char_15: 'ruan-mei',
    app_rate_15: 5.79,
    avg_round_15: 30684,
    app_flat_15: 35,
    char_16: 'imbibitor-lunae',
    app_rate_16: 4.85,
    avg_round_16: 20290,
    app_flat_16: 4,
    char_17: 'Himeko',
    app_rate_17: 4.54,
    avg_round_17: 30278,
    app_flat_17: 38,
    char_18: 'Seele',
    app_rate_18: 4.5,
    avg_round_18: 27395,
    app_flat_18: 26,
    char_19: 'Kafka',
    app_rate_19: 4.19,
    avg_round_19: 27333,
    app_flat_19: 19,
    char_20: 'Luocha',
    app_rate_20: 4.12,
    avg_round_20: 27574,
    app_flat_20: 27,
    char_21: 'silver-wolf',
    app_rate_21: 3.11,
    avg_round_21: 24314,
    app_flat_21: 6,
    char_22: 'Gepard',
    app_rate_22: 2.02,
    avg_round_22: 30280,
    app_flat_22: 6,
    char_23: 'black-swan',
    app_rate_23: 1.98,
    avg_round_23: 22587,
    app_flat_23: 3,
    char_24: 'Clara',
    app_rate_24: 1.9,
    avg_round_24: 26897,
    app_flat_24: 24,
    char_25: 'Bronya',
    app_rate_25: 1.55,
    avg_round_25: 24608,
    app_flat_25: 9,
    char_26: 'Feixiao',
    app_rate_26: 1.4,
    avg_round_26: 31200,
    app_flat_26: 10,
    char_27: 'Lynx',
    app_rate_27: 1.01,
    avg_round_27: 26938,
    app_flat_27: 11,
    char_28: 'march-7th-swordmaster',
    app_rate_28: 0.97,
    avg_round_28: 26731,
    app_flat_28: 8,
    char_29: 'Serval',
    app_rate_29: 0.82,
    avg_round_29: 23122,
    app_flat_29: 8,
    char_30: 'Blade',
    app_rate_30: 0.74,
    avg_round_30: 28684,
    app_flat_30: 6
  },
  {
    char: 'Clara',
    round: 28951,
    char_1: 'Robin',
    app_rate_1: 76.69,
    avg_round_1: 29611,
    app_flat_1: 128,
    char_2: 'Aventurine',
    app_rate_2: 35.28,
    avg_round_2: 29886,
    app_flat_2: 61,
    char_3: 'Herta',
    app_rate_3: 23.01,
    avg_round_3: 29634,
    app_flat_3: 43,
    char_4: 'Huohuo',
    app_rate_4: 21.17,
    avg_round_4: 29321,
    app_flat_4: 39,
    char_5: 'fu-xuan',
    app_rate_5: 18.1,
    avg_round_5: 27344,
    app_flat_5: 28,
    char_6: 'Himeko',
    app_rate_6: 17.79,
    avg_round_6: 29024,
    app_flat_6: 35,
    char_7: 'Feixiao',
    app_rate_7: 15.95,
    avg_round_7: 31260,
    app_flat_7: 24,
    char_8: 'Sparkle',
    app_rate_8: 15.03,
    avg_round_8: 26897,
    app_flat_8: 24,
    char_9: 'Tingyun',
    app_rate_9: 11.35,
    avg_round_9: 25839,
    app_flat_9: 19,
    char_10: 'Luocha',
    app_rate_10: 10.12,
    avg_round_10: 28319,
    app_flat_10: 16,
    char_11: 'Yunli',
    app_rate_11: 8.9,
    avg_round_11: 27534,
    app_flat_11: 18,
    char_12: 'topaz',
    app_rate_12: 8.28,
    avg_round_12: 26849,
    app_flat_12: 9,
    char_13: 'Jade',
    app_rate_13: 7.98,
    avg_round_13: 28959,
    app_flat_13: 12,
    char_14: 'Gallagher',
    app_rate_14: 7.06,
    avg_round_14: 25260,
    app_flat_14: 12,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 6.75,
    avg_round_15: 28089,
    app_flat_15: 14,
    char_16: 'ruan-mei',
    app_rate_16: 5.83,
    avg_round_16: 27144,
    app_flat_16: 7,
    char_17: 'Lynx',
    app_rate_17: 2.15,
    avg_round_17: 26392,
    app_flat_17: 3,
    char_18: 'jing-yuan',
    app_rate_18: 1.53,
    avg_round_18: 23157,
    app_flat_18: 2,
    char_19: 'Jiaoqiu',
    app_rate_19: 1.23,
    avg_round_19: 30605,
    app_flat_19: 3,
    char_20: 'Bailu',
    app_rate_20: 0.92,
    avg_round_20: 22218,
    app_flat_20: 2,
    char_21: 'Kafka',
    app_rate_21: 0.92,
    avg_round_21: 23736,
    app_flat_21: 1,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.61,
    avg_round_22: 21263,
    app_flat_22: 1,
    char_23: 'imbibitor-lunae',
    app_rate_23: 0.61,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Acheron',
    app_rate_24: 0.61,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'black-swan',
    app_rate_25: 0.61,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Bronya',
    app_rate_26: 0.31,
    avg_round_26: 24010,
    app_flat_26: 1,
    char_27: 'Lingsha',
    app_rate_27: 0.31,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Seele',
    app_rate_28: 0.31,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Boothill',
    app_rate_29: 0.31,
    avg_round_29: 25265,
    app_flat_29: 1,
    char_30: 'Blade',
    app_rate_30: 0.31,
    avg_round_30: 21554,
    app_flat_30: 1
  },
  {
    char: 'Tingyun',
    round: 28864,
    char_1: 'Sparkle',
    app_rate_1: 48.76,
    avg_round_1: 28004,
    app_flat_1: 233,
    char_2: 'Huohuo',
    app_rate_2: 38.67,
    avg_round_2: 29832,
    app_flat_2: 193,
    char_3: 'Robin',
    app_rate_3: 30.57,
    avg_round_3: 30299,
    app_flat_3: 155,
    char_4: 'Yunli',
    app_rate_4: 29.52,
    avg_round_4: 30512,
    app_flat_4: 157,
    char_5: 'fu-xuan',
    app_rate_5: 25.62,
    avg_round_5: 27171,
    app_flat_5: 106,
    char_6: 'Argenti',
    app_rate_6: 21.9,
    avg_round_6: 29148,
    app_flat_6: 121,
    char_7: 'jing-yuan',
    app_rate_7: 19.43,
    avg_round_7: 26590,
    app_flat_7: 96,
    char_8: 'ruan-mei',
    app_rate_8: 12.0,
    avg_round_8: 30885,
    app_flat_8: 53,
    char_9: 'Aventurine',
    app_rate_9: 11.14,
    avg_round_9: 28072,
    app_flat_9: 62,
    char_10: 'Luocha',
    app_rate_10: 7.33,
    avg_round_10: 28348,
    app_flat_10: 39,
    char_11: 'Gallagher',
    app_rate_11: 6.38,
    avg_round_11: 28257,
    app_flat_11: 36,
    char_12: 'Seele',
    app_rate_12: 5.9,
    avg_round_12: 27648,
    app_flat_12: 22,
    char_13: 'imbibitor-lunae',
    app_rate_13: 5.71,
    avg_round_13: 21018,
    app_flat_13: 2,
    char_14: 'Herta',
    app_rate_14: 5.33,
    avg_round_14: 29054,
    app_flat_14: 28,
    char_15: 'Jade',
    app_rate_15: 4.0,
    avg_round_15: 32668,
    app_flat_15: 20,
    char_16: 'Kafka',
    app_rate_16: 3.71,
    avg_round_16: 26167,
    app_flat_16: 14,
    char_17: 'Himeko',
    app_rate_17: 3.52,
    avg_round_17: 29147,
    app_flat_17: 20,
    char_18: 'Clara',
    app_rate_18: 3.52,
    avg_round_18: 25839,
    app_flat_18: 19,
    char_19: 'Bronya',
    app_rate_19: 2.29,
    avg_round_19: 27642,
    app_flat_19: 11,
    char_20: 'black-swan',
    app_rate_20: 2.1,
    avg_round_20: 24222,
    app_flat_20: 6,
    char_21: 'Serval',
    app_rate_21: 2.1,
    avg_round_21: 23235,
    app_flat_21: 11,
    char_22: 'Jiaoqiu',
    app_rate_22: 1.71,
    avg_round_22: 33659,
    app_flat_22: 9,
    char_23: 'Lynx',
    app_rate_23: 1.62,
    avg_round_23: 29141,
    app_flat_23: 9,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 1.24,
    avg_round_24: 25210,
    app_flat_24: 4,
    char_25: 'Bailu',
    app_rate_25: 1.14,
    avg_round_25: 25084,
    app_flat_25: 5,
    char_26: 'Acheron',
    app_rate_26: 0.67,
    avg_round_26: 32720,
    app_flat_26: 4,
    char_27: 'Lingsha',
    app_rate_27: 0.67,
    avg_round_27: 37490,
    app_flat_27: 3,
    char_28: 'Feixiao',
    app_rate_28: 0.57,
    avg_round_28: 32203,
    app_flat_28: 1,
    char_29: 'Hanya',
    app_rate_29: 0.57,
    avg_round_29: 25568,
    app_flat_29: 4,
    char_30: 'Xueyi',
    app_rate_30: 0.48,
    avg_round_30: 23650,
    app_flat_30: 2
  },
  {
    char: 'jing-yuan',
    round: 28802,
    char_1: 'Sparkle',
    app_rate_1: 51.4,
    avg_round_1: 27102,
    app_flat_1: 120,
    char_2: 'Robin',
    app_rate_2: 41.75,
    avg_round_2: 29951,
    app_flat_2: 105,
    char_3: 'fu-xuan',
    app_rate_3: 40.18,
    avg_round_3: 28636,
    app_flat_3: 92,
    char_4: 'Tingyun',
    app_rate_4: 35.79,
    avg_round_4: 26590,
    app_flat_4: 96,
    char_5: 'Jade',
    app_rate_5: 30.35,
    avg_round_5: 32893,
    app_flat_5: 76,
    char_6: 'Aventurine',
    app_rate_6: 28.77,
    avg_round_6: 30378,
    app_flat_6: 80,
    char_7: 'Huohuo',
    app_rate_7: 19.12,
    avg_round_7: 26823,
    app_flat_7: 40,
    char_8: 'ruan-mei',
    app_rate_8: 12.81,
    avg_round_8: 30997,
    app_flat_8: 21,
    char_9: 'Herta',
    app_rate_9: 12.46,
    avg_round_9: 27773,
    app_flat_9: 37,
    char_10: 'Luocha',
    app_rate_10: 5.96,
    avg_round_10: 27905,
    app_flat_10: 14,
    char_11: 'Bronya',
    app_rate_11: 3.33,
    avg_round_11: 26340,
    app_flat_11: 5,
    char_12: 'Gallagher',
    app_rate_12: 2.98,
    avg_round_12: 27258,
    app_flat_12: 7,
    char_13: 'Acheron',
    app_rate_13: 1.93,
    avg_round_13: 31137,
    app_flat_13: 6,
    char_14: 'Yunli',
    app_rate_14: 1.93,
    avg_round_14: 29421,
    app_flat_14: 7,
    char_15: 'Feixiao',
    app_rate_15: 1.93,
    avg_round_15: 32155,
    app_flat_15: 3,
    char_16: 'Jiaoqiu',
    app_rate_16: 1.4,
    avg_round_16: 37170,
    app_flat_16: 2,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 1.05,
    avg_round_17: 24115,
    app_flat_17: 1,
    char_18: 'Bailu',
    app_rate_18: 1.05,
    avg_round_18: 26078,
    app_flat_18: 3,
    char_19: 'Xueyi',
    app_rate_19: 1.05,
    avg_round_19: 22234,
    app_flat_19: 1,
    char_20: 'Clara',
    app_rate_20: 0.88,
    avg_round_20: 23157,
    app_flat_20: 2,
    char_21: 'Asta',
    app_rate_21: 0.7,
    avg_round_21: 22677,
    app_flat_21: 1,
    char_22: 'Pela',
    app_rate_22: 0.7,
    avg_round_22: 24276,
    app_flat_22: 2,
    char_23: 'Kafka',
    app_rate_23: 0.53,
    avg_round_23: 30665,
    app_flat_23: 1,
    char_24: 'Lynx',
    app_rate_24: 0.53,
    avg_round_24: 27852,
    app_flat_24: 2,
    char_25: 'Argenti',
    app_rate_25: 0.53,
    avg_round_25: 28170,
    app_flat_25: 1,
    char_26: 'black-swan',
    app_rate_26: 0.35,
    avg_round_26: 30665,
    app_flat_26: 1,
    char_27: 'Lingsha',
    app_rate_27: 0.35,
    avg_round_27: 30971,
    app_flat_27: 2,
    char_28: 'Hanya',
    app_rate_28: 0.18,
    avg_round_28: 20768,
    app_flat_28: 1,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    round: 28541,
    char_1: 'Acheron',
    app_rate_1: 95.65,
    avg_round_1: 28481,
    app_flat_1: 107,
    char_2: 'fu-xuan',
    app_rate_2: 55.18,
    avg_round_2: 29331,
    app_flat_2: 50,
    char_3: 'Pela',
    app_rate_3: 32.78,
    avg_round_3: 26052,
    app_flat_3: 50,
    char_4: 'Sparkle',
    app_rate_4: 26.76,
    avg_round_4: 24314,
    app_flat_4: 6,
    char_5: 'Jiaoqiu',
    app_rate_5: 23.75,
    avg_round_5: 32236,
    app_flat_5: 35,
    char_6: 'Aventurine',
    app_rate_6: 19.73,
    avg_round_6: 27432,
    app_flat_6: 26,
    char_7: 'Kafka',
    app_rate_7: 9.36,
    avg_round_7: 29135,
    app_flat_7: 13,
    char_8: 'Gepard',
    app_rate_8: 8.36,
    avg_round_8: 29312,
    app_flat_8: 9,
    char_9: 'Huohuo',
    app_rate_9: 3.68,
    avg_round_9: 29981,
    app_flat_9: 5,
    char_10: 'Robin',
    app_rate_10: 3.34,
    avg_round_10: 29109,
    app_flat_10: 5,
    char_11: 'ruan-mei',
    app_rate_11: 3.01,
    avg_round_11: 33425,
    app_flat_11: 1,
    char_12: 'black-swan',
    app_rate_12: 2.68,
    avg_round_12: 28476,
    app_flat_12: 2,
    char_13: 'Yunli',
    app_rate_13: 2.34,
    avg_round_13: 30908,
    app_flat_13: 5,
    char_14: 'Luocha',
    app_rate_14: 2.34,
    avg_round_14: 27672,
    app_flat_14: 4,
    char_15: 'Seele',
    app_rate_15: 2.01,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'trailblazer-fire',
    app_rate_16: 2.01,
    avg_round_16: 25236,
    app_flat_16: 4,
    char_17: 'Gallagher',
    app_rate_17: 2.01,
    avg_round_17: 30070,
    app_flat_17: 4,
    char_18: 'Bronya',
    app_rate_18: 1.67,
    avg_round_18: 25078,
    app_flat_18: 2,
    char_19: 'Jade',
    app_rate_19: 1.0,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Guinaifen',
    app_rate_20: 0.33,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Feixiao',
    app_rate_21: 0.33,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: 0.33,
    avg_round_22: 30636,
    app_flat_22: 1,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.33,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Qingque',
    app_rate_24: 0.33,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Tingyun',
    app_rate_25: 0.33,
    avg_round_25: 28679,
    app_flat_25: 1,
    char_26: 'Xueyi',
    app_rate_26: 0.33,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    round: 28531,
    char_1: 'Kafka',
    app_rate_1: 40.0,
    avg_round_1: 26968,
    app_flat_1: 15,
    char_2: 'Robin',
    app_rate_2: 32.63,
    avg_round_2: 30461,
    app_flat_2: 19,
    char_3: 'black-swan',
    app_rate_3: 26.32,
    avg_round_3: 27739,
    app_flat_3: 9,
    char_4: 'Acheron',
    app_rate_4: 24.21,
    avg_round_4: 30811,
    app_flat_4: 9,
    char_5: 'ruan-mei',
    app_rate_5: 23.16,
    avg_round_5: 29184,
    app_flat_5: 8,
    char_6: 'Jade',
    app_rate_6: 21.05,
    avg_round_6: 35141,
    app_flat_6: 13,
    char_7: 'Herta',
    app_rate_7: 20.0,
    avg_round_7: 32093,
    app_flat_7: 13,
    char_8: 'Jiaoqiu',
    app_rate_8: 13.68,
    avg_round_8: 33819,
    app_flat_8: 5,
    char_9: 'Tingyun',
    app_rate_9: 12.63,
    avg_round_9: 25084,
    app_flat_9: 5,
    char_10: 'Feixiao',
    app_rate_10: 9.47,
    avg_round_10: 27658,
    app_flat_10: 6,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 8.42,
    avg_round_11: 26378,
    app_flat_11: 2,
    char_12: 'Yunli',
    app_rate_12: 8.42,
    avg_round_12: 26397,
    app_flat_12: 6,
    char_13: 'Sparkle',
    app_rate_13: 8.42,
    avg_round_13: 22842,
    app_flat_13: 4,
    char_14: 'Firefly',
    app_rate_14: 7.37,
    avg_round_14: 26745,
    app_flat_14: 1,
    char_15: 'jing-yuan',
    app_rate_15: 6.32,
    avg_round_15: 26078,
    app_flat_15: 3,
    char_16: 'Himeko',
    app_rate_16: 6.32,
    avg_round_16: 29875,
    app_flat_16: 6,
    char_17: 'Pela',
    app_rate_17: 5.26,
    avg_round_17: 33778,
    app_flat_17: 3,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 5.26,
    avg_round_18: 21610,
    app_flat_18: 4,
    char_19: 'Bronya',
    app_rate_19: 4.21,
    avg_round_19: 31250,
    app_flat_19: 4,
    char_20: 'Clara',
    app_rate_20: 3.16,
    avg_round_20: 22218,
    app_flat_20: 2,
    char_21: 'Serval',
    app_rate_21: 3.16,
    avg_round_21: 20629,
    app_flat_21: 2,
    char_22: 'Seele',
    app_rate_22: 3.16,
    avg_round_22: 20000,
    app_flat_22: 1,
    char_23: 'Argenti',
    app_rate_23: 2.11,
    avg_round_23: 32970,
    app_flat_23: 2,
    char_24: 'topaz',
    app_rate_24: 2.11,
    avg_round_24: 24435,
    app_flat_24: 1,
    char_25: 'imbibitor-lunae',
    app_rate_25: 1.05,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Blade',
    app_rate_26: 1.05,
    avg_round_26: 32175,
    app_flat_26: 1,
    char_27: 'Xueyi',
    app_rate_27: 1.05,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    round: 27726,
    char_1: 'Himeko',
    app_rate_1: 48.15,
    avg_round_1: 29175,
    app_flat_1: 20,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 30.86,
    avg_round_2: 26714,
    app_flat_2: 14,
    char_3: 'Gallagher',
    app_rate_3: 28.4,
    avg_round_3: 26385,
    app_flat_3: 13,
    char_4: 'Firefly',
    app_rate_4: 24.69,
    avg_round_4: 25110,
    app_flat_4: 7,
    char_5: 'ruan-mei',
    app_rate_5: 19.75,
    avg_round_5: 30966,
    app_flat_5: 8,
    char_6: 'Herta',
    app_rate_6: 14.81,
    avg_round_6: 27450,
    app_flat_6: 8,
    char_7: 'Aventurine',
    app_rate_7: 14.81,
    avg_round_7: 26748,
    app_flat_7: 6,
    char_8: 'Kafka',
    app_rate_8: 13.58,
    avg_round_8: 22062,
    app_flat_8: 2,
    char_9: 'Huohuo',
    app_rate_9: 11.11,
    avg_round_9: 25012,
    app_flat_9: 2,
    char_10: 'black-swan',
    app_rate_10: 9.88,
    avg_round_10: 22062,
    app_flat_10: 2,
    char_11: 'Jade',
    app_rate_11: 8.64,
    avg_round_11: 25568,
    app_flat_11: 4,
    char_12: 'topaz',
    app_rate_12: 8.64,
    avg_round_12: 22117,
    app_flat_12: 1,
    char_13: 'Lingsha',
    app_rate_13: 7.41,
    avg_round_13: 24773,
    app_flat_13: 1,
    char_14: 'Jiaoqiu',
    app_rate_14: 7.41,
    avg_round_14: 28912,
    app_flat_14: 2,
    char_15: 'fu-xuan',
    app_rate_15: 7.41,
    avg_round_15: 26429,
    app_flat_15: 1,
    char_16: 'Sparkle',
    app_rate_16: 4.94,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'jing-yuan',
    app_rate_17: 4.94,
    avg_round_17: 22677,
    app_flat_17: 1,
    char_18: 'Tingyun',
    app_rate_18: 4.94,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Robin',
    app_rate_19: 4.94,
    avg_round_19: 30932,
    app_flat_19: 2,
    char_20: 'Luocha',
    app_rate_20: 3.7,
    avg_round_20: 27695,
    app_flat_20: 1,
    char_21: 'Acheron',
    app_rate_21: 3.7,
    avg_round_21: 30418,
    app_flat_21: 1,
    char_22: 'Argenti',
    app_rate_22: 2.47,
    avg_round_22: 31966,
    app_flat_22: 1,
    char_23: 'Bronya',
    app_rate_23: 2.47,
    avg_round_23: 24233,
    app_flat_23: 1,
    char_24: 'Serval',
    app_rate_24: 2.47,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Pela',
    app_rate_25: 1.23,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'trailblazer-fire',
    app_rate_26: 1.23,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Sampo',
    app_rate_27: 1.23,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'imbibitor-lunae',
    app_rate_28: 1.23,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Yunli',
    app_rate_29: 1.23,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Natasha',
    app_rate_30: 1.23,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    round: 27506,
    char_1: 'Acheron',
    app_rate_1: 38.57,
    avg_round_1: 24384,
    app_flat_1: 12,
    char_2: 'ruan-mei',
    app_rate_2: 26.43,
    avg_round_2: 28062,
    app_flat_2: 14,
    char_3: 'fu-xuan',
    app_rate_3: 24.76,
    avg_round_3: 26320,
    app_flat_3: 12,
    char_4: 'Firefly',
    app_rate_4: 21.67,
    avg_round_4: 23395,
    app_flat_4: 9,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 20.71,
    avg_round_5: 23185,
    app_flat_5: 11,
    char_6: 'Jiaoqiu',
    app_rate_6: 18.1,
    avg_round_6: 38445,
    app_flat_6: 2,
    char_7: 'Pela',
    app_rate_7: 15.0,
    avg_round_7: 23177,
    app_flat_7: 9,
    char_8: 'Gallagher',
    app_rate_8: 12.38,
    avg_round_8: 26546,
    app_flat_8: 16,
    char_9: 'Luocha',
    app_rate_9: 11.43,
    avg_round_9: 28308,
    app_flat_9: 11,
    char_10: 'Aventurine',
    app_rate_10: 10.95,
    avg_round_10: 27938,
    app_flat_10: 13,
    char_11: 'Jade',
    app_rate_11: 10.95,
    avg_round_11: 33166,
    app_flat_11: 21,
    char_12: 'Robin',
    app_rate_12: 10.71,
    avg_round_12: 33135,
    app_flat_12: 9,
    char_13: 'Sparkle',
    app_rate_13: 9.52,
    avg_round_13: 24608,
    app_flat_13: 9,
    char_14: 'Herta',
    app_rate_14: 6.67,
    avg_round_14: 32099,
    app_flat_14: 14,
    char_15: 'Blade',
    app_rate_15: 6.67,
    avg_round_15: 30069,
    app_flat_15: 9,
    char_16: 'Tingyun',
    app_rate_16: 5.71,
    avg_round_16: 27642,
    app_flat_16: 11,
    char_17: 'Argenti',
    app_rate_17: 5.24,
    avg_round_17: 28892,
    app_flat_17: 8,
    char_18: 'Himeko',
    app_rate_18: 5.24,
    avg_round_18: 28856,
    app_flat_18: 5,
    char_19: 'Huohuo',
    app_rate_19: 5.0,
    avg_round_19: 28140,
    app_flat_19: 2,
    char_20: 'jing-yuan',
    app_rate_20: 4.52,
    avg_round_20: 26340,
    app_flat_20: 5,
    char_21: 'Feixiao',
    app_rate_21: 3.81,
    avg_round_21: 31098,
    app_flat_21: 6,
    char_22: 'Gepard',
    app_rate_22: 2.86,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 2.38,
    avg_round_23: 28791,
    app_flat_23: 4,
    char_24: 'imbibitor-lunae',
    app_rate_24: 2.14,
    avg_round_24: 20035,
    app_flat_24: 1,
    char_25: 'Seele',
    app_rate_25: 2.14,
    avg_round_25: 25260,
    app_flat_25: 3,
    char_26: 'Jingliu',
    app_rate_26: 1.9,
    avg_round_26: 21115,
    app_flat_26: 1,
    char_27: 'Kafka',
    app_rate_27: 1.9,
    avg_round_27: 23264,
    app_flat_27: 2,
    char_28: 'black-swan',
    app_rate_28: 1.9,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Lingsha',
    app_rate_29: 1.43,
    avg_round_29: 40000,
    app_flat_29: 1,
    char_30: 'Serval',
    app_rate_30: 1.43,
    avg_round_30: 21459,
    app_flat_30: 2
  },
  {
    char: 'Seele',
    round: 27391,
    char_1: 'Sparkle',
    app_rate_1: 75.32,
    avg_round_1: 27395,
    app_flat_1: 26,
    char_2: 'fu-xuan',
    app_rate_2: 68.83,
    avg_round_2: 26699,
    app_flat_2: 24,
    char_3: 'Tingyun',
    app_rate_3: 40.26,
    avg_round_3: 27648,
    app_flat_3: 22,
    char_4: 'Robin',
    app_rate_4: 35.06,
    avg_round_4: 27050,
    app_flat_4: 8,
    char_5: 'Herta',
    app_rate_5: 12.34,
    avg_round_5: 26930,
    app_flat_5: 2,
    char_6: 'Jade',
    app_rate_6: 8.44,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Huohuo',
    app_rate_7: 8.44,
    avg_round_7: 27830,
    app_flat_7: 2,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 7.14,
    avg_round_8: 20000,
    app_flat_8: 1,
    char_9: 'Bronya',
    app_rate_9: 5.84,
    avg_round_9: 25260,
    app_flat_9: 3,
    char_10: 'Aventurine',
    app_rate_10: 5.84,
    avg_round_10: 30158,
    app_flat_10: 3,
    char_11: 'Gallagher',
    app_rate_11: 4.55,
    avg_round_11: 29340,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: 3.9,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Feixiao',
    app_rate_13: 3.9,
    avg_round_13: 29148,
    app_flat_13: 2,
    char_14: 'ruan-mei',
    app_rate_14: 3.9,
    avg_round_14: 26192,
    app_flat_14: 1,
    char_15: 'Yunli',
    app_rate_15: 1.95,
    avg_round_15: 31215,
    app_flat_15: 2,
    char_16: 'Kafka',
    app_rate_16: 1.95,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Lynx',
    app_rate_17: 1.95,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: 1.95,
    avg_round_18: 20000,
    app_flat_18: 1,
    char_19: 'Pela',
    app_rate_19: 1.3,
    avg_round_19: 21389,
    app_flat_19: 1,
    char_20: 'Acheron',
    app_rate_20: 1.3,
    avg_round_20: 21389,
    app_flat_20: 1,
    char_21: 'Luocha',
    app_rate_21: 1.3,
    avg_round_21: 35949,
    app_flat_21: 2,
    char_22: 'black-swan',
    app_rate_22: 0.65,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Clara',
    app_rate_23: 0.65,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Himeko',
    app_rate_24: 0.65,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Argenti',
    app_rate_25: 0.65,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Xueyi',
    app_rate_26: 0.65,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Sampo',
    app_rate_27: 0.65,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Jiaoqiu',
    app_rate_28: 0.65,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    round: 25799,
    char_1: 'Acheron',
    app_rate_1: 74.24,
    avg_round_1: 25937,
    app_flat_1: 28,
    char_2: 'fu-xuan',
    app_rate_2: 28.79,
    avg_round_2: 24980,
    app_flat_2: 6,
    char_3: 'Pela',
    app_rate_3: 27.27,
    avg_round_3: 24417,
    app_flat_3: 12,
    char_4: 'Gallagher',
    app_rate_4: 27.27,
    avg_round_4: 24531,
    app_flat_4: 14,
    char_5: 'Kafka',
    app_rate_5: 22.73,
    avg_round_5: 25597,
    app_flat_5: 8,
    char_6: 'ruan-mei',
    app_rate_6: 22.73,
    avg_round_6: 25156,
    app_flat_6: 6,
    char_7: 'Jiaoqiu',
    app_rate_7: 19.7,
    avg_round_7: 29213,
    app_flat_7: 7,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 16.67,
    avg_round_8: 24395,
    app_flat_8: 6,
    char_9: 'Aventurine',
    app_rate_9: 10.61,
    avg_round_9: 28076,
    app_flat_9: 5,
    char_10: 'Gepard',
    app_rate_10: 10.61,
    avg_round_10: 26662,
    app_flat_10: 4,
    char_11: 'Huohuo',
    app_rate_11: 10.61,
    avg_round_11: 23731,
    app_flat_11: 3,
    char_12: 'black-swan',
    app_rate_12: 9.09,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Himeko',
    app_rate_13: 7.58,
    avg_round_13: 27558,
    app_flat_13: 2,
    char_14: 'Bronya',
    app_rate_14: 3.03,
    avg_round_14: 23966,
    app_flat_14: 1,
    char_15: 'Sparkle',
    app_rate_15: 3.03,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Jade',
    app_rate_16: 3.03,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Firefly',
    app_rate_17: 1.52,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'silver-wolf',
    app_rate_18: 1.52,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    round: 25679,
    char_1: 'Robin',
    app_rate_1: 38.03,
    avg_round_1: 27553,
    app_flat_1: 14,
    char_2: 'fu-xuan',
    app_rate_2: 32.39,
    avg_round_2: 26020,
    app_flat_2: 5,
    char_3: 'ruan-mei',
    app_rate_3: 25.35,
    avg_round_3: 26167,
    app_flat_3: 6,
    char_4: 'Jade',
    app_rate_4: 25.35,
    avg_round_4: 30336,
    app_flat_4: 5,
    char_5: 'Herta',
    app_rate_5: 23.94,
    avg_round_5: 26474,
    app_flat_5: 10,
    char_6: 'Aventurine',
    app_rate_6: 23.94,
    avg_round_6: 29700,
    app_flat_6: 8,
    char_7: 'Sparkle',
    app_rate_7: 23.94,
    avg_round_7: 25737,
    app_flat_7: 3,
    char_8: 'Huohuo',
    app_rate_8: 11.27,
    avg_round_8: 27657,
    app_flat_8: 3,
    char_9: 'Acheron',
    app_rate_9: 11.27,
    avg_round_9: 24093,
    app_flat_9: 3,
    char_10: 'Kafka',
    app_rate_10: 9.86,
    avg_round_10: 23985,
    app_flat_10: 6,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 8.45,
    avg_round_11: 24004,
    app_flat_11: 3,
    char_12: 'jing-yuan',
    app_rate_12: 8.45,
    avg_round_12: 22234,
    app_flat_12: 1,
    char_13: 'Tingyun',
    app_rate_13: 7.04,
    avg_round_13: 23650,
    app_flat_13: 2,
    char_14: 'Serval',
    app_rate_14: 5.63,
    avg_round_14: 22209,
    app_flat_14: 2,
    char_15: 'Luocha',
    app_rate_15: 5.63,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Yunli',
    app_rate_16: 5.63,
    avg_round_16: 32665,
    app_flat_16: 1,
    char_17: 'Firefly',
    app_rate_17: 2.82,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Feixiao',
    app_rate_18: 2.82,
    avg_round_18: 30760,
    app_flat_18: 1,
    char_19: 'black-swan',
    app_rate_19: 2.82,
    avg_round_19: 22591,
    app_flat_19: 1,
    char_20: 'Qingque',
    app_rate_20: 2.82,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Lingsha',
    app_rate_21: 2.82,
    avg_round_21: 26155,
    app_flat_21: 1,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 2.82,
    avg_round_22: 25312,
    app_flat_22: 1,
    char_23: 'Gallagher',
    app_rate_23: 2.82,
    avg_round_23: 29570,
    app_flat_23: 2,
    char_24: 'Bronya',
    app_rate_24: 2.82,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'topaz',
    app_rate_25: 1.41,
    avg_round_25: 23406,
    app_flat_25: 1,
    char_26: 'march-7th',
    app_rate_26: 1.41,
    avg_round_26: 24568,
    app_flat_26: 1,
    char_27: 'Jiaoqiu',
    app_rate_27: 1.41,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Seele',
    app_rate_28: 1.41,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Bailu',
    app_rate_29: 1.41,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Pela',
    app_rate_30: 1.41,
    avg_round_30: 23597,
    app_flat_30: 1
  },
  {
    char: 'Serval',
    round: 24992,
    char_1: 'fu-xuan',
    app_rate_1: 41.76,
    avg_round_1: 23325,
    app_flat_1: 12,
    char_2: 'Robin',
    app_rate_2: 30.77,
    avg_round_2: 26761,
    app_flat_2: 12,
    char_3: 'Kafka',
    app_rate_3: 26.37,
    avg_round_3: 25783,
    app_flat_3: 10,
    char_4: 'ruan-mei',
    app_rate_4: 26.37,
    avg_round_4: 25304,
    app_flat_4: 14,
    char_5: 'Tingyun',
    app_rate_5: 24.18,
    avg_round_5: 23235,
    app_flat_5: 11,
    char_6: 'Sparkle',
    app_rate_6: 23.08,
    avg_round_6: 23122,
    app_flat_6: 8,
    char_7: 'Acheron',
    app_rate_7: 18.68,
    avg_round_7: 26877,
    app_flat_7: 11,
    char_8: 'Herta',
    app_rate_8: 15.38,
    avg_round_8: 23009,
    app_flat_8: 8,
    char_9: 'Huohuo',
    app_rate_9: 15.38,
    avg_round_9: 27688,
    app_flat_9: 5,
    char_10: 'Jade',
    app_rate_10: 10.99,
    avg_round_10: 26612,
    app_flat_10: 3,
    char_11: 'Aventurine',
    app_rate_11: 9.89,
    avg_round_11: 28008,
    app_flat_11: 6,
    char_12: 'Pela',
    app_rate_12: 6.59,
    avg_round_12: 25434,
    app_flat_12: 5,
    char_13: 'Bronya',
    app_rate_13: 6.59,
    avg_round_13: 21459,
    app_flat_13: 2,
    char_14: 'black-swan',
    app_rate_14: 5.49,
    avg_round_14: 22232,
    app_flat_14: 2,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 5.49,
    avg_round_15: 20468,
    app_flat_15: 4,
    char_16: 'Gallagher',
    app_rate_16: 5.49,
    avg_round_16: 20393,
    app_flat_16: 5,
    char_17: 'Xueyi',
    app_rate_17: 4.4,
    avg_round_17: 22209,
    app_flat_17: 2,
    char_18: 'Luocha',
    app_rate_18: 3.3,
    avg_round_18: 20163,
    app_flat_18: 1,
    char_19: 'Gepard',
    app_rate_19: 3.3,
    avg_round_19: 29695,
    app_flat_19: 1,
    char_20: 'Bailu',
    app_rate_20: 3.3,
    avg_round_20: 20629,
    app_flat_20: 2,
    char_21: 'Qingque',
    app_rate_21: 2.2,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: 2.2,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: 2.2,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'trailblazer-fire',
    app_rate_24: 1.1,
    avg_round_24: 31912,
    app_flat_24: 1,
    char_25: 'march-7th-swordmaster',
    app_rate_25: 1.1,
    avg_round_25: 23653,
    app_flat_25: 1,
    char_26: 'Yunli',
    app_rate_26: 1.1,
    avg_round_26: 27850,
    app_flat_26: 1,
    char_27: 'Jingliu',
    app_rate_27: 1.1,
    avg_round_27: 21213,
    app_flat_27: 1,
    char_28: 'Lynx',
    app_rate_28: 1.1,
    avg_round_28: 25542,
    app_flat_28: 1,
    char_29: 'Firefly',
    app_rate_29: 1.1,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    round: 0,
    char_1: 'Sparkle',
    app_rate_1: 85.03,
    avg_round_1: 20290,
    app_flat_1: 4,
    char_2: 'Tingyun',
    app_rate_2: 40.82,
    avg_round_2: 21018,
    app_flat_2: 2,
    char_3: 'Huohuo',
    app_rate_3: 36.05,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: 27.21,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Luocha',
    app_rate_5: 21.09,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Robin',
    app_rate_6: 19.73,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Aventurine',
    app_rate_7: 16.33,
    avg_round_7: 20860,
    app_flat_7: 3,
    char_8: 'Gallagher',
    app_rate_8: 13.61,
    avg_round_8: 20035,
    app_flat_8: 1,
    char_9: 'Himeko',
    app_rate_9: 8.16,
    avg_round_9: 20544,
    app_flat_9: 1,
    char_10: 'Bronya',
    app_rate_10: 6.12,
    avg_round_10: 20035,
    app_flat_10: 1,
    char_11: 'fu-xuan',
    app_rate_11: 5.44,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Jiaoqiu',
    app_rate_12: 4.76,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Herta',
    app_rate_13: 2.72,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 2.72,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 2.04,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Clara',
    app_rate_16: 1.36,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Yunli',
    app_rate_17: 1.36,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Yukong',
    app_rate_18: 1.36,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Firefly',
    app_rate_19: 0.68,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Pela',
    app_rate_20: 0.68,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Bailu',
    app_rate_21: 0.68,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Boothill',
    app_rate_22: 0.68,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: 0.68,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Hanya',
    app_rate_24: 0.68,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    round: 0,
    char_1: 'Acheron',
    app_rate_1: 65.71,
    avg_round_1: 28874,
    app_flat_1: 9,
    char_2: 'Sparkle',
    app_rate_2: 37.14,
    avg_round_2: 31116,
    app_flat_2: 3,
    char_3: 'Pela',
    app_rate_3: 28.57,
    avg_round_3: 28829,
    app_flat_3: 6,
    char_4: 'fu-xuan',
    app_rate_4: 22.86,
    avg_round_4: 27218,
    app_flat_4: 2,
    char_5: 'ruan-mei',
    app_rate_5: 22.86,
    avg_round_5: 25566,
    app_flat_5: 4,
    char_6: 'Gallagher',
    app_rate_6: 20.0,
    avg_round_6: 23046,
    app_flat_6: 4,
    char_7: 'Himeko',
    app_rate_7: 17.14,
    avg_round_7: 26074,
    app_flat_7: 3,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 17.14,
    avg_round_8: 23189,
    app_flat_8: 4,
    char_9: 'Jiaoqiu',
    app_rate_9: 17.14,
    avg_round_9: 30309,
    app_flat_9: 4,
    char_10: 'Luocha',
    app_rate_10: 11.43,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Bronya',
    app_rate_11: 8.57,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 5.71,
    avg_round_12: 20051,
    app_flat_12: 1,
    char_13: 'Aventurine',
    app_rate_13: 5.71,
    avg_round_13: 33471,
    app_flat_13: 1,
    char_14: 'Robin',
    app_rate_14: 5.71,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Gepard',
    app_rate_15: 5.71,
    avg_round_15: 30517,
    app_flat_15: 1,
    char_16: 'trailblazer-fire',
    app_rate_16: 2.86,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Argenti',
    app_rate_17: 2.86,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Firefly',
    app_rate_18: 2.86,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    round: 0,
    char_1: 'Aventurine',
    app_rate_1: 82.61,
    avg_round_1: 29263,
    app_flat_1: 1,
    char_2: 'topaz',
    app_rate_2: 78.26,
    avg_round_2: 24433,
    app_flat_2: 1,
    char_3: 'Robin',
    app_rate_3: 69.57,
    avg_round_3: 29263,
    app_flat_3: 1,
    char_4: 'Himeko',
    app_rate_4: 17.39,
    avg_round_4: 29263,
    app_flat_4: 1,
    char_5: 'ruan-mei',
    app_rate_5: 8.7,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Feixiao',
    app_rate_6: 4.35,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Herta',
    app_rate_7: 4.35,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Pela',
    app_rate_8: 4.35,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Tingyun',
    app_rate_9: 4.35,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Acheron',
    app_rate_10: 4.35,
    avg_round_10: 24433,
    app_flat_10: 1,
    char_11: 'Gallagher',
    app_rate_11: 4.35,
    avg_round_11: 24433,
    app_flat_11: 1,
    char_12: 'Yunli',
    app_rate_12: 4.35,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Jiaoqiu',
    app_rate_13: 4.35,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Huohuo',
    app_rate_14: 4.35,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Firefly',
    app_rate_15: 4.35,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Moze',
    round: 0,
    char_1: 'Aventurine',
    app_rate_1: 77.78,
    avg_round_1: 25857,
    app_flat_1: 4,
    char_2: 'Feixiao',
    app_rate_2: 61.11,
    avg_round_2: 25017,
    app_flat_2: 3,
    char_3: 'Robin',
    app_rate_3: 55.56,
    avg_round_3: 25857,
    app_flat_3: 4,
    char_4: 'Jade',
    app_rate_4: 16.67,
    avg_round_4: 28455,
    app_flat_4: 1,
    char_5: 'Yunli',
    app_rate_5: 16.67,
    avg_round_5: 28455,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: 11.11,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Herta',
    app_rate_7: 11.11,
    avg_round_7: 25793,
    app_flat_7: 1,
    char_8: 'Sparkle',
    app_rate_8: 5.56,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Huohuo',
    app_rate_9: 5.56,
    avg_round_9: 28455,
    app_flat_9: 1,
    char_10: 'Acheron',
    app_rate_10: 5.56,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Jiaoqiu',
    app_rate_11: 5.56,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Luocha',
    app_rate_12: 5.56,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Tingyun',
    app_rate_13: 5.56,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'fu-xuan',
    app_rate_14: 5.56,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'topaz',
    app_rate_15: 5.56,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 5.56,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    round: 0,
    char_1: 'Sparkle',
    app_rate_1: 77.78,
    avg_round_1: 21071,
    app_flat_1: 1,
    char_2: 'fu-xuan',
    app_rate_2: 66.67,
    avg_round_2: 28836,
    app_flat_2: 2,
    char_3: 'Herta',
    app_rate_3: 33.33,
    avg_round_3: 26033,
    app_flat_3: 2,
    char_4: 'Robin',
    app_rate_4: 22.22,
    avg_round_4: 26033,
    app_flat_4: 2,
    char_5: 'Aventurine',
    app_rate_5: 11.11,
    avg_round_5: 23536,
    app_flat_5: 2,
    char_6: 'Xueyi',
    app_rate_6: 11.11,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: 11.11,
    avg_round_7: 31608,
    app_flat_7: 1,
    char_8: 'Serval',
    app_rate_8: 11.11,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Jade',
    app_rate_9: 11.11,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Gallagher',
    app_rate_10: 5.56,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Acheron',
    app_rate_11: 5.56,
    avg_round_11: 21071,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: 5.56,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Tingyun',
    app_rate_13: 5.56,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Huohuo',
    app_rate_14: 5.56,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Bronya',
    app_rate_15: 5.56,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Argenti',
    app_rate_16: 5.56,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Yunli',
    app_rate_17: 5.56,
    avg_round_17: 31608,
    app_flat_17: 1,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Boothill',
    round: 0,
    char_1: 'ruan-mei',
    app_rate_1: 71.43,
    avg_round_1: 31094,
    app_flat_1: 2,
    char_2: 'Gallagher',
    app_rate_2: 35.71,
    avg_round_2: 26208,
    app_flat_2: 1,
    char_3: 'Acheron',
    app_rate_3: 28.57,
    avg_round_3: 31428,
    app_flat_3: 2,
    char_4: 'Himeko',
    app_rate_4: 21.43,
    avg_round_4: 25265,
    app_flat_4: 1,
    char_5: 'Pela',
    app_rate_5: 21.43,
    avg_round_5: 31428,
    app_flat_5: 2,
    char_6: 'Argenti',
    app_rate_6: 14.29,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Huohuo',
    app_rate_7: 14.29,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Luocha',
    app_rate_8: 14.29,
    avg_round_8: 26875,
    app_flat_8: 1,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 14.29,
    avg_round_9: 26208,
    app_flat_9: 1,
    char_10: 'Gepard',
    app_rate_10: 7.14,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Jade',
    app_rate_11: 7.14,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Aventurine',
    app_rate_12: 7.14,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Clara',
    app_rate_13: 7.14,
    avg_round_13: 25265,
    app_flat_13: 1,
    char_14: 'Robin',
    app_rate_14: 7.14,
    avg_round_14: 25265,
    app_flat_14: 1,
    char_15: 'Firefly',
    app_rate_15: 7.14,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'imbibitor-lunae',
    app_rate_16: 7.14,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Jiaoqiu',
    app_rate_17: 7.14,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bronya',
    app_rate_18: 7.14,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    round: 0,
    char_1: 'Acheron',
    app_rate_1: 81.82,
    avg_round_1: 26886,
    app_flat_1: 3,
    char_2: 'Kafka',
    app_rate_2: 63.64,
    avg_round_2: 25314,
    app_flat_2: 2,
    char_3: 'Pela',
    app_rate_3: 36.36,
    avg_round_3: 25707,
    app_flat_3: 2,
    char_4: 'fu-xuan',
    app_rate_4: 36.36,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Aventurine',
    app_rate_5: 18.18,
    avg_round_5: 29245,
    app_flat_5: 1,
    char_6: 'black-swan',
    app_rate_6: 9.09,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Gallagher',
    app_rate_7: 9.09,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Asta',
    app_rate_8: 9.09,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Seele',
    app_rate_9: 9.09,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Jiaoqiu',
    app_rate_10: 9.09,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'march-7th',
    app_rate_11: 9.09,
    avg_round_11: 30030,
    app_flat_11: 1,
    char_12: 'Huohuo',
    app_rate_12: 9.09,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    round: 0,
    char_1: 'Bronya',
    app_rate_1: 44.44,
    avg_round_1: 21115,
    app_flat_1: 1,
    char_2: 'ruan-mei',
    app_rate_2: 44.44,
    avg_round_2: 21115,
    app_flat_2: 1,
    char_3: 'Blade',
    app_rate_3: 38.89,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: 33.33,
    avg_round_4: 22456,
    app_flat_4: 2,
    char_5: 'Luocha',
    app_rate_5: 27.78,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Huohuo',
    app_rate_6: 27.78,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: 16.67,
    avg_round_7: 22456,
    app_flat_7: 2,
    char_8: 'Robin',
    app_rate_8: 16.67,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: 11.11,
    avg_round_9: 21115,
    app_flat_9: 1,
    char_10: 'Lynx',
    app_rate_10: 5.56,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Himeko',
    app_rate_11: 5.56,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Yunli',
    app_rate_12: 5.56,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Acheron',
    app_rate_13: 5.56,
    avg_round_13: 23699,
    app_flat_13: 1,
    char_14: 'Lingsha',
    app_rate_14: 5.56,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Serval',
    app_rate_15: 5.56,
    avg_round_15: 21213,
    app_flat_15: 1,
    char_16: 'Jiaoqiu',
    app_rate_16: 5.56,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    round: 0,
    char_1: 'ruan-mei',
    app_rate_1: 66.67,
    avg_round_1: 29365,
    app_flat_1: 2,
    char_2: 'Kafka',
    app_rate_2: 55.56,
    avg_round_2: 28899,
    app_flat_2: 3,
    char_3: 'Acheron',
    app_rate_3: 33.33,
    avg_round_3: 29586,
    app_flat_3: 2,
    char_4: 'black-swan',
    app_rate_4: 33.33,
    avg_round_4: 27525,
    app_flat_4: 1,
    char_5: 'Jade',
    app_rate_5: 33.33,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Himeko',
    app_rate_6: 33.33,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Herta',
    app_rate_7: 11.11,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Robin',
    app_rate_8: 11.11,
    avg_round_8: 27967,
    app_flat_8: 1,
    char_9: 'Yunli',
    app_rate_9: 11.11,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Asta',
    app_rate_10: 11.11,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    round: 0,
    char_1: 'Tingyun',
    app_rate_1: 60.0,
    avg_round_1: 25568,
    app_flat_1: 4,
    char_2: 'Argenti',
    app_rate_2: 50.0,
    avg_round_2: 25715,
    app_flat_2: 3,
    char_3: 'Luocha',
    app_rate_3: 30.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'fu-xuan',
    app_rate_4: 30.0,
    avg_round_4: 23389,
    app_flat_4: 2,
    char_5: 'Huohuo',
    app_rate_5: 10.0,
    avg_round_5: 25350,
    app_flat_5: 1,
    char_6: 'jing-yuan',
    app_rate_6: 10.0,
    avg_round_6: 20768,
    app_flat_6: 1,
    char_7: 'Kafka',
    app_rate_7: 10.0,
    avg_round_7: 20000,
    app_flat_7: 1,
    char_8: 'black-swan',
    app_rate_8: 10.0,
    avg_round_8: 20000,
    app_flat_8: 1,
    char_9: 'Asta',
    app_rate_9: 10.0,
    avg_round_9: 20000,
    app_flat_9: 1,
    char_10: 'Himeko',
    app_rate_10: 10.0,
    avg_round_10: 20000,
    app_flat_10: 1,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 10.0,
    avg_round_11: 20000,
    app_flat_11: 1,
    char_12: 'Gallagher',
    app_rate_12: 10.0,
    avg_round_12: 20000,
    app_flat_12: 1,
    char_13: 'Acheron',
    app_rate_13: 10.0,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Sparkle',
    app_rate_14: 10.0,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Aventurine',
    app_rate_15: 10.0,
    avg_round_15: 25785,
    app_flat_15: 1,
    char_16: 'imbibitor-lunae',
    app_rate_16: 10.0,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Yukong',
    app_rate_17: 10.0,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    round: 0,
    char_1: 'Robin',
    app_rate_1: 66.67,
    avg_round_1: 32383,
    app_flat_1: 4,
    char_2: 'Herta',
    app_rate_2: 55.56,
    avg_round_2: 32383,
    app_flat_2: 4,
    char_3: 'Feixiao',
    app_rate_3: 44.44,
    avg_round_3: 34988,
    app_flat_3: 3,
    char_4: 'Acheron',
    app_rate_4: 33.33,
    avg_round_4: 30271,
    app_flat_4: 2,
    char_5: 'Pela',
    app_rate_5: 33.33,
    avg_round_5: 30271,
    app_flat_5: 2,
    char_6: 'Kafka',
    app_rate_6: 22.22,
    avg_round_6: 30512,
    app_flat_6: 1,
    char_7: 'Yunli',
    app_rate_7: 11.11,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Sparkle',
    app_rate_8: 11.11,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Xueyi',
    app_rate_9: 11.11,
    avg_round_9: 24568,
    app_flat_9: 1,
    char_10: 'Sampo',
    app_rate_10: 11.11,
    avg_round_10: 30030,
    app_flat_10: 1,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    round: 0,
    char_1: 'imbibitor-lunae',
    app_rate_1: 66.67,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Luocha',
    app_rate_2: 66.67,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Yunli',
    app_rate_3: 33.33,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Tingyun',
    app_rate_4: 33.33,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Huohuo',
    app_rate_5: 33.33,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Hanya',
    app_rate_6: 33.33,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Sparkle',
    app_rate_7: 33.33,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    round: 0,
    char_1: 'Himeko',
    app_rate_1: 100.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Robin',
    app_rate_2: 100.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Gallagher',
    app_rate_3: 100.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
