export const ApoDuos = [
  {
    char: 'Feixiao',
    round: 3516,
    char_1: 'Robin',
    app_rate_1: 85.24,
    avg_round_1: 3556,
    app_flat_1: 2731,
    char_2: 'Aventurine',
    app_rate_2: 50.0,
    avg_round_2: 3555,
    app_flat_2: 1574,
    char_3: 'topaz',
    app_rate_3: 41.78,
    avg_round_3: 3527,
    app_flat_3: 1236,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 40.88,
    avg_round_4: 3554,
    app_flat_4: 1526,
    char_5: 'fu-xuan',
    app_rate_5: 18.7,
    avg_round_5: 3514,
    app_flat_5: 632,
    char_6: 'Huohuo',
    app_rate_6: 10.09,
    avg_round_6: 3513,
    app_flat_6: 357,
    char_7: 'Moze',
    app_rate_7: 8.9,
    avg_round_7: 3461,
    app_flat_7: 314,
    char_8: 'Gallagher',
    app_rate_8: 8.63,
    avg_round_8: 3539,
    app_flat_8: 334,
    char_9: 'Luocha',
    app_rate_9: 7.46,
    avg_round_9: 3516,
    app_flat_9: 283,
    char_10: 'Bronya',
    app_rate_10: 6.2,
    avg_round_10: 3523,
    app_flat_10: 200,
    char_11: 'Sparkle',
    app_rate_11: 4.79,
    avg_round_11: 3430,
    app_flat_11: 148,
    char_12: 'Yunli',
    app_rate_12: 3.05,
    avg_round_12: 3497,
    app_flat_12: 91,
    char_13: 'ruan-mei',
    app_rate_13: 3.03,
    avg_round_13: 3475,
    app_flat_13: 85,
    char_14: 'Herta',
    app_rate_14: 2.7,
    avg_round_14: 3505,
    app_flat_14: 74,
    char_15: 'Jade',
    app_rate_15: 1.62,
    avg_round_15: 3471,
    app_flat_15: 15,
    char_16: 'Clara',
    app_rate_16: 0.99,
    avg_round_16: 3347,
    app_flat_16: 39,
    char_17: 'Bailu',
    app_rate_17: 0.97,
    avg_round_17: 3497,
    app_flat_17: 38,
    char_18: 'Gepard',
    app_rate_18: 0.63,
    avg_round_18: 3524,
    app_flat_18: 26,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.47,
    avg_round_19: 3355,
    app_flat_19: 10,
    char_20: 'Lynx',
    app_rate_20: 0.38,
    avg_round_20: 3434,
    app_flat_20: 17,
    char_21: 'Pela',
    app_rate_21: 0.38,
    avg_round_21: 3404,
    app_flat_21: 13,
    char_22: 'Blade',
    app_rate_22: 0.36,
    avg_round_22: 3364,
    app_flat_22: 8,
    char_23: 'Tingyun',
    app_rate_23: 0.29,
    avg_round_23: 3290,
    app_flat_23: 5,
    char_24: 'dr-ratio',
    app_rate_24: 0.25,
    avg_round_24: 3463,
    app_flat_24: 9,
    char_25: 'Acheron',
    app_rate_25: 0.25,
    avg_round_25: 3450,
    app_flat_25: 3,
    char_26: 'Jingliu',
    app_rate_26: 0.23,
    avg_round_26: 3439,
    app_flat_26: 10,
    char_27: 'Natasha',
    app_rate_27: 0.22,
    avg_round_27: 3523,
    app_flat_27: 9,
    char_28: 'silver-wolf',
    app_rate_28: 0.16,
    avg_round_28: 3427,
    app_flat_28: 3,
    char_29: 'jing-yuan',
    app_rate_29: 0.16,
    avg_round_29: 3411,
    app_flat_29: 3,
    char_30: 'black-swan',
    app_rate_30: 0.14,
    avg_round_30: 3308,
    app_flat_30: 1
  },
  {
    char: 'topaz',
    round: 3506,
    char_1: 'Robin',
    app_rate_1: 87.61,
    avg_round_1: 3522,
    app_flat_1: 1184,
    char_2: 'Feixiao',
    app_rate_2: 87.08,
    avg_round_2: 3527,
    app_flat_2: 1236,
    char_3: 'Aventurine',
    app_rate_3: 67.78,
    avg_round_3: 3547,
    app_flat_3: 871,
    char_4: 'fu-xuan',
    app_rate_4: 12.81,
    avg_round_4: 3463,
    app_flat_4: 197,
    char_5: 'dr-ratio',
    app_rate_5: 7.59,
    avg_round_5: 3325,
    app_flat_5: 80,
    char_6: 'Huohuo',
    app_rate_6: 6.91,
    avg_round_6: 3433,
    app_flat_6: 113,
    char_7: 'Luocha',
    app_rate_7: 5.41,
    avg_round_7: 3478,
    app_flat_7: 97,
    char_8: 'Gallagher',
    app_rate_8: 4.69,
    avg_round_8: 3506,
    app_flat_8: 81,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 4.21,
    avg_round_9: 3388,
    app_flat_9: 71,
    char_10: 'ruan-mei',
    app_rate_10: 2.7,
    avg_round_10: 3480,
    app_flat_10: 24,
    char_11: 'Yunli',
    app_rate_11: 2.52,
    avg_round_11: 3276,
    app_flat_11: 37,
    char_12: 'Sparkle',
    app_rate_12: 2.4,
    avg_round_12: 3395,
    app_flat_12: 43,
    char_13: 'Bronya',
    app_rate_13: 2.18,
    avg_round_13: 3429,
    app_flat_13: 37,
    char_14: 'Moze',
    app_rate_14: 0.94,
    avg_round_14: 3359,
    app_flat_14: 15,
    char_15: 'Clara',
    app_rate_15: 0.64,
    avg_round_15: 3236,
    app_flat_15: 7,
    char_16: 'Jade',
    app_rate_16: 0.6,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Bailu',
    app_rate_17: 0.53,
    avg_round_17: 3473,
    app_flat_17: 8,
    char_18: 'jing-yuan',
    app_rate_18: 0.45,
    avg_round_18: 3432,
    app_flat_18: 2,
    char_19: 'Herta',
    app_rate_19: 0.38,
    avg_round_19: 3244,
    app_flat_19: 4,
    char_20: 'Gepard',
    app_rate_20: 0.34,
    avg_round_20: 3537,
    app_flat_20: 4,
    char_21: 'Acheron',
    app_rate_21: 0.3,
    avg_round_21: 3291,
    app_flat_21: 2,
    char_22: 'Jiaoqiu',
    app_rate_22: 0.26,
    avg_round_22: 3289,
    app_flat_22: 3,
    char_23: 'Asta',
    app_rate_23: 0.23,
    avg_round_23: 3339,
    app_flat_23: 1,
    char_24: 'Lynx',
    app_rate_24: 0.19,
    avg_round_24: 3512,
    app_flat_24: 3,
    char_25: 'Tingyun',
    app_rate_25: 0.19,
    avg_round_25: 3183,
    app_flat_25: 3,
    char_26: 'silver-wolf',
    app_rate_26: 0.15,
    avg_round_26: 3267,
    app_flat_26: 2,
    char_27: 'Natasha',
    app_rate_27: 0.15,
    avg_round_27: 3616,
    app_flat_27: 2,
    char_28: 'Kafka',
    app_rate_28: 0.15,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th',
    app_rate_29: 0.15,
    avg_round_29: 3423,
    app_flat_29: 2,
    char_30: 'Blade',
    app_rate_30: 0.11,
    avg_round_30: 3130,
    app_flat_30: 2
  },
  {
    char: 'Robin',
    round: 3476,
    char_1: 'Feixiao',
    app_rate_1: 71.33,
    avg_round_1: 3556,
    app_flat_1: 2731,
    char_2: 'Aventurine',
    app_rate_2: 44.2,
    avg_round_2: 3564,
    app_flat_2: 1558,
    char_3: 'topaz',
    app_rate_3: 35.17,
    avg_round_3: 3522,
    app_flat_3: 1184,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 30.57,
    avg_round_4: 3579,
    app_flat_4: 1324,
    char_5: 'fu-xuan',
    app_rate_5: 16.98,
    avg_round_5: 3491,
    app_flat_5: 624,
    char_6: 'Huohuo',
    app_rate_6: 16.73,
    avg_round_6: 3444,
    app_flat_6: 621,
    char_7: 'Yunli',
    app_rate_7: 9.5,
    avg_round_7: 3381,
    app_flat_7: 349,
    char_8: 'Gallagher',
    app_rate_8: 7.82,
    avg_round_8: 3530,
    app_flat_8: 330,
    char_9: 'Luocha',
    app_rate_9: 7.76,
    avg_round_9: 3483,
    app_flat_9: 306,
    char_10: 'Moze',
    app_rate_10: 6.2,
    avg_round_10: 3482,
    app_flat_10: 242,
    char_11: 'Bronya',
    app_rate_11: 5.8,
    avg_round_11: 3470,
    app_flat_11: 160,
    char_12: 'Jingliu',
    app_rate_12: 5.38,
    avg_round_12: 3382,
    app_flat_12: 186,
    char_13: 'Tingyun',
    app_rate_13: 5.1,
    avg_round_13: 3351,
    app_flat_13: 176,
    char_14: 'Sparkle',
    app_rate_14: 4.54,
    avg_round_14: 3350,
    app_flat_14: 83,
    char_15: 'dr-ratio',
    app_rate_15: 3.5,
    avg_round_15: 3342,
    app_flat_15: 100,
    char_16: 'Herta',
    app_rate_16: 3.32,
    avg_round_16: 3447,
    app_flat_16: 90,
    char_17: 'Kafka',
    app_rate_17: 3.27,
    avg_round_17: 3327,
    app_flat_17: 67,
    char_18: 'black-swan',
    app_rate_18: 3.2,
    avg_round_18: 3321,
    app_flat_18: 60,
    char_19: 'Acheron',
    app_rate_19: 2.28,
    avg_round_19: 3381,
    app_flat_19: 24,
    char_20: 'jing-yuan',
    app_rate_20: 2.16,
    avg_round_20: 3319,
    app_flat_20: 55,
    char_21: 'Blade',
    app_rate_21: 1.75,
    avg_round_21: 3288,
    app_flat_21: 52,
    char_22: 'Jade',
    app_rate_22: 1.7,
    avg_round_22: 3426,
    app_flat_22: 17,
    char_23: 'Jiaoqiu',
    app_rate_23: 1.64,
    avg_round_23: 3380,
    app_flat_23: 23,
    char_24: 'Clara',
    app_rate_24: 1.42,
    avg_round_24: 3296,
    app_flat_24: 45,
    char_25: 'ruan-mei',
    app_rate_25: 1.28,
    avg_round_25: 3394,
    app_flat_25: 20,
    char_26: 'Pela',
    app_rate_26: 1.21,
    avg_round_26: 3303,
    app_flat_26: 30,
    char_27: 'Bailu',
    app_rate_27: 1.03,
    avg_round_27: 3437,
    app_flat_27: 48,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.66,
    avg_round_28: 3382,
    app_flat_28: 3,
    char_29: 'Gepard',
    app_rate_29: 0.65,
    avg_round_29: 3483,
    app_flat_29: 31,
    char_30: 'Yanqing',
    app_rate_30: 0.57,
    avg_round_30: 3355,
    app_flat_30: 19
  },
  {
    char: 'Aventurine',
    round: 3469,
    char_1: 'Robin',
    app_rate_1: 66.91,
    avg_round_1: 3564,
    app_flat_1: 1558,
    char_2: 'Feixiao',
    app_rate_2: 63.33,
    avg_round_2: 3555,
    app_flat_2: 1574,
    char_3: 'topaz',
    app_rate_3: 41.19,
    avg_round_3: 3547,
    app_flat_3: 871,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 19.76,
    avg_round_4: 3574,
    app_flat_4: 619,
    char_5: 'Acheron',
    app_rate_5: 15.45,
    avg_round_5: 3352,
    app_flat_5: 289,
    char_6: 'Jiaoqiu',
    app_rate_6: 11.18,
    avg_round_6: 3362,
    app_flat_6: 196,
    char_7: 'Sparkle',
    app_rate_7: 10.02,
    avg_round_7: 3383,
    app_flat_7: 124,
    char_8: 'Moze',
    app_rate_8: 8.38,
    avg_round_8: 3466,
    app_flat_8: 217,
    char_9: 'ruan-mei',
    app_rate_9: 7.35,
    avg_round_9: 3412,
    app_flat_9: 135,
    char_10: 'Pela',
    app_rate_10: 7.05,
    avg_round_10: 3328,
    app_flat_10: 178,
    char_11: 'dr-ratio',
    app_rate_11: 6.18,
    avg_round_11: 3336,
    app_flat_11: 101,
    char_12: 'black-swan',
    app_rate_12: 5.73,
    avg_round_12: 3371,
    app_flat_12: 130,
    char_13: 'Bronya',
    app_rate_13: 5.39,
    avg_round_13: 3489,
    app_flat_13: 129,
    char_14: 'Kafka',
    app_rate_14: 4.7,
    avg_round_14: 3377,
    app_flat_14: 106,
    char_15: 'Tingyun',
    app_rate_15: 3.35,
    avg_round_15: 3298,
    app_flat_15: 70,
    char_16: 'Yunli',
    app_rate_16: 3.29,
    avg_round_16: 3385,
    app_flat_16: 91,
    char_17: 'silver-wolf',
    app_rate_17: 2.83,
    avg_round_17: 3310,
    app_flat_17: 61,
    char_18: 'Herta',
    app_rate_18: 2.15,
    avg_round_18: 3504,
    app_flat_18: 44,
    char_19: 'Boothill',
    app_rate_19: 1.94,
    avg_round_19: 3433,
    app_flat_19: 51,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 1.89,
    avg_round_20: 3350,
    app_flat_20: 38,
    char_21: 'jing-yuan',
    app_rate_21: 1.87,
    avg_round_21: 3339,
    app_flat_21: 36,
    char_22: 'imbibitor-lunae',
    app_rate_22: 1.73,
    avg_round_22: 3344,
    app_flat_22: 14,
    char_23: 'Jade',
    app_rate_23: 1.48,
    avg_round_23: 3476,
    app_flat_23: 7,
    char_24: 'Yanqing',
    app_rate_24: 1.23,
    avg_round_24: 3296,
    app_flat_24: 26,
    char_25: 'Jingliu',
    app_rate_25: 1.21,
    avg_round_25: 3364,
    app_flat_25: 24,
    char_26: 'Firefly',
    app_rate_26: 1.19,
    avg_round_26: 3287,
    app_flat_26: 15,
    char_27: 'Clara',
    app_rate_27: 1.0,
    avg_round_27: 3329,
    app_flat_27: 23,
    char_28: 'Sampo',
    app_rate_28: 0.41,
    avg_round_28: 3322,
    app_flat_28: 9,
    char_29: 'Argenti',
    app_rate_29: 0.23,
    avg_round_29: 3165,
    app_flat_29: 1,
    char_30: 'Himeko',
    app_rate_30: 0.23,
    avg_round_30: 3408,
    app_flat_30: 4
  },
  {
    char: 'Boothill',
    round: 3456,
    char_1: 'ruan-mei',
    app_rate_1: 84.52,
    avg_round_1: 3469,
    app_flat_1: 398,
    char_2: 'Bronya',
    app_rate_2: 59.55,
    avg_round_2: 3486,
    app_flat_2: 295,
    char_3: 'Gallagher',
    app_rate_3: 43.57,
    avg_round_3: 3467,
    app_flat_3: 249,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 33.08,
    avg_round_4: 3410,
    app_flat_4: 184,
    char_5: 'Luocha',
    app_rate_5: 12.73,
    avg_round_5: 3444,
    app_flat_5: 70,
    char_6: 'Huohuo',
    app_rate_6: 10.86,
    avg_round_6: 3455,
    app_flat_6: 56,
    char_7: 'fu-xuan',
    app_rate_7: 10.61,
    avg_round_7: 3434,
    app_flat_7: 47,
    char_8: 'Aventurine',
    app_rate_8: 10.61,
    avg_round_8: 3433,
    app_flat_8: 51,
    char_9: 'Pela',
    app_rate_9: 7.87,
    avg_round_9: 3436,
    app_flat_9: 33,
    char_10: 'Robin',
    app_rate_10: 3.0,
    avg_round_10: 3371,
    app_flat_10: 13,
    char_11: 'Gepard',
    app_rate_11: 2.62,
    avg_round_11: 3428,
    app_flat_11: 14,
    char_12: 'Sparkle',
    app_rate_12: 2.37,
    avg_round_12: 3411,
    app_flat_12: 12,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 2.25,
    avg_round_13: 3404,
    app_flat_13: 11,
    char_14: 'Luka',
    app_rate_14: 2.0,
    avg_round_14: 3360,
    app_flat_14: 13,
    char_15: 'Herta',
    app_rate_15: 1.87,
    avg_round_15: 3301,
    app_flat_15: 5,
    char_16: 'Argenti',
    app_rate_16: 1.87,
    avg_round_16: 3502,
    app_flat_16: 2,
    char_17: 'Bailu',
    app_rate_17: 1.87,
    avg_round_17: 3412,
    app_flat_17: 9,
    char_18: 'Yunli',
    app_rate_18: 1.25,
    avg_round_18: 3410,
    app_flat_18: 6,
    char_19: 'Firefly',
    app_rate_19: 1.0,
    avg_round_19: 3457,
    app_flat_19: 1,
    char_20: 'Blade',
    app_rate_20: 0.75,
    avg_round_20: 3414,
    app_flat_20: 6,
    char_21: 'Acheron',
    app_rate_21: 0.75,
    avg_round_21: 3306,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: 0.62,
    avg_round_22: 3212,
    app_flat_22: 3,
    char_23: 'Jingliu',
    app_rate_23: 0.62,
    avg_round_23: 3520,
    app_flat_23: 4,
    char_24: 'Feixiao',
    app_rate_24: 0.5,
    avg_round_24: 3451,
    app_flat_24: 2,
    char_25: 'Lynx',
    app_rate_25: 0.5,
    avg_round_25: 3407,
    app_flat_25: 3,
    char_26: 'Hanya',
    app_rate_26: 0.5,
    avg_round_26: 3192,
    app_flat_26: 4,
    char_27: 'Jiaoqiu',
    app_rate_27: 0.37,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Natasha',
    app_rate_28: 0.25,
    avg_round_28: 3454,
    app_flat_28: 1,
    char_29: 'Tingyun',
    app_rate_29: 0.25,
    avg_round_29: 3408,
    app_flat_29: 1,
    char_30: 'march-7th',
    app_rate_30: 0.25,
    avg_round_30: 3445,
    app_flat_30: 1
  },
  {
    char: 'Moze',
    round: 3452,
    char_1: 'Feixiao',
    app_rate_1: 87.9,
    avg_round_1: 3461,
    app_flat_1: 314,
    char_2: 'Robin',
    app_rate_2: 73.13,
    avg_round_2: 3482,
    app_flat_2: 242,
    char_3: 'Aventurine',
    app_rate_3: 65.3,
    avg_round_3: 3466,
    app_flat_3: 217,
    char_4: 'fu-xuan',
    app_rate_4: 14.06,
    avg_round_4: 3411,
    app_flat_4: 51,
    char_5: 'march-7th-swordmaster',
    app_rate_5: 11.21,
    avg_round_5: 3353,
    app_flat_5: 49,
    char_6: 'dr-ratio',
    app_rate_6: 7.83,
    avg_round_6: 3334,
    app_flat_6: 18,
    char_7: 'Luocha',
    app_rate_7: 6.94,
    avg_round_7: 3426,
    app_flat_7: 22,
    char_8: 'Gallagher',
    app_rate_8: 6.41,
    avg_round_8: 3434,
    app_flat_8: 27,
    char_9: 'Huohuo',
    app_rate_9: 5.16,
    avg_round_9: 3381,
    app_flat_9: 21,
    char_10: 'topaz',
    app_rate_10: 4.45,
    avg_round_10: 3359,
    app_flat_10: 15,
    char_11: 'Jiaoqiu',
    app_rate_11: 3.74,
    avg_round_11: 3298,
    app_flat_11: 8,
    char_12: 'ruan-mei',
    app_rate_12: 3.38,
    avg_round_12: 3401,
    app_flat_12: 11,
    char_13: 'Bronya',
    app_rate_13: 2.14,
    avg_round_13: 3361,
    app_flat_13: 8,
    char_14: 'Sparkle',
    app_rate_14: 1.78,
    avg_round_14: 3416,
    app_flat_14: 6,
    char_15: 'Yunli',
    app_rate_15: 0.71,
    avg_round_15: 3255,
    app_flat_15: 3,
    char_16: 'jing-yuan',
    app_rate_16: 0.71,
    avg_round_16: 3398,
    app_flat_16: 1,
    char_17: 'Pela',
    app_rate_17: 0.71,
    avg_round_17: 3295,
    app_flat_17: 3,
    char_18: 'Yanqing',
    app_rate_18: 0.36,
    avg_round_18: 3049,
    app_flat_18: 2,
    char_19: 'Gepard',
    app_rate_19: 0.36,
    avg_round_19: 3158,
    app_flat_19: 2,
    char_20: 'Jingliu',
    app_rate_20: 0.36,
    avg_round_20: 3332,
    app_flat_20: 2,
    char_21: 'Blade',
    app_rate_21: 0.36,
    avg_round_21: 3144,
    app_flat_21: 1,
    char_22: 'Lynx',
    app_rate_22: 0.36,
    avg_round_22: 3374,
    app_flat_22: 2,
    char_23: 'Bailu',
    app_rate_23: 0.36,
    avg_round_23: 3459,
    app_flat_23: 1,
    char_24: 'Clara',
    app_rate_24: 0.36,
    avg_round_24: 3118,
    app_flat_24: 2,
    char_25: 'Herta',
    app_rate_25: 0.36,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Kafka',
    app_rate_26: 0.18,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'silver-wolf',
    app_rate_27: 0.18,
    avg_round_27: 3052,
    app_flat_27: 1,
    char_28: 'Natasha',
    app_rate_28: 0.18,
    avg_round_28: 3474,
    app_flat_28: 1,
    char_29: 'march-7th',
    app_rate_29: 0.18,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Argenti',
    app_rate_30: 0.18,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    round: 3428,
    char_1: 'Robin',
    app_rate_1: 70.97,
    avg_round_1: 3447,
    app_flat_1: 90,
    char_2: 'Feixiao',
    app_rate_2: 48.39,
    avg_round_2: 3505,
    app_flat_2: 74,
    char_3: 'Aventurine',
    app_rate_3: 30.32,
    avg_round_3: 3504,
    app_flat_3: 44,
    char_4: 'fu-xuan',
    app_rate_4: 22.58,
    avg_round_4: 3331,
    app_flat_4: 24,
    char_5: 'Jingliu',
    app_rate_5: 14.19,
    avg_round_5: 3242,
    app_flat_5: 11,
    char_6: 'Huohuo',
    app_rate_6: 14.19,
    avg_round_6: 3390,
    app_flat_6: 20,
    char_7: 'Bronya',
    app_rate_7: 13.55,
    avg_round_7: 3374,
    app_flat_7: 6,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 13.23,
    avg_round_8: 3350,
    app_flat_8: 20,
    char_9: 'ruan-mei',
    app_rate_9: 12.26,
    avg_round_9: 3247,
    app_flat_9: 10,
    char_10: 'Sparkle',
    app_rate_10: 8.71,
    avg_round_10: 3245,
    app_flat_10: 5,
    char_11: 'Luocha',
    app_rate_11: 7.74,
    avg_round_11: 3348,
    app_flat_11: 18,
    char_12: 'Yunli',
    app_rate_12: 6.77,
    avg_round_12: 3241,
    app_flat_12: 6,
    char_13: 'Boothill',
    app_rate_13: 4.84,
    avg_round_13: 3301,
    app_flat_13: 5,
    char_14: 'Jade',
    app_rate_14: 4.19,
    avg_round_14: 3450,
    app_flat_14: 1,
    char_15: 'Bailu',
    app_rate_15: 3.55,
    avg_round_15: 3308,
    app_flat_15: 5,
    char_16: 'Gallagher',
    app_rate_16: 3.23,
    avg_round_16: 3408,
    app_flat_16: 6,
    char_17: 'topaz',
    app_rate_17: 3.23,
    avg_round_17: 3244,
    app_flat_17: 4,
    char_18: 'Tingyun',
    app_rate_18: 2.9,
    avg_round_18: 3241,
    app_flat_18: 3,
    char_19: 'jing-yuan',
    app_rate_19: 2.26,
    avg_round_19: 3143,
    app_flat_19: 3,
    char_20: 'Pela',
    app_rate_20: 1.94,
    avg_round_20: 3324,
    app_flat_20: 3,
    char_21: 'Yanqing',
    app_rate_21: 1.61,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Clara',
    app_rate_22: 1.61,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Gepard',
    app_rate_23: 1.29,
    avg_round_23: 3369,
    app_flat_23: 2,
    char_24: 'Lynx',
    app_rate_24: 0.97,
    avg_round_24: 3370,
    app_flat_24: 3,
    char_25: 'Blade',
    app_rate_25: 0.97,
    avg_round_25: 2994,
    app_flat_25: 1,
    char_26: 'Misha',
    app_rate_26: 0.65,
    avg_round_26: 3186,
    app_flat_26: 1,
    char_27: 'Acheron',
    app_rate_27: 0.65,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Kafka',
    app_rate_28: 0.65,
    avg_round_28: 3161,
    app_flat_28: 1,
    char_29: 'Moze',
    app_rate_29: 0.65,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Himeko',
    app_rate_30: 0.32,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Gallagher',
    round: 3422,
    char_1: 'ruan-mei',
    app_rate_1: 80.17,
    avg_round_1: 3425,
    app_flat_1: 2439,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 73.62,
    avg_round_2: 3416,
    app_flat_2: 2195,
    char_3: 'Firefly',
    app_rate_3: 68.91,
    avg_round_3: 3423,
    app_flat_3: 1940,
    char_4: 'Acheron',
    app_rate_4: 9.17,
    avg_round_4: 3393,
    app_flat_4: 303,
    char_5: 'Robin',
    app_rate_5: 7.39,
    avg_round_5: 3530,
    app_flat_5: 330,
    char_6: 'black-swan',
    app_rate_6: 7.21,
    avg_round_6: 3410,
    app_flat_6: 263,
    char_7: 'Feixiao',
    app_rate_7: 6.82,
    avg_round_7: 3539,
    app_flat_7: 334,
    char_8: 'Kafka',
    app_rate_8: 6.69,
    avg_round_8: 3414,
    app_flat_8: 249,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 5.77,
    avg_round_9: 3494,
    app_flat_9: 299,
    char_10: 'Jiaoqiu',
    app_rate_10: 5.64,
    avg_round_10: 3412,
    app_flat_10: 201,
    char_11: 'Bronya',
    app_rate_11: 5.04,
    avg_round_11: 3496,
    app_flat_11: 215,
    char_12: 'Boothill',
    app_rate_12: 4.97,
    avg_round_12: 3467,
    app_flat_12: 249,
    char_13: 'Pela',
    app_rate_13: 3.46,
    avg_round_13: 3388,
    app_flat_13: 151,
    char_14: 'Sparkle',
    app_rate_14: 3.22,
    avg_round_14: 3347,
    app_flat_14: 48,
    char_15: 'topaz',
    app_rate_15: 1.78,
    avg_round_15: 3506,
    app_flat_15: 81,
    char_16: 'Himeko',
    app_rate_16: 1.62,
    avg_round_16: 3324,
    app_flat_16: 79,
    char_17: 'silver-wolf',
    app_rate_17: 1.54,
    avg_round_17: 3398,
    app_flat_17: 52,
    char_18: 'imbibitor-lunae',
    app_rate_18: 0.95,
    avg_round_18: 3383,
    app_flat_18: 10,
    char_19: 'Jingliu',
    app_rate_19: 0.9,
    avg_round_19: 3361,
    app_flat_19: 39,
    char_20: 'Tingyun',
    app_rate_20: 0.81,
    avg_round_20: 3355,
    app_flat_20: 26,
    char_21: 'Guinaifen',
    app_rate_21: 0.68,
    avg_round_21: 3316,
    app_flat_21: 31,
    char_22: 'Asta',
    app_rate_22: 0.57,
    avg_round_22: 3301,
    app_flat_22: 22,
    char_23: 'Moze',
    app_rate_23: 0.51,
    avg_round_23: 3434,
    app_flat_23: 27,
    char_24: 'jing-yuan',
    app_rate_24: 0.43,
    avg_round_24: 3325,
    app_flat_24: 13,
    char_25: 'Blade',
    app_rate_25: 0.4,
    avg_round_25: 3353,
    app_flat_25: 14,
    char_26: 'Sampo',
    app_rate_26: 0.37,
    avg_round_26: 3360,
    app_flat_26: 13,
    char_27: 'dr-ratio',
    app_rate_27: 0.28,
    avg_round_27: 3352,
    app_flat_27: 12,
    char_28: 'Jade',
    app_rate_28: 0.17,
    avg_round_28: 3302,
    app_flat_28: 1,
    char_29: 'Herta',
    app_rate_29: 0.14,
    avg_round_29: 3408,
    app_flat_29: 6,
    char_30: 'Welt',
    app_rate_30: 0.11,
    avg_round_30: 3240,
    app_flat_30: 4
  },
  {
    char: 'Firefly',
    round: 3416,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 99.59,
    avg_round_1: 3416,
    app_flat_1: 2027,
    char_2: 'ruan-mei',
    app_rate_2: 98.23,
    avg_round_2: 3419,
    app_flat_2: 1986,
    char_3: 'Gallagher',
    app_rate_3: 90.19,
    avg_round_3: 3423,
    app_flat_3: 1940,
    char_4: 'Huohuo',
    app_rate_4: 2.68,
    avg_round_4: 3198,
    app_flat_4: 22,
    char_5: 'Luocha',
    app_rate_5: 1.9,
    avg_round_5: 3319,
    app_flat_5: 39,
    char_6: 'Bronya',
    app_rate_6: 1.51,
    avg_round_6: 3047,
    app_flat_6: 1,
    char_7: 'Aventurine',
    app_rate_7: 0.97,
    avg_round_7: 3287,
    app_flat_7: 15,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 0.88,
    avg_round_8: 3319,
    app_flat_8: 16,
    char_9: 'Asta',
    app_rate_9: 0.73,
    avg_round_9: 3281,
    app_flat_9: 16,
    char_10: 'Himeko',
    app_rate_10: 0.62,
    avg_round_10: 3257,
    app_flat_10: 4,
    char_11: 'fu-xuan',
    app_rate_11: 0.5,
    avg_round_11: 3150,
    app_flat_11: 5,
    char_12: 'Bailu',
    app_rate_12: 0.35,
    avg_round_12: 3246,
    app_flat_12: 4,
    char_13: 'Lynx',
    app_rate_13: 0.21,
    avg_round_13: 3166,
    app_flat_13: 1,
    char_14: 'Jiaoqiu',
    app_rate_14: 0.21,
    avg_round_14: 3214,
    app_flat_14: 1,
    char_15: 'Sparkle',
    app_rate_15: 0.19,
    avg_round_15: 3230,
    app_flat_15: 2,
    char_16: 'Robin',
    app_rate_16: 0.19,
    avg_round_16: 3308,
    app_flat_16: 1,
    char_17: 'Boothill',
    app_rate_17: 0.15,
    avg_round_17: 3457,
    app_flat_17: 1,
    char_18: 'Feixiao',
    app_rate_18: 0.11,
    avg_round_18: 3304,
    app_flat_18: 2,
    char_19: 'Gepard',
    app_rate_19: 0.11,
    avg_round_19: 3430,
    app_flat_19: 2,
    char_20: 'silver-wolf',
    app_rate_20: 0.09,
    avg_round_20: 3144,
    app_flat_20: 1,
    char_21: 'Acheron',
    app_rate_21: 0.09,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Kafka',
    app_rate_22: 0.06,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Guinaifen',
    app_rate_23: 0.06,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Pela',
    app_rate_24: 0.06,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Yunli',
    app_rate_25: 0.06,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'black-swan',
    app_rate_26: 0.06,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'imbibitor-lunae',
    app_rate_27: 0.04,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th',
    app_rate_28: 0.04,
    avg_round_28: 3242,
    app_flat_28: 1,
    char_29: 'jing-yuan',
    app_rate_29: 0.04,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Natasha',
    app_rate_30: 0.02,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    round: 3411,
    char_1: 'Jingliu',
    app_rate_1: 45.3,
    avg_round_1: 3371,
    app_flat_1: 560,
    char_2: 'ruan-mei',
    app_rate_2: 38.81,
    avg_round_2: 3464,
    app_flat_2: 406,
    char_3: 'Huohuo',
    app_rate_3: 21.23,
    avg_round_3: 3390,
    app_flat_3: 254,
    char_4: 'Boothill',
    app_rate_4: 20.21,
    avg_round_4: 3486,
    app_flat_4: 295,
    char_5: 'Luocha',
    app_rate_5: 19.53,
    avg_round_5: 3384,
    app_flat_5: 260,
    char_6: 'fu-xuan',
    app_rate_6: 18.43,
    avg_round_6: 3411,
    app_flat_6: 187,
    char_7: 'Robin',
    app_rate_7: 16.31,
    avg_round_7: 3470,
    app_flat_7: 160,
    char_8: 'Pela',
    app_rate_8: 15.72,
    avg_round_8: 3332,
    app_flat_8: 186,
    char_9: 'Gallagher',
    app_rate_9: 15.0,
    avg_round_9: 3496,
    app_flat_9: 215,
    char_10: 'Feixiao',
    app_rate_10: 14.58,
    avg_round_10: 3523,
    app_flat_10: 200,
    char_11: 'Aventurine',
    app_rate_11: 10.0,
    avg_round_11: 3489,
    app_flat_11: 129,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 8.52,
    avg_round_12: 3521,
    app_flat_12: 111,
    char_13: 'Acheron',
    app_rate_13: 8.18,
    avg_round_13: 3224,
    app_flat_13: 2,
    char_14: 'Sparkle',
    app_rate_14: 8.18,
    avg_round_14: 3342,
    app_flat_14: 92,
    char_15: 'Blade',
    app_rate_15: 7.67,
    avg_round_15: 3296,
    app_flat_15: 76,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 4.75,
    avg_round_16: 3401,
    app_flat_16: 30,
    char_17: 'Jiaoqiu',
    app_rate_17: 4.15,
    avg_round_17: 3289,
    app_flat_17: 1,
    char_18: 'Firefly',
    app_rate_18: 3.43,
    avg_round_18: 3047,
    app_flat_18: 1,
    char_19: 'topaz',
    app_rate_19: 2.46,
    avg_round_19: 3429,
    app_flat_19: 37,
    char_20: 'Bailu',
    app_rate_20: 2.33,
    avg_round_20: 3367,
    app_flat_20: 38,
    char_21: 'Tingyun',
    app_rate_21: 2.08,
    avg_round_21: 3333,
    app_flat_21: 30,
    char_22: 'Herta',
    app_rate_22: 1.78,
    avg_round_22: 3374,
    app_flat_22: 6,
    char_23: 'Jade',
    app_rate_23: 1.65,
    avg_round_23: 3295,
    app_flat_23: 8,
    char_24: 'Gepard',
    app_rate_24: 1.53,
    avg_round_24: 3448,
    app_flat_24: 11,
    char_25: 'Lynx',
    app_rate_25: 1.31,
    avg_round_25: 3348,
    app_flat_25: 20,
    char_26: 'silver-wolf',
    app_rate_26: 1.31,
    avg_round_26: 3226,
    app_flat_26: 3,
    char_27: 'imbibitor-lunae',
    app_rate_27: 0.72,
    avg_round_27: 3232,
    app_flat_27: 4,
    char_28: 'Yunli',
    app_rate_28: 0.59,
    avg_round_28: 3335,
    app_flat_28: 5,
    char_29: 'jing-yuan',
    app_rate_29: 0.55,
    avg_round_29: 3227,
    app_flat_29: 4,
    char_30: 'Moze',
    app_rate_30: 0.51,
    avg_round_30: 3361,
    app_flat_30: 8
  },
  {
    char: 'trailblazer-imaginary',
    round: 3410,
    char_1: 'ruan-mei',
    app_rate_1: 97.37,
    avg_round_1: 3413,
    app_flat_1: 2284,
    char_2: 'Firefly',
    app_rate_2: 91.39,
    avg_round_2: 3416,
    app_flat_2: 2027,
    char_3: 'Gallagher',
    app_rate_3: 88.43,
    avg_round_3: 3416,
    app_flat_3: 2195,
    char_4: 'Boothill',
    app_rate_4: 4.53,
    avg_round_4: 3410,
    app_flat_4: 184,
    char_5: 'Huohuo',
    app_rate_5: 2.86,
    avg_round_5: 3314,
    app_flat_5: 42,
    char_6: 'Himeko',
    app_rate_6: 2.34,
    avg_round_6: 3327,
    app_flat_6: 79,
    char_7: 'Luocha',
    app_rate_7: 2.28,
    avg_round_7: 3341,
    app_flat_7: 56,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 2.22,
    avg_round_8: 3348,
    app_flat_8: 77,
    char_9: 'Bronya',
    app_rate_9: 1.92,
    avg_round_9: 3401,
    app_flat_9: 30,
    char_10: 'Aventurine',
    app_rate_10: 1.42,
    avg_round_10: 3350,
    app_flat_10: 38,
    char_11: 'fu-xuan',
    app_rate_11: 0.84,
    avg_round_11: 3389,
    app_flat_11: 23,
    char_12: 'Asta',
    app_rate_12: 0.77,
    avg_round_12: 3284,
    app_flat_12: 18,
    char_13: 'Bailu',
    app_rate_13: 0.41,
    avg_round_13: 3329,
    app_flat_13: 8,
    char_14: 'Gepard',
    app_rate_14: 0.27,
    avg_round_14: 3443,
    app_flat_14: 8,
    char_15: 'Sparkle',
    app_rate_15: 0.27,
    avg_round_15: 3304,
    app_flat_15: 4,
    char_16: 'Jiaoqiu',
    app_rate_16: 0.26,
    avg_round_16: 3331,
    app_flat_16: 3,
    char_17: 'Lynx',
    app_rate_17: 0.24,
    avg_round_17: 3284,
    app_flat_17: 2,
    char_18: 'Pela',
    app_rate_18: 0.24,
    avg_round_18: 3384,
    app_flat_18: 1,
    char_19: 'Guinaifen',
    app_rate_19: 0.19,
    avg_round_19: 3301,
    app_flat_19: 6,
    char_20: 'imbibitor-lunae',
    app_rate_20: 0.15,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Argenti',
    app_rate_21: 0.15,
    avg_round_21: 3308,
    app_flat_21: 1,
    char_22: 'Luka',
    app_rate_22: 0.15,
    avg_round_22: 3350,
    app_flat_22: 7,
    char_23: 'jing-yuan',
    app_rate_23: 0.12,
    avg_round_23: 3390,
    app_flat_23: 3,
    char_24: 'Robin',
    app_rate_24: 0.12,
    avg_round_24: 3308,
    app_flat_24: 1,
    char_25: 'Acheron',
    app_rate_25: 0.12,
    avg_round_25: 3123,
    app_flat_25: 1,
    char_26: 'silver-wolf',
    app_rate_26: 0.1,
    avg_round_26: 3168,
    app_flat_26: 2,
    char_27: 'Serval',
    app_rate_27: 0.1,
    avg_round_27: 3355,
    app_flat_27: 5,
    char_28: 'Blade',
    app_rate_28: 0.09,
    avg_round_28: 3376,
    app_flat_28: 3,
    char_29: 'Kafka',
    app_rate_29: 0.09,
    avg_round_29: 3442,
    app_flat_29: 2,
    char_30: 'Feixiao',
    app_rate_30: 0.07,
    avg_round_30: 3223,
    app_flat_30: 1
  },
  {
    char: 'ruan-mei',
    round: 3410,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 65.73,
    avg_round_1: 3413,
    app_flat_1: 2284,
    char_2: 'Gallagher',
    app_rate_2: 65.02,
    avg_round_2: 3425,
    app_flat_2: 2439,
    char_3: 'Firefly',
    app_rate_3: 60.86,
    avg_round_3: 3419,
    app_flat_3: 1986,
    char_4: 'Kafka',
    app_rate_4: 13.27,
    avg_round_4: 3375,
    app_flat_4: 382,
    char_5: 'black-swan',
    app_rate_5: 13.02,
    avg_round_5: 3377,
    app_flat_5: 367,
    char_6: 'Huohuo',
    app_rate_6: 11.75,
    avg_round_6: 3365,
    app_flat_6: 332,
    char_7: 'Bronya',
    app_rate_7: 10.58,
    avg_round_7: 3464,
    app_flat_7: 406,
    char_8: 'Boothill',
    app_rate_8: 7.82,
    avg_round_8: 3469,
    app_flat_8: 398,
    char_9: 'Jingliu',
    app_rate_9: 7.22,
    avg_round_9: 3369,
    app_flat_9: 267,
    char_10: 'Luocha',
    app_rate_10: 6.58,
    avg_round_10: 3362,
    app_flat_10: 240,
    char_11: 'fu-xuan',
    app_rate_11: 4.25,
    avg_round_11: 3369,
    app_flat_11: 134,
    char_12: 'Acheron',
    app_rate_12: 3.8,
    avg_round_12: 3334,
    app_flat_12: 14,
    char_13: 'Aventurine',
    app_rate_13: 3.72,
    avg_round_13: 3412,
    app_flat_13: 135,
    char_14: 'Sparkle',
    app_rate_14: 3.1,
    avg_round_14: 3277,
    app_flat_14: 39,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 2.16,
    avg_round_15: 3408,
    app_flat_15: 104,
    char_16: 'imbibitor-lunae',
    app_rate_16: 1.96,
    avg_round_16: 3314,
    app_flat_16: 13,
    char_17: 'Feixiao',
    app_rate_17: 1.94,
    avg_round_17: 3475,
    app_flat_17: 85,
    char_18: 'Jiaoqiu',
    app_rate_18: 1.94,
    avg_round_18: 3403,
    app_flat_18: 13,
    char_19: 'Blade',
    app_rate_19: 1.73,
    avg_round_19: 3317,
    app_flat_19: 49,
    char_20: 'Himeko',
    app_rate_20: 1.55,
    avg_round_20: 3332,
    app_flat_20: 74,
    char_21: 'Pela',
    app_rate_21: 1.43,
    avg_round_21: 3290,
    app_flat_21: 37,
    char_22: 'Tingyun',
    app_rate_22: 1.33,
    avg_round_22: 3309,
    app_flat_22: 29,
    char_23: 'Robin',
    app_rate_23: 0.98,
    avg_round_23: 3394,
    app_flat_23: 20,
    char_24: 'Bailu',
    app_rate_24: 0.92,
    avg_round_24: 3339,
    app_flat_24: 36,
    char_25: 'topaz',
    app_rate_25: 0.83,
    avg_round_25: 3480,
    app_flat_25: 24,
    char_26: 'Yunli',
    app_rate_26: 0.8,
    avg_round_26: 3333,
    app_flat_26: 23,
    char_27: 'Gepard',
    app_rate_27: 0.69,
    avg_round_27: 3404,
    app_flat_27: 21,
    char_28: 'jing-yuan',
    app_rate_28: 0.59,
    avg_round_28: 3277,
    app_flat_28: 15,
    char_29: 'Lynx',
    app_rate_29: 0.49,
    avg_round_29: 3353,
    app_flat_29: 14,
    char_30: 'Herta',
    app_rate_30: 0.44,
    avg_round_30: 3247,
    app_flat_30: 10
  },
  {
    char: 'Natasha',
    round: 3381,
    char_1: 'Feixiao',
    app_rate_1: 38.71,
    avg_round_1: 3523,
    app_flat_1: 9,
    char_2: 'Robin',
    app_rate_2: 35.48,
    avg_round_2: 3523,
    app_flat_2: 9,
    char_3: 'ruan-mei',
    app_rate_3: 32.26,
    avg_round_3: 3293,
    app_flat_3: 5,
    char_4: 'Bronya',
    app_rate_4: 29.03,
    avg_round_4: 3296,
    app_flat_4: 4,
    char_5: 'Jingliu',
    app_rate_5: 19.35,
    avg_round_5: 3238,
    app_flat_5: 3,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 16.13,
    avg_round_6: 3514,
    app_flat_6: 4,
    char_7: 'Kafka',
    app_rate_7: 12.9,
    avg_round_7: 3365,
    app_flat_7: 2,
    char_8: 'topaz',
    app_rate_8: 12.9,
    avg_round_8: 3616,
    app_flat_8: 2,
    char_9: 'Tingyun',
    app_rate_9: 12.9,
    avg_round_9: 3280,
    app_flat_9: 2,
    char_10: 'black-swan',
    app_rate_10: 9.68,
    avg_round_10: 3365,
    app_flat_10: 2,
    char_11: 'Blade',
    app_rate_11: 9.68,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 9.68,
    avg_round_12: 3242,
    app_flat_12: 2,
    char_13: 'Acheron',
    app_rate_13: 6.45,
    avg_round_13: 3347,
    app_flat_13: 1,
    char_14: 'Boothill',
    app_rate_14: 6.45,
    avg_round_14: 3454,
    app_flat_14: 1,
    char_15: 'Sparkle',
    app_rate_15: 6.45,
    avg_round_15: 3559,
    app_flat_15: 1,
    char_16: 'Luka',
    app_rate_16: 6.45,
    avg_round_16: 3266,
    app_flat_16: 2,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 6.45,
    avg_round_17: 3295,
    app_flat_17: 1,
    char_18: 'Yunli',
    app_rate_18: 6.45,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Clara',
    app_rate_19: 3.23,
    avg_round_19: 3175,
    app_flat_19: 1,
    char_20: 'Moze',
    app_rate_20: 3.23,
    avg_round_20: 3474,
    app_flat_20: 1,
    char_21: 'Misha',
    app_rate_21: 3.23,
    avg_round_21: 3325,
    app_flat_21: 1,
    char_22: 'dr-ratio',
    app_rate_22: 3.23,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Firefly',
    app_rate_23: 3.23,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Sushang',
    app_rate_24: 3.23,
    avg_round_24: 3236,
    app_flat_24: 1,
    char_25: 'Jade',
    app_rate_25: 3.23,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    round: 3380,
    char_1: 'black-swan',
    app_rate_1: 91.81,
    avg_round_1: 3378,
    app_flat_1: 834,
    char_2: 'ruan-mei',
    app_rate_2: 45.01,
    avg_round_2: 3375,
    app_flat_2: 382,
    char_3: 'Acheron',
    app_rate_3: 43.63,
    avg_round_3: 3384,
    app_flat_3: 393,
    char_4: 'Huohuo',
    app_rate_4: 33.37,
    avg_round_4: 3352,
    app_flat_4: 279,
    char_5: 'Gallagher',
    app_rate_5: 18.41,
    avg_round_5: 3414,
    app_flat_5: 249,
    char_6: 'fu-xuan',
    app_rate_6: 13.67,
    avg_round_6: 3343,
    app_flat_6: 126,
    char_7: 'Luocha',
    app_rate_7: 11.79,
    avg_round_7: 3376,
    app_flat_7: 103,
    char_8: 'Jiaoqiu',
    app_rate_8: 9.95,
    avg_round_8: 3397,
    app_flat_8: 72,
    char_9: 'Robin',
    app_rate_9: 8.5,
    avg_round_9: 3327,
    app_flat_9: 67,
    char_10: 'Aventurine',
    app_rate_10: 8.07,
    avg_round_10: 3377,
    app_flat_10: 106,
    char_11: 'Guinaifen',
    app_rate_11: 2.47,
    avg_round_11: 3337,
    app_flat_11: 22,
    char_12: 'Sampo',
    app_rate_12: 2.27,
    avg_round_12: 3305,
    app_flat_12: 21,
    char_13: 'Gepard',
    app_rate_13: 1.8,
    avg_round_13: 3409,
    app_flat_13: 24,
    char_14: 'Bailu',
    app_rate_14: 1.76,
    avg_round_14: 3338,
    app_flat_14: 21,
    char_15: 'Asta',
    app_rate_15: 1.29,
    avg_round_15: 3335,
    app_flat_15: 10,
    char_16: 'Sparkle',
    app_rate_16: 1.14,
    avg_round_16: 3248,
    app_flat_16: 2,
    char_17: 'Pela',
    app_rate_17: 0.86,
    avg_round_17: 3206,
    app_flat_17: 8,
    char_18: 'Tingyun',
    app_rate_18: 0.86,
    avg_round_18: 3232,
    app_flat_18: 5,
    char_19: 'Lynx',
    app_rate_19: 0.59,
    avg_round_19: 3296,
    app_flat_19: 6,
    char_20: 'silver-wolf',
    app_rate_20: 0.47,
    avg_round_20: 3356,
    app_flat_20: 4,
    char_21: 'march-7th-swordmaster',
    app_rate_21: 0.35,
    avg_round_21: 3240,
    app_flat_21: 4,
    char_22: 'Feixiao',
    app_rate_22: 0.31,
    avg_round_22: 3335,
    app_flat_22: 3,
    char_23: 'Himeko',
    app_rate_23: 0.27,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.2,
    avg_round_24: 3442,
    app_flat_24: 2,
    char_25: 'Natasha',
    app_rate_25: 0.16,
    avg_round_25: 3365,
    app_flat_25: 2,
    char_26: 'topaz',
    app_rate_26: 0.16,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Luka',
    app_rate_27: 0.16,
    avg_round_27: 3255,
    app_flat_27: 1,
    char_28: 'Jade',
    app_rate_28: 0.12,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Firefly',
    app_rate_29: 0.12,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Blade',
    app_rate_30: 0.08,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Luocha',
    round: 3374,
    char_1: 'ruan-mei',
    app_rate_1: 33.47,
    avg_round_1: 3362,
    app_flat_1: 240,
    char_2: 'Robin',
    app_rate_2: 30.24,
    avg_round_2: 3483,
    app_flat_2: 306,
    char_3: 'Bronya',
    app_rate_3: 27.07,
    avg_round_3: 3384,
    app_flat_3: 260,
    char_4: 'Feixiao',
    app_rate_4: 24.31,
    avg_round_4: 3516,
    app_flat_4: 283,
    char_5: 'Jingliu',
    app_rate_5: 23.08,
    avg_round_5: 3335,
    app_flat_5: 205,
    char_6: 'black-swan',
    app_rate_6: 18.32,
    avg_round_6: 3372,
    app_flat_6: 109,
    char_7: 'Kafka',
    app_rate_7: 17.67,
    avg_round_7: 3376,
    app_flat_7: 103,
    char_8: 'Sparkle',
    app_rate_8: 15.21,
    avg_round_8: 3278,
    app_flat_8: 68,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 13.8,
    avg_round_9: 3539,
    app_flat_9: 160,
    char_10: 'Acheron',
    app_rate_10: 13.39,
    avg_round_10: 3355,
    app_flat_10: 64,
    char_11: 'Blade',
    app_rate_11: 9.22,
    avg_round_11: 3272,
    app_flat_11: 61,
    char_12: 'Pela',
    app_rate_12: 9.1,
    avg_round_12: 3309,
    app_flat_12: 89,
    char_13: 'Jiaoqiu',
    app_rate_13: 8.57,
    avg_round_13: 3355,
    app_flat_13: 36,
    char_14: 'topaz',
    app_rate_14: 8.46,
    avg_round_14: 3478,
    app_flat_14: 97,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 7.81,
    avg_round_15: 3341,
    app_flat_15: 56,
    char_16: 'imbibitor-lunae',
    app_rate_16: 7.05,
    avg_round_16: 3222,
    app_flat_16: 10,
    char_17: 'Firefly',
    app_rate_17: 5.99,
    avg_round_17: 3319,
    app_flat_17: 39,
    char_18: 'Boothill',
    app_rate_18: 5.99,
    avg_round_18: 3444,
    app_flat_18: 70,
    char_19: 'Tingyun',
    app_rate_19: 4.82,
    avg_round_19: 3288,
    app_flat_19: 30,
    char_20: 'Yunli',
    app_rate_20: 2.52,
    avg_round_20: 3330,
    app_flat_20: 25,
    char_21: 'Moze',
    app_rate_21: 2.29,
    avg_round_21: 3426,
    app_flat_21: 22,
    char_22: 'Jade',
    app_rate_22: 1.64,
    avg_round_22: 3244,
    app_flat_22: 4,
    char_23: 'jing-yuan',
    app_rate_23: 1.53,
    avg_round_23: 3262,
    app_flat_23: 16,
    char_24: 'silver-wolf',
    app_rate_24: 1.53,
    avg_round_24: 3205,
    app_flat_24: 7,
    char_25: 'Herta',
    app_rate_25: 1.41,
    avg_round_25: 3348,
    app_flat_25: 18,
    char_26: 'Clara',
    app_rate_26: 0.94,
    avg_round_26: 3298,
    app_flat_26: 11,
    char_27: 'Guinaifen',
    app_rate_27: 0.88,
    avg_round_27: 3305,
    app_flat_27: 6,
    char_28: 'Sampo',
    app_rate_28: 0.65,
    avg_round_28: 3229,
    app_flat_28: 6,
    char_29: 'dr-ratio',
    app_rate_29: 0.59,
    avg_round_29: 3206,
    app_flat_29: 5,
    char_30: 'Sushang',
    app_rate_30: 0.35,
    avg_round_30: 3288,
    app_flat_30: 3
  },
  {
    char: 'Jade',
    round: 3374,
    char_1: 'Robin',
    app_rate_1: 55.67,
    avg_round_1: 3426,
    app_flat_1: 17,
    char_2: 'Feixiao',
    app_rate_2: 44.33,
    avg_round_2: 3471,
    app_flat_2: 15,
    char_3: 'Aventurine',
    app_rate_3: 32.02,
    avg_round_3: 3476,
    app_flat_3: 7,
    char_4: 'Blade',
    app_rate_4: 26.6,
    avg_round_4: 3264,
    app_flat_4: 15,
    char_5: 'Bronya',
    app_rate_5: 19.21,
    avg_round_5: 3295,
    app_flat_5: 8,
    char_6: 'Huohuo',
    app_rate_6: 18.23,
    avg_round_6: 3325,
    app_flat_6: 12,
    char_7: 'fu-xuan',
    app_rate_7: 16.75,
    avg_round_7: 3412,
    app_flat_7: 8,
    char_8: 'Luocha',
    app_rate_8: 13.79,
    avg_round_8: 3244,
    app_flat_8: 4,
    char_9: 'ruan-mei',
    app_rate_9: 10.84,
    avg_round_9: 3294,
    app_flat_9: 3,
    char_10: 'Yunli',
    app_rate_10: 9.36,
    avg_round_10: 3303,
    app_flat_10: 6,
    char_11: 'Sparkle',
    app_rate_11: 8.87,
    avg_round_11: 3248,
    app_flat_11: 4,
    char_12: 'topaz',
    app_rate_12: 7.88,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Herta',
    app_rate_13: 6.4,
    avg_round_13: 3450,
    app_flat_13: 1,
    char_14: 'Gallagher',
    app_rate_14: 5.91,
    avg_round_14: 3302,
    app_flat_14: 1,
    char_15: 'Jingliu',
    app_rate_15: 4.93,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 2.96,
    avg_round_16: 3545,
    app_flat_16: 1,
    char_17: 'Bailu',
    app_rate_17: 2.46,
    avg_round_17: 3319,
    app_flat_17: 2,
    char_18: 'Lynx',
    app_rate_18: 2.46,
    avg_round_18: 3301,
    app_flat_18: 2,
    char_19: 'jing-yuan',
    app_rate_19: 1.97,
    avg_round_19: 3371,
    app_flat_19: 1,
    char_20: 'Kafka',
    app_rate_20: 1.48,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: 1.48,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Tingyun',
    app_rate_22: 0.99,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'black-swan',
    app_rate_23: 0.99,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: 0.99,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Argenti',
    app_rate_25: 0.99,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Moze',
    app_rate_26: 0.49,
    avg_round_26: 3370,
    app_flat_26: 1,
    char_27: 'Jiaoqiu',
    app_rate_27: 0.49,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Firefly',
    app_rate_28: 0.49,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.49,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Natasha',
    app_rate_30: 0.49,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    round: 3372,
    char_1: 'Acheron',
    app_rate_1: 57.61,
    avg_round_1: 3363,
    app_flat_1: 45,
    char_2: 'Pela',
    app_rate_2: 26.34,
    avg_round_2: 3302,
    app_flat_2: 23,
    char_3: 'ruan-mei',
    app_rate_3: 24.69,
    avg_round_3: 3404,
    app_flat_3: 21,
    char_4: 'black-swan',
    app_rate_4: 23.05,
    avg_round_4: 3408,
    app_flat_4: 25,
    char_5: 'Kafka',
    app_rate_5: 18.93,
    avg_round_5: 3409,
    app_flat_5: 24,
    char_6: 'Jiaoqiu',
    app_rate_6: 18.93,
    avg_round_6: 3316,
    app_flat_6: 16,
    char_7: 'Sparkle',
    app_rate_7: 18.11,
    avg_round_7: 3228,
    app_flat_7: 4,
    char_8: 'Robin',
    app_rate_8: 17.7,
    avg_round_8: 3483,
    app_flat_8: 31,
    char_9: 'Bronya',
    app_rate_9: 14.81,
    avg_round_9: 3448,
    app_flat_9: 11,
    char_10: 'Feixiao',
    app_rate_10: 14.4,
    avg_round_10: 3524,
    app_flat_10: 26,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 9.88,
    avg_round_11: 3512,
    app_flat_11: 21,
    char_12: 'silver-wolf',
    app_rate_12: 9.05,
    avg_round_12: 3308,
    app_flat_12: 8,
    char_13: 'Boothill',
    app_rate_13: 8.64,
    avg_round_13: 3428,
    app_flat_13: 14,
    char_14: 'Jingliu',
    app_rate_14: 8.23,
    avg_round_14: 3303,
    app_flat_14: 7,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 6.58,
    avg_round_15: 3443,
    app_flat_15: 8,
    char_16: 'topaz',
    app_rate_16: 3.7,
    avg_round_16: 3537,
    app_flat_16: 4,
    char_17: 'Yanqing',
    app_rate_17: 3.7,
    avg_round_17: 3203,
    app_flat_17: 6,
    char_18: 'Guinaifen',
    app_rate_18: 3.29,
    avg_round_18: 3283,
    app_flat_18: 3,
    char_19: 'Firefly',
    app_rate_19: 2.47,
    avg_round_19: 3430,
    app_flat_19: 2,
    char_20: 'Tingyun',
    app_rate_20: 2.06,
    avg_round_20: 3288,
    app_flat_20: 4,
    char_21: 'Herta',
    app_rate_21: 1.65,
    avg_round_21: 3369,
    app_flat_21: 2,
    char_22: 'jing-yuan',
    app_rate_22: 1.23,
    avg_round_22: 2931,
    app_flat_22: 1,
    char_23: 'Luka',
    app_rate_23: 1.23,
    avg_round_23: 3324,
    app_flat_23: 2,
    char_24: 'Blade',
    app_rate_24: 0.82,
    avg_round_24: 3204,
    app_flat_24: 1,
    char_25: 'Moze',
    app_rate_25: 0.82,
    avg_round_25: 3158,
    app_flat_25: 2,
    char_26: 'Welt',
    app_rate_26: 0.41,
    avg_round_26: 3188,
    app_flat_26: 1,
    char_27: 'dr-ratio',
    app_rate_27: 0.41,
    avg_round_27: 3129,
    app_flat_27: 1,
    char_28: 'Yunli',
    app_rate_28: 0.41,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Clara',
    app_rate_29: 0.41,
    avg_round_29: 3155,
    app_flat_29: 1,
    char_30: 'Hanya',
    app_rate_30: 0.41,
    avg_round_30: 3155,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    round: 3370,
    char_1: 'Kafka',
    app_rate_1: 85.86,
    avg_round_1: 3378,
    app_flat_1: 834,
    char_2: 'Acheron',
    app_rate_2: 49.34,
    avg_round_2: 3365,
    app_flat_2: 496,
    char_3: 'ruan-mei',
    app_rate_3: 41.28,
    avg_round_3: 3377,
    app_flat_3: 367,
    char_4: 'Huohuo',
    app_rate_4: 31.76,
    avg_round_4: 3348,
    app_flat_4: 288,
    char_5: 'Gallagher',
    app_rate_5: 18.53,
    avg_round_5: 3410,
    app_flat_5: 263,
    char_6: 'fu-xuan',
    app_rate_6: 15.09,
    avg_round_6: 3320,
    app_flat_6: 149,
    char_7: 'Jiaoqiu',
    app_rate_7: 12.56,
    avg_round_7: 3351,
    app_flat_7: 130,
    char_8: 'Luocha',
    app_rate_8: 11.43,
    avg_round_8: 3372,
    app_flat_8: 109,
    char_9: 'Aventurine',
    app_rate_9: 9.19,
    avg_round_9: 3371,
    app_flat_9: 130,
    char_10: 'Robin',
    app_rate_10: 7.77,
    avg_round_10: 3321,
    app_flat_10: 60,
    char_11: 'Pela',
    app_rate_11: 2.64,
    avg_round_11: 3245,
    app_flat_11: 40,
    char_12: 'Sparkle',
    app_rate_12: 2.34,
    avg_round_12: 3368,
    app_flat_12: 1,
    char_13: 'Guinaifen',
    app_rate_13: 2.2,
    avg_round_13: 3368,
    app_flat_13: 18,
    char_14: 'Gepard',
    app_rate_14: 2.05,
    avg_round_14: 3408,
    app_flat_14: 25,
    char_15: 'Bailu',
    app_rate_15: 1.58,
    avg_round_15: 3334,
    app_flat_15: 20,
    char_16: 'Sampo',
    app_rate_16: 1.21,
    avg_round_16: 3314,
    app_flat_16: 8,
    char_17: 'Asta',
    app_rate_17: 1.17,
    avg_round_17: 3341,
    app_flat_17: 10,
    char_18: 'Tingyun',
    app_rate_18: 0.84,
    avg_round_18: 3232,
    app_flat_18: 5,
    char_19: 'silver-wolf',
    app_rate_19: 0.62,
    avg_round_19: 3309,
    app_flat_19: 7,
    char_20: 'Lynx',
    app_rate_20: 0.48,
    avg_round_20: 3339,
    app_flat_20: 5,
    char_21: 'Bronya',
    app_rate_21: 0.4,
    avg_round_21: 3176,
    app_flat_21: 2,
    char_22: 'Feixiao',
    app_rate_22: 0.29,
    avg_round_22: 3308,
    app_flat_22: 1,
    char_23: 'Himeko',
    app_rate_23: 0.26,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 0.15,
    avg_round_24: 3161,
    app_flat_24: 1,
    char_25: 'Natasha',
    app_rate_25: 0.11,
    avg_round_25: 3365,
    app_flat_25: 2,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.11,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Firefly',
    app_rate_27: 0.11,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Jade',
    app_rate_28: 0.07,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-fire',
    app_rate_29: 0.07,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'topaz',
    app_rate_30: 0.07,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    round: 3367,
    char_1: 'Feixiao',
    app_rate_1: 83.48,
    avg_round_1: 3554,
    app_flat_1: 1526,
    char_2: 'Robin',
    app_rate_2: 74.61,
    avg_round_2: 3579,
    app_flat_2: 1324,
    char_3: 'Aventurine',
    app_rate_3: 31.86,
    avg_round_3: 3574,
    app_flat_3: 619,
    char_4: 'fu-xuan',
    app_rate_4: 22.08,
    avg_round_4: 3553,
    app_flat_4: 376,
    char_5: 'Gallagher',
    app_rate_5: 14.9,
    avg_round_5: 3494,
    app_flat_5: 299,
    char_6: 'Huohuo',
    app_rate_6: 14.39,
    avg_round_6: 3498,
    app_flat_6: 259,
    char_7: 'Luocha',
    app_rate_7: 8.65,
    avg_round_7: 3539,
    app_flat_7: 160,
    char_8: 'Bronya',
    app_rate_8: 7.4,
    avg_round_8: 3521,
    app_flat_8: 111,
    char_9: 'ruan-mei',
    app_rate_9: 6.88,
    avg_round_9: 3408,
    app_flat_9: 104,
    char_10: 'Sparkle',
    app_rate_10: 5.37,
    avg_round_10: 3426,
    app_flat_10: 72,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 4.78,
    avg_round_11: 3348,
    app_flat_11: 77,
    char_12: 'Yunli',
    app_rate_12: 4.49,
    avg_round_12: 3357,
    app_flat_12: 78,
    char_13: 'topaz',
    app_rate_13: 4.12,
    avg_round_13: 3388,
    app_flat_13: 71,
    char_14: 'Moze',
    app_rate_14: 2.32,
    avg_round_14: 3353,
    app_flat_14: 49,
    char_15: 'Firefly',
    app_rate_15: 1.73,
    avg_round_15: 3319,
    app_flat_15: 16,
    char_16: 'Herta',
    app_rate_16: 1.51,
    avg_round_16: 3350,
    app_flat_16: 20,
    char_17: 'Bailu',
    app_rate_17: 1.18,
    avg_round_17: 3512,
    app_flat_17: 29,
    char_18: 'Clara',
    app_rate_18: 0.92,
    avg_round_18: 3226,
    app_flat_18: 13,
    char_19: 'Gepard',
    app_rate_19: 0.88,
    avg_round_19: 3512,
    app_flat_19: 21,
    char_20: 'Lynx',
    app_rate_20: 0.81,
    avg_round_20: 3343,
    app_flat_20: 17,
    char_21: 'Jingliu',
    app_rate_21: 0.81,
    avg_round_21: 3349,
    app_flat_21: 15,
    char_22: 'Tingyun',
    app_rate_22: 0.7,
    avg_round_22: 3207,
    app_flat_22: 12,
    char_23: 'Blade',
    app_rate_23: 0.66,
    avg_round_23: 3266,
    app_flat_23: 10,
    char_24: 'Boothill',
    app_rate_24: 0.66,
    avg_round_24: 3404,
    app_flat_24: 11,
    char_25: 'dr-ratio',
    app_rate_25: 0.59,
    avg_round_25: 3329,
    app_flat_25: 10,
    char_26: 'Yanqing',
    app_rate_26: 0.52,
    avg_round_26: 3286,
    app_flat_26: 9,
    char_27: 'jing-yuan',
    app_rate_27: 0.4,
    avg_round_27: 3330,
    app_flat_27: 5,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.37,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Kafka',
    app_rate_29: 0.33,
    avg_round_29: 3240,
    app_flat_29: 4,
    char_30: 'Pela',
    app_rate_30: 0.33,
    avg_round_30: 3313,
    app_flat_30: 5
  },
  {
    char: 'Huohuo',
    round: 3367,
    char_1: 'Robin',
    app_rate_1: 36.0,
    avg_round_1: 3444,
    app_flat_1: 621,
    char_2: 'ruan-mei',
    app_rate_2: 32.99,
    avg_round_2: 3365,
    app_flat_2: 332,
    char_3: 'black-swan',
    app_rate_3: 28.12,
    avg_round_3: 3348,
    app_flat_3: 288,
    char_4: 'Kafka',
    app_rate_4: 27.64,
    avg_round_4: 3352,
    app_flat_4: 279,
    char_5: 'Jingliu',
    app_rate_5: 18.81,
    avg_round_5: 3357,
    app_flat_5: 297,
    char_6: 'Feixiao',
    app_rate_6: 18.16,
    avg_round_6: 3513,
    app_flat_6: 357,
    char_7: 'Bronya',
    app_rate_7: 16.25,
    avg_round_7: 3390,
    app_flat_7: 254,
    char_8: 'Sparkle',
    app_rate_8: 16.19,
    avg_round_8: 3338,
    app_flat_8: 201,
    char_9: 'Yunli',
    app_rate_9: 15.41,
    avg_round_9: 3365,
    app_flat_9: 265,
    char_10: 'Tingyun',
    app_rate_10: 15.12,
    avg_round_10: 3344,
    app_flat_10: 244,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 12.68,
    avg_round_11: 3498,
    app_flat_11: 259,
    char_12: 'Acheron',
    app_rate_12: 11.84,
    avg_round_12: 3336,
    app_flat_12: 140,
    char_13: 'Pela',
    app_rate_13: 6.26,
    avg_round_13: 3289,
    app_flat_13: 109,
    char_14: 'topaz',
    app_rate_14: 5.97,
    avg_round_14: 3433,
    app_flat_14: 113,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 5.42,
    avg_round_15: 3314,
    app_flat_15: 42,
    char_16: 'Jiaoqiu',
    app_rate_16: 5.35,
    avg_round_16: 3316,
    app_flat_16: 53,
    char_17: 'Firefly',
    app_rate_17: 4.67,
    avg_round_17: 3198,
    app_flat_17: 22,
    char_18: 'Blade',
    app_rate_18: 4.54,
    avg_round_18: 3285,
    app_flat_18: 65,
    char_19: 'imbibitor-lunae',
    app_rate_19: 3.02,
    avg_round_19: 3319,
    app_flat_19: 11,
    char_20: 'Boothill',
    app_rate_20: 2.82,
    avg_round_20: 3455,
    app_flat_20: 56,
    char_21: 'jing-yuan',
    app_rate_21: 2.79,
    avg_round_21: 3361,
    app_flat_21: 32,
    char_22: 'Herta',
    app_rate_22: 1.43,
    avg_round_22: 3390,
    app_flat_22: 20,
    char_23: 'silver-wolf',
    app_rate_23: 1.2,
    avg_round_23: 3202,
    app_flat_23: 15,
    char_24: 'Jade',
    app_rate_24: 1.2,
    avg_round_24: 3325,
    app_flat_24: 12,
    char_25: 'Argenti',
    app_rate_25: 1.04,
    avg_round_25: 3378,
    app_flat_25: 17,
    char_26: 'Moze',
    app_rate_26: 0.94,
    avg_round_26: 3381,
    app_flat_26: 21,
    char_27: 'Clara',
    app_rate_27: 0.91,
    avg_round_27: 3188,
    app_flat_27: 12,
    char_28: 'Guinaifen',
    app_rate_28: 0.71,
    avg_round_28: 3358,
    app_flat_28: 4,
    char_29: 'Sampo',
    app_rate_29: 0.45,
    avg_round_29: 3334,
    app_flat_29: 3,
    char_30: 'dr-ratio',
    app_rate_30: 0.32,
    avg_round_30: 3275,
    app_flat_30: 7
  },
  {
    char: 'Yunli',
    round: 3364,
    char_1: 'Robin',
    app_rate_1: 69.38,
    avg_round_1: 3381,
    app_flat_1: 349,
    char_2: 'Huohuo',
    app_rate_2: 52.31,
    avg_round_2: 3365,
    app_flat_2: 265,
    char_3: 'Tingyun',
    app_rate_3: 42.73,
    avg_round_3: 3329,
    app_flat_3: 232,
    char_4: 'Sparkle',
    app_rate_4: 25.77,
    avg_round_4: 3299,
    app_flat_4: 95,
    char_5: 'fu-xuan',
    app_rate_5: 20.37,
    avg_round_5: 3309,
    app_flat_5: 82,
    char_6: 'Feixiao',
    app_rate_6: 18.61,
    avg_round_6: 3497,
    app_flat_6: 91,
    char_7: 'Aventurine',
    app_rate_7: 15.86,
    avg_round_7: 3385,
    app_flat_7: 91,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 13.44,
    avg_round_8: 3357,
    app_flat_8: 78,
    char_9: 'ruan-mei',
    app_rate_9: 7.6,
    avg_round_9: 3333,
    app_flat_9: 23,
    char_10: 'topaz',
    app_rate_10: 7.38,
    avg_round_10: 3276,
    app_flat_10: 37,
    char_11: 'Luocha',
    app_rate_11: 4.74,
    avg_round_11: 3330,
    app_flat_11: 25,
    char_12: 'Herta',
    app_rate_12: 2.31,
    avg_round_12: 3241,
    app_flat_12: 6,
    char_13: 'Clara',
    app_rate_13: 2.09,
    avg_round_13: 3206,
    app_flat_13: 10,
    char_14: 'Jade',
    app_rate_14: 2.09,
    avg_round_14: 3303,
    app_flat_14: 6,
    char_15: 'Blade',
    app_rate_15: 1.98,
    avg_round_15: 3236,
    app_flat_15: 6,
    char_16: 'Bronya',
    app_rate_16: 1.54,
    avg_round_16: 3335,
    app_flat_16: 5,
    char_17: 'Jingliu',
    app_rate_17: 1.43,
    avg_round_17: 3139,
    app_flat_17: 5,
    char_18: 'Bailu',
    app_rate_18: 1.32,
    avg_round_18: 3305,
    app_flat_18: 9,
    char_19: 'Pela',
    app_rate_19: 1.32,
    avg_round_19: 3188,
    app_flat_19: 7,
    char_20: 'Lynx',
    app_rate_20: 1.32,
    avg_round_20: 3265,
    app_flat_20: 8,
    char_21: 'Boothill',
    app_rate_21: 1.1,
    avg_round_21: 3410,
    app_flat_21: 6,
    char_22: 'Gallagher',
    app_rate_22: 0.88,
    avg_round_22: 3449,
    app_flat_22: 5,
    char_23: 'jing-yuan',
    app_rate_23: 0.66,
    avg_round_23: 3283,
    app_flat_23: 3,
    char_24: 'Jiaoqiu',
    app_rate_24: 0.66,
    avg_round_24: 3409,
    app_flat_24: 2,
    char_25: 'Moze',
    app_rate_25: 0.44,
    avg_round_25: 3255,
    app_flat_25: 3,
    char_26: 'Acheron',
    app_rate_26: 0.44,
    avg_round_26: 3141,
    app_flat_26: 1,
    char_27: 'Sushang',
    app_rate_27: 0.33,
    avg_round_27: 3115,
    app_flat_27: 1,
    char_28: 'Firefly',
    app_rate_28: 0.33,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Hanya',
    app_rate_29: 0.22,
    avg_round_29: 3200,
    app_flat_29: 2,
    char_30: 'Natasha',
    app_rate_30: 0.22,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    round: 3356,
    char_1: 'Tingyun',
    app_rate_1: 48.0,
    avg_round_1: 3336,
    app_flat_1: 17,
    char_2: 'Huohuo',
    app_rate_2: 42.67,
    avg_round_2: 3378,
    app_flat_2: 17,
    char_3: 'Sparkle',
    app_rate_3: 36.0,
    avg_round_3: 3346,
    app_flat_3: 15,
    char_4: 'ruan-mei',
    app_rate_4: 30.67,
    avg_round_4: 3390,
    app_flat_4: 4,
    char_5: 'Robin',
    app_rate_5: 26.67,
    avg_round_5: 3370,
    app_flat_5: 6,
    char_6: 'Boothill',
    app_rate_6: 20.0,
    avg_round_6: 3502,
    app_flat_6: 2,
    char_7: 'Bronya',
    app_rate_7: 14.67,
    avg_round_7: 3388,
    app_flat_7: 3,
    char_8: 'Aventurine',
    app_rate_8: 13.33,
    avg_round_8: 3165,
    app_flat_8: 1,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 12.0,
    avg_round_9: 3308,
    app_flat_9: 1,
    char_10: 'fu-xuan',
    app_rate_10: 12.0,
    avg_round_10: 3228,
    app_flat_10: 2,
    char_11: 'Luocha',
    app_rate_11: 8.0,
    avg_round_11: 3259,
    app_flat_11: 3,
    char_12: 'Gallagher',
    app_rate_12: 6.67,
    avg_round_12: 3502,
    app_flat_12: 2,
    char_13: 'Jingliu',
    app_rate_13: 4.0,
    avg_round_13: 3234,
    app_flat_13: 1,
    char_14: 'Feixiao',
    app_rate_14: 4.0,
    avg_round_14: 3400,
    app_flat_14: 1,
    char_15: 'Hanya',
    app_rate_15: 2.67,
    avg_round_15: 3165,
    app_flat_15: 1,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 2.67,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'jing-yuan',
    app_rate_17: 2.67,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Jade',
    app_rate_18: 2.67,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Yunli',
    app_rate_19: 2.67,
    avg_round_19: 3309,
    app_flat_19: 1,
    char_20: 'Moze',
    app_rate_20: 1.33,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Bailu',
    app_rate_21: 1.33,
    avg_round_21: 3308,
    app_flat_21: 1,
    char_22: 'Lynx',
    app_rate_22: 1.33,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Acheron',
    app_rate_23: 1.33,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Herta',
    app_rate_24: 1.33,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Jiaoqiu',
    app_rate_25: 1.33,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jiaoqiu',
    round: 3352,
    char_1: 'Acheron',
    app_rate_1: 85.59,
    avg_round_1: 3355,
    app_flat_1: 623,
    char_2: 'Sparkle',
    app_rate_2: 32.82,
    avg_round_2: 3337,
    app_flat_2: 39,
    char_3: 'fu-xuan',
    app_rate_3: 30.02,
    avg_round_3: 3308,
    app_flat_3: 195,
    char_4: 'Pela',
    app_rate_4: 25.69,
    avg_round_4: 3352,
    app_flat_4: 348,
    char_5: 'Aventurine',
    app_rate_5: 22.84,
    avg_round_5: 3362,
    app_flat_5: 196,
    char_6: 'Gallagher',
    app_rate_6: 18.46,
    avg_round_6: 3412,
    app_flat_6: 201,
    char_7: 'black-swan',
    app_rate_7: 15.99,
    avg_round_7: 3351,
    app_flat_7: 130,
    char_8: 'Kafka',
    app_rate_8: 11.84,
    avg_round_8: 3397,
    app_flat_8: 72,
    char_9: 'silver-wolf',
    app_rate_9: 8.95,
    avg_round_9: 3374,
    app_flat_9: 85,
    char_10: 'ruan-mei',
    app_rate_10: 7.83,
    avg_round_10: 3403,
    app_flat_10: 13,
    char_11: 'Huohuo',
    app_rate_11: 7.69,
    avg_round_11: 3316,
    app_flat_11: 53,
    char_12: 'Luocha',
    app_rate_12: 6.81,
    avg_round_12: 3355,
    app_flat_12: 36,
    char_13: 'Robin',
    app_rate_13: 5.08,
    avg_round_13: 3380,
    app_flat_13: 23,
    char_14: 'Bronya',
    app_rate_14: 4.57,
    avg_round_14: 3289,
    app_flat_14: 1,
    char_15: 'Gepard',
    app_rate_15: 2.14,
    avg_round_15: 3316,
    app_flat_15: 16,
    char_16: 'dr-ratio',
    app_rate_16: 2.05,
    avg_round_16: 3303,
    app_flat_16: 16,
    char_17: 'imbibitor-lunae',
    app_rate_17: 1.86,
    avg_round_17: 3262,
    app_flat_17: 2,
    char_18: 'Feixiao',
    app_rate_18: 1.21,
    avg_round_18: 3355,
    app_flat_18: 10,
    char_19: 'Sampo',
    app_rate_19: 0.98,
    avg_round_19: 3332,
    app_flat_19: 7,
    char_20: 'Moze',
    app_rate_20: 0.98,
    avg_round_20: 3298,
    app_flat_20: 8,
    char_21: 'Guinaifen',
    app_rate_21: 0.84,
    avg_round_21: 3243,
    app_flat_21: 3,
    char_22: 'Tingyun',
    app_rate_22: 0.79,
    avg_round_22: 3366,
    app_flat_22: 3,
    char_23: 'trailblazer-imaginary',
    app_rate_23: 0.7,
    avg_round_23: 3331,
    app_flat_23: 3,
    char_24: 'Firefly',
    app_rate_24: 0.51,
    avg_round_24: 3214,
    app_flat_24: 1,
    char_25: 'Blade',
    app_rate_25: 0.42,
    avg_round_25: 3057,
    app_flat_25: 1,
    char_26: 'jing-yuan',
    app_rate_26: 0.42,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Welt',
    app_rate_27: 0.37,
    avg_round_27: 3386,
    app_flat_27: 4,
    char_28: 'topaz',
    app_rate_28: 0.33,
    avg_round_28: 3289,
    app_flat_28: 3,
    char_29: 'march-7th-swordmaster',
    app_rate_29: 0.33,
    avg_round_29: 3394,
    app_flat_29: 4,
    char_30: 'Bailu',
    app_rate_30: 0.28,
    avg_round_30: 3267,
    app_flat_30: 4
  },
  {
    char: 'fu-xuan',
    round: 3352,
    char_1: 'Acheron',
    app_rate_1: 40.63,
    avg_round_1: 3295,
    app_flat_1: 400,
    char_2: 'Robin',
    app_rate_2: 32.4,
    avg_round_2: 3491,
    app_flat_2: 624,
    char_3: 'Feixiao',
    app_rate_3: 29.87,
    avg_round_3: 3514,
    app_flat_3: 632,
    char_4: 'Sparkle',
    app_rate_4: 29.35,
    avg_round_4: 3320,
    app_flat_4: 195,
    char_5: 'Pela',
    app_rate_5: 19.31,
    avg_round_5: 3275,
    app_flat_5: 298,
    char_6: 'Jiaoqiu',
    app_rate_6: 18.53,
    avg_round_6: 3308,
    app_flat_6: 195,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 17.27,
    avg_round_7: 3553,
    app_flat_7: 376,
    char_8: 'silver-wolf',
    app_rate_8: 12.92,
    avg_round_8: 3268,
    app_flat_8: 139,
    char_9: 'Bronya',
    app_rate_9: 12.52,
    avg_round_9: 3411,
    app_flat_9: 187,
    char_10: 'black-swan',
    app_rate_10: 11.86,
    avg_round_10: 3320,
    app_flat_10: 149,
    char_11: 'Jingliu',
    app_rate_11: 11.4,
    avg_round_11: 3346,
    app_flat_11: 174,
    char_12: 'ruan-mei',
    app_rate_12: 10.59,
    avg_round_12: 3369,
    app_flat_12: 134,
    char_13: 'Kafka',
    app_rate_13: 10.04,
    avg_round_13: 3343,
    app_flat_13: 126,
    char_14: 'topaz',
    app_rate_14: 9.81,
    avg_round_14: 3463,
    app_flat_14: 197,
    char_15: 'Tingyun',
    app_rate_15: 6.1,
    avg_round_15: 3271,
    app_flat_15: 86,
    char_16: 'Yunli',
    app_rate_16: 5.32,
    avg_round_16: 3309,
    app_flat_16: 82,
    char_17: 'jing-yuan',
    app_rate_17: 2.79,
    avg_round_17: 3273,
    app_flat_17: 42,
    char_18: 'Boothill',
    app_rate_18: 2.45,
    avg_round_18: 3434,
    app_flat_18: 47,
    char_19: 'Moze',
    app_rate_19: 2.27,
    avg_round_19: 3411,
    app_flat_19: 51,
    char_20: 'Seele',
    app_rate_20: 2.07,
    avg_round_20: 3354,
    app_flat_20: 12,
    char_21: 'Blade',
    app_rate_21: 2.07,
    avg_round_21: 3299,
    app_flat_21: 27,
    char_22: 'Herta',
    app_rate_22: 2.01,
    avg_round_22: 3331,
    app_flat_22: 24,
    char_23: 'trailblazer-imaginary',
    app_rate_23: 1.41,
    avg_round_23: 3389,
    app_flat_23: 23,
    char_24: 'imbibitor-lunae',
    app_rate_24: 1.29,
    avg_round_24: 3278,
    app_flat_24: 6,
    char_25: 'Clara',
    app_rate_25: 0.98,
    avg_round_25: 3254,
    app_flat_25: 14,
    char_26: 'Jade',
    app_rate_26: 0.98,
    avg_round_26: 3412,
    app_flat_26: 8,
    char_27: 'Firefly',
    app_rate_27: 0.78,
    avg_round_27: 3150,
    app_flat_27: 5,
    char_28: 'Qingque',
    app_rate_28: 0.58,
    avg_round_28: 3098,
    app_flat_28: 3,
    char_29: 'Guinaifen',
    app_rate_29: 0.43,
    avg_round_29: 3346,
    app_flat_29: 3,
    char_30: 'dr-ratio',
    app_rate_30: 0.37,
    avg_round_30: 3356,
    app_flat_30: 6
  },
  {
    char: 'Jingliu',
    round: 3346,
    char_1: 'Bronya',
    app_rate_1: 66.27,
    avg_round_1: 3371,
    app_flat_1: 560,
    char_2: 'ruan-mei',
    app_rate_2: 38.75,
    avg_round_2: 3369,
    app_flat_2: 267,
    char_3: 'Huohuo',
    app_rate_3: 35.96,
    avg_round_3: 3357,
    app_flat_3: 297,
    char_4: 'fu-xuan',
    app_rate_4: 24.55,
    avg_round_4: 3346,
    app_flat_4: 174,
    char_5: 'Luocha',
    app_rate_5: 24.36,
    avg_round_5: 3335,
    app_flat_5: 205,
    char_6: 'Pela',
    app_rate_6: 23.74,
    avg_round_6: 3298,
    app_flat_6: 232,
    char_7: 'Robin',
    app_rate_7: 22.13,
    avg_round_7: 3382,
    app_flat_7: 186,
    char_8: 'Sparkle',
    app_rate_8: 18.6,
    avg_round_8: 3316,
    app_flat_8: 146,
    char_9: 'Blade',
    app_rate_9: 13.95,
    avg_round_9: 3290,
    app_flat_9: 94,
    char_10: 'Tingyun',
    app_rate_10: 7.81,
    avg_round_10: 3297,
    app_flat_10: 73,
    char_11: 'Gallagher',
    app_rate_11: 3.91,
    avg_round_11: 3361,
    app_flat_11: 39,
    char_12: 'Bailu',
    app_rate_12: 3.66,
    avg_round_12: 3321,
    app_flat_12: 41,
    char_13: 'Aventurine',
    app_rate_13: 3.29,
    avg_round_13: 3364,
    app_flat_13: 24,
    char_14: 'Herta',
    app_rate_14: 2.73,
    avg_round_14: 3242,
    app_flat_14: 11,
    char_15: 'Lynx',
    app_rate_15: 2.05,
    avg_round_15: 3327,
    app_flat_15: 21,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 1.36,
    avg_round_16: 3349,
    app_flat_16: 15,
    char_17: 'Gepard',
    app_rate_17: 1.24,
    avg_round_17: 3303,
    app_flat_17: 7,
    char_18: 'Yunli',
    app_rate_18: 0.81,
    avg_round_18: 3139,
    app_flat_18: 5,
    char_19: 'Feixiao',
    app_rate_19: 0.81,
    avg_round_19: 3439,
    app_flat_19: 10,
    char_20: 'Jade',
    app_rate_20: 0.62,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: 0.56,
    avg_round_21: 3211,
    app_flat_21: 4,
    char_22: 'silver-wolf',
    app_rate_22: 0.43,
    avg_round_22: 3199,
    app_flat_22: 5,
    char_23: 'Natasha',
    app_rate_23: 0.37,
    avg_round_23: 3238,
    app_flat_23: 3,
    char_24: 'Yanqing',
    app_rate_24: 0.31,
    avg_round_24: 3346,
    app_flat_24: 1,
    char_25: 'Boothill',
    app_rate_25: 0.31,
    avg_round_25: 3520,
    app_flat_25: 4,
    char_26: 'Acheron',
    app_rate_26: 0.25,
    avg_round_26: 3196,
    app_flat_26: 3,
    char_27: 'Argenti',
    app_rate_27: 0.19,
    avg_round_27: 3234,
    app_flat_27: 1,
    char_28: 'jing-yuan',
    app_rate_28: 0.19,
    avg_round_28: 3272,
    app_flat_28: 1,
    char_29: 'Jiaoqiu',
    app_rate_29: 0.19,
    avg_round_29: 3289,
    app_flat_29: 1,
    char_30: 'march-7th',
    app_rate_30: 0.19,
    avg_round_30: 3297,
    app_flat_30: 1
  },
  {
    char: 'Acheron',
    round: 3343,
    char_1: 'Jiaoqiu',
    app_rate_1: 46.79,
    avg_round_1: 3355,
    app_flat_1: 623,
    char_2: 'fu-xuan',
    app_rate_2: 35.98,
    avg_round_2: 3295,
    app_flat_2: 400,
    char_3: 'black-swan',
    app_rate_3: 34.33,
    avg_round_3: 3365,
    app_flat_3: 496,
    char_4: 'Pela',
    app_rate_4: 32.36,
    avg_round_4: 3310,
    app_flat_4: 592,
    char_5: 'Sparkle',
    app_rate_5: 29.23,
    avg_round_5: 3299,
    app_flat_5: 56,
    char_6: 'Kafka',
    app_rate_6: 28.39,
    avg_round_6: 3384,
    app_flat_6: 393,
    char_7: 'Aventurine',
    app_rate_7: 17.25,
    avg_round_7: 3352,
    app_flat_7: 289,
    char_8: 'silver-wolf',
    app_rate_8: 17.23,
    avg_round_8: 3299,
    app_flat_8: 255,
    char_9: 'Gallagher',
    app_rate_9: 16.41,
    avg_round_9: 3393,
    app_flat_9: 303,
    char_10: 'Huohuo',
    app_rate_10: 9.3,
    avg_round_10: 3336,
    app_flat_10: 140,
    char_11: 'ruan-mei',
    app_rate_11: 8.38,
    avg_round_11: 3334,
    app_flat_11: 14,
    char_12: 'Luocha',
    app_rate_12: 5.81,
    avg_round_12: 3355,
    app_flat_12: 64,
    char_13: 'Bronya',
    app_rate_13: 4.92,
    avg_round_13: 3224,
    app_flat_13: 2,
    char_14: 'Robin',
    app_rate_14: 3.85,
    avg_round_14: 3381,
    app_flat_14: 24,
    char_15: 'Gepard',
    app_rate_15: 3.57,
    avg_round_15: 3363,
    app_flat_15: 45,
    char_16: 'Guinaifen',
    app_rate_16: 1.71,
    avg_round_16: 3279,
    app_flat_16: 28,
    char_17: 'Bailu',
    app_rate_17: 0.89,
    avg_round_17: 3322,
    app_flat_17: 15,
    char_18: 'Welt',
    app_rate_18: 0.66,
    avg_round_18: 3272,
    app_flat_18: 9,
    char_19: 'Sampo',
    app_rate_19: 0.54,
    avg_round_19: 3324,
    app_flat_19: 14,
    char_20: 'Feixiao',
    app_rate_20: 0.36,
    avg_round_20: 3450,
    app_flat_20: 3,
    char_21: 'Lynx',
    app_rate_21: 0.31,
    avg_round_21: 3316,
    app_flat_21: 3,
    char_22: 'topaz',
    app_rate_22: 0.2,
    avg_round_22: 3291,
    app_flat_22: 2,
    char_23: 'trailblazer-fire',
    app_rate_23: 0.18,
    avg_round_23: 3194,
    app_flat_23: 1,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.18,
    avg_round_24: 3123,
    app_flat_24: 1,
    char_25: 'Boothill',
    app_rate_25: 0.15,
    avg_round_25: 3306,
    app_flat_25: 1,
    char_26: 'Firefly',
    app_rate_26: 0.13,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Jingliu',
    app_rate_27: 0.1,
    avg_round_27: 3196,
    app_flat_27: 3,
    char_28: 'Yunli',
    app_rate_28: 0.1,
    avg_round_28: 3141,
    app_flat_28: 1,
    char_29: 'Himeko',
    app_rate_29: 0.08,
    avg_round_29: 2897,
    app_flat_29: 1,
    char_30: 'Asta',
    app_rate_30: 0.08,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    round: 3342,
    char_1: 'silver-wolf',
    app_rate_1: 93.81,
    avg_round_1: 3342,
    app_flat_1: 13,
    char_2: 'Sparkle',
    app_rate_2: 89.69,
    avg_round_2: 3343,
    app_flat_2: 12,
    char_3: 'fu-xuan',
    app_rate_3: 74.23,
    avg_round_3: 3354,
    app_flat_3: 12,
    char_4: 'Robin',
    app_rate_4: 14.43,
    avg_round_4: 3645,
    app_flat_4: 1,
    char_5: 'Huohuo',
    app_rate_5: 9.28,
    avg_round_5: 3336,
    app_flat_5: 2,
    char_6: 'Tingyun',
    app_rate_6: 4.12,
    avg_round_6: 3327,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: 3.09,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Feixiao',
    app_rate_8: 2.06,
    avg_round_8: 3645,
    app_flat_8: 1,
    char_9: 'Bronya',
    app_rate_9: 2.06,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Pela',
    app_rate_10: 2.06,
    avg_round_10: 3477,
    app_flat_10: 1,
    char_11: 'Lynx',
    app_rate_11: 2.06,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Luocha',
    app_rate_12: 1.03,
    avg_round_12: 3645,
    app_flat_12: 1,
    char_13: 'Boothill',
    app_rate_13: 1.03,
    avg_round_13: 3477,
    app_flat_13: 1,
    char_14: 'Yunli',
    app_rate_14: 1.03,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    round: 3336,
    char_1: 'ruan-mei',
    app_rate_1: 35.09,
    avg_round_1: 3339,
    app_flat_1: 36,
    char_2: 'Robin',
    app_rate_2: 29.82,
    avg_round_2: 3437,
    app_flat_2: 48,
    char_3: 'Jingliu',
    app_rate_3: 25.88,
    avg_round_3: 3321,
    app_flat_3: 41,
    char_4: 'Bronya',
    app_rate_4: 24.12,
    avg_round_4: 3367,
    app_flat_4: 38,
    char_5: 'Feixiao',
    app_rate_5: 23.68,
    avg_round_5: 3497,
    app_flat_5: 38,
    char_6: 'Kafka',
    app_rate_6: 19.74,
    avg_round_6: 3338,
    app_flat_6: 21,
    char_7: 'black-swan',
    app_rate_7: 18.86,
    avg_round_7: 3334,
    app_flat_7: 20,
    char_8: 'Acheron',
    app_rate_8: 15.35,
    avg_round_8: 3322,
    app_flat_8: 15,
    char_9: 'Pela',
    app_rate_9: 14.04,
    avg_round_9: 3270,
    app_flat_9: 24,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 14.04,
    avg_round_10: 3512,
    app_flat_10: 29,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 10.53,
    avg_round_11: 3329,
    app_flat_11: 8,
    char_12: 'Firefly',
    app_rate_12: 8.33,
    avg_round_12: 3246,
    app_flat_12: 4,
    char_13: 'Tingyun',
    app_rate_13: 7.46,
    avg_round_13: 3272,
    app_flat_13: 13,
    char_14: 'Sparkle',
    app_rate_14: 7.02,
    avg_round_14: 3306,
    app_flat_14: 6,
    char_15: 'Boothill',
    app_rate_15: 6.58,
    avg_round_15: 3412,
    app_flat_15: 9,
    char_16: 'topaz',
    app_rate_16: 6.14,
    avg_round_16: 3473,
    app_flat_16: 8,
    char_17: 'Yunli',
    app_rate_17: 5.26,
    avg_round_17: 3305,
    app_flat_17: 9,
    char_18: 'Herta',
    app_rate_18: 4.82,
    avg_round_18: 3308,
    app_flat_18: 5,
    char_19: 'Blade',
    app_rate_19: 4.82,
    avg_round_19: 3228,
    app_flat_19: 6,
    char_20: 'silver-wolf',
    app_rate_20: 3.51,
    avg_round_20: 3276,
    app_flat_20: 4,
    char_21: 'Jiaoqiu',
    app_rate_21: 2.63,
    avg_round_21: 3267,
    app_flat_21: 4,
    char_22: 'Jade',
    app_rate_22: 2.19,
    avg_round_22: 3319,
    app_flat_22: 2,
    char_23: 'imbibitor-lunae',
    app_rate_23: 1.75,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jing-yuan',
    app_rate_24: 0.88,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Guinaifen',
    app_rate_25: 0.88,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Hanya',
    app_rate_26: 0.88,
    avg_round_26: 3350,
    app_flat_26: 2,
    char_27: 'Luka',
    app_rate_27: 0.88,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Moze',
    app_rate_28: 0.88,
    avg_round_28: 3459,
    app_flat_28: 1,
    char_29: 'Clara',
    app_rate_29: 0.88,
    avg_round_29: 3187,
    app_flat_29: 1,
    char_30: 'Yanqing',
    app_rate_30: 0.44,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    round: 3336,
    char_1: 'Boothill',
    app_rate_1: 57.14,
    avg_round_1: 3360,
    app_flat_1: 13,
    char_2: 'ruan-mei',
    app_rate_2: 35.71,
    avg_round_2: 3338,
    app_flat_2: 5,
    char_3: 'trailblazer-imaginary',
    app_rate_3: 32.14,
    avg_round_3: 3350,
    app_flat_3: 7,
    char_4: 'Huohuo',
    app_rate_4: 21.43,
    avg_round_4: 3374,
    app_flat_4: 4,
    char_5: 'Aventurine',
    app_rate_5: 17.86,
    avg_round_5: 3323,
    app_flat_5: 5,
    char_6: 'Luocha',
    app_rate_6: 17.86,
    avg_round_6: 3350,
    app_flat_6: 4,
    char_7: 'Kafka',
    app_rate_7: 14.29,
    avg_round_7: 3255,
    app_flat_7: 1,
    char_8: 'Robin',
    app_rate_8: 10.71,
    avg_round_8: 3177,
    app_flat_8: 3,
    char_9: 'Gallagher',
    app_rate_9: 10.71,
    avg_round_9: 3288,
    app_flat_9: 2,
    char_10: 'Gepard',
    app_rate_10: 10.71,
    avg_round_10: 3324,
    app_flat_10: 2,
    char_11: 'Sushang',
    app_rate_11: 7.14,
    avg_round_11: 3233,
    app_flat_11: 2,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 7.14,
    avg_round_12: 3148,
    app_flat_12: 2,
    char_13: 'Bailu',
    app_rate_13: 7.14,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Acheron',
    app_rate_14: 7.14,
    avg_round_14: 3184,
    app_flat_14: 2,
    char_15: 'Natasha',
    app_rate_15: 7.14,
    avg_round_15: 3266,
    app_flat_15: 2,
    char_16: 'Bronya',
    app_rate_16: 7.14,
    avg_round_16: 3360,
    app_flat_16: 2,
    char_17: 'Jiaoqiu',
    app_rate_17: 3.57,
    avg_round_17: 3231,
    app_flat_17: 1,
    char_18: 'Lynx',
    app_rate_18: 3.57,
    avg_round_18: 3255,
    app_flat_18: 1,
    char_19: 'Blade',
    app_rate_19: 3.57,
    avg_round_19: 3342,
    app_flat_19: 1,
    char_20: 'Tingyun',
    app_rate_20: 3.57,
    avg_round_20: 3236,
    app_flat_20: 1,
    char_21: 'Yanqing',
    app_rate_21: 3.57,
    avg_round_21: 3429,
    app_flat_21: 1,
    char_22: 'black-swan',
    app_rate_22: 3.57,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Pela',
    app_rate_23: 3.57,
    avg_round_23: 3138,
    app_flat_23: 1,
    char_24: 'fu-xuan',
    app_rate_24: 3.57,
    avg_round_24: 3138,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    round: 3330,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 81.55,
    avg_round_1: 3327,
    app_flat_1: 79,
    char_2: 'ruan-mei',
    app_rate_2: 79.76,
    avg_round_2: 3332,
    app_flat_2: 74,
    char_3: 'Gallagher',
    app_rate_3: 67.86,
    avg_round_3: 3324,
    app_flat_3: 79,
    char_4: 'Firefly',
    app_rate_4: 19.64,
    avg_round_4: 3257,
    app_flat_4: 4,
    char_5: 'Robin',
    app_rate_5: 5.95,
    avg_round_5: 3368,
    app_flat_5: 3,
    char_6: 'Aventurine',
    app_rate_6: 5.95,
    avg_round_6: 3408,
    app_flat_6: 4,
    char_7: 'Feixiao',
    app_rate_7: 4.76,
    avg_round_7: 3282,
    app_flat_7: 3,
    char_8: 'Kafka',
    app_rate_8: 4.17,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'black-swan',
    app_rate_9: 4.17,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 3.57,
    avg_round_10: 3236,
    app_flat_10: 3,
    char_11: 'Sparkle',
    app_rate_11: 3.57,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'fu-xuan',
    app_rate_12: 2.98,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Huohuo',
    app_rate_13: 2.38,
    avg_round_13: 3312,
    app_flat_13: 1,
    char_14: 'Acheron',
    app_rate_14: 1.79,
    avg_round_14: 2897,
    app_flat_14: 1,
    char_15: 'Guinaifen',
    app_rate_15: 1.79,
    avg_round_15: 2897,
    app_flat_15: 1,
    char_16: 'dr-ratio',
    app_rate_16: 1.79,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'topaz',
    app_rate_17: 1.79,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Luocha',
    app_rate_18: 1.19,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Herta',
    app_rate_19: 0.6,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Tingyun',
    app_rate_20: 0.6,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: 0.6,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'imbibitor-lunae',
    app_rate_22: 0.6,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jing-yuan',
    app_rate_23: 0.6,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Asta',
    app_rate_24: 0.6,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: 0.6,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Bronya',
    app_rate_26: 0.6,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Jiaoqiu',
    app_rate_27: 0.6,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    round: 3323,
    char_1: 'ruan-mei',
    app_rate_1: 34.15,
    avg_round_1: 3353,
    app_flat_1: 14,
    char_2: 'Jingliu',
    app_rate_2: 26.83,
    avg_round_2: 3327,
    app_flat_2: 21,
    char_3: 'Robin',
    app_rate_3: 26.02,
    avg_round_3: 3397,
    app_flat_3: 18,
    char_4: 'Bronya',
    app_rate_4: 25.2,
    avg_round_4: 3348,
    app_flat_4: 20,
    char_5: 'Blade',
    app_rate_5: 23.58,
    avg_round_5: 3305,
    app_flat_5: 12,
    char_6: 'Sparkle',
    app_rate_6: 18.7,
    avg_round_6: 3258,
    app_flat_6: 12,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 17.89,
    avg_round_7: 3343,
    app_flat_7: 17,
    char_8: 'Feixiao',
    app_rate_8: 17.07,
    avg_round_8: 3434,
    app_flat_8: 17,
    char_9: 'Kafka',
    app_rate_9: 12.2,
    avg_round_9: 3296,
    app_flat_9: 6,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 11.38,
    avg_round_10: 3284,
    app_flat_10: 2,
    char_11: 'black-swan',
    app_rate_11: 10.57,
    avg_round_11: 3339,
    app_flat_11: 5,
    char_12: 'Acheron',
    app_rate_12: 9.76,
    avg_round_12: 3316,
    app_flat_12: 3,
    char_13: 'Yunli',
    app_rate_13: 9.76,
    avg_round_13: 3265,
    app_flat_13: 8,
    char_14: 'Firefly',
    app_rate_14: 8.94,
    avg_round_14: 3166,
    app_flat_14: 1,
    char_15: 'Pela',
    app_rate_15: 8.94,
    avg_round_15: 3259,
    app_flat_15: 9,
    char_16: 'Clara',
    app_rate_16: 4.07,
    avg_round_16: 3319,
    app_flat_16: 3,
    char_17: 'topaz',
    app_rate_17: 4.07,
    avg_round_17: 3512,
    app_flat_17: 3,
    char_18: 'Jiaoqiu',
    app_rate_18: 4.07,
    avg_round_18: 3274,
    app_flat_18: 1,
    char_19: 'silver-wolf',
    app_rate_19: 4.07,
    avg_round_19: 3274,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: 4.07,
    avg_round_20: 3301,
    app_flat_20: 2,
    char_21: 'Tingyun',
    app_rate_21: 3.25,
    avg_round_21: 3251,
    app_flat_21: 3,
    char_22: 'Boothill',
    app_rate_22: 3.25,
    avg_round_22: 3407,
    app_flat_22: 3,
    char_23: 'Herta',
    app_rate_23: 2.44,
    avg_round_23: 3370,
    app_flat_23: 3,
    char_24: 'Moze',
    app_rate_24: 1.63,
    avg_round_24: 3374,
    app_flat_24: 2,
    char_25: 'imbibitor-lunae',
    app_rate_25: 1.63,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Seele',
    app_rate_26: 1.63,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Luka',
    app_rate_27: 0.81,
    avg_round_27: 3255,
    app_flat_27: 1,
    char_28: 'Misha',
    app_rate_28: 0.81,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Argenti',
    app_rate_29: 0.81,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'jing-yuan',
    app_rate_30: 0.81,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    round: 3322,
    char_1: 'Aventurine',
    app_rate_1: 82.62,
    avg_round_1: 3336,
    app_flat_1: 101,
    char_2: 'Robin',
    app_rate_2: 70.73,
    avg_round_2: 3342,
    app_flat_2: 100,
    char_3: 'topaz',
    app_rate_3: 61.59,
    avg_round_3: 3325,
    app_flat_3: 80,
    char_4: 'Jiaoqiu',
    app_rate_4: 13.41,
    avg_round_4: 3303,
    app_flat_4: 16,
    char_5: 'Moze',
    app_rate_5: 13.41,
    avg_round_5: 3334,
    app_flat_5: 18,
    char_6: 'ruan-mei',
    app_rate_6: 10.06,
    avg_round_6: 3328,
    app_flat_6: 10,
    char_7: 'Gallagher',
    app_rate_7: 6.1,
    avg_round_7: 3352,
    app_flat_7: 12,
    char_8: 'silver-wolf',
    app_rate_8: 5.79,
    avg_round_8: 3185,
    app_flat_8: 7,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 4.88,
    avg_round_9: 3329,
    app_flat_9: 10,
    char_10: 'Feixiao',
    app_rate_10: 4.27,
    avg_round_10: 3463,
    app_flat_10: 9,
    char_11: 'fu-xuan',
    app_rate_11: 3.96,
    avg_round_11: 3356,
    app_flat_11: 6,
    char_12: 'Luocha',
    app_rate_12: 3.05,
    avg_round_12: 3206,
    app_flat_12: 5,
    char_13: 'Pela',
    app_rate_13: 3.05,
    avg_round_13: 3195,
    app_flat_13: 5,
    char_14: 'Huohuo',
    app_rate_14: 3.05,
    avg_round_14: 3275,
    app_flat_14: 7,
    char_15: 'Tingyun',
    app_rate_15: 2.74,
    avg_round_15: 3360,
    app_flat_15: 3,
    char_16: 'Sparkle',
    app_rate_16: 2.74,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Bronya',
    app_rate_17: 1.52,
    avg_round_17: 3026,
    app_flat_17: 1,
    char_18: 'Himeko',
    app_rate_18: 0.91,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'imbibitor-lunae',
    app_rate_19: 0.61,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jing-yuan',
    app_rate_20: 0.61,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 0.61,
    avg_round_21: 3183,
    app_flat_21: 2,
    char_22: 'Blade',
    app_rate_22: 0.61,
    avg_round_22: 3242,
    app_flat_22: 2,
    char_23: 'Clara',
    app_rate_23: 0.3,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Gepard',
    app_rate_24: 0.3,
    avg_round_24: 3129,
    app_flat_24: 1,
    char_25: 'Asta',
    app_rate_25: 0.3,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Bailu',
    app_rate_26: 0.3,
    avg_round_26: 3026,
    app_flat_26: 1,
    char_27: 'Herta',
    app_rate_27: 0.3,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Guinaifen',
    app_rate_28: 0.3,
    avg_round_28: 3486,
    app_flat_28: 1,
    char_29: 'Natasha',
    app_rate_29: 0.3,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Welt',
    app_rate_30: 0.3,
    avg_round_30: 3239,
    app_flat_30: 1
  },
  {
    char: 'Sparkle',
    round: 3321,
    char_1: 'Acheron',
    app_rate_1: 41.24,
    avg_round_1: 3299,
    app_flat_1: 56,
    char_2: 'fu-xuan',
    app_rate_2: 36.68,
    avg_round_2: 3320,
    app_flat_2: 195,
    char_3: 'Jiaoqiu',
    app_rate_3: 25.31,
    avg_round_3: 3337,
    app_flat_3: 39,
    char_4: 'Tingyun',
    app_rate_4: 18.16,
    avg_round_4: 3310,
    app_flat_4: 195,
    char_5: 'Huohuo',
    app_rate_5: 17.94,
    avg_round_5: 3338,
    app_flat_5: 201,
    char_6: 'Aventurine',
    app_rate_6: 15.79,
    avg_round_6: 3383,
    app_flat_6: 124,
    char_7: 'imbibitor-lunae',
    app_rate_7: 13.66,
    avg_round_7: 3307,
    app_flat_7: 47,
    char_8: 'Pela',
    app_rate_8: 11.08,
    avg_round_8: 3249,
    app_flat_8: 34,
    char_9: 'Robin',
    app_rate_9: 10.82,
    avg_round_9: 3350,
    app_flat_9: 83,
    char_10: 'Jingliu',
    app_rate_10: 10.79,
    avg_round_10: 3316,
    app_flat_10: 146,
    char_11: 'silver-wolf',
    app_rate_11: 10.46,
    avg_round_11: 3282,
    app_flat_11: 19,
    char_12: 'ruan-mei',
    app_rate_12: 9.64,
    avg_round_12: 3277,
    app_flat_12: 39,
    char_13: 'Feixiao',
    app_rate_13: 9.56,
    avg_round_13: 3430,
    app_flat_13: 148,
    char_14: 'Luocha',
    app_rate_14: 9.31,
    avg_round_14: 3278,
    app_flat_14: 68,
    char_15: 'Yunli',
    app_rate_15: 8.41,
    avg_round_15: 3299,
    app_flat_15: 95,
    char_16: 'Gallagher',
    app_rate_16: 8.13,
    avg_round_16: 3347,
    app_flat_16: 48,
    char_17: 'jing-yuan',
    app_rate_17: 7.01,
    avg_round_17: 3303,
    app_flat_17: 89,
    char_18: 'Bronya',
    app_rate_18: 6.94,
    avg_round_18: 3342,
    app_flat_18: 92,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 5.25,
    avg_round_19: 3426,
    app_flat_19: 72,
    char_20: 'Blade',
    app_rate_20: 3.88,
    avg_round_20: 3266,
    app_flat_20: 40,
    char_21: 'Seele',
    app_rate_21: 3.13,
    avg_round_21: 3343,
    app_flat_21: 12,
    char_22: 'topaz',
    app_rate_22: 2.3,
    avg_round_22: 3395,
    app_flat_22: 43,
    char_23: 'black-swan',
    app_rate_23: 2.3,
    avg_round_23: 3368,
    app_flat_23: 1,
    char_24: 'Gepard',
    app_rate_24: 1.58,
    avg_round_24: 3228,
    app_flat_24: 4,
    char_25: 'Kafka',
    app_rate_25: 1.04,
    avg_round_25: 3248,
    app_flat_25: 2,
    char_26: 'Herta',
    app_rate_26: 0.97,
    avg_round_26: 3245,
    app_flat_26: 5,
    char_27: 'Argenti',
    app_rate_27: 0.97,
    avg_round_27: 3346,
    app_flat_27: 15,
    char_28: 'Lynx',
    app_rate_28: 0.83,
    avg_round_28: 3258,
    app_flat_28: 12,
    char_29: 'Qingque',
    app_rate_29: 0.72,
    avg_round_29: 3098,
    app_flat_29: 3,
    char_30: 'Boothill',
    app_rate_30: 0.68,
    avg_round_30: 3411,
    app_flat_30: 12
  },
  {
    char: 'Tingyun',
    round: 3320,
    char_1: 'Sparkle',
    app_rate_1: 47.42,
    avg_round_1: 3310,
    app_flat_1: 195,
    char_2: 'Huohuo',
    app_rate_2: 43.76,
    avg_round_2: 3344,
    app_flat_2: 244,
    char_3: 'Yunli',
    app_rate_3: 36.43,
    avg_round_3: 3329,
    app_flat_3: 232,
    char_4: 'Robin',
    app_rate_4: 31.74,
    avg_round_4: 3351,
    app_flat_4: 176,
    char_5: 'fu-xuan',
    app_rate_5: 19.91,
    avg_round_5: 3271,
    app_flat_5: 86,
    char_6: 'imbibitor-lunae',
    app_rate_6: 19.72,
    avg_round_6: 3328,
    app_flat_6: 31,
    char_7: 'jing-yuan',
    app_rate_7: 19.34,
    avg_round_7: 3325,
    app_flat_7: 88,
    char_8: 'Aventurine',
    app_rate_8: 13.8,
    avg_round_8: 3298,
    app_flat_8: 70,
    char_9: 'Jingliu',
    app_rate_9: 11.83,
    avg_round_9: 3297,
    app_flat_9: 73,
    char_10: 'ruan-mei',
    app_rate_10: 10.8,
    avg_round_10: 3309,
    app_flat_10: 29,
    char_11: 'Luocha',
    app_rate_11: 7.7,
    avg_round_11: 3288,
    app_flat_11: 30,
    char_12: 'Gallagher',
    app_rate_12: 5.35,
    avg_round_12: 3355,
    app_flat_12: 26,
    char_13: 'Bronya',
    app_rate_13: 4.6,
    avg_round_13: 3333,
    app_flat_13: 30,
    char_14: 'Argenti',
    app_rate_14: 3.38,
    avg_round_14: 3336,
    app_flat_14: 17,
    char_15: 'Yanqing',
    app_rate_15: 2.16,
    avg_round_15: 3210,
    app_flat_15: 14,
    char_16: 'Pela',
    app_rate_16: 2.16,
    avg_round_16: 3200,
    app_flat_16: 12,
    char_17: 'black-swan',
    app_rate_17: 2.16,
    avg_round_17: 3232,
    app_flat_17: 5,
    char_18: 'Kafka',
    app_rate_18: 2.07,
    avg_round_18: 3232,
    app_flat_18: 5,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 1.78,
    avg_round_19: 3207,
    app_flat_19: 12,
    char_20: 'Jiaoqiu',
    app_rate_20: 1.6,
    avg_round_20: 3366,
    app_flat_20: 3,
    char_21: 'Bailu',
    app_rate_21: 1.6,
    avg_round_21: 3272,
    app_flat_21: 13,
    char_22: 'Feixiao',
    app_rate_22: 1.5,
    avg_round_22: 3290,
    app_flat_22: 5,
    char_23: 'dr-ratio',
    app_rate_23: 0.85,
    avg_round_23: 3360,
    app_flat_23: 3,
    char_24: 'Herta',
    app_rate_24: 0.85,
    avg_round_24: 3241,
    app_flat_24: 3,
    char_25: 'Hanya',
    app_rate_25: 0.75,
    avg_round_25: 3227,
    app_flat_25: 5,
    char_26: 'Misha',
    app_rate_26: 0.66,
    avg_round_26: 3258,
    app_flat_26: 2,
    char_27: 'silver-wolf',
    app_rate_27: 0.56,
    avg_round_27: 3205,
    app_flat_27: 2,
    char_28: 'topaz',
    app_rate_28: 0.47,
    avg_round_28: 3183,
    app_flat_28: 3,
    char_29: 'Gepard',
    app_rate_29: 0.47,
    avg_round_29: 3288,
    app_flat_29: 4,
    char_30: 'Blade',
    app_rate_30: 0.38,
    avg_round_30: 3193,
    app_flat_30: 1
  },
  {
    char: 'imbibitor-lunae',
    round: 3314,
    char_1: 'Sparkle',
    app_rate_1: 85.59,
    avg_round_1: 3307,
    app_flat_1: 47,
    char_2: 'Tingyun',
    app_rate_2: 47.3,
    avg_round_2: 3328,
    app_flat_2: 31,
    char_3: 'ruan-mei',
    app_rate_3: 38.29,
    avg_round_3: 3314,
    app_flat_3: 13,
    char_4: 'Luocha',
    app_rate_4: 27.03,
    avg_round_4: 3222,
    app_flat_4: 10,
    char_5: 'Huohuo',
    app_rate_5: 20.95,
    avg_round_5: 3319,
    app_flat_5: 11,
    char_6: 'Aventurine',
    app_rate_6: 17.12,
    avg_round_6: 3344,
    app_flat_6: 14,
    char_7: 'Gallagher',
    app_rate_7: 15.09,
    avg_round_7: 3383,
    app_flat_7: 10,
    char_8: 'fu-xuan',
    app_rate_8: 10.14,
    avg_round_8: 3278,
    app_flat_8: 6,
    char_9: 'Robin',
    app_rate_9: 9.91,
    avg_round_9: 3382,
    app_flat_9: 3,
    char_10: 'Jiaoqiu',
    app_rate_10: 9.01,
    avg_round_10: 3262,
    app_flat_10: 2,
    char_11: 'Bronya',
    app_rate_11: 3.83,
    avg_round_11: 3232,
    app_flat_11: 4,
    char_12: 'Blade',
    app_rate_12: 2.7,
    avg_round_12: 3105,
    app_flat_12: 1,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 2.25,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Yukong',
    app_rate_14: 2.25,
    avg_round_14: 3250,
    app_flat_14: 1,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 2.03,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Hanya',
    app_rate_16: 1.58,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Asta',
    app_rate_17: 0.9,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: 0.9,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Pela',
    app_rate_19: 0.68,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'dr-ratio',
    app_rate_20: 0.45,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Firefly',
    app_rate_21: 0.45,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Lynx',
    app_rate_22: 0.45,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'silver-wolf',
    app_rate_23: 0.45,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Himeko',
    app_rate_24: 0.23,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Feixiao',
    app_rate_25: 0.23,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Yunli',
    app_rate_26: 0.23,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Pela',
    round: 3312,
    char_1: 'Acheron',
    app_rate_1: 71.27,
    avg_round_1: 3310,
    app_flat_1: 592,
    char_2: 'fu-xuan',
    app_rate_2: 37.65,
    avg_round_2: 3275,
    app_flat_2: 298,
    char_3: 'Jiaoqiu',
    app_rate_3: 30.92,
    avg_round_3: 3352,
    app_flat_3: 348,
    char_4: 'Jingliu',
    app_rate_4: 21.49,
    avg_round_4: 3298,
    app_flat_4: 232,
    char_5: 'Bronya',
    app_rate_5: 20.82,
    avg_round_5: 3332,
    app_flat_5: 186,
    char_6: 'Aventurine',
    app_rate_6: 17.34,
    avg_round_6: 3328,
    app_flat_6: 178,
    char_7: 'Sparkle',
    app_rate_7: 17.28,
    avg_round_7: 3249,
    app_flat_7: 34,
    char_8: 'silver-wolf',
    app_rate_8: 14.76,
    avg_round_8: 3253,
    app_flat_8: 152,
    char_9: 'Gallagher',
    app_rate_9: 13.64,
    avg_round_9: 3388,
    app_flat_9: 151,
    char_10: 'Huohuo',
    app_rate_10: 10.83,
    avg_round_10: 3289,
    app_flat_10: 109,
    char_11: 'Luocha',
    app_rate_11: 8.7,
    avg_round_11: 3309,
    app_flat_11: 89,
    char_12: 'ruan-mei',
    app_rate_12: 6.96,
    avg_round_12: 3290,
    app_flat_12: 37,
    char_13: 'Robin',
    app_rate_13: 4.49,
    avg_round_13: 3303,
    app_flat_13: 30,
    char_14: 'black-swan',
    app_rate_14: 4.04,
    avg_round_14: 3245,
    app_flat_14: 40,
    char_15: 'Gepard',
    app_rate_15: 3.59,
    avg_round_15: 3302,
    app_flat_15: 23,
    char_16: 'Boothill',
    app_rate_16: 3.54,
    avg_round_16: 3436,
    app_flat_16: 33,
    char_17: 'Bailu',
    app_rate_17: 1.8,
    avg_round_17: 3270,
    app_flat_17: 24,
    char_18: 'Tingyun',
    app_rate_18: 1.29,
    avg_round_18: 3200,
    app_flat_18: 12,
    char_19: 'Kafka',
    app_rate_19: 1.23,
    avg_round_19: 3206,
    app_flat_19: 8,
    char_20: 'Feixiao',
    app_rate_20: 1.18,
    avg_round_20: 3404,
    app_flat_20: 13,
    char_21: 'Guinaifen',
    app_rate_21: 0.79,
    avg_round_21: 3251,
    app_flat_21: 9,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.79,
    avg_round_22: 3384,
    app_flat_22: 1,
    char_23: 'Yunli',
    app_rate_23: 0.67,
    avg_round_23: 3188,
    app_flat_23: 7,
    char_24: 'Lynx',
    app_rate_24: 0.62,
    avg_round_24: 3259,
    app_flat_24: 9,
    char_25: 'Blade',
    app_rate_25: 0.56,
    avg_round_25: 3062,
    app_flat_25: 4,
    char_26: 'dr-ratio',
    app_rate_26: 0.56,
    avg_round_26: 3195,
    app_flat_26: 5,
    char_27: 'march-7th-swordmaster',
    app_rate_27: 0.51,
    avg_round_27: 3313,
    app_flat_27: 5,
    char_28: 'Welt',
    app_rate_28: 0.45,
    avg_round_28: 3178,
    app_flat_28: 4,
    char_29: 'Yanqing',
    app_rate_29: 0.34,
    avg_round_29: 3264,
    app_flat_29: 3,
    char_30: 'Herta',
    app_rate_30: 0.34,
    avg_round_30: 3324,
    app_flat_30: 3
  },
  {
    char: 'jing-yuan',
    round: 3311,
    char_1: 'Tingyun',
    app_rate_1: 59.88,
    avg_round_1: 3325,
    app_flat_1: 88,
    char_2: 'Sparkle',
    app_rate_2: 56.69,
    avg_round_2: 3303,
    app_flat_2: 89,
    char_3: 'Robin',
    app_rate_3: 41.57,
    avg_round_3: 3319,
    app_flat_3: 55,
    char_4: 'fu-xuan',
    app_rate_4: 28.2,
    avg_round_4: 3273,
    app_flat_4: 42,
    char_5: 'Huohuo',
    app_rate_5: 25.0,
    avg_round_5: 3361,
    app_flat_5: 32,
    char_6: 'Aventurine',
    app_rate_6: 23.84,
    avg_round_6: 3339,
    app_flat_6: 36,
    char_7: 'ruan-mei',
    app_rate_7: 14.83,
    avg_round_7: 3277,
    app_flat_7: 15,
    char_8: 'Gallagher',
    app_rate_8: 8.72,
    avg_round_8: 3325,
    app_flat_8: 13,
    char_9: 'Luocha',
    app_rate_9: 7.56,
    avg_round_9: 3262,
    app_flat_9: 16,
    char_10: 'Bronya',
    app_rate_10: 3.78,
    avg_round_10: 3227,
    app_flat_10: 4,
    char_11: 'topaz',
    app_rate_11: 3.49,
    avg_round_11: 3432,
    app_flat_11: 2,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 3.2,
    avg_round_12: 3330,
    app_flat_12: 5,
    char_13: 'Jiaoqiu',
    app_rate_13: 2.62,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Feixiao',
    app_rate_14: 2.62,
    avg_round_14: 3411,
    app_flat_14: 3,
    char_15: 'Blade',
    app_rate_15: 2.33,
    avg_round_15: 3158,
    app_flat_15: 2,
    char_16: 'Herta',
    app_rate_16: 2.03,
    avg_round_16: 3143,
    app_flat_16: 3,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 2.03,
    avg_round_17: 3390,
    app_flat_17: 3,
    char_18: 'Yunli',
    app_rate_18: 1.74,
    avg_round_18: 3283,
    app_flat_18: 3,
    char_19: 'Jade',
    app_rate_19: 1.16,
    avg_round_19: 3371,
    app_flat_19: 1,
    char_20: 'Moze',
    app_rate_20: 1.16,
    avg_round_20: 3398,
    app_flat_20: 1,
    char_21: 'Hanya',
    app_rate_21: 1.16,
    avg_round_21: 3274,
    app_flat_21: 3,
    char_22: 'Jingliu',
    app_rate_22: 0.87,
    avg_round_22: 3272,
    app_flat_22: 1,
    char_23: 'Gepard',
    app_rate_23: 0.87,
    avg_round_23: 2931,
    app_flat_23: 1,
    char_24: 'Bailu',
    app_rate_24: 0.58,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'dr-ratio',
    app_rate_25: 0.58,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 0.58,
    avg_round_26: 3067,
    app_flat_26: 1,
    char_27: 'Argenti',
    app_rate_27: 0.58,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Boothill',
    app_rate_28: 0.58,
    avg_round_28: 3496,
    app_flat_28: 1,
    char_29: 'Firefly',
    app_rate_29: 0.58,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Himeko',
    app_rate_30: 0.29,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    round: 3295,
    char_1: 'Kafka',
    app_rate_1: 69.05,
    avg_round_1: 3305,
    app_flat_1: 21,
    char_2: 'black-swan',
    app_rate_2: 39.29,
    avg_round_2: 3314,
    app_flat_2: 8,
    char_3: 'ruan-mei',
    app_rate_3: 32.14,
    avg_round_3: 3271,
    app_flat_3: 11,
    char_4: 'Gallagher',
    app_rate_4: 30.95,
    avg_round_4: 3360,
    app_flat_4: 13,
    char_5: 'Acheron',
    app_rate_5: 25.0,
    avg_round_5: 3324,
    app_flat_5: 14,
    char_6: 'Jiaoqiu',
    app_rate_6: 25.0,
    avg_round_6: 3332,
    app_flat_6: 7,
    char_7: 'Aventurine',
    app_rate_7: 21.43,
    avg_round_7: 3322,
    app_flat_7: 9,
    char_8: 'Huohuo',
    app_rate_8: 16.67,
    avg_round_8: 3334,
    app_flat_8: 3,
    char_9: 'Luocha',
    app_rate_9: 13.1,
    avg_round_9: 3229,
    app_flat_9: 6,
    char_10: 'Guinaifen',
    app_rate_10: 10.71,
    avg_round_10: 3218,
    app_flat_10: 4,
    char_11: 'Robin',
    app_rate_11: 5.95,
    avg_round_11: 3404,
    app_flat_11: 2,
    char_12: 'fu-xuan',
    app_rate_12: 4.76,
    avg_round_12: 3324,
    app_flat_12: 2,
    char_13: 'Asta',
    app_rate_13: 1.19,
    avg_round_13: 3324,
    app_flat_13: 1,
    char_14: 'trailblazer-imaginary',
    app_rate_14: 1.19,
    avg_round_14: 3204,
    app_flat_14: 1,
    char_15: 'Lynx',
    app_rate_15: 1.19,
    avg_round_15: 3095,
    app_flat_15: 1,
    char_16: 'silver-wolf',
    app_rate_16: 1.19,
    avg_round_16: 3194,
    app_flat_16: 1,
    char_17: 'trailblazer-fire',
    app_rate_17: 1.19,
    avg_round_17: 3194,
    app_flat_17: 1,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    round: 3294,
    char_1: 'Acheron',
    app_rate_1: 80.86,
    avg_round_1: 3299,
    app_flat_1: 255,
    char_2: 'fu-xuan',
    app_rate_2: 53.71,
    avg_round_2: 3268,
    app_flat_2: 139,
    char_3: 'Sparkle',
    app_rate_3: 34.81,
    avg_round_3: 3282,
    app_flat_3: 19,
    char_4: 'Pela',
    app_rate_4: 31.46,
    avg_round_4: 3253,
    app_flat_4: 152,
    char_5: 'Jiaoqiu',
    app_rate_5: 22.97,
    avg_round_5: 3374,
    app_flat_5: 85,
    char_6: 'Aventurine',
    app_rate_6: 14.83,
    avg_round_6: 3310,
    app_flat_6: 61,
    char_7: 'Gallagher',
    app_rate_7: 12.92,
    avg_round_7: 3398,
    app_flat_7: 52,
    char_8: 'Seele',
    app_rate_8: 10.89,
    avg_round_8: 3342,
    app_flat_8: 13,
    char_9: 'Huohuo',
    app_rate_9: 4.43,
    avg_round_9: 3202,
    app_flat_9: 15,
    char_10: 'Robin',
    app_rate_10: 4.07,
    avg_round_10: 3367,
    app_flat_10: 5,
    char_11: 'Bronya',
    app_rate_11: 3.71,
    avg_round_11: 3226,
    app_flat_11: 3,
    char_12: 'Luocha',
    app_rate_12: 3.11,
    avg_round_12: 3205,
    app_flat_12: 7,
    char_13: 'Gepard',
    app_rate_13: 2.63,
    avg_round_13: 3308,
    app_flat_13: 8,
    char_14: 'ruan-mei',
    app_rate_14: 2.39,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Qingque',
    app_rate_15: 2.39,
    avg_round_15: 3098,
    app_flat_15: 3,
    char_16: 'dr-ratio',
    app_rate_16: 2.27,
    avg_round_16: 3185,
    app_flat_16: 7,
    char_17: 'black-swan',
    app_rate_17: 2.03,
    avg_round_17: 3309,
    app_flat_17: 7,
    char_18: 'Kafka',
    app_rate_18: 1.44,
    avg_round_18: 3356,
    app_flat_18: 4,
    char_19: 'Feixiao',
    app_rate_19: 1.08,
    avg_round_19: 3427,
    app_flat_19: 3,
    char_20: 'Bailu',
    app_rate_20: 0.96,
    avg_round_20: 3276,
    app_flat_20: 4,
    char_21: 'Jingliu',
    app_rate_21: 0.84,
    avg_round_21: 3199,
    app_flat_21: 5,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 0.84,
    avg_round_22: 3311,
    app_flat_22: 3,
    char_23: 'trailblazer-imaginary',
    app_rate_23: 0.72,
    avg_round_23: 3168,
    app_flat_23: 2,
    char_24: 'Tingyun',
    app_rate_24: 0.72,
    avg_round_24: 3205,
    app_flat_24: 2,
    char_25: 'Firefly',
    app_rate_25: 0.6,
    avg_round_25: 3144,
    app_flat_25: 1,
    char_26: 'Lynx',
    app_rate_26: 0.6,
    avg_round_26: 3274,
    app_flat_26: 1,
    char_27: 'Guinaifen',
    app_rate_27: 0.48,
    avg_round_27: 3496,
    app_flat_27: 2,
    char_28: 'topaz',
    app_rate_28: 0.48,
    avg_round_28: 3267,
    app_flat_28: 2,
    char_29: 'trailblazer-fire',
    app_rate_29: 0.24,
    avg_round_29: 3194,
    app_flat_29: 1,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.24,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    round: 3288,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 48.39,
    avg_round_1: 3284,
    app_flat_1: 18,
    char_2: 'Gallagher',
    app_rate_2: 43.01,
    avg_round_2: 3301,
    app_flat_2: 22,
    char_3: 'Firefly',
    app_rate_3: 41.94,
    avg_round_3: 3281,
    app_flat_3: 16,
    char_4: 'Kafka',
    app_rate_4: 35.48,
    avg_round_4: 3335,
    app_flat_4: 10,
    char_5: 'black-swan',
    app_rate_5: 34.41,
    avg_round_5: 3341,
    app_flat_5: 10,
    char_6: 'ruan-mei',
    app_rate_6: 25.81,
    avg_round_6: 3294,
    app_flat_6: 1,
    char_7: 'Aventurine',
    app_rate_7: 9.68,
    avg_round_7: 3337,
    app_flat_7: 5,
    char_8: 'Huohuo',
    app_rate_8: 7.53,
    avg_round_8: 3364,
    app_flat_8: 2,
    char_9: 'topaz',
    app_rate_9: 6.45,
    avg_round_9: 3339,
    app_flat_9: 1,
    char_10: 'Luocha',
    app_rate_10: 6.45,
    avg_round_10: 3176,
    app_flat_10: 1,
    char_11: 'Feixiao',
    app_rate_11: 5.38,
    avg_round_11: 3307,
    app_flat_11: 2,
    char_12: 'imbibitor-lunae',
    app_rate_12: 4.3,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Jiaoqiu',
    app_rate_13: 4.3,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Bronya',
    app_rate_14: 3.23,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Acheron',
    app_rate_15: 3.23,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Guinaifen',
    app_rate_16: 3.23,
    avg_round_16: 3384,
    app_flat_16: 1,
    char_17: 'Tingyun',
    app_rate_17: 3.23,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'fu-xuan',
    app_rate_18: 3.23,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 2.15,
    avg_round_19: 3317,
    app_flat_19: 1,
    char_20: 'Robin',
    app_rate_20: 2.15,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Sampo',
    app_rate_21: 1.08,
    avg_round_21: 3324,
    app_flat_21: 1,
    char_22: 'Welt',
    app_rate_22: 1.08,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'dr-ratio',
    app_rate_23: 1.08,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Bailu',
    app_rate_24: 1.08,
    avg_round_24: 3250,
    app_flat_24: 1,
    char_25: 'Himeko',
    app_rate_25: 1.08,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 1.08,
    avg_round_26: 3275,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    round: 3286,
    char_1: 'Jingliu',
    app_rate_1: 47.77,
    avg_round_1: 3290,
    app_flat_1: 94,
    char_2: 'Bronya',
    app_rate_2: 38.43,
    avg_round_2: 3296,
    app_flat_2: 76,
    char_3: 'Luocha',
    app_rate_3: 33.33,
    avg_round_3: 3272,
    app_flat_3: 61,
    char_4: 'ruan-mei',
    app_rate_4: 31.85,
    avg_round_4: 3317,
    app_flat_4: 49,
    char_5: 'Huohuo',
    app_rate_5: 29.72,
    avg_round_5: 3285,
    app_flat_5: 65,
    char_6: 'Robin',
    app_rate_6: 24.63,
    avg_round_6: 3288,
    app_flat_6: 52,
    char_7: 'Sparkle',
    app_rate_7: 22.93,
    avg_round_7: 3266,
    app_flat_7: 40,
    char_8: 'fu-xuan',
    app_rate_8: 15.29,
    avg_round_8: 3299,
    app_flat_8: 27,
    char_9: 'Jade',
    app_rate_9: 11.46,
    avg_round_9: 3264,
    app_flat_9: 15,
    char_10: 'Lynx',
    app_rate_10: 6.16,
    avg_round_10: 3305,
    app_flat_10: 12,
    char_11: 'Gallagher',
    app_rate_11: 5.94,
    avg_round_11: 3353,
    app_flat_11: 14,
    char_12: 'Feixiao',
    app_rate_12: 4.25,
    avg_round_12: 3364,
    app_flat_12: 8,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 3.82,
    avg_round_13: 3266,
    app_flat_13: 10,
    char_14: 'Yunli',
    app_rate_14: 3.82,
    avg_round_14: 3236,
    app_flat_14: 6,
    char_15: 'imbibitor-lunae',
    app_rate_15: 2.55,
    avg_round_15: 3105,
    app_flat_15: 1,
    char_16: 'Bailu',
    app_rate_16: 2.34,
    avg_round_16: 3228,
    app_flat_16: 6,
    char_17: 'Pela',
    app_rate_17: 2.12,
    avg_round_17: 3062,
    app_flat_17: 4,
    char_18: 'Jiaoqiu',
    app_rate_18: 1.91,
    avg_round_18: 3057,
    app_flat_18: 1,
    char_19: 'jing-yuan',
    app_rate_19: 1.7,
    avg_round_19: 3158,
    app_flat_19: 2,
    char_20: 'Aventurine',
    app_rate_20: 1.7,
    avg_round_20: 3231,
    app_flat_20: 2,
    char_21: 'Boothill',
    app_rate_21: 1.27,
    avg_round_21: 3414,
    app_flat_21: 6,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 1.06,
    avg_round_22: 3376,
    app_flat_22: 3,
    char_23: 'Tingyun',
    app_rate_23: 0.85,
    avg_round_23: 3193,
    app_flat_23: 1,
    char_24: 'Natasha',
    app_rate_24: 0.64,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Herta',
    app_rate_25: 0.64,
    avg_round_25: 2994,
    app_flat_25: 1,
    char_26: 'topaz',
    app_rate_26: 0.64,
    avg_round_26: 3130,
    app_flat_26: 2,
    char_27: 'Kafka',
    app_rate_27: 0.42,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Gepard',
    app_rate_28: 0.42,
    avg_round_28: 3204,
    app_flat_28: 1,
    char_29: 'Acheron',
    app_rate_29: 0.42,
    avg_round_29: 3057,
    app_flat_29: 1,
    char_30: 'Moze',
    app_rate_30: 0.42,
    avg_round_30: 3144,
    app_flat_30: 1
  },
  {
    char: 'Hanya',
    round: 3282,
    char_1: 'Tingyun',
    app_rate_1: 33.33,
    avg_round_1: 3227,
    app_flat_1: 5,
    char_2: 'Aventurine',
    app_rate_2: 33.33,
    avg_round_2: 3336,
    app_flat_2: 7,
    char_3: 'imbibitor-lunae',
    app_rate_3: 29.17,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Luocha',
    app_rate_4: 16.67,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Boothill',
    app_rate_5: 16.67,
    avg_round_5: 3192,
    app_flat_5: 4,
    char_6: 'ruan-mei',
    app_rate_6: 16.67,
    avg_round_6: 3189,
    app_flat_6: 1,
    char_7: 'jing-yuan',
    app_rate_7: 16.67,
    avg_round_7: 3274,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: 12.5,
    avg_round_8: 3167,
    app_flat_8: 2,
    char_9: 'Feixiao',
    app_rate_9: 12.5,
    avg_round_9: 3466,
    app_flat_9: 2,
    char_10: 'Gallagher',
    app_rate_10: 12.5,
    avg_round_10: 3194,
    app_flat_10: 1,
    char_11: 'Jiaoqiu',
    app_rate_11: 8.33,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Yunli',
    app_rate_12: 8.33,
    avg_round_12: 3200,
    app_flat_12: 2,
    char_13: 'Robin',
    app_rate_13: 8.33,
    avg_round_13: 3342,
    app_flat_13: 2,
    char_14: 'Bailu',
    app_rate_14: 8.33,
    avg_round_14: 3350,
    app_flat_14: 2,
    char_15: 'Argenti',
    app_rate_15: 8.33,
    avg_round_15: 3165,
    app_flat_15: 1,
    char_16: 'Huohuo',
    app_rate_16: 8.33,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 8.33,
    avg_round_17: 3475,
    app_flat_17: 1,
    char_18: 'Bronya',
    app_rate_18: 4.17,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Kafka',
    app_rate_19: 4.17,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'black-swan',
    app_rate_20: 4.17,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Pela',
    app_rate_21: 4.17,
    avg_round_21: 3446,
    app_flat_21: 1,
    char_22: 'topaz',
    app_rate_22: 4.17,
    avg_round_22: 3458,
    app_flat_22: 1,
    char_23: 'Yanqing',
    app_rate_23: 4.17,
    avg_round_23: 3430,
    app_flat_23: 1,
    char_24: 'Sparkle',
    app_rate_24: 4.17,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'trailblazer-imaginary',
    app_rate_25: 4.17,
    avg_round_25: 3194,
    app_flat_25: 1,
    char_26: 'Clara',
    app_rate_26: 4.17,
    avg_round_26: 3155,
    app_flat_26: 1,
    char_27: 'Gepard',
    app_rate_27: 4.17,
    avg_round_27: 3155,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanqing',
    round: 3280,
    char_1: 'Aventurine',
    app_rate_1: 70.13,
    avg_round_1: 3296,
    app_flat_1: 26,
    char_2: 'Robin',
    app_rate_2: 49.35,
    avg_round_2: 3355,
    app_flat_2: 19,
    char_3: 'ruan-mei',
    app_rate_3: 31.17,
    avg_round_3: 3289,
    app_flat_3: 13,
    char_4: 'Tingyun',
    app_rate_4: 29.87,
    avg_round_4: 3210,
    app_flat_4: 14,
    char_5: 'Sparkle',
    app_rate_5: 22.08,
    avg_round_5: 3202,
    app_flat_5: 6,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 18.18,
    avg_round_6: 3286,
    app_flat_6: 9,
    char_7: 'Bronya',
    app_rate_7: 11.69,
    avg_round_7: 3285,
    app_flat_7: 3,
    char_8: 'Gepard',
    app_rate_8: 11.69,
    avg_round_8: 3203,
    app_flat_8: 6,
    char_9: 'Pela',
    app_rate_9: 7.79,
    avg_round_9: 3264,
    app_flat_9: 3,
    char_10: 'Herta',
    app_rate_10: 6.49,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: 6.49,
    avg_round_11: 3228,
    app_flat_11: 3,
    char_12: 'Jingliu',
    app_rate_12: 6.49,
    avg_round_12: 3346,
    app_flat_12: 1,
    char_13: 'Huohuo',
    app_rate_13: 6.49,
    avg_round_13: 3385,
    app_flat_13: 3,
    char_14: 'Feixiao',
    app_rate_14: 5.19,
    avg_round_14: 3452,
    app_flat_14: 3,
    char_15: 'march-7th',
    app_rate_15: 3.9,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Moze',
    app_rate_16: 2.6,
    avg_round_16: 3049,
    app_flat_16: 2,
    char_17: 'topaz',
    app_rate_17: 2.6,
    avg_round_17: 3399,
    app_flat_17: 1,
    char_18: 'Bailu',
    app_rate_18: 1.3,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jiaoqiu',
    app_rate_19: 1.3,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Yunli',
    app_rate_20: 1.3,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Misha',
    app_rate_21: 1.3,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Hanya',
    app_rate_22: 1.3,
    avg_round_22: 3430,
    app_flat_22: 1,
    char_23: 'Luka',
    app_rate_23: 1.3,
    avg_round_23: 3429,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    round: 3279,
    char_1: 'Robin',
    app_rate_1: 68.12,
    avg_round_1: 3296,
    app_flat_1: 45,
    char_2: 'Feixiao',
    app_rate_2: 39.86,
    avg_round_2: 3347,
    app_flat_2: 39,
    char_3: 'Aventurine',
    app_rate_3: 31.88,
    avg_round_3: 3329,
    app_flat_3: 23,
    char_4: 'fu-xuan',
    app_rate_4: 24.64,
    avg_round_4: 3254,
    app_flat_4: 14,
    char_5: 'Huohuo',
    app_rate_5: 20.29,
    avg_round_5: 3188,
    app_flat_5: 12,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 18.12,
    avg_round_6: 3226,
    app_flat_6: 13,
    char_7: 'Yunli',
    app_rate_7: 13.77,
    avg_round_7: 3206,
    app_flat_7: 10,
    char_8: 'topaz',
    app_rate_8: 12.32,
    avg_round_8: 3236,
    app_flat_8: 7,
    char_9: 'Luocha',
    app_rate_9: 11.59,
    avg_round_9: 3298,
    app_flat_9: 11,
    char_10: 'ruan-mei',
    app_rate_10: 7.97,
    avg_round_10: 3174,
    app_flat_10: 4,
    char_11: 'Sparkle',
    app_rate_11: 7.25,
    avg_round_11: 3134,
    app_flat_11: 3,
    char_12: 'Jingliu',
    app_rate_12: 6.52,
    avg_round_12: 3211,
    app_flat_12: 4,
    char_13: 'Boothill',
    app_rate_13: 3.62,
    avg_round_13: 3212,
    app_flat_13: 3,
    char_14: 'Lynx',
    app_rate_14: 3.62,
    avg_round_14: 3319,
    app_flat_14: 3,
    char_15: 'Gallagher',
    app_rate_15: 3.62,
    avg_round_15: 3316,
    app_flat_15: 3,
    char_16: 'Herta',
    app_rate_16: 3.62,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Bronya',
    app_rate_17: 2.9,
    avg_round_17: 3259,
    app_flat_17: 2,
    char_18: 'Tingyun',
    app_rate_18: 2.9,
    avg_round_18: 2988,
    app_flat_18: 2,
    char_19: 'Jade',
    app_rate_19: 2.17,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jing-yuan',
    app_rate_20: 1.45,
    avg_round_20: 3067,
    app_flat_20: 1,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 1.45,
    avg_round_21: 3158,
    app_flat_21: 1,
    char_22: 'Blade',
    app_rate_22: 1.45,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Pela',
    app_rate_23: 1.45,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Moze',
    app_rate_24: 1.45,
    avg_round_24: 3118,
    app_flat_24: 2,
    char_25: 'Bailu',
    app_rate_25: 1.45,
    avg_round_25: 3187,
    app_flat_25: 1,
    char_26: 'Natasha',
    app_rate_26: 0.72,
    avg_round_26: 3175,
    app_flat_26: 1,
    char_27: 'dr-ratio',
    app_rate_27: 0.72,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th',
    app_rate_28: 0.72,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Acheron',
    app_rate_29: 0.72,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'black-swan',
    app_rate_30: 0.72,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    round: 3272,
    char_1: 'Acheron',
    app_rate_1: 47.86,
    avg_round_1: 3279,
    app_flat_1: 28,
    char_2: 'Kafka',
    app_rate_2: 45.0,
    avg_round_2: 3337,
    app_flat_2: 22,
    char_3: 'black-swan',
    app_rate_3: 42.86,
    avg_round_3: 3368,
    app_flat_3: 18,
    char_4: 'Gallagher',
    app_rate_4: 34.29,
    avg_round_4: 3316,
    app_flat_4: 31,
    char_5: 'ruan-mei',
    app_rate_5: 20.71,
    avg_round_5: 3274,
    app_flat_5: 7,
    char_6: 'Huohuo',
    app_rate_6: 15.71,
    avg_round_6: 3358,
    app_flat_6: 4,
    char_7: 'Jiaoqiu',
    app_rate_7: 12.86,
    avg_round_7: 3243,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: 10.71,
    avg_round_8: 3346,
    app_flat_8: 3,
    char_9: 'Luocha',
    app_rate_9: 10.71,
    avg_round_9: 3305,
    app_flat_9: 6,
    char_10: 'Pela',
    app_rate_10: 10.0,
    avg_round_10: 3251,
    app_flat_10: 9,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 7.86,
    avg_round_11: 3301,
    app_flat_11: 6,
    char_12: 'Sampo',
    app_rate_12: 6.43,
    avg_round_12: 3218,
    app_flat_12: 4,
    char_13: 'Aventurine',
    app_rate_13: 6.43,
    avg_round_13: 3280,
    app_flat_13: 5,
    char_14: 'Gepard',
    app_rate_14: 5.71,
    avg_round_14: 3283,
    app_flat_14: 3,
    char_15: 'Sparkle',
    app_rate_15: 4.29,
    avg_round_15: 3184,
    app_flat_15: 1,
    char_16: 'silver-wolf',
    app_rate_16: 2.86,
    avg_round_16: 3496,
    app_flat_16: 2,
    char_17: 'Robin',
    app_rate_17: 2.14,
    avg_round_17: 3257,
    app_flat_17: 1,
    char_18: 'Firefly',
    app_rate_18: 2.14,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Himeko',
    app_rate_19: 2.14,
    avg_round_19: 2897,
    app_flat_19: 1,
    char_20: 'Asta',
    app_rate_20: 2.14,
    avg_round_20: 3384,
    app_flat_20: 1,
    char_21: 'Bailu',
    app_rate_21: 1.43,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Welt',
    app_rate_22: 1.43,
    avg_round_22: 3188,
    app_flat_22: 1,
    char_23: 'Tingyun',
    app_rate_23: 1.43,
    avg_round_23: 3486,
    app_flat_23: 1,
    char_24: 'Bronya',
    app_rate_24: 0.71,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Yukong',
    app_rate_25: 0.71,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'dr-ratio',
    app_rate_26: 0.71,
    avg_round_26: 3486,
    app_flat_26: 1,
    char_27: 'Lynx',
    app_rate_27: 0.71,
    avg_round_27: 3095,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    round: 0,
    char_1: 'Acheron',
    app_rate_1: 86.67,
    avg_round_1: 3272,
    app_flat_1: 9,
    char_2: 'ruan-mei',
    app_rate_2: 26.67,
    avg_round_2: 3278,
    app_flat_2: 1,
    char_3: 'Gallagher',
    app_rate_3: 26.67,
    avg_round_3: 3240,
    app_flat_3: 4,
    char_4: 'Pela',
    app_rate_4: 26.67,
    avg_round_4: 3178,
    app_flat_4: 4,
    char_5: 'Jiaoqiu',
    app_rate_5: 26.67,
    avg_round_5: 3386,
    app_flat_5: 4,
    char_6: 'Aventurine',
    app_rate_6: 20.0,
    avg_round_6: 3363,
    app_flat_6: 4,
    char_7: 'fu-xuan',
    app_rate_7: 16.67,
    avg_round_7: 3123,
    app_flat_7: 1,
    char_8: 'Sparkle',
    app_rate_8: 13.33,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 10.0,
    avg_round_9: 3278,
    app_flat_9: 1,
    char_10: 'Luocha',
    app_rate_10: 10.0,
    avg_round_10: 3239,
    app_flat_10: 1,
    char_11: 'Guinaifen',
    app_rate_11: 6.67,
    avg_round_11: 3188,
    app_flat_11: 1,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 6.67,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Gepard',
    app_rate_13: 3.33,
    avg_round_13: 3188,
    app_flat_13: 1,
    char_14: 'Kafka',
    app_rate_14: 3.33,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Asta',
    app_rate_15: 3.33,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Bronya',
    app_rate_16: 3.33,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'dr-ratio',
    app_rate_17: 3.33,
    avg_round_17: 3239,
    app_flat_17: 1,
    char_18: 'Robin',
    app_rate_18: 3.33,
    avg_round_18: 3239,
    app_flat_18: 1,
    char_19: 'black-swan',
    app_rate_19: 3.33,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    round: 0,
    char_1: 'silver-wolf',
    app_rate_1: 100.0,
    avg_round_1: 3098,
    app_flat_1: 3,
    char_2: 'Sparkle',
    app_rate_2: 100.0,
    avg_round_2: 3098,
    app_flat_2: 3,
    char_3: 'fu-xuan',
    app_rate_3: 100.0,
    avg_round_3: 3098,
    app_flat_3: 3,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    round: 0,
    char_1: 'Robin',
    app_rate_1: 66.67,
    avg_round_1: 3281,
    app_flat_1: 8,
    char_2: 'ruan-mei',
    app_rate_2: 37.5,
    avg_round_2: 3249,
    app_flat_2: 5,
    char_3: 'march-7th-swordmaster',
    app_rate_3: 37.5,
    avg_round_3: 3252,
    app_flat_3: 5,
    char_4: 'Huohuo',
    app_rate_4: 25.0,
    avg_round_4: 3248,
    app_flat_4: 4,
    char_5: 'Luocha',
    app_rate_5: 25.0,
    avg_round_5: 3288,
    app_flat_5: 3,
    char_6: 'fu-xuan',
    app_rate_6: 20.83,
    avg_round_6: 3195,
    app_flat_6: 2,
    char_7: 'Bronya',
    app_rate_7: 16.67,
    avg_round_7: 3195,
    app_flat_7: 2,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 12.5,
    avg_round_8: 3261,
    app_flat_8: 2,
    char_9: 'Gallagher',
    app_rate_9: 12.5,
    avg_round_9: 3306,
    app_flat_9: 3,
    char_10: 'Yunli',
    app_rate_10: 12.5,
    avg_round_10: 3115,
    app_flat_10: 1,
    char_11: 'Luka',
    app_rate_11: 8.33,
    avg_round_11: 3233,
    app_flat_11: 2,
    char_12: 'Boothill',
    app_rate_12: 4.17,
    avg_round_12: 3143,
    app_flat_12: 1,
    char_13: 'Aventurine',
    app_rate_13: 4.17,
    avg_round_13: 3143,
    app_flat_13: 1,
    char_14: 'march-7th',
    app_rate_14: 4.17,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Tingyun',
    app_rate_15: 4.17,
    avg_round_15: 3236,
    app_flat_15: 1,
    char_16: 'Natasha',
    app_rate_16: 4.17,
    avg_round_16: 3236,
    app_flat_16: 1,
    char_17: 'Misha',
    app_rate_17: 4.17,
    avg_round_17: 3456,
    app_flat_17: 1,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    round: 0,
    char_1: 'imbibitor-lunae',
    app_rate_1: 71.43,
    avg_round_1: 3250,
    app_flat_1: 1,
    char_2: 'Sparkle',
    app_rate_2: 42.86,
    avg_round_2: 3250,
    app_flat_2: 1,
    char_3: 'Luocha',
    app_rate_3: 28.57,
    avg_round_3: 3250,
    app_flat_3: 1,
    char_4: 'Tingyun',
    app_rate_4: 21.43,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Gallagher',
    app_rate_5: 21.43,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Feixiao',
    app_rate_6: 14.29,
    avg_round_6: 3434,
    app_flat_6: 2,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 14.29,
    avg_round_7: 3434,
    app_flat_7: 2,
    char_8: 'Aventurine',
    app_rate_8: 14.29,
    avg_round_8: 3475,
    app_flat_8: 1,
    char_9: 'ruan-mei',
    app_rate_9: 14.29,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'fu-xuan',
    app_rate_10: 7.14,
    avg_round_10: 3393,
    app_flat_10: 1,
    char_11: 'Bailu',
    app_rate_11: 7.14,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Robin',
    app_rate_12: 7.14,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Acheron',
    app_rate_13: 7.14,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Guinaifen',
    app_rate_14: 7.14,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Jiaoqiu',
    app_rate_15: 7.14,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'dr-ratio',
    app_rate_16: 7.14,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'topaz',
    app_rate_17: 7.14,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    round: 0,
    char_1: 'ruan-mei',
    app_rate_1: 55.56,
    avg_round_1: 3398,
    app_flat_1: 4,
    char_2: 'Feixiao',
    app_rate_2: 38.89,
    avg_round_2: 3484,
    app_flat_2: 3,
    char_3: 'Robin',
    app_rate_3: 38.89,
    avg_round_3: 3572,
    app_flat_3: 2,
    char_4: 'Bronya',
    app_rate_4: 22.22,
    avg_round_4: 3376,
    app_flat_4: 2,
    char_5: 'topaz',
    app_rate_5: 22.22,
    avg_round_5: 3423,
    app_flat_5: 2,
    char_6: 'Yanqing',
    app_rate_6: 16.67,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Jingliu',
    app_rate_7: 16.67,
    avg_round_7: 3297,
    app_flat_7: 1,
    char_8: 'Firefly',
    app_rate_8: 11.11,
    avg_round_8: 3242,
    app_flat_8: 1,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 11.11,
    avg_round_9: 3242,
    app_flat_9: 1,
    char_10: 'Boothill',
    app_rate_10: 11.11,
    avg_round_10: 3445,
    app_flat_10: 1,
    char_11: 'Misha',
    app_rate_11: 11.11,
    avg_round_11: 3297,
    app_flat_11: 1,
    char_12: 'Clara',
    app_rate_12: 5.56,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Moze',
    app_rate_13: 5.56,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Sushang',
    app_rate_14: 5.56,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Tingyun',
    app_rate_15: 5.56,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Sparkle',
    app_rate_16: 5.56,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Acheron',
    app_rate_17: 5.56,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Kafka',
    app_rate_18: 5.56,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'black-swan',
    app_rate_19: 5.56,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    round: 0,
    char_1: 'Sparkle',
    app_rate_1: 40.91,
    avg_round_1: 3255,
    app_flat_1: 3,
    char_2: 'Robin',
    app_rate_2: 36.36,
    avg_round_2: 3305,
    app_flat_2: 3,
    char_3: 'Tingyun',
    app_rate_3: 31.82,
    avg_round_3: 3258,
    app_flat_3: 2,
    char_4: 'Huohuo',
    app_rate_4: 27.27,
    avg_round_4: 3287,
    app_flat_4: 2,
    char_5: 'Aventurine',
    app_rate_5: 22.73,
    avg_round_5: 3266,
    app_flat_5: 2,
    char_6: 'ruan-mei',
    app_rate_6: 22.73,
    avg_round_6: 3297,
    app_flat_6: 1,
    char_7: 'fu-xuan',
    app_rate_7: 18.18,
    avg_round_7: 3186,
    app_flat_7: 1,
    char_8: 'Pela',
    app_rate_8: 13.64,
    avg_round_8: 3312,
    app_flat_8: 2,
    char_9: 'Luocha',
    app_rate_9: 9.09,
    avg_round_9: 3456,
    app_flat_9: 1,
    char_10: 'Herta',
    app_rate_10: 9.09,
    avg_round_10: 3186,
    app_flat_10: 1,
    char_11: 'Jiaoqiu',
    app_rate_11: 9.09,
    avg_round_11: 3343,
    app_flat_11: 1,
    char_12: 'march-7th',
    app_rate_12: 9.09,
    avg_round_12: 3297,
    app_flat_12: 1,
    char_13: 'Bronya',
    app_rate_13: 9.09,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Gallagher',
    app_rate_14: 4.55,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Acheron',
    app_rate_15: 4.55,
    avg_round_15: 3343,
    app_flat_15: 1,
    char_16: 'Natasha',
    app_rate_16: 4.55,
    avg_round_16: 3325,
    app_flat_16: 1,
    char_17: 'Yanqing',
    app_rate_17: 4.55,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 4.55,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 4.55,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Lynx',
    app_rate_20: 4.55,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Sushang',
    app_rate_21: 4.55,
    avg_round_21: 3456,
    app_flat_21: 1,
    char_22: 'Jingliu',
    app_rate_22: 4.55,
    avg_round_22: 3297,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 0,
    char_1: 'Acheron',
    app_rate_1: 100.0,
    avg_round_1: 3194,
    app_flat_1: 1,
    char_2: 'Pela',
    app_rate_2: 42.86,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'black-swan',
    app_rate_3: 28.57,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Jiaoqiu',
    app_rate_4: 28.57,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'silver-wolf',
    app_rate_5: 28.57,
    avg_round_5: 3194,
    app_flat_5: 1,
    char_6: 'Gallagher',
    app_rate_6: 14.29,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Sparkle',
    app_rate_7: 14.29,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: 14.29,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Bronya',
    app_rate_9: 14.29,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Sampo',
    app_rate_10: 14.29,
    avg_round_10: 3194,
    app_flat_10: 1,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    round: 0,
    char_1: 'Gallagher',
    app_rate_1: 58.33,
    avg_round_1: 3345,
    app_flat_1: 6,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 50.0,
    avg_round_2: 3355,
    app_flat_2: 5,
    char_3: 'ruan-mei',
    app_rate_3: 33.33,
    avg_round_3: 3320,
    app_flat_3: 3,
    char_4: 'Sparkle',
    app_rate_4: 33.33,
    avg_round_4: 3305,
    app_flat_4: 1,
    char_5: 'Tingyun',
    app_rate_5: 33.33,
    avg_round_5: 3367,
    app_flat_5: 2,
    char_6: 'Huohuo',
    app_rate_6: 16.67,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: 8.33,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Robin',
    app_rate_8: 8.33,
    avg_round_8: 3429,
    app_flat_8: 1,
    char_9: 'Boothill',
    app_rate_9: 8.33,
    avg_round_9: 3362,
    app_flat_9: 1,
    char_10: 'Bailu',
    app_rate_10: 8.33,
    avg_round_10: 3362,
    app_flat_10: 1,
    char_11: 'Acheron',
    app_rate_11: 8.33,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 8.33,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 8.33,
    avg_round_13: 3376,
    app_flat_13: 1,
    char_14: 'Kafka',
    app_rate_14: 8.33,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'black-swan',
    app_rate_15: 8.33,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    round: 0,
    char_1: 'Robin',
    app_rate_1: 83.33,
    avg_round_1: 3104,
    app_flat_1: 3,
    char_2: 'march-7th-swordmaster',
    app_rate_2: 50.0,
    avg_round_2: 3120,
    app_flat_2: 2,
    char_3: 'ruan-mei',
    app_rate_3: 33.33,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'fu-xuan',
    app_rate_4: 33.33,
    avg_round_4: 3115,
    app_flat_4: 1,
    char_5: 'Huohuo',
    app_rate_5: 33.33,
    avg_round_5: 3126,
    app_flat_5: 1,
    char_6: 'Tingyun',
    app_rate_6: 16.67,
    avg_round_6: 3072,
    app_flat_6: 1,
    char_7: 'Aventurine',
    app_rate_7: 16.67,
    avg_round_7: 3072,
    app_flat_7: 1,
    char_8: 'Bronya',
    app_rate_8: 16.67,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Herta',
    app_rate_9: 16.67,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    round: 0,
    char_1: 'Gallagher',
    app_rate_1: 75.0,
    avg_round_1: 3476,
    app_flat_1: 1,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 50.0,
    avg_round_2: 3476,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: 50.0,
    avg_round_3: 3476,
    app_flat_3: 1,
    char_4: 'Robin',
    app_rate_4: 25.0,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Sparkle',
    app_rate_5: 25.0,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Acheron',
    app_rate_6: 25.0,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Jiaoqiu',
    app_rate_7: 25.0,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Pela',
    app_rate_8: 25.0,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Arlan',
    round: 0,
    char_1: 'Bronya',
    app_rate_1: 100.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Sparkle',
    app_rate_2: 50.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: 50.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Robin',
    app_rate_4: 50.0,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'fu-xuan',
    app_rate_5: 50.0,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    round: 0,
    char_1: 'Yunli',
    app_rate_1: 50.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Tingyun',
    app_rate_2: 50.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Huohuo',
    app_rate_3: 50.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'silver-wolf',
    app_rate_4: 50.0,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Sparkle',
    app_rate_5: 50.0,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'fu-xuan',
    app_rate_6: 50.0,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
